import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Image } from 'primereact/image';
import dayjs from "dayjs";
import { dayjs as dayjs_custom } from "../../../../utils/dayjs_custom";
import QueryString from "qs";
import { ButtonCustom, ButtonPrimary } from '../../../../components/Button';
import { LoadingFloat } from '../../../../components/Loading';
import VideoPlayer from '../../../../components/Modal/VideoPlayer';
import { useCustomState } from "../../../../hooks/useCustomState";
import { getVimeoVideoId } from '../../../../utils/getDataVimeoApi';
import { colors } from "../../../../config/colors";
import { optionsToast } from "../../../../config/toast";
import { deleteWorkout } from "../../../../api/admin/calendarios";
import parseErrorMessage from "../../../../utils/parseErrorMessage";
import CalendarOverlay from "../../../../components/Calendario";

const prefix = '/admin/calendarios/asignar-workout';

const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

const NombreWorkout = ({ calendarDayID, calendarWeekID, day, data, startDate, isWeek, startDateDayWorkout, onChangeDate }) => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    isLoading: false,
    isVideoVisible: false,
    id_video: null,
    filtrar_fecha: null,
    deleted_items: [],
  });

  const filterData = data?.filter(item => !state.deleted_items?.includes(item?.id));
  const date = startDate ? dayjs_custom(startDate, { dayjs })?.endOf("month") : new Date();
  const dateSelected = startDateDayWorkout && startDateDayWorkout[startDateDayWorkout?.findIndex(item => item?.day === day)];
  const params_url = QueryString.stringify({
    calendarDayID: calendarDayID,
    calendarWeekID: calendarWeekID,
    dayWeek: day,
    isWeek: isWeek,
  });

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onRemove = useCallback(async (item) => {
    const deleted_items = [...state.deleted_items];
    try {
      deleted_items.push(item?.id);
      setState({ deleted_items, isLoading: true });

      const response = await deleteWorkout(item?.id, { isWeek: isWeek === true ? "true" : false });
      if (response?.status === true) {
        message(null, "El workout ha sido eliminado.");
        return setState({ isLoading: false });
      } else {
        message(null, "Ocurrio un error al eliminar el workout.");
      }
    } catch (error) {
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al asignar los workouts. Por favor, inténtalo de nuevo más tarde.');
    }
    return setState({
      deleted_items: deleted_items?.filter(elem => elem !== item?.id),
      isLoading: false
    });
  }, [state.deleted_items, isWeek, setState]);

  return (
    <>
      <VideoPlayer
        url={state?.id_video && getVimeoVideoId(state.id_video, true)}
        visible={state.isVideoVisible}
        onHide={() => setState({ isVideoVisible: false })}
        propsVideo={{
          controls: true,
        }}
        propsContainer={{
          style: {
            height: 360
          }
        }}
      />
      {state.isLoading && (<LoadingFloat />)}
      <div className='flex flex-col bg-white rounded-xl'>
        <div className='flex flex-row items-center justify-between bg-Crayola p-4 rounded-t-xl'>
          <p className='flex-1 font-golos-semibold uppercase text-white whitespace-nowrap text-center'>{isWeek ? `SEMANA ${day}` : dayjs_custom(date, { dayjs }).day(day).format("dddd")}</p>
          {!isWeek && (
            <div>
              <CalendarOverlay
                labelButton={dateSelected?.date && dayjs_custom(dateSelected?.date, { dayjs }).format("YYYY-MM-DD")}
                showDate={true}
                notDisabledDays={[day]}
                // minDate={dayjs(startDate).startOf("month").add(1, "day").toDate()}
                // maxDate={dayjs(startDate).endOf("month").subtract(1, "day").toDate()}
                viewDate={dayjs_custom(startDate, { dayjs }).toDate()}
                onChangeDate={(e) => onChangeDate(e, day)}
              />
            </div>
          )}
        </div>
        {filterData?.length > 0 ? (
          <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 max-h-96 overflow-auto p-4 gap-4">
            {filterData?.map((item, index) => (
              <div key={`workout-id-${item?.id || index}`} className='col-span-1 bg-white border border-gris-F7F7F7 rounded-lg'>
                <div className="flex items-center justify-between border-b py-1 px-2">
                  {!isWeek && (
                    <p className='font-golos-medium text-Crayola text-sm'>{item?.startDate ? dayjs_custom(item?.startDate, { dayjs }).format("DD MMM YYYY") : ""}</p>
                  )}
                  <div className="inline-flex ml-auto items-center cursor-pointer p-1.5" onClick={() => onRemove(item)}>
                    <i className="pi pi-times-circle" style={{ color: colors.Crayola }} />
                  </div>
                </div>
                <div className="flex flex-col items-center gap-4 py-6 px-4">
                  <div className='flex flex-col justify-center items-center text-center'>
                    <p className='!leading-3 text-sm'>Rutina</p>
                    <p className='font-golos-medium text-Crayola text-md'>{item?.Workout?.title ? item?.Workout?.title : "Desconocida"}</p>
                  </div>
                  {item?.Workout?.image && (
                    <div className='mx-auto'>
                      <Image
                        src={item?.Workout?.image}
                        imageClassName="bg-black object-cover w-[90px] rounded-xl h-[90px] select-none pointer-events-none m-auto"
                        onError={(event) => {
                          event.target.onerror = null; // previene un bucle infinito
                          event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                        }}
                      />
                    </div>
                  )}
                  <div className='mx-auto'>
                    <ButtonPrimary
                      text="Ver vídeo"
                      classNameButton={"!px-8 !py-1.5 text-xs"}
                      onClick={() => setState({ id_video: item?.Workout?.idVideo, isVideoVisible: true })}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-1 items-center justify-center w-full p-6">
            <p className="font-golos-medium text-center text-Crayola">No tiene workouts asignados para {isWeek ? "esta semana" : "este día"}.</p>
          </div>
        )}
        <div className="border-t py-4 px-6">
          <ButtonCustom
            text="Asignar workout"
            classNameContainer="flex justify-end"
            classNameButton="inline-flex whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
            onClick={() => navigate(`${prefix}?${params_url}`)}
          />
        </div>
      </div>
    </>
  );
}

export default NombreWorkout;