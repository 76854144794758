import React from 'react';
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const TerminosCondiciones = () => {
  return (
    <>
      <SEO
        title="Términos y Condiciones"
        description="Lea estos términos y condiciones detenidamente antes de utilizar Nuestro Servicio."
      />
      <div className={`bg-white`}>
        <HeaderNav
          theme="white"
        />
      </div>

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto mt-20 mb-2 py-10">
          <div className='flex justify-center relative px-6 w-full'>
            <div className='w-full bg-white color-[#676a78] py-10 px-6 rounded-xl shadow-md shadow-DeepKoamaru/16'>
              <div id="contentDocument" className='break-word'>
                <p style={{ marginBottom: "14pt", lineHeight: "normal", fontSize: "24pt" }}>
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    TERMINOS Y CONDICIONES
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Última actualización: 2/ Febrero/2024
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Lea estos términos y condiciones detenidamente antes de utilizar Nuestro
                    Servicio.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    INTERPRETACION Y DEFINICIONES
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>Interpretación</span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Las palabras cuya letra inicial está en mayúscula tienen significados
                    definidos en las siguientes condiciones. Las siguientes definiciones
                    tendrán el mismo significado independientemente de que aparezcan en
                    singular o en plural.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    Definiciones
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Para efectos de términos y condiciones
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Afiliado significa una entidad que controla, está controlada por o está
                    bajo control común con una parte, donde "control" significa la propiedad
                    del 50% o más de las acciones, participación accionaria u otros valores
                    con derecho a voto para la elección de directores u otros administradores.
                    autoridad.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Cuenta significa una cuenta única creada para que usted acceda a nuestro
                    Servicio o partes de nuestro Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    País se refiere a: Texas, Estados Unidos
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Compañía (denominada "la Compañía", "Nosotros", "Nos" o "Nuestro" en este
                    Acuerdo) se refiere a Jime Fit Mom, 2804 E Bluebonnet Ln.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Dispositivo significa cualquier dispositivo que pueda acceder al Servicio,
                    como una computadora, un teléfono celular o una tableta digital.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Retroalimentación significa retroalimentación, innovaciones o sugerencias
                    enviadas por Usted con respecto a los atributos, desempeño o
                    características de nuestro Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Prueba gratuita se refiere a un período de tiempo limitado que puede ser
                    gratuito al comprar una Suscripción.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Los bienes se refieren a los artículos que se ofrecen a la venta en el
                    Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Órdenes significa una solicitud por su parte para comprarnos Productos.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Las promociones se refieren a concursos, sorteos u otras promociones que
                    se ofrecen a través del Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Servicio se refiere al sitio web.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Las suscripciones se refieren a los servicios o al acceso al Servicio que
                    la Compañía le ofrece a Usted mediante suscripción.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Términos y condiciones (también denominados "Términos") se refieren a
                    estos Términos y condiciones que forman el acuerdo completo entre Usted y
                    la Compañía con respecto al uso del Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Servicio de redes sociales de terceros significa cualquier servicio o
                    contenido (incluidos datos, información, productos o servicios)
                    proporcionado por un tercero que puede ser mostrado, incluido o puesto a
                    disposición por el Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    El sitio web se refiere a Jime Fit Mom, accesible desde
                    https://jimefitmom.com
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Usted se refiere a la persona que accede o utiliza el Servicio, o la
                    empresa u otra entidad legal en nombre de la cual dicha persona accede o
                    utiliza el Servicio, según corresponda.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    AUTONOCONOCIMIENTO
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Estos son los Términos y Condiciones que rigen el uso de este Servicio y
                    el acuerdo que opera entre Usted y la Compañía. Estos Términos y
                    Condiciones establecen los derechos y obligaciones de todos los usuarios
                    con respecto al uso del Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Su acceso y uso del Servicio está condicionado a su aceptación y
                    cumplimiento de estos Términos y Condiciones. Estos Términos y Condiciones
                    se aplican a todos los visitantes, usuarios y otras personas que acceden o
                    utilizan el Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos
                    Términos y Condiciones. Si no está de acuerdo con alguna parte de estos
                    Términos y condiciones, no podrá acceder al Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Usted declara que es mayor de 18 años. La Compañía no permite que menores
                    de 18 años utilicen el Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Su acceso y uso del Servicio también está condicionado a Su aceptación y
                    cumplimiento de la Política de Privacidad de la Compañía. Nuestra Política
                    de privacidad describe Nuestras políticas y procedimientos sobre la
                    recopilación, el uso y la divulgación de Su información personal cuando
                    utiliza la Aplicación o el Sitio web y le informa sobre Sus derechos de
                    privacidad y cómo la ley lo protege. Lea nuestra Política de privacidad
                    detenidamente antes de utilizar nuestro servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    REALIZACION DE PEDIDOS
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Al realizar un Pedido de Productos a través del Servicio, usted garantiza
                    que es legalmente capaz de celebrar contratos vinculantes.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    Tu Información
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si desea realizar un Pedido de Productos disponibles en el Servicio, es
                    posible que se le solicite que proporcione cierta información relevante
                    para Su Pedido, que incluye, entre otros, Su nombre, Su correo
                    electrónico, Su número de teléfono, Su número de tarjeta de crédito, la
                    fecha de vencimiento de Su tarjeta de crédito, su dirección de facturación
                    y su información de envío.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Usted declara y garantiza que: (i) tiene el derecho legal de utilizar
                    cualquier tarjeta de crédito o débito u otro método de pago en relación
                    con cualquier Pedido; y que (ii) la información que nos proporciona es
                    verdadera, correcta y completa.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Al enviar dicha información, nos otorga el derecho a proporcionar la
                    información a terceros que procesan pagos con el fin de facilitar la
                    finalización de su pedido.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Cancelación de una orden
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nos reservamos el derecho de rechazar o cancelar su pedido en cualquier
                    momento por ciertas razones, que incluyen, entre otras, las siguientes:
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • Disponibilidad de bienes
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • Errores en la descripción o precios de los Bienes
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • Errores en su pedido
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    •Fraude o transacción no autorizada
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    DERECHOS DE CANCELACION DE UNA ORDEN
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Cualquier Producto que compre solo puede devolverse de acuerdo con estos
                    Términos y condiciones y Nuestra Política de devoluciones.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nuestra Política de devoluciones forma parte de estos Términos y
                    condiciones. Lea nuestra Política de devoluciones para obtener más
                    información sobre su derecho a cancelar su pedido.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Su derecho a cancelar un Pedido solo se aplica a los Bienes que se
                    devuelven en las mismas condiciones en que los recibió. También debe
                    incluir todas las instrucciones, documentos y envoltorios de los
                    productos. No se reembolsarán los productos que estén dañados o que no
                    estén en las mismas condiciones en que los recibió o que se hayan usado
                    simplemente después de abrir el embalaje original. Por lo tanto, debe
                    cuidar razonablemente los Productos comprados mientras estén en Su
                    posesión.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Le reembolsaremos a más tardar 14 días a partir del día en que recibamos
                    los Productos devueltos. Usaremos el mismo medio de pago que utilizó para
                    el Pedido y no incurrirá en ningún cargo por dicho reembolso.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    No tendrá derecho a cancelar un Pedido por el suministro de cualquiera de
                    los siguientes productos:
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • El suministro de Bienes confeccionados según Sus especificaciones o
                    claramente personalizados.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • El suministro de Bienes que por su naturaleza no sean aptos para ser
                    devueltos, se deterioren rápidamente o cuando la fecha de caducidad haya
                    terminado.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • El suministro de Bienes que no son aptos para devolución por razones de
                    protección de la salud o higiene y que fueron abiertos después de la
                    entrega.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • El suministro de Bienes que, después de la entrega, según su naturaleza,
                    se mezclan inseparablemente con otros artículos.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    • El suministro de contenido digital que no se proporciona en un medio
                    tangible si la ejecución ha comenzado con su previo consentimiento expreso
                    y ha reconocido la pérdida del derecho de cancelación.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Disponibilidad, errores e inexactitudes
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Actualizamos constantemente Nuestras ofertas de Productos en el Servicio.
                    Los Productos disponibles en Nuestro Servicio pueden tener un precio
                    incorrecto, describirse de manera inexacta o no estar disponibles, y es
                    posible que experimentemos retrasos en la actualización de la información
                    sobre nuestros Productos en el Servicio y en Nuestra publicidad en otros
                    sitios web.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    No podemos garantizar y no garantizamos la precisión o integridad de
                    ninguna información, incluidos precios, imágenes de productos,
                    especificaciones, disponibilidad y servicios. Nos reservamos el derecho de
                    cambiar o actualizar la información y corregir errores, inexactitudes u
                    omisiones en cualquier momento sin previo aviso.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    POLITICA DE PRECIOS
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Compañía se reserva el derecho de revisar sus precios en cualquier
                    momento antes de aceptar un Pedido.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Los precios cotizados pueden ser revisados ​​por la Compañía después de
                    aceptar un Pedido en caso de que ocurra algún incidente que afecte la
                    entrega debido a una acción gubernamental, variación en los aranceles
                    aduaneros, aumento de los gastos de envío, mayores costos de cambio de
                    divisas y cualquier otro asunto fuera del control de la Compañía. En ese
                    caso, tendrá derecho a cancelar su pedido
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>Pagos</span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Todos los Productos comprados están sujetos a un pago único. El pago se
                    puede realizar a través de varios métodos de pago que tenemos disponibles,
                    como Visa, MasterCard, Affinity Card, tarjetas American Express o métodos
                    de pago online (PayPal, por ejemplo).
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Las tarjetas de pago (tarjetas de crédito o débito) están sujetas a
                    verificaciones de validación y autorización por parte del emisor de su
                    tarjeta. Si no recibimos la autorización requerida, no seremos
                    responsables de ningún retraso falta de entrega o cancelación de su pedido
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    SUSCRIPCIONES
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Periodo de Suscripción
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    El Servicio o algunas partes del Servicio están disponibles solo con una
                    Suscripción paga. Se le facturará por adelantado de forma periódica y
                    periódica (por ejemplo, diaria, semanal, mensual, semestral o anual),
                    según el tipo de plan de suscripción que seleccione al comprar la
                    suscripción.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Al final de cada período, Su Suscripción se renovará automáticamente bajo
                    las mismas condiciones exactas a menos que la cancele o la Compañía la
                    cancele.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    CANCELACION DE SUSCRIPCION
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Puede cancelar la renovación de su suscripción a través de la página de
                    configuración de su cuenta o comunicándose con la empresa. No recibirá un
                    reembolso por las tarifas que ya pagó durante su período de suscripción
                    actual y podrá acceder al servicio hasta el final de su período de
                    suscripción actual. En caso de que su membresía este por renovarse debe
                    cancelar al menos 24 horas antes para evitar que el cobro pase, una vez
                    que el cobro es procesado no habra reembolsos ni devoluciones.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    FACTURACION
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Deberá proporcionar a la Compañía información de facturación precisa y
                    completa, incluido el nombre completo, la dirección, el estado, el código
                    postal, el número de teléfono y la información de un método de pago
                    válido.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    En caso de que la facturación automática no se produzca por cualquier
                    motivo, la Compañía emitirá una factura electrónica indicando que debe
                    proceder manualmente, dentro de una fecha límite determinada, con el pago
                    total correspondiente al período de facturación indicado en la factura.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    CARGOS POR SUSCRIPCION
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Compañía, a su sola discreción y en cualquier momento, puede modificar
                    las tarifas de Suscripción. Cualquier cambio en la tarifa de suscripción
                    entrará en vigencia al final del período de suscripción vigente en ese
                    momento.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Compañía le proporcionará un aviso previo razonable de cualquier cambio
                    en las tarifas de Suscripción para darle la oportunidad de rescindir Su
                    Suscripción antes de que dicho cambio entre en vigencia.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Su uso continuado del Servicio después de que el cambio de tarifa de
                    suscripción entre en vigencia constituye su acuerdo de pagar el monto de
                    la tarifa de suscripción modificada.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>Reembolsos</span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Excepto cuando lo exija la ley, las tarifas de suscripción pagadas no son
                    reembolsables.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Compañía puede considerar ciertas solicitudes de reembolso para
                    Suscripciones caso por caso y otorgarlas a discreción exclusiva de la
                    Compañía.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Cuando se haya suscrito a la Plataforma a través de nuestro sitio web,
                    puede cambiar de opinión y recibir un reembolso completo del monto que
                    haya pagado por la suscripción dentro de los catorce (14) días a partir
                    del día en que se suscribió a la Plataforma a través de nuestro sitio web
                    solo si se cumplen la siguientes condiciones:
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    1) El usuario se registra para una prueba gratuita, y dentro de esos días
                    no utilizo la plataforma por al menos 2 días y pasado el periodo de prueba
                    se hizo el cobro.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    2) Si el usuario cambia de plan y en los primeros 7 dias, se hizo el cobro
                    de su membresía anterior, podrá ser candidato a reembolso, una vez
                    suscrito al otro plan por lo que el equipo procederá hacer el reembolso de
                    sus membresía anterior en los próximos 7 dias y hacer el cobro de su
                    membresía actual.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    En estos casos se pierde el derecho a reembolso si se cumplen las
                    siguientes condiciones:
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Se pierde el derecho a reembolso si el usuario utilizo su periodo de
                    prueba al menos 3 días y olvida cancelar la membresía posterior al cobro
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Se pierde el derecho a reembolso si el usuario cambio de membresía en un
                    mismo mes y han pasado 7 días de cobro de la membresía anterior
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Para ejercer este derecho, comuníquese con el equipo de JIMEFITMOM
                    utilizando los detalles establecidos en la Cláusula 9 de estos Términos de
                    uso.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Después de la expiración del Período de prueba, las suscripciones no son
                    reembolsables y no hay derecho a reembolsos o créditos a menos que lo
                    exijan las leyes aplicables. Aunque puede notificarnos su intención de
                    cancelar su suscripción en cualquier momento, dicho aviso solo entrará en
                    vigencia al final de su período de suscripción actual y no recibirá un
                    reembolso
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    PRUEBA GRATUITA
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Compañía puede, a su entera discreción, ofrecer una Suscripción con una
                    prueba gratuita por un período de tiempo limitado.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Es posible que deba ingresar su información de facturación para
                    inscribirse en la prueba gratuita.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    El último día del período de prueba gratuito, a menos que cancele su
                    suscripción, se le cobrarán automáticamente las tarifas de suscripción
                    correspondientes al tipo de suscripción que haya seleccionado.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    En cualquier momento y sin previo aviso, la Compañía se reserva el derecho
                    de (i) modificar los términos y condiciones de la oferta de prueba
                    gratuita, o (ii) cancelar dicha oferta de prueba gratuita.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Al utilizar códigos cupón dentro de nuestra plataforma se anula el periodo
                    de prueba gratuita.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    El periodo de prueba gratuita solo aplica una sola vez en cualquiera de
                    los planes por usuario, si el usuario ya ha tomado una prueba gratuita
                    antes, al reinscribirse se le cobrara las tarifas del plan seleccionado
                    ese mismo día{" "}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    PROMOCIONES
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Cualquier Promoción disponible a través del Servicio puede regirse por
                    reglas independientes de estos Términos.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si participa en alguna promoción, revise las reglas aplicables, así como
                    nuestra política de privacidad. Si las reglas de una Promoción entran en
                    conflicto con estos Términos, se aplicarán las reglas de la Promoción
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    UPGRADE O CAMBIOS DE PLANES
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Al hacer un cambio de plan en cualquiera de nuestra oferta de planes, el
                    cobro del siguiente plan se hará hasta que el periodo del plan anterior
                    haya finalizado. Sin embargo tendrá acceso al “nuevo plan” desde el
                    momento en que ejecuta la orden de cambio{" "}
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', AwImport: "ignore" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    CUENTA DE USUARIO
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Cuando crea una cuenta con Nosotros, debe proporcionarnos información
                    precisa, completa y actualizada en todo momento. El no hacerlo constituye
                    un incumplimiento de los Términos, lo que puede resultar en la cancelación
                    inmediata de Su cuenta en Nuestro Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Usted es responsable de proteger la contraseña que utiliza para acceder al
                    Servicio y de cualquier actividad o acción bajo Su contraseña, ya sea que
                    Su contraseña sea de Nuestro Servicio o de un Servicio de redes sociales
                    de terceros.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Acepta no revelar su contraseña a ningún tercero. Debe notificarnos
                    inmediatamente cuando tenga conocimiento de cualquier violación de
                    seguridad o uso no autorizado de su cuenta.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    No puede usar como nombre de usuario el nombre de otra persona o entidad o
                    que no esté legalmente disponible para su uso, un nombre o marca comercial
                    que esté sujeta a cualquier derecho de otra persona o entidad que no sea
                    usted sin la debida autorización, o un nombre que sea de otro modo
                    ofensivo, vulgar u obsceno.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    PROPIEDAD INTELECTUAL
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    El Servicio y su contenido original (excluido el Contenido proporcionado
                    por Usted u otros usuarios), las características y la funcionalidad son y
                    seguirán siendo propiedad exclusiva de la Compañía y sus licenciantes.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    El Servicio está protegido por derechos de autor, marcas comerciales y
                    otras leyes tanto del país como de otros países.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nuestras marcas comerciales e imagen comercial no se pueden utilizar en
                    relación con ningún producto o servicio sin el consentimiento previo por
                    escrito de la Compañía.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    RETROALIMENTACION
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Usted asigna todos los derechos, títulos e intereses sobre cualquier
                    comentario que proporcione a la Compañía. Si por alguna razón dicha sesión
                    es ineficaz, usted acepta otorgar a la Compañía un derecho y una licencia
                    no exclusivos, perpetuos, irrevocables, libres de regalías y mundiales
                    para usar, reproducir, divulgar, sublicenciar, distribuir, modificar y
                    explotar dichos comentarios sin restricción.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    LINKS A OTROS WEBSITES
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nuestro Servicio puede contener enlaces a sitios web o servicios de
                    terceros que no son propiedad ni están controlados por la Compañía.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    La Compañía no tiene control ni asume responsabilidad por el contenido,
                    las políticas de privacidad o las prácticas de los sitios web o servicios
                    de terceros. Además, reconoce y acepta que la Compañía no será
                    responsable, directa o indirectamente, por ningún daño o pérdida causados
                    o presuntamente causados ​​por o en conexión con el uso o la confianza
                    en dicho contenido, bienes o servicios disponibles en oa través de dichos
                    sitios web o servicios.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Le recomendamos encarecidamente que lea los términos y condiciones y las
                    políticas de privacidad de los sitios web o servicios de terceros que
                    visite.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    CANCELACION
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Podemos rescindir o suspender su Cuenta de inmediato, sin previo aviso ni
                    responsabilidad, por cualquier motivo, incluido, entre otros, si incumple
                    estos Términos y condiciones.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Tras la rescisión, su derecho a utilizar el Servicio cesará de inmediato.
                    Si desea cancelar su cuenta, simplemente puede dejar de usar el servicio y
                    cancelarlo desde su cuenta, pero no recibirá reembolso por los pagos ya
                    procesados
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    LIMITACION DE RESPONSABILIDAD
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Sin perjuicio de los daños en los que pueda incurrir, la responsabilidad
                    total de la Compañía y cualquiera de sus proveedores bajo cualquier
                    disposición de estos Términos y Su recurso exclusivo para todo lo anterior
                    se limitará al monto realmente pagado por Usted a través del Servicio o
                    100 USD. si no ha comprado nada a través del Servicio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    En la máxima medida permitida por la ley aplicable, en ningún caso la
                    Compañía o sus proveedores serán responsables de ningún daño especial,
                    incidental, indirecto o consecuente de ningún tipo (incluidos, entre
                    otros, daños por lucro cesante, pérdida de datos o otra información, por
                    interrupción del negocio, por lesiones personales, pérdida de privacidad
                    que surja de o de alguna manera relacionada con el uso o la imposibilidad
                    de usar el Servicio, software de terceros y / o hardware de terceros
                    utilizado con el Servicio, o de lo contrario en relación con cualquier
                    disposición de estos Términos), incluso si la Compañía o cualquier
                    proveedor han sido informados de la posibilidad de tales daños e incluso
                    si el recurso no cumple con su propósito esencial.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Algunos estados no permiten la exclusión de garantías implícitas o la
                    limitación de responsabilidad por daños incidentales o consecuentes, lo
                    que significa que algunas de las limitaciones anteriores pueden no
                    aplicarse. En estos estados, la responsabilidad de cada parte estará
                    limitada en la mayor medida permitida por la ley.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    “TAL CUAL” Y “SEGUN DISPONIBILIDAD” CARGO DE RESPONSABILIDAD
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    El Servicio se le proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD" y con
                    todas las fallas y defectos sin garantía de ningún tipo. En la medida
                    máxima permitida por la ley aplicable, la Compañía, en su propio nombre y
                    en nombre de sus Afiliadas y sus respectivos licenciantes y proveedores de
                    servicios, renuncia expresamente a todas las garantías, ya sean expresas,
                    implícitas, estatutarias o de otro tipo, con respecto a la Servicio,
                    incluidas todas las garantías implícitas de comerciabilidad, idoneidad
                    para un propósito particular, título y no infracción, y garantías que
                    puedan surgir del curso del trato, el curso del desempeño, el uso o la
                    práctica comercial. Sin limitación a lo anterior, la Compañía no ofrece
                    garantía ni compromiso, y no hace ninguna representación de ningún tipo de
                    que el Servicio cumplirá con Sus requisitos, logrará los resultados
                    previstos, será compatible o funcionará con cualquier otro software,
                    aplicación, sistema o servicio, operará sin interrupción, cumplir con los
                    estándares de rendimiento o confiabilidad o estar libre de errores o que
                    cualquier error o defecto pueda o será corregido.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Sin perjuicio de lo anterior, ni la Compañía ni ninguno de los proveedores
                    de la compañía hacen ninguna representación o garantía de ningún tipo,
                    expresa o implícita: (i) en cuanto al funcionamiento o disponibilidad del
                    Servicio, o la información, contenido y materiales o productos. incluido
                    en el mismo; (ii) que el Servicio será ininterrumpido o libre de errores;
                    (iii) en cuanto a la precisión, confiabilidad o vigencia de cualquier
                    información o contenido proporcionado a través del Servicio; o (iv) que el
                    Servicio, sus servidores, el contenido o los correos electrónicos enviados
                    desde o en nombre de la Compañía están libres de virus, scripts, troyanos,
                    gusanos, malware, bombas de tiempo u otros componentes dañinos.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Algunas jurisdicciones no permiten la exclusión de ciertos tipos de
                    garantías o limitaciones sobre los derechos legales aplicables de un
                    consumidor, por lo que algunas o todas las exclusiones y limitaciones
                    anteriores pueden no aplicarse a usted. Pero en tal caso, las exclusiones
                    y limitaciones establecidas en esta sección se aplicarán en la mayor
                    medida exigible según la ley aplicable.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    LEY QUE RIGE
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Las leyes del País, excluyendo sus conflictos de reglas legales, regirán
                    estos Términos y Su uso del Servicio. Su uso de la Aplicación también
                    puede estar sujeto a otras leyes locales, estatales, nacionales o
                    internacionales.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    RESOLUCION DE DISPUTAS
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si tiene alguna inquietud o disputa sobre el Servicio, acepta primero
                    intentar resolver la disputa de manera informal comunicándose con la
                    Compañía.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    USUARIOS DE LA UNION EUROPEA
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si es un usuario de la Unión Europea, se beneficiará de las disposiciones
                    obligatorias de la ley del país en el que reside.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    DISPOSCIONES DEL GOBIERNO FEDERAL DE ESTADOS UNIDOS
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si usted es un usuario perteneciente al gobierno federal de los EE. UU.,
                    Nuestro Servicio es un "Artículo comercial", tal como se define ese
                    término en 48 C.F.R. §2.101.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    CUMPLIMIENTO LEGAL DE LOS ESTADOS UNIDOS
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Usted declara y garantiza que no se encuentra en un país que está sujeto
                    al embargo del gobierno de los Estados Unidos, o que ha sido designado por
                    el gobierno de los Estados Unidos como un país "que apoya al terrorismo",
                    y no está incluido en cualquier lista del gobierno de los Estados Unidos
                    de partes prohibidas o restringidas.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    DIVISIBILIDAD Y RENUNCIA
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>DIVISIBILIDAD</span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si alguna disposición de estos Términos se considera inaplicable o
                    inválida, dicha disposición se cambiará e interpretará para lograr los
                    objetivos de dicha disposición en la mayor medida posible según la ley
                    aplicable y las disposiciones restantes continuarán en pleno vigor y
                    efecto.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>RENUNCIA</span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Salvo lo dispuesto en el presente, el hecho de no ejercer un derecho o
                    exigir el cumplimiento de una obligación en virtud de estos Términos no
                    afectará la capacidad de una de las partes para ejercer dicho derecho o
                    exigir dicho cumplimiento en cualquier momento posterior, ni constituirá
                    una renuncia la renuncia a un incumplimiento. de cualquier incumplimiento
                    posterior.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    TRADUCION E INTERPRETACION
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Estos Términos y Condiciones pueden haberse traducido si los hemos puesto
                    a su disposición en nuestro Servicio. Usted acepta que el texto original
                    en inglés prevalecerá en caso de disputa.
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    CAMBIOS EN TERMINOS Y CONDICIONES
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Nos reservamos el derecho, a Nuestro exclusivo criterio, de modificar o
                    reemplazar estos Términos en cualquier momento. Si una revisión es
                    importante, haremos los esfuerzos razonables para proporcionar un aviso de
                    al menos 30 días antes de que entren en vigencia los nuevos términos. Lo
                    que constituye un cambio material se determinará a Nuestro exclusivo
                    criterio.
                  </span>
                  <br />
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Al continuar accediendo o utilizando Nuestro Servicio después de que estas
                    revisiones entren en vigencia, usted acepta estar sujeto a los términos
                    revisados. Si no está de acuerdo con los nuevos términos, en su totalidad
                    o en parte, puede cancelar su membresía en cualquier momento
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    lineHeight: "normal",
                    fontSize: "24pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"', fontWeight: "bold" }}>
                    CONTACTANOS
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "14pt",
                    lineHeight: "normal",
                    fontSize: "12pt"
                  }}
                >
                  <span style={{ fontFamily: '"Times New Roman"' }}>
                    Si tiene dudas sobre estos terminos y condiciones puede contactarnos a:
                  </span>
                  <a href="mailto:hola@jimefitmom.com" style={{ textDecoration: "none", paddingLeft: 5 }}>
                    <span className="Hyperlink" style={{ fontFamily: '"Times New Roman"', fontWeight: 500, }}>
                      hola@jimefitmom.com
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TerminosCondiciones;