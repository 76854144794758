import React, { useEffect } from "react";
import { useCustomState } from "../hooks/useCustomState";

const Promocion = () => {
  const [state, setState] = useCustomState({
    isActive: false
  });

  useEffect(() => {
    setState({ isActive: false });
  }, [setState]);

  if (state?.isActive !== true) return null;

  return (
    <div className='bg-Orchid text-DarkJungleGreen p-3'>
      <p className='font-golos-medium mb-0 text-center' style={{ fontSize: "0.930em" }}>
        Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut <a href="https://jimefitmom.com" className='text-white font-bold underline'>labore et dolore magna aliqua.</a>
      </p>
    </div>
  );
}

export default Promocion;