import { getPerfil } from '../api/client/perfil';
import { logout, updateUser } from '../redux/reducers/authSlice';
import parseErrorMessage from "./parseErrorMessage";

async function updateUserStore(_user, dispatch) {
  if (!_user || !dispatch) return false;

  if (_user?.id && _user?.email) {
    try {
      const response = await getPerfil({ id: _user?.id, email: _user?.email });
      if (response?.status === true) dispatch(updateUser({ user: response?.data }));
      return response;
    } catch (error) {
      const message = parseErrorMessage(error);
      if (["No se encontró ningún usuario con las credenciales proporcionadas"].includes(message) || error.response?.status === 401 || error.response?.status === 404) {
        dispatch(logout());
      }
      return { error, message };
    }
  } else {
    return { error: { message: "La información del usuario es requerida" } };
  }
}

export default updateUserStore;