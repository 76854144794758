import React, { memo, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { useCustomState } from '../../../hooks/useCustomState';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { createCalendary, getCalendarios } from '../../../api/admin/calendarios';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { optionsToast } from '../../../config/toast';
import CalendarioEmbarazo from './Embarazo';

const CalendarioPosparto = ({ programID, startDate }) => {
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    error: null,
    descripcion: "",
    instrucciones: "",
    listado_workouts: undefined,
  });

  useEffect(() => {
    loadData({ programID, startDate });
    // eslint-disable-next-line
  }, [programID, startDate]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const loadData = useCallback(async ({ programID, startDate }) => {
    try {
      if (state?.loading !== true) setState({ loading: true });
      const _getListadoCalendarios = await getCalendarios({ populate: ["workouts"], programID, startDate: (startDate && dayjs(startDate).format("YYYY-MM-DD")), group: "day" });
      const CalendarWeek = _getListadoCalendarios?.data?.CalendarWeek;

      setState({
        listado_workouts: CalendarWeek || null,
        descripcion: CalendarWeek?.description || "",
        instrucciones: CalendarWeek?.instructions || "",
        loading: false,
      });
    } catch (error) {
      setState({
        listado_workouts: undefined,
        descripcion: "",
        instrucciones: "",
        loading: false,
        error: parseErrorMessage(error) || null
      });
    }
    // eslint-disable-next-line
  }, [state?.loading]);

  const onCreateCalendar = useCallback(async () => {
    const _starDate = dayjs(new Date()).format("YYYY-MM-DD");

    try {
      setState({ sending: true });
      const response = await createCalendary({ programID, startDate: (startDate || _starDate) });

      setState({ sending: false });
      if (response?.status !== true) {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el calendario. Por favor, inténtalo de nuevo más tarde.');
      } else {
        loadData({ programID, startDate: (startDate || _starDate) });
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el calendario. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, [programID, startDate, loadData]);

  if (state?.error) {
    return (<p className='font-golos-medium text-center text-red-600'>{state?.error}</p>);
  } else if (startDate && state?.listado_workouts === null) {
    return (
      <div className='flex flex-col justify-center text-center gap-6'>
        <div className='font-golos-medium text-Crayola'>
          <p>Lamentablemente, no se ha encontrado ningún calendario disponible para la fecha seleccionada.</p>
          <p>Sin embargo, puedes generar un calendario específico para esta fecha haciendo clic en el siguiente botón.</p>
        </div>
        <ButtonCustom
          text="Crear calendario"
          classNameButton="inline-flex whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
          onClick={onCreateCalendar}
          loading={state?.sending}
        />
      </div>
    );
  } else if (state?.listado_workouts === null) {
    return (
      <div className='flex flex-col justify-center text-center gap-6'>
        <div className='font-golos-medium text-Crayola'>
          <p>Lamentablemente, no se ha encontrado ningún calendario disponible.</p>
          <p>Sin embargo, puedes generar un calendario específico para esta fecha haciendo clic en el siguiente botón.</p>
        </div>
        <ButtonCustom
          text="Crear calendario"
          classNameButton="inline-flex whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
          onClick={onCreateCalendar}
          loading={state?.sending}
        />
      </div>
    );
  }

  return (
    state.loading ? (
      <Loading containerClassName="h-full" />
    ) : (
      <div className='flex flex-col gap-8 divide-y divide-gray-300'>
        <div>
          <CalendarioEmbarazo programID={programID} startDate={startDate} />
        </div>
      </div>
    )
  );
};

export default memo(CalendarioPosparto, (prev, next) => (
  prev?.programID === next?.programID &&
  prev?.startDate === next?.startDate
));