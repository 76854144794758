import React, { useCallback, useContext, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import GlobalContext from '../../contexts/GlobalContext';
import { optionsToast } from '../../config/toast';
import { sendMailTestimonio } from '../../api/contacto';
import TestimonioForm from '../Form/TestimonioForm';
import { useCustomState } from '../../hooks/useCustomState';
import { isEmail, isEmpty } from '../../utils';
import parseErrorMessage from '../../utils/parseErrorMessage';

function TestimonioModal({ stylesContainer }) {
  const modalType = "testimonio";
  const { showModal, typeModal, setModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    sending: false,
    listado_asuntos: [],
    formData: {
      nombre: "",
      correo_electronico: "",
      asunto: "Testimonio",
      testimonio: "",
    },
  });

  useEffect(() => {
    (async () => {
      setState({
        listado_asuntos: [
          {
            name: "Testimonio",
            value: "Testimonio"
          },
        ]
      });
    })();
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail, life) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
      life: life,
    });
  }

  const closeModal = useCallback(() => {
    setModal(modalType);
  }, [setModal, modalType]);

  const onChange = useCallback((_state) => {
    setState(_state, 'formData');
  }, [setState]);

  const onSubmit = useCallback(async (event, tokenCaptcha) => {
    event?.preventDefault();

    try {
      if (isEmpty(state.formData?.nombre)) return message(null, "El nombre es requerido");
      if (isEmpty(state.formData?.correo_electronico)) return message(null, "El correo electrónico es requerido");
      if (!isEmail(state.formData?.correo_electronico)) return message(null, "El correo electrónico es inválido");
      if (isEmpty(state.formData?.asunto)) return message(null, "El asunto es requerido");
      if (isEmpty(state.formData?.testimonio)) return message(null, "El testimonio es requerido");
      if (isEmpty(tokenCaptcha)) return message(null, "Selecciona la verificación de ReCaptcha para continuar");

      setState({ sending: true });

      const response = await sendMailTestimonio({
        name: state.formData?.nombre,
        email: state.formData?.correo_electronico,
        subject: state.formData?.asunto,
        content: state.formData?.testimonio,
        token: tokenCaptcha
      });

      setState({ sending: false });

      if (response?.status) {
        closeModal();
        if (!isEmpty(response?.mensaje)) message('Formulario enviado', response?.mensaje);
      } else {
        message('Ocurrio un problema', response?.mensaje || 'Lo sentimos, ocurrió un error al enviar el testimonio. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al enviar el testimonio. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state.formData, closeModal, setState]);

  return (
    <Dialog
      visible={showModal && typeModal === modalType}
      onHide={closeModal}
      dismissableMask={true}
      draggable={false}
      headerClassName="!rounded-t-xl"
      contentClassName="!rounded-b-xl"
      headerStyle={{ paddingBottom: 0 }}
      style={{ marginLeft: 15, marginRight: 15 }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
    >
      <div {...stylesContainer}>
        <h2 className='text-center text-5xl text-Crayola/80 animate__animated animate__slideInLeft'>Enviar testimonio</h2>
        <p className='text-center text-sm text-DeepKoamaru/80 mt-2 animate__animated animate__slideInRight'>Completa el formulario.</p>
        <TestimonioForm
          listado_asuntos={state.listado_asuntos}
          data={{ ...state.formData, sending: state.sending }}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    </Dialog>
  );
}

export default TestimonioModal;