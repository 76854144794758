import React, { useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import Icon1 from "../assets/icons/home/icon01.svg";
import Icon2 from "../assets/icons/home/icon02.svg";
import Icon3 from "../assets/icons/home/icon03.svg";
import Icon4 from "../assets/icons/home/icon04.svg";
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { ButtonPrimary } from '../components/Button';
import VideoPlayer from '../components/Modal/VideoPlayer';
import CarouselProgramasTemplate from './components/CarouselProgramasTemplate';
import CarouselTestimoniosTemplate from './components/CarouselTestimoniosTemplate';
import EvaluacionPersonalizada from './components/EvaluacionPersonalizada';
import EspecialistaCertificada from './components/EspecialistaCertificada';
import GlobalContext from '../contexts/GlobalContext';
import { useCustomState } from '../hooks/useCustomState';
import { getCarouselProgramasItems, getCarouselTestimoniosEmbarazoItems } from '../misc/getCarouselItems';
import { responsiveOptionsCarousel } from '../utils/responsiveOptionsCarousel';
import intersectionObserver from '../utils/intersectionObserver';
import ScrollToAnchor from '../components/ScrollToAnchor';

const Index = ({ onLogout }) => {
  const elementsRef = useRef([]);
  const navigate = useNavigate();

  const { setModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    carouselProgramasItems: [],
    carouselTestimoniosItems: [],
    isVideoVisible: false,
    videoUrl: "https://player.vimeo.com/video/891950744",
    guiaInicio: {
      nombre: "",
      correo: ""
    }
  });

  useEffect(() => {
    setState({
      carouselProgramasItems: getCarouselProgramasItems(),
      carouselTestimoniosItems: getCarouselTestimoniosEmbarazoItems(),
    });

    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  // const enviarGuiaForm = useCallback((event) => {
  //   event.preventDefault();
  // }, []);

  return (
    <>
      <ScrollToAnchor />
      <SEO
        title="Página principal"
        description="Descripción de la página principal."
      />
      <VideoPlayer
        url={state.videoUrl}
        visible={state.isVideoVisible}
        onHide={() => setState({ isVideoVisible: false })}
        propsVideo={{
          controls: true,
          controlsList: "nodownload"
        }}
        propsContainer={{
          style: {
            height: "auto"
          }
        }}
      />

      <HeaderNav />
      <div className="w-full justify-between items-center animate__animated animate__fadeIn">
        <Carousel
          value={state.carouselProgramasItems}
          numVisible={1}
          numScroll={1}
          className="custom-carousel"
          circular
          showNavigators={false}
          autoplayInterval={7000}
          itemTemplate={CarouselProgramasTemplate}
        />
      </div>


      <div className="xl:container mx-auto px-6 py-4 bg-white">
        <div className='grid md:grid-cols-2 gap-14 mt-20 mb-14 justify-center'>
          <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInLeft"} className='animate__animated'>
            <img src={require('../assets/images/home/appimg.png')} alt="App Preview" className='w-full mx-auto' style={{ maxWidth: "90%" }} />
          </div>
          <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInRight"} className='animate__animated mt-12 text-center md:text-start'>
            <img src={require('../assets/images/home/appicon.png')} alt="App Preview" className='mx-auto md:mx-none' style={{ maxWidth: 64 }} />
            <h2 className='text-xl md:text-3xl lg:text-4xl text-DeepKoamaru mt-7'>Entrena donde sea</h2>
            <h2 className='text-xl md:text-3xl lg:text-4xl text-DeepKoamaru'>con la <span className='font-golos-bold'>app Jimefitmom</span></h2>
            <div className='mt-10 flex flex-wrap gap-2 items-center justify-center md:justify-start'>
              <Link to="https://apps.apple.com/mx/app/jimefitmom-pre-y-posnatal/id1615435709?l=en" target={"_blank"}>
                <img src={require('../assets/images/Appstore.png')} alt="App Download" className='object-cover h-[56px] max-w-[160px]' />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.jimena.jimefitmom&hl=en_US&gl=US" target={"_blank"}>
                <img src={require('../assets/images/Googleplay.png')} alt="App Download" className='object-cover h-[60px] max-w-[160px]' />
              </Link>
            </div>
          </div>
        </div>

        <div id="programas" className='mt-20 mb-20'>
          <h1 ref={(el) => elementsRef.current.push(el)} id="programas-de-entrenamiento" className='font-bold text-2xl text-Crayola text-center mb-2 animate__animated' data-animation={"animate__fadeInDown"}>Conoce mis programas de entrenamiento</h1>
          <p className='text-sm text-center mb-0'>Un programa para cada etapa de la maternidad</p>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-0 justify-center mt-6'>
            <div style={{ marginRight: 2 }}>
              <div>
                <Image
                  src={require('../assets/images/home/programa1-img.jpg')}
                  alt="programa1"
                  className='flex justify-center items-center mx-auto'
                  imageStyle={{ width: "90%", paddingBottom: 2.5 }} />
              </div>
              <div className='hidden md:flex relative timeline-right justify-center items-center mt-6'>
                <Image src={require('../assets/images/pin.png')} alt="programa Embarazo" className='flex items-center mx-auto' imageStyle={{ maxWidth: 20, zIndex: 1 }} />
              </div>
              <div className='mt-6 flex flex-col items-center justify-center px-4'>
                <p className='mb-2 font-golos-semibold text-Crayola text-xl text-center'>Fitmom Embarazo</p>
                <p className='text-DarkJungleGreen text-sm text-center md:min-h-[3.7rem]'>Para mujeres en embarazo o en planeación.</p>
                <ButtonPrimary text="Ver detalles" classNameContainer="mt-6 md:mt-8" onClick={() => navigate("/programas/fitmom-embarazo")} />
              </div>
            </div>
            <div>
              <div>
                <Image
                  src={require('../assets/images/home/programa2-img.jpg')}
                  alt="programa2"
                  className='flex justify-center items-center mx-auto'
                  imageStyle={{ width: "90%" }} />
              </div>
              <div className='hidden md:flex w-full items-center mt-6'>
                <div style={{ height: 1, width: "100%", borderStyle: "dashed", borderBottomWidth: 1, borderColor: "#7e73d6" }} />
                <Image src={require('../assets/images/pin.png')} alt="programa Posparto" className='mx-auto' imageStyle={{ maxWidth: 20, zIndex: 1 }} />
                <div style={{ height: 1, width: "100%", borderStyle: "dashed", borderBottomWidth: 1, borderColor: "#7e73d6" }} />
              </div>
              <div className='mt-6 flex flex-col items-center justify-center px-4'>
                <p className='mb-2 font-golos-semibold text-Crayola text-xl text-center'>Fitmom Posparto</p>
                <p className='text-DarkJungleGreen text-sm text-center min-h-[3rem]'>Para mujeres de 0 a 12 meses posparto/postcesárea o mujeres que desean corregir diástasis, incontinencia, vientre abultado y otras disfunciones.</p>
                <ButtonPrimary text="Ver detalles" styleContainer={{ marginTop: "2em" }} onClick={() => navigate("/programas/fitmom-posparto")} />
              </div>
            </div>
            <div style={{ marginLeft: 2 }}>
              <div>
                <Image
                  src={require('../assets/images/home/programa3-img.jpg')}
                  alt="programa3"
                  className='flex justify-center items-center mx-auto'
                  imageStyle={{ width: "90%", paddingBottom: 2.5 }}
                />
              </div>
              <div className='hidden md:flex relative timeline-left justify-center items-center mt-6'>
                <Image src={require('../assets/images/pin.png')} alt="programa Strength" className='flex items-center mx-auto' imageStyle={{ maxWidth: 20, zIndex: 1 }} />
              </div>
              <div className='mt-6 flex flex-col items-center justify-center px-4'>
                <p className='mb-2 font-golos-semibold text-Crayola text-xl text-center'>Fitmom Strength</p>
                <p className='text-DarkJungleGreen text-sm text-center min-h-[3rem]'>Para mujeres que han completado el programa posparto o mujeres sin hijos y con un alto nivel de fitness que desean aprender sobre su core.</p>
                <ButtonPrimary text="Ver detalles" styleContainer={{ marginTop: "2em" }} onClick={() => navigate("/programas/fitmom-strength")} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-16 bg-Magnolia'>
        <div className="xl:container mx-auto px-6">
          <div className='flex flex-wrap gap-10 items-center justify-center'>
            <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInLeft"} className='animate__animated relative flex items-center justify-center'>
              <Image src={require('../assets/images/home/video-img.jpg')} alt="video preview" className='flex justify-center items-center mx-auto' imageClassName='mx-auto max-w-[240px] sm:max-w-[320px] lg:max-w-[460px]' imageStyle={{ borderRadius: 25, width: "90%" }} />
              <div className='absolute c-pointer active:scale-90 transition ease-in-out' onClick={() => setState({ isVideoVisible: true })}>
                <Image src={require('../assets/images/play-btn.png')} alt="icon play" imageClassName='mx-auto' imageStyle={{ width: "90%", maxWidth: 120 }} />
              </div>
            </div>
            <div className='flex-1 text-center md:text-left sm:min-w-[320px]' style={{ maxWidth: 420 }}>
              <Image src={require('../assets/images/logo-video.png')} alt="icon logo" imageClassName='mx-auto md:mx-none' imageStyle={{ width: "90%", maxWidth: 120 }} />
              <h1 ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInRight"} className='animate__animated font-medium text-DeepKoamaru text-4xl mt-8'>Entrena desde cualquier país <span className='font-golos-bold'>y únete a la comunidad.</span></h1>
              <p className='font-medium text-DeepKoamaru mt-8' style={{ fontSize: ".920em" }}>Únete a las miles de mamás que han experimentado los beneficios de mis programas.</p>
              <div className='flex justify-center md:justify-start'>
                <ButtonPrimary
                  text="Comienza tu prueba gratis"
                  withIcon
                  styleContainer={{ marginTop: "2em" }}
                  onClick={() => navigate("/programas/fitmom-embarazo")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-16 bg-white'>
        <div className="xl:container mx-auto px-6 max-w-5xl">
          <h1 ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInDown"} className='animate__animated font-golos-semibold text-Crayola text-center text-2xl mt-8 mb-10'>Principales beneficios de mis programas</h1>
          <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4'>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon1} alt="Icono" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Diseñado con ejercicios específicos para prepararte para las demandas del parto y de la maternidad.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon2} alt="Icono" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Metodología probada para corregir diástasis, incontinencia, vientre abultado y dolores comunes del embarazo y posparto.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon3} alt="Icono" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Guiados por una especialista certificada que entiende los cambios y retos fisiológicos que pasa el cuerpo en las etapas de embarazo y posparto.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon4} alt="Icono" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Ejercicios que te ayudarán a tener una recuperación más rápida y efectiva.</p>
            </div>
          </div>
          <p className='text-gris-9A9A9A text-sm text-center mt-10 mb-0'>*Siempre consulta a tu médico antes de iniciar cualquier rutina.</p>
          <EspecialistaCertificada />
        </div>
      </div>

      <EvaluacionPersonalizada />

      <div className='sm:pt-6 bg-white'>
        <div className="xl:container mx-auto px-6">
          <div className='flex flex-wrap items-center justify-center sm:justify-between mx-auto max-w-5xl gap-4 mb-6'>
            <h1 ref={(el) => elementsRef.current.push(el)} className='font-golos-semibold text-Crayola text-center text-2xl mt-8 mb-10 animate__animated' data-animation={"animate__slideInLeft"}>Testimonios de clientas felices</h1>
            <div>
              <ButtonPrimary
                text="¡Envía tu testimonio!"
                withIcon
                styleContainer={{
                  minWidth: 200
                }}
                onClick={() => setModal("testimonio", navigate)}
              />
            </div>
          </div>

          <Carousel
            value={state.carouselTestimoniosItems}
            numVisible={4}
            numScroll={4}
            responsiveOptions={responsiveOptionsCarousel}
            className="custom-carousel pagination-indicators-gray"
            circular
            showNavigators={false}
            autoplayInterval={7000}
            itemTemplate={CarouselTestimoniosTemplate}
          />

          {/* <div className='mx-auto max-w-4xl bg-Crayola rounded-xl'>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 mt-6">
              <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeIn"} className="animate__animated hidden md:block col-span-1 md:col-span-1 lg:col-span-5 rounded-tl-xl rounded-bl-xl relative">
                <Image
                  src={require("../assets/images/home/guia-img.png")}
                  imageClassName="w-full h-full object-cover md:rounded-tl-xl md:rounded-bl-xl"
                />
                <Image
                  src={require("../assets/images/home/guia-sello.png")}
                  imageClassName="absolute top-16 -right-9"
                  imageStyle={{ maxWidth: 100 }}
                />
              </div>
              <div className="col-span-1 md:col-span-1 lg:col-span-7">
                <div className='px-6 md:px-14 lg:px-20 py-6 md:py-10 lg:py-16'>
                  <p className='font-golos-bold text-DeepKoamaru text-3xl mb-0'>Guía de inicio:</p>
                  <p className='text-white text-3xl lg:text-5xl mb-6'>Ejercicio en el embarazo</p>
                  <p className='text-white text-sm mb-0'><span className='font-golos-bold text-DeepKoamaru'>¿Estás embarazada y no sabes cómo empezar a hacer ejercicio?</span> Conoce los beneficios del ejercicio en el embarazo y otros temas importantes como la diastasis recti y suelo pélvico.</p>
                  <form className="space-y-4 mt-10" onSubmit={enviarGuiaForm}>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      placeholder='Nombre'
                      className="focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full"
                      value={state.guiaInicio.nombre}
                      onChange={(event) => setState({ nombre: event.target.value }, "guiaInicio")}
                    />
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      placeholder='Correo electrónico'
                      className="focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full"
                      value={state.guiaInicio.correo}
                      onChange={(event) => setState({ correo: event.target.value }, "guiaInicio")}
                    />

                    <div>
                      <ButtonPrimary
                        text="Enviar Guía"
                        withIcon
                        classNameContainer="flex justify-end mt-8"
                        styleContainer={{
                          minWidth: 200
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Index;