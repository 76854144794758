import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Chip } from 'primereact/chip';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { OverlayPanel } from 'primereact/overlaypanel';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { getCategorias, deleteCategory } from '../../../api/admin/categorias';
import { paginationComponentOptions, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
import onSearchFields from '../../../utils/onSearchFields';
import obtenerNombreImagen from '../../../utils/obtenerNombreImagen';
import { deleteSubCategory } from '../../../api/admin/subcategorias';
import { getProgramas } from '../../../api/admin/programas';
import { getTypesCategory } from '../../../misc/getTypesCategory';
import FilterComponent from '../../../components/Form/FilterComponent';
const prefix = "/admin/categorias";

const Categorias = () => {
  const op = useRef(null);
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    filterText: "",
    categorias: [],
    programas: [],
    programSelected: [],
    tipos: [],
    typeSelected: null,
  });

  useEffect(() => {
    const filters = {
      programID: state.programSelected,
      type: state.typeSelected,
    }
    loadData(filters);
    // eslint-disable-next-line
  }, [state.programSelected, state.typeSelected]);

  const loadData = useCallback(async (params = {}) => {
    setState({ loading: true });
    const categorias = await getCategorias({ populate: ["sub_categoria"], ...params });
    const programas = await getProgramas();
    const tipos = await getTypesCategory();

    setState({
      categorias: categorias?.data,
      programas: programas?.data,
      tipos: tipos,
      loading: false,
    });
  }, [setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    // eslint-disable-next-line
    if (type === "editar") {
      if (params?.id) navigate(`${prefix}/editar-categoria/${params?.id}`);
    } else if (type === "eliminar") {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <div className='text-center'>
            <p>¿Estás segura?</p>
            <p>Esta acción no se puede deshacer.</p>
          </div>
        ),
        icon: null,
        rejectClassName: "bg-neutral-400 border-neutral-400 hover:!bg-neutral-500 hover:!border-neutral-500 !py-1",
        acceptClassName: 'p-button-danger !py-1',
        acceptLabel: "Si, eliminar",
        accept: async () => {
          if (params?.id) await deleteCategory(params?.id);
          loadData();
        },
      });
    }
  }, [loadData, navigate]);

  const { columnsTable, filteredItems } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Nombre',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.name),
        cell: row => (row.name)
      },
      {
        name: 'Descripción',
        sortable: false,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.description),
        cell: row => (row.description)
      },
      {
        name: 'Portada',
        sortable: false,
        fixed: 'left',
        minWidth: '100px',
        selector: row => (row.image),
        cell: row => (
          <a className='text-blue-500 underline' href={row.image} rel="noreferrer" target="_blank">{row?.image && obtenerNombreImagen(row?.image, true)}</a>
        )
      },
      {
        name: 'Tipo',
        sortable: true,
        fixed: 'left',
        minWidth: '100px',
        selector: row => (row.type),
        cell: row => (row.type)
      },
      {
        name: 'Subcategorías',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => row.sub_categorias?.map(item => item?.name)?.join(" "),
        cell: row => {
          return (
            <div className='flex flex-wrap gap-1 my-2'>
              {
                row.sub_categorias?.map(item => (
                  <Chip className='bg-blue-500 text-white rounded-md font-medium text-xs' label={item?.name} removable={true} onRemove={(event) => deleteSubCategory(item?.id)} />
                ))
              }
            </div>
          );
        },
      },
      {
        name: 'Programa',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => row.Program?.name,
        cell: row => {
          return (
            <div className='flex flex-wrap gap-1 my-2'>
              <Chip className='bg-blue-500 text-white rounded-md font-medium text-xs' label={row.Program?.name} />
            </div>
          );
        },
      },
      {
        name: 'Acciones',
        center: true,
        width: '260px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
            <button type="button" className='bg-red-600 hover:bg-red-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "eliminar")}>Eliminar</button>
          </div>
        ),
      },
    ],
    filteredItems: onSearchFields(state?.categorias, {
      searchText: state.filterText,
      fieldsToSearch: ['name'],
    })
  }), [state.categorias, state.filterText, onActionTable]);

  const subHeaderComponentMemo = useMemo(() => (
    <FilterComponent onFilter={e => setState({ filterText: e.target.value })} filterText={state.filterText} allowedValues={["Nombre"]} />
  ), [state.filterText, setState]);

  return (
    <>
      <SEO
        title="Categorías"
      />
      <ConfirmPopup />
      <OverlayPanel
        ref={op}
        showCloseIcon={false}
        className={"shadow-sm shadow-[#ddd] max-w-[95%] mx-4"}
      >
        <div className='divide-y divide-[#ddd] space-y-4 text-center sm:text-left'>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Tipo de categoría</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {state.tipos?.map(item => (
                <div
                  key={`tipo-key-${item?.value}`}
                  className={`${state.typeSelected === item?.value ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    setState({ typeSelected: item?.value === state.typeSelected ? null : item?.value });
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Programas</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {state.programas?.map(item => (
                <div
                  key={`programa-key-${item?.id}`}
                  className={`${state.programSelected?.includes(item?.id) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    const updatedProgramsSelected = state.programSelected?.includes(item?.id)
                      ? state.programSelected?.filter(program => program !== item?.id)
                      : [...state.programSelected, item?.id];

                    setState({ programSelected: updatedProgramsSelected });
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OverlayPanel>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col lg:flex-row items-center justify-center lg:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center lg:text-left'>Categorías</h1>
                <div className='inline-flex flex-wrap justify-center lg:justify-start gap-3'>
                  <ButtonCustom
                    text="Crear categoría"
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={() => navigate(`${prefix}/crear-categoria`)}
                  />
                  <ButtonCustom
                    text="Crear subcategoría"
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={() => navigate(`${prefix}/crear-subcategoria`)}
                  />
                  <ButtonCustom
                    text="Editar subcategorías"
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={() => navigate(`${prefix}/editar-subcategorias`)}
                  />
                  <ButtonCustom
                    text="Filtros"
                    icon={<i className="pi pi-filter" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={(e) => op.current.toggle(e)}
                  />
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  selectableRows={false}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Categorias;