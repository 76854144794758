import React, { memo, useCallback, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "primereact/image";
import { Sidebar } from 'primereact/sidebar';
import ProfleIcon from "../../../assets/icons/profile.svg";
import { ReactComponent as BarsIcon } from '../../../assets/icons/bars-icon.svg';
import { ReactComponent as GymIcon } from "../../../assets/icons/gym.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play.svg";
import { useCustomState } from "../../../hooks/useCustomState";
import { logout } from "../../../redux/reducers/admin/authSlice";

const LayoutMenuLeft = memo(() => {
  const [state, setState] = useCustomState({
    isMenuMobileVisible: false,
  });
  const { user: getUser } = useSelector(state => state.admin.auth);
  const user = getUser?.user || getUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathCurrent, classNameIsActiveMenu } = useMemo(() => ({
    pathCurrent: "/admin",
    classNameIsActiveMenu: (location, pathname, contains = false) => {
      if (location?.pathname === pathname) {
        return "font-golos-semibold !bg-Magnolia !text-Crayola hover:!text-CrayolaHover cursor-pointer";
      }
      if (contains === true && location?.pathname?.startsWith(pathname + "/")) {
        return "font-golos-semibold !bg-Magnolia !text-Crayola hover:!text-CrayolaHover cursor-pointer";
      }
      return "font-golos text-DarkJungleGreen cursor-pointer";
    },
  }), []);

  const cerrarSesion = useCallback(() => {
    dispatch(logout());
    navigate('/admin');
  }, [dispatch, navigate]);

  const MenuNav = useCallback(() => (
    <div className="flex flex-col space-y-2 pt-7">
      <div className="space-y-1">
        <Link to={`${pathCurrent}/perfiles`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/perfiles`, true)} ${classNameIsActiveMenu(location, pathCurrent)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-id-card" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Perfiles</span>
        </Link>
        <Link to={`${pathCurrent}/usuarios`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/usuarios`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-user" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Usuarios</span>
        </Link>
        <Link to={`${pathCurrent}/clientas`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/clientas`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-heart" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Clientas</span>
        </Link>
        <Link to={`${pathCurrent}/compras`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/compras`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-shopping-cart" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Compras</span>
        </Link>
      </div>
      <div className="space-y-1">
        <Link to={`${pathCurrent}/categorias`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/categorias`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-tags" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Categorías</span>
        </Link>
        <Link to={`${pathCurrent}/calificaciones-videos`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/calificaciones-videos`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-star" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Reseñas de vídeos</span>
        </Link>
        <Link to={`${pathCurrent}/secciones`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/secciones`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-table" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Secciones</span>
        </Link>
        <Link to={`${pathCurrent}/frases`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/frases`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-comments" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Frases del día</span>
        </Link>
      </div>
      <div className="space-y-1">
        <Link to={`${pathCurrent}/galeria-cores`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/galeria-cores`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <PlayIcon width={"1rem"} fill="#7e73d6" />
          <span className={`text-inherit transition-all`}>Galería core</span>
        </Link>
        <Link to={`${pathCurrent}/galeria-workouts`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/galeria-workouts`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <PlayIcon width={"1rem"} fill="#7e73d6" />
          <span className={`text-inherit transition-all`}>Galería workouts</span>
        </Link>
        <Link to={`${pathCurrent}/galeria-parto`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/galeria-parto`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <PlayIcon width={"1rem"} fill="#7e73d6" />
          <span className={`text-inherit transition-all`}>Galería parto</span>
        </Link>
        <Link to={`${pathCurrent}/programas`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/programas`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <GymIcon width={"1rem"} fill="#7e73d6" />
          <span className={`text-inherit transition-all`}>Programas</span>
        </Link>
        <Link to={`${pathCurrent}/calendarios`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/calendarios`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-calendar" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Calendarios</span>
        </Link>
        <Link to={`${pathCurrent}/descargables`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/descargables`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-file-pdf" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit truncate transition-all`}>Descargables</span>
        </Link>
        <Link to={`${pathCurrent}/banners`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/banners`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-image" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit truncate transition-all`}>Banners</span>
        </Link>
      </div>
      <div className="space-y-1">
        <Link to={`${pathCurrent}/productos`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/productos`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-star" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Productos</span>
        </Link>
        <Link to={`${pathCurrent}/cupones`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/cupones`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-tag" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Cupones</span>
        </Link>
        <Link to={`${pathCurrent}/blog`} className={`${classNameIsActiveMenu(location, `${pathCurrent}/blog`, true)} flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia transition-all`}>
          <i className="pi pi-pencil" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <span className={`text-inherit transition-all`}>Blog</span>
        </Link>
      </div>

      <div>
        <div className="flex items-center gap-3 px-4 py-2 rounded-lg bg-transparent hover:bg-Magnolia text-DarkJungleGreen c-pointer transition-all mt-4" onClick={cerrarSesion}>
          <i className="pi pi-sign-out" style={{ color: '#7e73d6', fontSize: '1rem' }}></i>
          <p>Cerrar sesión</p>
        </div>
      </div>
    </div>
  ), [location, pathCurrent, classNameIsActiveMenu, cerrarSesion]);

  return (
    <>
      <div className="md:hidden col-span-1 w-full border-b border-gris-F7F7F7">
        <div className="flex flex-1 space-x-4 items-center bg-white pt-4 pb-4 px-8 border-t border-[#F5F5F5]">
          <div className="flex-1">
            <Link to="/admin">
              <Image
                src={require("../../../assets/images/logo-head.png")}
                imageClassName="w-full max-w-[160px] select-none pointer-events-none"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="inline-block ml-auto c-pointer active:scale-90 transition ease-in-out" onClick={() => setState({ isMenuMobileVisible: true })}>
            <BarsIcon fill={"#7e73d6"} height={28} />
          </div>
        </div>
      </div>
      <Sidebar
        visible={state.isMenuMobileVisible}
        onHide={() => setState({ isMenuMobileVisible: false })}
        className="menu-close-padding-custom"
      >
        <div className="bg-white pb-10 rounded-xl space-y-2">
          <div className="flex flex-col space-y-5 items-center justify-center">
            <div className="pt-0 pb-4">
              <Link to="/admin">
                <Image
                  src={require("../../../assets/images/logo-head.png")}
                  imageClassName="w-full max-w-[180px] mx-auto select-none pointer-events-none"
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="bg-Crayola rounded-lg px-4 py-4 w-full flex flex-row items-center justify-start text-left gap-4">
              <div>
                <Image
                  src={user?.image || ProfleIcon}
                  imageClassName="bg-white border border-white w-[40px] rounded-full"
                  loading="lazy"
                />
              </div>
              <div className="flex-1 space-y-1">
                <p className="font-golos-medium text-md text-white m-0 !leading-6">{`${user?.firstName} ${user?.lastName}`}</p>
                <p className="font-golos-medium text-muted text-white/80 m-0">{user?.Profile?.name || "Perfil desconocido"}</p>
              </div>
            </div>
          </div>
          <MenuNav />
        </div>
      </Sidebar>
      <aside className="hidden md:block md:col-span-4 lg:col-span-3 space-y-4 h-full">
        <div className="bg-white border-r border-gris-F7F7F7 py-10 space-y-2 h-full">
          <div className="flex flex-col space-y-5 items-center justify-start px-6">
            <div className="pt-2 pb-4">
              <Link to="/admin">
                <Image
                  src={require("../../../assets/images/logo-head.png")}
                  imageClassName="w-full max-w-[180px] mx-auto select-none pointer-events-none"
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="bg-Crayola rounded-lg px-4 py-4 w-full flex flex-col lg:flex-row items-center justify-center lg:justify-start text-center lg:text-left gap-2 lg:gap-4">
              <div>
                <Image
                  src={user?.image || ProfleIcon}
                  imageClassName="bg-white border border-white w-[46px] rounded-full"
                  loading="lazy"
                />
              </div>
              <div className="flex-1 space-y-1">
                <p className="font-golos-medium text-lg text-white m-0 !leading-6">{`${user?.firstName} ${user?.lastName}`}</p>
                <p className="font-golos-medium text-muted text-white/80 m-0">{user?.Profile?.name || "Perfil desconocido"}</p>
              </div>
            </div>
          </div>
          <div className="px-6">
            <MenuNav />
          </div>
        </div>
      </aside>
    </>
  );
}, (prev, next) => prev === next);

const Grid = ({ children }) => {
  return (
    <section className="md:grid grid-cols-1 sm:grid-cols-12 gap-3 sm:gap-0 min-h-screen">
      <LayoutMenuLeft />
      <div className="col-span-1 md:col-span-8 lg:col-span-9">
        {children}
      </div>
    </section>
  );
}

export default memo(Grid);