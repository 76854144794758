import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { OverlayPanel } from 'primereact/overlaypanel';
import dayjs from 'dayjs';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { deleteClienta, exportClienta, getClientas } from '../../../api/admin/clientas';
import { getProgramas } from '../../../api/admin/programas';
import { paginationComponentOptions, Checkbox, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
import downloadFile from '../../../utils/downloadFile';
import onSearchFields from '../../../utils/onSearchFields';
import { isEmpty } from '../../../utils';
const prefix = "admin/clientas";

const Clientas = () => {
  const op = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    exports_loading: false,
    sending: false,
    loading: true,
    buscador: "",
    statusSelected: null,
    fechaRegistroSelected: null,
    fechaAdquisicionSelected: null,
    fechaReingresoSelected: null,
    programSelected: [],
    plataformaSelected: [],
    selectedRows: [],
    clientas: [],
    programas: [],
  });

  const { filters } = useMemo(() => ({
    filters: {
      status: state.statusSelected,
      fecha_registro: (!isEmpty(state.fechaRegistroSelected?.[0]) && !isEmpty(state.fechaRegistroSelected?.[1])) ? state.fechaRegistroSelected : null,
      // fecha_adquisicion: (!isEmpty(state.fechaAdquisicionSelected?.[0]) && !isEmpty(state.fechaAdquisicionSelected?.[1])) ? state.fechaAdquisicionSelected : null,
      fecha_reingreso: (!isEmpty(state.fechaReingresoSelected?.[0]) && !isEmpty(state.fechaReingresoSelected?.[1])) ? state.fechaReingresoSelected : null,
      programa: state.programSelected,
      plataforma: state.plataformaSelected,
    }
  }), [state.statusSelected, state.fechaRegistroSelected, state.fechaReingresoSelected, state.programSelected, state.plataformaSelected]);

  useEffect(() => {
    loadData(filters);
    // eslint-disable-next-line
  }, [filters]);

  const loadData = useCallback(async (_filters) => {
    setState({ loading: true });
    const clientas = await getClientas(null, _filters);
    const programas = await getProgramas();
    setState({
      clientas: clientas?.data,
      programas: programas?.data,
      loading: false,
    });
  }, [setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    if (type === "ver") {
      if (params?.id) navigate(`/${prefix}/${params?.id}`);
    } else if (type === "editar") {
      if (params?.id) navigate(`/${prefix}/editar-clienta/${params?.id}`);
    } else if (type === "eliminar") {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <div className='text-center'>
            <p>¿Estás segura?</p>
            <p>Esta acción no se puede deshacer.</p>
          </div>
        ),
        icon: null,
        rejectClassName: "bg-neutral-400 border-neutral-400 hover:!bg-neutral-500 hover:!border-neutral-500 !py-1",
        acceptClassName: 'p-button-danger !py-1',
        acceptLabel: "Si, eliminar",
        accept: async () => {
          if (params?.id) await deleteClienta(params?.id);
          loadData();
        },
      });
    }
  }, [loadData, navigate]);

  const { plataformas, columnsTable, filteredItems } = useMemo(() => ({
    plataformas: [
      {
        id: "web",
        name: "Página web",
      },
      {
        id: "app",
        name: "Aplicación móvil",
      },
    ],
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Nombre',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.fullName),
        cell: row => (row.fullName)
      },
      {
        name: 'Correo electrónico',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => (row.email),
        cell: row => (row.email)
      },
      {
        name: 'Estado (clienta)',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => {
          return row.status;
        },
        cell: row => {
          if (row?.status) {
            if (row.status === "active") return <span className='bg-green-600 text-white rounded-lg py-2 px-4'>Activa</span>;
            if (row.status === "inactive") return <span className='bg-gray-500 text-white rounded-lg py-2 px-4'>Inactiva</span>;
          }
          return "";
        },
      },
      {
        name: 'Código de cupón',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row.subscription?.couponCode;
        },
        cell: row => {
          return row.subscription?.couponCode;
        },
      },
      {
        name: 'Pasarela de cobro',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row.subscription?.isInternal === true ? "Administrado por JimeFitMom" : row.subscription?.paymentType;
        },
        cell: row => {
          return row.subscription?.isInternal === true ? "Administrado por JimeFitMom" : row.subscription?.paymentType;
        },
      },
      {
        name: 'Programa',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        selector: row => {
          return row.Program && (`${row.Program?.name}${(row.subscription?.typePlan ? " - " + row.subscription?.typePlan : "")}`);
        },
        cell: row => {
          return row.Program && (`${row.Program?.name}${(row.subscription?.typePlan ? " - " + row.subscription?.typePlan : "")}`);
        },
      },
      {
        name: 'Precio',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        selector: row => {
          return parseFloat(row.subscription?.initialPayment || 0).toFixed(2);
        },
        cell: row => {
          return `$${parseFloat(row.subscription?.initialPayment || 0).toFixed(2)}`;
        },
      },
      {
        name: 'Adquisición',
        sortable: true,
        fixed: 'left',
        minWidth: '140px',
        selector: row => {
          return row.subscription?.subscriptionStartDate && dayjs(row.subscription.subscriptionStartDate).format("MM/DD/YYYY");
        },
        cell: row => {
          return row.subscription?.subscriptionStartDate && dayjs(row.subscription.subscriptionStartDate).format("MM/DD/YYYY");
        },
      },
      {
        name: 'Fecha de registro',
        sortable: true,
        fixed: 'left',
        minWidth: '160px',
        selector: row => {
          return !isEmpty(row.createdAt) ? dayjs(row.createdAt).format("MM/DD/YYYY") : "";
        },
        cell: row => {
          return !isEmpty(row.createdAt) ? dayjs(row.createdAt).format("MM/DD/YYYY") : "";
        },
      },
      {
        name: 'Reingreso',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        selector: row => {
          return !isEmpty(row.reEntryAt) ? dayjs(row.reEntryAt).format("MM/DD/YYYY") : "No";
        },
        cell: row => {
          return !isEmpty(row.reEntryAt) ? dayjs(row.reEntryAt).format("MM/DD/YYYY") : "No";
        },
      },
      {
        name: 'Estado de suscripción',
        sortable: true,
        fixed: 'left',
        minWidth: '185px',
        selector: row => {
          const subscription = row.subscription;
          if (subscription?.status) {
            if (["trialing", "paid"].includes(subscription.status)) return "Activa";
            if (subscription.status === "hold") return "En espera";
            if (subscription.status === "rejected") return "Rechazada";
            if (["cancelled", "expired", "paused"].includes(subscription?.status) && subscription.status_to_expire) return subscription.status_to_expire;
            if (subscription.status === "expired") return "Expirada";
            if (subscription.status === "cancelled") return "Cancelada";
          } else {
            return "Desconocido";
          }
        },
        cell: row => {
          const subscription = row.subscription;
          if (subscription?.status) {
            if (["trialing", "paid"].includes(subscription.status)) return <span className='bg-green-600 text-white rounded-lg py-2 px-4'>Activa</span>;
            if (subscription.status === "hold") return <span className='bg-amber-500 text-white rounded-lg py-2 px-4'>En espera</span>;
            if (subscription.status === "rejected") return <span className='bg-red-500 text-white rounded-lg py-2 px-4'>Rechazada</span>;
            if (["cancelled", "expired", "paused"].includes(subscription?.status) && subscription.status_to_expire) return <span className={`${["Expirada", "Cancelada"].includes(subscription.status_to_expire) ? "bg-red-500" : "bg-green-500"} text-white rounded-lg py-2 px-4`}>{subscription.status_to_expire}</span>;
            if (subscription.status === "expired") return <span className='bg-red-500 text-white rounded-lg py-2 px-4'>Expirada</span>;
            if (subscription.status === "cancelled") return <span className='bg-red-600 text-white rounded-lg py-2 px-4'>Cancelada</span>;
          }
          return "Desconocido";
        },
      },
      {
        name: 'Fecha de cancelación',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row.subscription?.cancelledAt && dayjs(row.subscription.cancelledAt).format("MM/DD/YYYY");
        },
        cell: row => {
          return row.subscription?.cancelledAt && dayjs(row.subscription.cancelledAt).format("MM/DD/YYYY");
        },
      },
      {
        name: 'Último ingreso',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.lastLogin && dayjs(row.lastLogin).format("MM/DD/YYYY") + (row.loginDevice && " - " + row.loginDevice)),
        cell: row => (row.lastLogin && dayjs(row.lastLogin).format("MM/DD/YYYY") + (row.loginDevice && " - " + row.loginDevice))
      },
      {
        name: 'Acciones',
        center: true,
        width: '260px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-green-600 hover:bg-green-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "ver")}>Ver</button>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
            <button type="button" className='bg-red-600 hover:bg-red-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "eliminar")}>Eliminar</button>
          </div>
        ),
      },
    ],
    filteredItems: onSearchFields(state?.clientas, {
      searchText: state.buscador,
      fieldsToSearch: ['fullName', 'email', 'Program.name'],
    })
  }), [state.clientas, state.buscador, onActionTable]);

  const descargarReporte = useCallback(async () => {
    setState({ exports_loading: true });

    try {
      const response = await exportClienta({ ...filters, userID: ((state.buscador?.length > 0 && state.selectedRows?.length === 0) ? filteredItems : state.selectedRows)?.map(item => (item?.userID)) });
      await downloadFile(response, 'reporte_clientas.xlsx');
      setState({ exports_loading: false });
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      setState({ exports_loading: false });
    }
  }, [state.selectedRows, state.buscador, filteredItems, filters, setState]);

  return (
    <>
      <SEO
        title="Clientas"
      />
      <ConfirmPopup />
      <OverlayPanel
        ref={op}
        showCloseIcon={false}
        className={"shadow-sm shadow-[#ddd] max-w-[95%] mx-4"}
      >
        <div className='divide-y divide-[#ddd] space-y-4 text-center sm:text-left'>
          <div className='flex flex-col sm:flex-row items-center sm:items-start gap-4'>
            <p className='sm:min-w-[140px]'>Estado</p>
            <div className='flex flex-1 flex-wrap gap-6'>
              <label className='inline-flex items-center c-pointer text-sm'>
                <input
                  type="checkbox"
                  className="check-input-custom !cursor-pointer rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                  checked={state.statusSelected === "active"}
                  onChange={(event) => setState({ statusSelected: event.target.checked ? "active" : null })}
                />
                <span className='pl-3'>Activa</span>
              </label>
              <label className='inline-flex items-center c-pointer text-sm'>
                <input
                  type="checkbox"
                  className="check-input-custom !cursor-pointer rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                  checked={state.statusSelected === "inactive"}
                  onChange={(event) => setState({ statusSelected: event.target.checked ? "inactive" : null })}
                />
                <span className='pl-3'>Cancelada</span>
              </label>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Fecha de registro</p>
            <div className='flex flex-1 flex-col sm:flex-row items-center gap-2'>
              <input
                type="date"
                className="rounded-md border-gris-F7F7F7 text-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                onChange={(event) => setState({ fechaRegistroSelected: [(event.target.value && dayjs(event.target.value)?.toISOString()), state.fechaRegistroSelected?.[1]] })}
                value={(!isEmpty(state.fechaRegistroSelected?.[0])) ? dayjs(state.fechaRegistroSelected?.[0]).format("YYYY-MM-DD") : ""}
              />
              <span className='text-[#aaa] rotate-90 sm:rotate-0'>—</span>
              <input
                type="date"
                className="rounded-md border-gris-F7F7F7 text-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                onChange={(event) => setState({ fechaRegistroSelected: [state.fechaRegistroSelected?.[0], (event.target.value && dayjs(event.target.value)?.toISOString())] })}
                value={(!isEmpty(state.fechaRegistroSelected?.[1])) ? dayjs(state.fechaRegistroSelected?.[1]).format("YYYY-MM-DD") : ""}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Fecha de reingreso</p>
            <div className='flex flex-1 flex-col sm:flex-row items-center gap-2'>
              <input
                type="date"
                className="rounded-md border-gris-F7F7F7 text-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                onChange={(event) => setState({ fechaReingresoSelected: [(event.target.value && dayjs(event.target.value)?.toISOString()), state.fechaReingresoSelected?.[1]] })}
                value={(!isEmpty(state.fechaReingresoSelected?.[0])) ? dayjs(state.fechaReingresoSelected?.[0]).format("YYYY-MM-DD") : ""}
              />
              <span className='text-[#aaa] rotate-90 sm:rotate-0'>—</span>
              <input
                type="date"
                className="rounded-md border-gris-F7F7F7 text-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                onChange={(event) => setState({ fechaReingresoSelected: [state.fechaReingresoSelected?.[0], (event.target.value && dayjs(event.target.value)?.toISOString())] })}
                value={(!isEmpty(state.fechaReingresoSelected?.[1])) ? dayjs(state.fechaReingresoSelected?.[1]).format("YYYY-MM-DD") : ""}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Programas</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {state.programas?.map(item => (
                <div
                  key={`programa-key-${item?.id}`}
                  className={`${state.programSelected?.includes(item?.id) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    const updatedProgramsSelected = state.programSelected?.includes(item?.id)
                      ? state.programSelected?.filter(program => program !== item?.id)
                      : [...state.programSelected, item?.id];

                    setState({ programSelected: updatedProgramsSelected });
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Plataformas</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {plataformas.map(item => (
                <div
                  key={`plataformas-${item?.id}`}
                  className={`${state.plataformaSelected?.includes(item?.id) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    const updatedProgramsSelected = state.plataformaSelected?.includes(item?.id)
                      ? state.plataformaSelected?.filter(program => program !== item?.id)
                      : [...state.plataformaSelected, item?.id];

                    setState({ plataformaSelected: updatedProgramsSelected });
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OverlayPanel>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col sm:flex-row items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Clientas</h1>
                <div className='inline-flex flex-col sm:flex-row flex-wrap justify-center lg:justify-start gap-3'>
                  <div className='flex-1 min-w-[180px] max-w-xs mx-auto'>
                    <input
                      type="search"
                      placeholder='Buscar clienta'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-2 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.buscador}
                      onChange={(event) => setState({ buscador: event.target.value })}
                    />
                  </div>
                  <div className='flex-1 inline-flex justify-center flex-wrap xs:flex-nowrap gap-3'>
                    <Button
                      type="button"
                      className='flex items-center font-golos-semibold text-sm border-transparent p-button bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru text-AntiFlashWhite !py-2 !px-4 overflow-visible'
                      icon={<i className="pi pi-filter" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                      label="Filtros"
                      onClick={(e) => op.current.toggle(e)}
                    />
                    <ButtonCustom
                      text="Descargar .xls"
                      icon={<i className="pi pi-download" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                      loading={state.exports_loading}
                      classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru min-h-[38px]"
                      onClick={descargarReporte}
                    />
                    <ButtonCustom
                      text="Crear clienta"
                      classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                      onClick={() => navigate(`/${prefix}/crear-clienta`)}
                    />
                  </div>
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader={false}
                  selectableRows
                  onSelectedRowsChange={({ selectedRows }) => setState({ selectedRows })}
                  selectableRowsComponent={Checkbox}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Clientas;