import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { deleteUsuario, getUsuarios } from '../../../api/admin/usuarios';
import { paginationComponentOptions, Checkbox, NoDataComponent, SortIcon } from '../../../config/table.config';
import FilterComponent from '../../../components/Form/FilterComponent';
import customTableStyles from '../../../styles/customTableStyles';
import onSearchFields from '../../../utils/onSearchFields';
const prefix = "admin/usuarios";

const Usuarios = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    filterText: "",
    selectedRows: [],
    usuarios: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    const usuarios = await getUsuarios();
    setState({
      usuarios: usuarios?.data,
      loading: false,
    });
  }, [setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    if (type === "ver") {
      if (params?.id) navigate(`/${prefix}/${params?.id}`);
    } else if (type === "editar") {
      if (params?.id) navigate(`/${prefix}/editar-usuario/${params?.id}`);
    } else if (type === "eliminar") {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <div className='text-center'>
            <p>¿Estás segura?</p>
            <p>Esta acción no se puede deshacer.</p>
          </div>
        ),
        icon: null,
        rejectClassName: "bg-neutral-400 border-neutral-400 hover:!bg-neutral-500 hover:!border-neutral-500 !py-1",
        acceptClassName: 'p-button-danger !py-1',
        acceptLabel: "Si, eliminar",
        accept: async () => {
          if (params?.id) await deleteUsuario(params?.id);
          loadData();
        },
      });
    }
  }, [loadData, navigate]);

  const { columnsTable, filteredItems } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Nombre',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.fullName),
        cell: row => (row.fullName)
      },
      {
        name: 'Correo electrónico',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.email),
        cell: row => (row.email)
      },
      {
        name: 'Perfil',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.Profile?.name),
        cell: row => (row.Profile?.name)
      },
      {
        name: 'Acciones',
        center: true,
        width: '260px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-green-600 hover:bg-green-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "ver")}>Ver</button>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
            <button type="button" className='bg-red-600 hover:bg-red-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "eliminar")}>Eliminar</button>
          </div>
        ),
      },
    ],
    filteredItems: onSearchFields(state?.usuarios, {
      searchText: state.filterText,
      fieldsToSearch: ['fullName', 'email', 'Profile.name'],
    })
  }), [onActionTable, state.usuarios, state.filterText]);

  const subHeaderComponentMemo = useMemo(() => (
    <FilterComponent onFilter={e => setState({ filterText: e.target.value })} filterText={state.filterText} allowedValues={["Nombre", "Correo electrónico", "Perfil"]} />
  ), [state.filterText, setState]);

  return (
    <>
      <SEO
        title="Usuarios"
      />
      <ConfirmPopup />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Usuarios</h1>
                <div className='inline-flex flex-col sm:flex-row flex-wrap justify-center lg:justify-start gap-3'>
                  <ButtonCustom
                    text="Nuevo usuario"
                    classNameButton="border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={() => navigate(`/${prefix}/crear-usuario`)}
                  />
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  // selectableRows
                  onSelectedRowsChange={({ selectedRows }) => setState({ selectedRows })}
                  selectableRowsComponent={Checkbox}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Usuarios;