import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '../../components/Button';
import SEO from '../../components/SEO';
import { useCustomState } from '../../hooks/useCustomState';
import { forgotUser } from '../../redux/reducers/admin/authSlice';
import { optionsToast } from '../../config/toast';
import parseErrorMessage from '../../utils/parseErrorMessage';

const RecuperarCuenta = () => {
  const [state, setState] = useCustomState({
    sending: false,
    form: {
      email: "",
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = useCallback((params) => {
    setState(params, 'form');
  }, [setState]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    setState({ sending: true });
    dispatch(forgotUser(state.form))
      .then((response) => {
        setState({ sending: false });
        if (response?.status === true) {
          window.PrimeToast?.show({
            ...optionsToast(),
            summary: 'Correo de recuperación enviado',
            detail: 'Se ha enviado un correo de recuperación de contraseña. Por favor, revisa tu bandeja de entrada.',
          });
          navigate('/admin/login');
        } else {
          window.PrimeToast?.show({
            ...optionsToast(),
            summary: 'Ocurrio un problema',
            detail: parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al enviar el correo electrónico. Por favor, inténtalo de nuevo más tarde.',
          });
        }
      });
  }, [state.form, dispatch, navigate, setState]);

  return (
    <>
      <SEO
        title="Recuperar contraseña"
      />
      <div className="min-h-screen bg-Magnolia bg-cover bg-center bg-no-repeat flex flex-col justify-center py-12 px-6 lg:px-8" style={{ backgroundImage: `url('${require("../../assets/images/login.jpg")}')` }}>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white pt-12 pb-9 px-4 shadow rounded-md sm:px-10">
            <img alt="Logo" src={require("../../assets/images/logo3.png")} className="mx-auto max-w-[220px] w-full mb-10" />
            <h1 className='font-golos-semibold text-Crayola text-center text-2xl leading-7'>
              RECUPERA TU
              <p>CONTRASEÑA</p>
            </h1>
            <form className="form-login space-y-6 mt-7" onSubmit={onSubmit}>
              <input
                type="email"
                autoComplete="email"
                required
                placeholder='Correo electrónico'
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.email}
                onChange={(event) => onChange({ email: event.target.value })}
              />
              <div className='flex justify-center'>
                <ButtonPrimary
                  text="ENVIAR"
                  classNameContainer="inline-flex justify-center min-w-[140px] !mt-2 !border-0"
                  classNameButton="py-3 px-10 !rounded-full !border-0 !bg-Orchid hover:!bg-OrchidHover w-full !text-center justify-center"
                  type="submit"
                  loading={state.sending}
                  onClick={onSubmit}
                />
              </div>
              <div className="flex justify-center pt-6">
                <p className='inline-block font-golos text-center text-muted text-Orchid underline cursor-pointer p-1' onClick={() => navigate("/admin/login")}>
                  Volver a inicio de sesión
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecuperarCuenta;