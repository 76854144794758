export const getMotivosCancelacion = async () => {
  return [
    {
      name: "Dificultad para organizar tiempo",
      value: "Dificultad para organizar tiempo"
    },
    {
      name: "Razones económicas",
      value: "Razones económicas"
    },
    {
      name: "Por instrucción médica",
      value: "Por instrucción médica"
    },
    {
      name: "Regreso al gym/otro tipo de ejercicio",
      value: "Regreso al gym/otro tipo de ejercicio"
    },
    {
      name: "No era lo que buscaba",
      value: "No era lo que buscaba"
    },
    {
      name: "Problemas con el app",
      value: "Problemas con el app"
    },
    {
      name: "Otro",
      value: "Otro"
    },
  ];
}