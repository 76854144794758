export const getAsuntosContacto = async () => {
  return [
    {
      name: "Problemas con mi cuenta",
      value: "Problemas con mi cuenta"
    },
    {
      name: "Dudas sobre el programa",
      value: "Dudas sobre el programa"
    },
    {
      name: "Problemas con el pago",
      value: "Problemas con el pago"
    },
    {
      name: "Dudas sobre algún entrenamiento",
      value: "Dudas sobre algún entrenamiento"
    },
    {
      name: "Problema con mi contraseña",
      value: "Problema con mi contraseña"
    },
    {
      name: "Otro",
      value: "Otro"
    },
  ];
}