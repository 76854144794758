export const getTypesCategory = async () => {
  return [
    {
      name: "Workouts",
      value: "workouts"
    },
    {
      name: "Core",
      value: "core"
    },
    {
      name: "Parto",
      value: "parto"
    },
  ];
}