import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import SEO from '../../../components/SEO';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import { getProgramas } from '../../../api/admin/programas';
import { paginationComponentOptions, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
const prefix = "/admin/programas";

const Programas = () => {
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    programas: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    setState({ loading: true });
    const programas = await getProgramas();
    setState({
      programas: programas?.data,
      loading: false,
    });
  }, [setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    // eslint-disable-next-line
    if (type === "editar") {
      if (params?.id) navigate(`${prefix}/editar-programa/${params?.id}`);
    }
  }, [navigate]);

  const { columnsTable } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Nombre',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.name),
        cell: row => row.name
      },
      {
        name: 'Costo mensual',
        sortable: true,
        fixed: 'left',
        minWidth: '100px',
        selector: row => (row.cost_month || 0),
        cell: row => (`$${row.cost_month || 0}`)
      },
      {
        name: 'Costo semestral',
        sortable: true,
        fixed: 'left',
        minWidth: '100px',
        selector: row => (row.cost_biannual || 0),
        cell: row => (`$${row.cost_biannual || 0}`)
      },
      {
        name: 'Estado',
        sortable: true,
        fixed: 'left',
        width: '120px',
        selector: row => {
          if (row.status === "active") {
            return "Activo";
          } else if (row.status === "inactive") {
            return "Inactivo";
          }
          return "";
        },
        cell: row => {
          if (row.status === "active") {
            return <span className='bg-green-600 text-white rounded-lg py-2 px-4'>Activo</span>;
          } else if (row.status === "inactive") {
            return <span className='bg-gray-500 text-white rounded-lg py-2 px-4'>Inactivo</span>;
          }
          return "";
        },
      },
      {
        name: '',
        sortable: false,
        fixed: 'left',
        minWidth: '200px',
        cell: row => (
          <ButtonCustom
            text="EDITAR CALENDARIO"
            classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover mx-auto text-xs my-2"
            onClick={() => navigate(`/admin/calendarios/editar-calendario/${row?.code}`)}
          />
        )
      },
      {
        name: 'Acciones',
        center: true,
        width: '100px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
          </div>
        ),
      },
    ],
  }), [navigate, onActionTable]);

  const filteredItems = state.programas;

  return (
    <>
      <SEO
        title="Programas"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Programas</h1>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader={false}
                  selectableRows={false}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Programas;