import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef('');
  const scrollToTimer = useRef(null);
  const navbarHeight = 110;

  useEffect(() => {
    const scrollToHash = () => {
      const hash = location.hash.slice(1);
      if (hash && hash !== lastHash.current) {
        lastHash.current = hash;
        const element = document.getElementById(hash);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: elementPosition - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
    };

    scrollToTimer.current = setTimeout(scrollToHash, 100);

    return () => {
      if (scrollToTimer.current) clearTimeout(scrollToTimer.current);
    };
  }, [location]);

  return null;
}

export default ScrollToAnchor;