import React, { useCallback, useContext, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import GlobalContext from '../../contexts/GlobalContext';
import { optionsToast } from '../../config/toast';
import ContactForm from '../Form/ContactForm';
import { useCustomState } from '../../hooks/useCustomState';
import { getAsuntosContacto } from '../../misc/getAsuntosContacto';
import { isEmail, isEmpty } from '../../utils';
import { sendMailContact } from '../../api/contacto';
import parseErrorMessage from '../../utils/parseErrorMessage';

function ContactoModal({ stylesContainer }) {
  const modalType = "contacto";
  const { showModal, typeModal, setModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    sending: false,
    listado_asuntos: [],
    contactForm: {
      nombre: "",
      correo_electronico: "",
      asunto: null,
      dudas: "",
    },
  });

  useEffect(() => {
    (async () => {
      setState({
        listado_asuntos: await getAsuntosContacto()
      });
    })();
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail, life) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
      life: life,
    });
  }

  const closeModal = useCallback(() => {
    setModal(modalType);
  }, [setModal, modalType]);

  const onChange = useCallback((_state) => {
    setState(_state, 'contactForm');
  }, [setState]);

  const onSubmit = useCallback(async (event, tokenCaptcha) => {
    event?.preventDefault();

    try {
      if (isEmpty(state.contactForm?.nombre)) return message(null, "El nombre es requerido");
      if (isEmpty(state.contactForm?.correo_electronico)) return message(null, "El correo electrónico es requerido");
      if (!isEmail(state.contactForm?.correo_electronico)) return message(null, "El correo electrónico es inválido");
      if (isEmpty(state.contactForm?.asunto)) return message(null, "El asunto es requerido");
      if (isEmpty(state.contactForm?.dudas)) return message(null, "Las dudas son requeridas");
      if (isEmpty(tokenCaptcha)) return message(null, "Selecciona la verificación de ReCaptcha para continuar");

      setState({ sending: true });

      const response = await sendMailContact({
        name: state.contactForm?.nombre,
        email: state.contactForm?.correo_electronico,
        subject: state.contactForm?.asunto,
        content: state.contactForm?.dudas,
        token: tokenCaptcha
      });

      setState({ sending: false });
      if (response?.status) {
        closeModal();
        if (!isEmpty(response?.mensaje)) message('Formulario enviado', response?.mensaje, 9999999);
      } else {
        message('Ocurrio un problema', response?.mensaje || 'Lo sentimos, ocurrió un error al contactarnos. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al contactarnos. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state.contactForm, closeModal, setState]);

  return (
    <Dialog
      visible={showModal && typeModal === modalType}
      onHide={closeModal}
      dismissableMask={true}
      draggable={false}
      headerClassName="!rounded-t-xl"
      contentClassName="!rounded-b-xl"
      headerStyle={{ paddingBottom: 0 }}
      style={{ marginLeft: 15, marginRight: 15 }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
    >
      <div {...stylesContainer}>
        <h1 className='font-golos-bold text-center text-2xl text-DeepKoamaru animate__animated animate__fadeIn'>¿Alguna duda?</h1>
        <h2 className='text-center text-5xl text-Crayola/80 animate__animated animate__slideInLeft'>Pongámonos en contacto</h2>
        <p className='text-center text-sm text-DeepKoamaru/80 mt-2 animate__animated animate__slideInRight'>Completa el formulario y te enviaré una respuesta a la brevedad.</p>
        <ContactForm
          listado_asuntos={state.listado_asuntos}
          data={{ ...state.contactForm, sending: state.sending }}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    </Dialog>
  );
}

export default ContactoModal;