import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { colors } from '../../../config/colors';
import { optionsToast } from '../../../config/toast';
import Loading from '../../../components/Loading';
import { isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { getCategorias } from '../../../api/admin/categorias';
import { getSubCategorias, updateSubCategory } from '../../../api/admin/subcategorias';
import { getProgramas } from '../../../api/client/programas';
import obtenerNombreImagen from '../../../utils/obtenerNombreImagen';
const prefix = "/admin/categorias";

const EditarSubCategoria = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    name: "",
    description: "",
    portada_file: null,
    listado_categorias: [],
    listado_subcategorias: [],
    categoria_seleccionada: null,
    subcategoria_seleccionada: null,
    programa_seleccionado: null,
    listado_programas: [],
  });

  const listado_categorias = state.programa_seleccionado?.id ? state.listado_categorias.map(item => ({ ...item, items: item.items.filter(subItem => subItem.programID === state.programa_seleccionado?.id) })) : [];

  const refPortadaFile = useRef(null);

  useEffect(() => {
    (async () => {
      const categorias = await getCategorias({ grouped: "type" });
      const subcategorias = await getSubCategorias({ grouped: "program" });
      const programas = await getProgramas();

      setState({
        listado_categorias: categorias?.data,
        listado_subcategorias: subcategorias?.data,
        listado_programas: programas?.data,
        loading: false,
      });
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const groupedItemTemplate = useCallback((option) => {
    return (
      <div className="flex items-center pl-0">
        <div className='capitalize'>{option.label}</div>
      </div>
    );
  }, []);

  const itemTemplate = useCallback((option) => {
    return (
      <div className="flex items-center pl-2">
        <i className="pi pi-circle-fill" style={{ color: colors.Crayola, fontSize: '0.346rem' }} />
        <div className='ml-2'>{option.name}</div>
      </div>
    );
  }, []);

  const itemTemplate2 = useCallback((option) => {
    return (
      <div className="flex items-center pl-2">
        <i className="pi pi-circle-fill" style={{ color: colors.Crayola, fontSize: '0.346rem' }} />
        <div className='ml-2'>{option.name} <sup className='text-gray-600'>({option.Category?.type})</sup></div>
      </div>
    );
  }, []);

  const onUpdate = useCallback(async () => {
    if (isEmpty(state.categoria_seleccionada?.id)) return message(null, 'Selecciona una categoría para continuar.');
    if (isEmpty(state?.programa_seleccionado?.id)) return message(null, 'Selecciona un programa para continuar.');
    if (isEmpty(state.name)) return message(null, 'El nombre es obligatorio.');

    const formDataFields = {
      programID: state.programa_seleccionado?.id,
      categoryID: state.categoria_seleccionada?.id,
      name: state.name,
      description: state.description,
      image: ((state.portada_file instanceof FileList && state.portada_file?.length > 0) && [...state.portada_file]),
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["image"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending: true });
      const response = await updateSubCategory(state.subcategoria_seleccionada?.id, _formData);
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Subcategoría actualizada', '¡La subcategoría se ha actualizado exitosamente! Serás redirigido a la página de subcategorías en breve...');
      } else {
        window.PrimeToast.onHide = null;
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la subcategoría. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      window.PrimeToast.onHide = null;
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar la subcategoría. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state, setState, navigate]);

  const onChangeSubCategory = useCallback(async (event) => {
    const value = event.target.value;
    const categoria_seleccionada = state?.listado_categorias.flatMap(item => item.items.filter(subItem => subItem.id === value?.Category?.id));
    const filter_programa_seleccionado = state?.listado_programas?.find(item => parseInt(item?.id) === parseInt(value?.programID));

    setState({
      subcategoria_seleccionada: value,
      categoria_seleccionada: categoria_seleccionada?.[0],
      programa_seleccionado: filter_programa_seleccionado,
      name: value?.name || "",
      description: value?.description || "",
    });
  }, [state, setState]);

  return (
    <>
      <SEO
        title="Editar subcategoría"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col sm:flex-row items-center justify-center sm:justify-between gap-4'>
            <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center sm:text-left'>Editar subcategoría</h1>
            <Dropdown
              value={state?.subcategoria_seleccionada}
              onChange={onChangeSubCategory}
              options={state.listado_subcategorias}
              optionLabel="name"
              optionGroupLabel="label"
              optionGroupChildren="items"
              optionGroupTemplate={groupedItemTemplate}
              itemTemplate={itemTemplate2}
              placeholder="Selecciona subcategoría"
              emptyMessage="No se encontraron resultados"
              className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 transition-all"
              panelClassName="custom-dropdown-panel"
            />
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='mt-10 mx-6 lg:mx-8'>
              <div className='bg-white rounded-lg max-w-md'>
                <div className='flex flex-col px-8 py-10'>
                  {state?.subcategoria_seleccionada ? (
                    <div className='flex flex-col gap-4 mb-12'>
                      <Dropdown
                        value={state?.programa_seleccionado}
                        onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                        options={state.listado_programas}
                        optionLabel="name"
                        placeholder="Selecciona programa"
                        emptyMessage="No se encontraron resultados"
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      />
                      <Dropdown
                        value={state?.categoria_seleccionada}
                        onChange={(event) => setState({ categoria_seleccionada: event.target.value })}
                        options={listado_categorias}
                        optionLabel="name"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        optionGroupTemplate={groupedItemTemplate}
                        itemTemplate={itemTemplate}
                        placeholder="Selecciona categoría"
                        emptyMessage="No se encontraron resultados"
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        panelClassName="custom-dropdown-panel"
                      />
                      <input
                        type="text"
                        placeholder='Nombre'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.name || state?.subcategoria_seleccionada?.name}
                        onChange={(event) => setState({ name: event.target.value })}
                      />
                      <textarea
                        placeholder='Descripción'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={(state?.description || state?.subcategoria_seleccionada?.description) || ""}
                        onChange={(event) => setState({ description: event.target.value })}
                      />
                      <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.portada_file?.length > 0 ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                        <p className='truncate'>{(state.portada_file?.length > 0 && state.portada_file?.[0]?.name) || (state.subcategoria_seleccionada?.image && obtenerNombreImagen(state.subcategoria_seleccionada?.image, true)) || "Sube una portada"}</p>
                        <input ref={refPortadaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_file: event.target.files })} />
                        <button className='underline' onClick={() => refPortadaFile?.current?.click()}>Examinar</button>
                      </div>
                    </div>
                  ) : (
                    <p className='font-golos-medium text-Crayola text-center text-md'>Selecciona una subcategoria para continuar.</p>
                  )}
                  {state?.subcategoria_seleccionada && (
                    <div className='flex flex-wrap justify-center items-center gap-3 mt-14'>
                      <ButtonCustom
                        text="CANCELAR"
                        classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                        onClick={() => navigate(prefix)}
                      />
                      <ButtonCustom
                        text="ACTUALIZAR"
                        classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                        onClick={onUpdate}
                        loading={state.sending}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarSubCategoria;