import React, { useCallback, useEffect, useMemo } from 'react';
import { Image } from 'primereact/image';
import Loading from './Loading';
import Pagination from './Pagination';
import { getWorkouts } from '../api/admin/workouts';
import { getCores } from '../api/admin/cores';
import { getVideos } from '../api/admin/galeriaVideos';
import { useCustomState } from '../hooks/useCustomState';
import paginate from '../utils/paginate';
import onSearchFields from '../utils/onSearchFields';
import { mergeObjects } from '../utils';

const VideosRecomendados = ({ buscador, hideIconSelect, workout_parent, core_parent, video_parent, videos_sugeridos_seleccionados, programa_seleccionado, categoria_seleccionado, subcategoria_seleccionado, onSelectVideoSugerido }) => {
  const [state, setState] = useCustomState({
    loading: false,
    loading_videos: true,
    sending: false,
    page: 1,
    listado_videos: [],
  });
  const isEqualEffect = JSON.stringify(mergeObjects(programa_seleccionado, categoria_seleccionado, subcategoria_seleccionado));

  useEffect(() => {
    reloadWorkouts({
      programa_seleccionado: programa_seleccionado,
      categoria_seleccionado: categoria_seleccionado,
      subcategoria_seleccionado: subcategoria_seleccionado,
    });
    // eslint-disable-next-line
  }, [isEqualEffect]);

  const { data_paginate } = useMemo(() => {
    // Aplicar la lógica de filtrado
    const filteredItems = onSearchFields(state?.listado_videos, {
      searchText: buscador,
      fieldsToSearch: ['title'],
    });

    // Aplicar la lógica de paginación si es necesario
    const data_paginate = filteredItems?.length > 0 ? paginate(filteredItems, state.page, 10) : null;

    return { data_paginate };
  }, [state?.listado_videos, buscador, state.page]);

  const reloadWorkouts = useCallback(async (filters) => {
    setState({ page: 1, loading_videos: true });
    const _filters = {
      program: filters?.programa_seleccionado?.map(item => item?.id),
      category: filters?.categoria_seleccionado?.map(item => item?.id),
      subCategory: filters?.subcategoria_seleccionado?.map(item => item?.id),
    }
    const workouts = await getWorkouts(null, _filters);
    const cores = await getCores(null, _filters);
    const parto = await getVideos(null, _filters);
    const _workouts = workouts?.data?.filter(item => parseInt(item?.id) !== parseInt(workout_parent));
    const _cores = cores?.data?.filter(item => parseInt(item?.id) !== parseInt(core_parent));
    const _parto = parto?.data?.filter(item => parseInt(item?.id) !== parseInt(video_parent));

    setState({
      listado_videos: [..._workouts, ..._cores, ..._parto],
      loading_videos: false,
    });
  }, [workout_parent, core_parent, video_parent, setState]);

  return (
    <>
      {state.loading ? (
        <Loading containerClassName="mt-0" textClassName="text-md" />
      ) : (
        <div className='flex flex-col gap-4'>
          {state.loading_videos ? (
            <Loading onlyIcon />
          ) : (
            <>
              {data_paginate?.items?.length > 0 ? (
                <>
                  <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4'>
                    {data_paginate?.items?.map((item, index) => {
                      const videos_seleccionado_index = videos_sugeridos_seleccionados?.findIndex(elem => (parseInt(elem?.id) === parseInt(item?.id) && elem?.type === item?.type));

                      return (
                        <div key={`video-sugerido-${item?.id || index}-${item?.type}`} className="bg-white rounded-lg">
                          <div className={`bg-Magnolia ${(videos_seleccionado_index !== -1) ? "border-t-[3px] border-Crayola" : "border-t-[3px] border-Magnolia"} px-4 pt-4 rounded-t-lg transition-all`}>
                            <Image
                              src={item?.image}
                              imageClassName="bg-black flex items-center justify-center h-24 object-cover mx-auto w-full rounded-t-lg"
                              onError={(event) => {
                                event.target.onerror = null; // previene un bucle infinito
                                event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                              }}
                            />
                          </div>
                          <div className='flex flex-col bg-Crayola py-1 rounded-b-lg'>
                            <div className='flex flex-row items-center'>
                              <p className='flex-1 font-golos-medium text-white text-left text-sm py-1.5 px-2'>{item?.title}</p>
                              {!hideIconSelect && (
                                <div className='flex items-center py-1.5 px-2 cursor-pointer' onClick={() => onSelectVideoSugerido(item)}>
                                  <i className={`pi ${(videos_seleccionado_index !== -1) ? "pi-times-circle" : "pi-plus-circle"} text-white`} style={{ fontSize: '0.986rem' }} />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {data_paginate?.totalPages > 1 && (
                    <Pagination
                      className="mt-8"
                      pageCurrent={parseInt(state.page)}
                      totalPages={data_paginate.totalPages}
                      onClickPage={(_page) => setState({ page: _page })}
                      onNextPage={() => state.page < data_paginate.totalPages && setState({ page: parseInt(state.page) + 1 })}
                      onPrevPage={() => state.page > 1 && setState({ page: parseInt(state.page) - 1 })}
                      breakpoints={{
                        visiblePageCount: {
                          0: 3,
                          460: 5,
                          760: 7,
                          1200: 9,
                          1460: 11,
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <div className="flex flex-1 items-center justify-center w-full">
                  <p className="font-golos-medium text-center text-Crayola">No se encontraron resultados.</p>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default VideosRecomendados;