import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Chip } from 'primereact/chip';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { OverlayPanel } from 'primereact/overlaypanel';
import SEO from '../../../components/SEO';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import { getDescargables, deleteDescargable } from '../../../api/admin/descargables';
import { getProgramas } from '../../../api/admin/programas';
import { paginationComponentOptions, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
import { isEmpty } from '../../../utils';
const prefix = "/admin/descargables";

const Descargables = () => {
  const op = useRef(null);
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    descargables: [],
    listado_programas: [],
    programa_seleccionado: [],
  });

  const { filters } = useMemo(() => ({
    filters: {
      programas: state.programa_seleccionado,
    }
  }), [state.programa_seleccionado]);

  useEffect(() => {
    loadData(filters);
    // eslint-disable-next-line
  }, [filters]);

  const loadData = useCallback(async (filters) => {
    setState({ loading: true });
    const descargables = await getDescargables(filters);
    const programas = await getProgramas();

    setState({
      descargables: descargables?.data,
      listado_programas: programas?.data,
      loading: false,
    });
  }, [setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    // eslint-disable-next-line
    if (type === "ver") {
      if (params?.id) navigate(`${prefix}/${params?.id}`);
    } else if (type === "editar") {
      if (params?.id) navigate(`${prefix}/editar-descargable/${params?.id}`);
    } else if (type === "eliminar") {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <div className='text-center'>
            <p>¿Estás segura?</p>
            <p>Esta acción no se puede deshacer.</p>
          </div>
        ),
        icon: null,
        rejectClassName: "bg-neutral-400 border-neutral-400 hover:!bg-neutral-500 hover:!border-neutral-500 !py-1",
        acceptClassName: 'p-button-danger !py-1',
        acceptLabel: "Si, eliminar",
        accept: async () => {
          if (params?.id) await deleteDescargable(params?.id);
          loadData();
        },
      });
    }
  }, [loadData, navigate]);

  const { columnsTable } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Nombre',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.name),
        cell: row => row.name
      },
      {
        name: 'Descripción',
        sortable: true,
        fixed: 'left',
        minWidth: '100px',
        selector: row => row.description,
        cell: row => (
          <div className='py-3'>{row.description}</div>
        )
      },
      {
        name: 'Portada',
        sortable: false,
        fixed: 'left',
        width: '140px',
        selector: row => (row.image),
        cell: row => (
          !isEmpty(row.image) ? (
            <a
              href={row.image}
              rel="noreferrer"
              target="_blank"
              className='font-golos-medium bg-Crayola hover:bg-CrayolaHover text-white rounded-lg duration-300 transition-all py-2 px-4'
            >
              Ver portada
            </a>
          ) : ""
        )
      },
      {
        name: 'Descargable',
        sortable: true,
        fixed: 'left',
        width: '170px',
        selector: row => row.file,
        cell: row => (
          !isEmpty(row.file) ? (
            <a
              href={row.file}
              rel="noreferrer"
              target="_blank"
              className='font-golos-medium bg-Crayola hover:bg-CrayolaHover text-white rounded-lg duration-300 transition-all py-2 px-4'
            >
              Ver descargable
            </a>
          ) : ""
        )
      },
      {
        name: 'Programas',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => row.programas?.map(item => item?.Program?.name)?.join(" "),
        cell: row => {
          return (
            <div className='flex flex-wrap gap-1 my-2'>
              {
                row.programas?.map(item => (
                  <Chip className='bg-blue-500 text-white rounded-md font-medium text-xs' label={item?.Program?.name} />
                ))
              }
            </div>
          );
        },
      },
      {
        name: 'Acciones',
        center: true,
        width: '260px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-green-600 hover:bg-green-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "ver")}>Ver</button>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
            <button type="button" className='bg-red-600 hover:bg-red-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "eliminar")}>Eliminar</button>
          </div>
        ),
      },
    ],
  }), [onActionTable]);

  const filteredItems = state.descargables;

  return (
    <>
      <SEO
        title="Descargables"
      />
      <ConfirmPopup />
      <OverlayPanel
        ref={op}
        showCloseIcon={false}
        className={"shadow-sm shadow-[#ddd] max-w-[95%] mx-4"}
      >
        <div className='flex flex-col flex-1 divide-y divide-[#ddd] space-y-4 text-center sm:text-left w-full'>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Programas</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {state.listado_programas?.map((item, index) => (
                <div
                  key={`programa-key-${item?.id || index}`}
                  className={`${state.programa_seleccionado?.includes(item?.id) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    const updatedProgramsSelected = state.programa_seleccionado?.includes(item?.id)
                      ? state.programa_seleccionado?.filter(program => program !== item?.id)
                      : [...state.programa_seleccionado, item?.id];

                    setState({ programa_seleccionado: updatedProgramsSelected });
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OverlayPanel>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col sm:flex-row items-center justify-center sm:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Descargables</h1>
                <div className='inline-flex gap-4'>
                  <ButtonCustom
                    text="Filtros"
                    icon={<i className="pi pi-filter" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={(e) => op.current.toggle(e)}
                  />
                  <ButtonCustom
                    text="Subir nuevo"
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={() => navigate(`${prefix}/crear-descargable`)}
                  />
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader={false}
                  selectableRows={false}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Descargables;