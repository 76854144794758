/**
 * Convierte la duración de un video en segundos a un objeto con horas, minutos y segundos.
 *
 * @param {number} duracion - Duración del video en segundos.
 * @param {Object} opciones - Opciones de formateo (opcional).
 * @param {boolean} [opciones.addZeroToHours] - Indica si se debe agregar un cero a las horas antes del 10.
 * @param {boolean} [opciones.addZeroToMinutes=false] - Indica si se debe agregar un cero a los minutos antes del 10 (predeterminado: false).
 * @param {boolean} [opciones.addZeroToSeconds=true] - Indica si se debe agregar un cero a los segundos antes del 10 (predeterminado: true).
 * @returns {Object} - Objeto con las propiedades 'horas', 'minutos' y 'segundos'.
 *
 * @example
 * // Ejemplo de uso
 * var duracionTotal = 3700;
 * var duracionFormateada = obtenerDuracionFormateada(duracionTotal, {
 *   addZeroToHours: true
 * });
 *
 * console.log(duracionFormateada.horas);    // '01' si addZeroToHours es true, '1' si es false
 * console.log(duracionFormateada.minutos);  // '01' (predeterminado: false)
 * console.log(duracionFormateada.segundos); // '40' (predeterminado: true)
 */
export function obtenerDuracionFormateada(duracion, opciones = {}) {
  duracion = parseInt(duracion);
  var horas = Math.floor(duracion / 3600);
  var minutos = Math.floor((duracion % 3600) / 60);
  var segundos = duracion % 60;

  var formatNumber = function (num, addZero) {
    return addZero && num < 10 ? '0' + num : parseInt(num);
  };

  var duracionFormateada = {
    horas: formatNumber(horas, opciones.addZeroToHours),
    minutos: formatNumber(minutos, opciones.hasOwnProperty('addZeroToMinutes') ? opciones.addZeroToMinutes : false),
    segundos: formatNumber(segundos, opciones.hasOwnProperty('addZeroToSeconds') ? opciones.addZeroToSeconds : true)
  };

  return duracionFormateada;
}

/**
 * Convierte la duración de un video de horas, minutos y segundos a segundos.
 *
 * @param {Object} duracion - Objeto con las propiedades 'horas', 'minutos' y 'segundos'.
 * @returns {number} - Duración del video en segundos.
 *
 * @example
 * // Ejemplo de uso
 * var duracionFormateada = {
 *   horas: 1,
 *   minutos: 30,
 *   segundos: 45
 * };
 *
 * var duracionEnSegundos = convertirASegundos(duracionFormateada);
 * console.log(duracionEnSegundos); // 5445
 */
export function convertirASegundos(duracion) {
  var horas = parseInt(duracion.horas) || 0;
  var minutos = parseInt(duracion.minutos) || 0;
  var segundos = parseInt(duracion.segundos) || 0;

  var duracionEnSegundos = (horas * 3600) + (minutos * 60) + segundos;
  return parseInt(duracionEnSegundos || 0);
}