import React, { useCallback, useContext, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NivelIcon } from '../../assets/icons/selec-nivel.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/icon-check.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/btn-close.svg';
import GlobalContext from '../../contexts/GlobalContext';
import { optionsToast } from '../../config/toast';
import { updatePerfil } from '../../api/client/perfil';
import getDescripcionNiveles from '../../misc/getDescripcionNiveles';
import { useCustomState } from '../../hooks/useCustomState';
import { selectUser, updateUser } from '../../redux/reducers/authSlice';
import { isEmpty } from '../../utils';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { ButtonPrimary } from '../Button';

function SeleccionarNivelModal({ stylesContainer }) {
  const modalType = "seleccionar_nivel";
  const { showModal, typeModal, setModal } = useContext(GlobalContext);
  const user = useSelector(selectUser);
  const [state, setState] = useCustomState({
    sending: false,
    niveles: [
      {
        name: "principiante",
        description: getDescripcionNiveles.principiante,
      },
      {
        name: "intermedio",
        description: getDescripcionNiveles.intermedio,
      },
      {
        name: "avanzado",
        description: getDescripcionNiveles.avanzado,
      },
    ],
    nivel_seleccionado: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setState({ nivel_seleccionado: user?.level });
  }, [user?.level, setState]);

  const message = (summary, detail, life) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
      life: life,
    });
  }

  const closeModal = useCallback(() => {
    setModal(modalType);
  }, [setModal, modalType]);

  const onAccept = useCallback(async (event) => {
    event?.preventDefault();
    if (isEmpty(state.nivel_seleccionado)) return message(null, "Selecciona un nivel para continuar");

    try {
      setState({ sending: true });

      const response = await updatePerfil(user?.id, { level: state.nivel_seleccionado });

      setState({ sending: false });

      if (response?.status) {
        closeModal();
        dispatch(updateUser({ user: { level: state.nivel_seleccionado } }));
        message('Nivel guardado', "El nivel seleccionado se ha guardado con éxito");
      } else {
        message('Ocurrio un problema', response?.mensaje || 'Lo sentimos, ocurrió un error al guardar el nivel seleccionado. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al guardar el nivel seleccionado. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [user?.id, state.nivel_seleccionado, closeModal, setState, dispatch]);

  return (
    <Dialog
      visible={showModal && typeModal === modalType}
      onHide={closeModal}
      blockScroll={true}
      dismissableMask={true}
      draggable={false}
      closeIcon={<CloseIcon />}
      headerClassName="!rounded-t-xl"
      contentClassName="!rounded-b-xl -mt-[1px]"
      headerStyle={{ paddingBottom: 0 }}
      style={{ marginLeft: 15, marginRight: 15 }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
    >
      <div {...stylesContainer}>
        <div className='flex justify-center mb-3 animate__animated animate__slideInDown'>
          <NivelIcon height={50} width={50} />
        </div>
        <div className='animate__animated animate__slideInUp'>
          <h2 className='font-golos-semibold text-center text-3xl text-DeepKoamaru -mb-2'>Selecciona</h2>
          <h2 className='font-golos-semibold text-center text-3xl text-DeepKoamaru'>tu nivel</h2>
        </div>
        <div className='flex flex-col gap-4 mt-7 animate__animated animate__fadeIn'>
          {state.niveles?.map((item, index) => (
            <div
              key={`nivel-key-${index}`}
              className='max-w-[320px]'
              onClick={() => setState({ nivel_seleccionado: item?.name })}
            >
              <div
                className={`${item?.name === state.nivel_seleccionado
                  ? "bg-Orchid border border-Orchid"
                  : "bg-white border border-gray-200"
                  } relative rounded-xl py-4 px-4 c-pointer transition-all`}
              >
                {item?.name === state.nivel_seleccionado && (
                  <div className='absolute right-3 top-3'>
                    <CheckIcon height={22} width={22} />
                  </div>
                )}
                <p
                  className={`${item?.name === state.nivel_seleccionado
                    ? "text-white"
                    : "text-Crayola"
                    } text-lg font-golos-semibold mb-1 capitalize text-left`}
                >
                  Nivel {item?.name}
                </p>
                <p
                  className={`${item?.name === state.nivel_seleccionado
                    ? "text-white"
                    : "text-text"
                    } font-golos text-sm text-left`}
                >
                  {item?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <ButtonPrimary
          loading={state.sending}
          text={"Actualizar"}
          classNameContainer={"mt-8 animate__animated animate__fadeIn"}
          classNameButton={"!bg-Orchid !border-Orchid !text-[1rem] justify-center py-3.5 w-full"}
          onClick={onAccept}
        />
      </div>
    </Dialog>
  );
}

export default SeleccionarNivelModal;