import axios from "../";
const prefix = "/config";

export const getApiKeys = async () => {
  try {
    const response = await axios.get(`${prefix}/api_keys`);
    return response.data;
  } catch (error) {
    throw error;
  }
};