import ExclamationIcon from "../../assets/icons/exclamation.svg";

const Container = {
  background: "linear-gradient(to bottom, #342566, #7e73d6, #342566)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  maxWidth: "100%",
  paddingLeft: "1em",
  paddingRight: "1em",
  paddingTop: "1em",
  paddingBottom: "1em",
  textAlign: "center",
};

const Icon = () => (
  <img
    src={ExclamationIcon}
    alt="Exclamation Icon"
    style={{
      height: "60px",
      color: "#FFC107",
      marginBottom: "20px",
    }}
  />
);

const Title = {
  fontSize: "48px",
  color: "#fff",
  marginBottom: "20px",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
};

const Message = {
  fontSize: "24px",
  color: "#fff",
  marginBottom: "30px",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
};

export {
  Container,
  Icon,
  Title,
  Message,
}