import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { optionsToast } from '../../../config/toast';
import Loading from '../../../components/Loading';
import { getPerfiles } from '../../../api/admin/perfiles';
import { createUsuario } from '../../../api/admin/usuarios';
import { isEmail, isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
const prefix = "/admin/usuarios";

const CrearUsuario = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    firstName: "",
    lastName: "",
    lastName2: "",
    correo_electronico: "",
    perfil_seleccionado: "",
    contrasenia: "",
    perfiles: [],
  });

  useEffect(() => {
    (async () => {
      const perfiles = await getPerfiles();
      if (perfiles?.status === true) {
        setState({
          perfiles: perfiles?.data,
          loading: false,
        });
      }
    })();
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onCreate = useCallback(async () => {
    if (isEmpty(state.firstName)) return message(null, 'El nombre es obligatorio.');
    if (isEmpty(state?.perfil_seleccionado?.id)) return message(null, 'Selecciona un perfil para continuar.');
    if (isEmpty(state.correo_electronico)) return message(null, 'El correo electrónico es obligatorio.');
    if (!isEmail(state.correo_electronico)) return message(null, 'El correo electrónico es incorrecto o inválido.');
    if (isEmpty(state.contrasenia)) return message(null, 'La contraseña es obligatoria.');

    try {
      setState({ sending: true });
      const response = await createUsuario({
        profileID: state?.perfil_seleccionado?.id,
        firstName: state.firstName,
        lastName: state.lastName,
        lastName2: state.lastName2,
        email: state.correo_electronico,
        password: state.contrasenia,
      });
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Usuario creado', '¡El usuario se ha creado exitosamente! Serás redirigido a la página de usuarios en breve...');
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el usuario. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el usuario. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state, setState, navigate]);

  return (
    <>
      <SEO
        title="Crear nuevo usuario"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Crear nuevo usuario</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg max-w-md'>
              <div className='flex flex-col gap-4 mb-12'>
                <input
                  type="text"
                  placeholder='Nombre'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.firstName}
                  onChange={(event) => setState({ firstName: event.target.value })}
                />
                <input
                  type="text"
                  placeholder='Apellido paterno'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.lastName}
                  onChange={(event) => setState({ lastName: event.target.value })}
                />
                <input
                  type="text"
                  placeholder='Apellido materno'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.lastName2}
                  onChange={(event) => setState({ lastName2: event.target.value })}
                />
                <Dropdown
                  value={state?.perfil_seleccionado}
                  onChange={(event) => setState({ perfil_seleccionado: event.target.value })}
                  options={state.perfiles}
                  optionLabel="name"
                  placeholder="Selecciona perfil"
                  emptyMessage="No se encontraron resultados"
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                />
                <input
                  type="email"
                  autoComplete="email"
                  placeholder='Correo electrónico'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.correo_electronico}
                  onChange={(event) => setState({ correo_electronico: event.target.value })}
                />
                <input
                  type="password"
                  placeholder='Contraseña'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.contrasenia}
                  onChange={(event) => setState({ contrasenia: event.target.value })}
                />
              </div>
              <div className='flex justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text="CANCELAR"
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                />
                <ButtonCustom
                  text="CREAR"
                  classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                  onClick={onCreate}
                  loading={state.sending}
                />
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default CrearUsuario;