import QueryString from "qs";
import axios from "../";
const prefix = "/posts";

export const getPosts = async (params) => {
  try {
    const _params = QueryString.stringify({
      ...params,
    });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};