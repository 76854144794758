import QueryString from "qs";
import axios from "../";
const prefix = "/cores";

export const getCores = async (id, params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/${id || ""}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRecommendedCores = async (id) => {
  try {
    const response = await axios.get(`${prefix}/videos_sugeridos/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoriesCores = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/categorias?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSubCategoriesCores = async (id, params) => {
  try {
    const _params = QueryString.stringify({ ...params, count_cores: true }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/subcategorias/${id}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};