import qs from 'qs';
import axios from "../";
const prefix = "/galeria_videos";

export const getVideos = async (id, filters, _params) => {
  try {
    const params = qs.stringify({
      populate: ["programa", "categoria", "sub_categoria", "seccion"],
      filters: filters,
      ..._params
    }, { arrayFormat: 'brackets' });
    const endpoint = id ? `/${id}` : "";
    const response = await axios.get(`${prefix}/${endpoint}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRecommendedVideos = async (id, _params) => {
  try {
    const params = qs.stringify({
      ..._params
    }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/videos_sugeridos/${id}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoriesPartos = async (params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/categorias?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSubCategoriesPartos = async (id, params) => {
  try {
    const _params = qs.stringify({ ...params, count_videos: true }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/subcategorias/${id}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};