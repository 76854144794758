import qs from 'qs';
import axios from "../";
const prefix = "admin/clientas";

export const getClientas = async (id, filters, params = {}) => {
  try {
    const _params = qs.stringify({
      populate: ["program"],
      filters: filters,
      ...params,
    });
    const endpoint = id ? `/${id}` : "";
    const response = await axios.get(`/${prefix}/${endpoint}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createClienta = async (params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`/${prefix}/nueva-clienta`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateClienta = async (id, params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.put(`/${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteClienta = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logoutClienta = async (id) => {
  try {
    const response = await axios.post(`/${prefix}/cerrar-sesion`, { userID: id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportClienta = async (filters, _params) => {
  try {
    const params = qs.stringify({
      populate: ["program"],
      filters: filters,
    });
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`/${prefix}/exportar-xls?${params}`, _params, {
      responseType: 'blob',
      timeout: 0,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};