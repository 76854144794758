import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import SEO from '../../../components/SEO';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { getSecciones } from '../../../api/admin/secciones';
import { paginationComponentOptions, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
const prefix = "/admin/secciones";

const Secciones = () => {
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    secciones: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    setState({ loading: true });
    const secciones = await getSecciones();
    setState({
      secciones: secciones?.data,
      loading: false,
    });
  }, [setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    // eslint-disable-next-line
    if (type === "editar") {
      if (params?.id) navigate(`${prefix}/editar-seccion/${params?.id}`);
    }
  }, [navigate]);

  const { columnsTable } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Nombre',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.name),
        cell: row => row.name
      },
      {
        name: 'Descripción',
        sortable: true,
        fixed: 'left',
        minWidth: '100px',
        selector: row => (row.description),
        cell: row => (
          <div className='py-2'>{row.description}</div>
        )
      },
      {
        name: 'Descripción de Embarazo',
        sortable: true,
        fixed: 'left',
        minWidth: '200px',
        selector: row => (row.description_embarazo),
        cell: row => (
          <div className='py-2'>{row.description_embarazo}</div>
        )
      },
      {
        name: 'Descripción de Posparto',
        sortable: true,
        fixed: 'left',
        minWidth: '200px',
        selector: row => (row.description_posparto),
        cell: row => (
          <div className='py-2'>{row.description_posparto}</div>
        )
      },
      {
        name: 'Descripción de Strength',
        sortable: true,
        fixed: 'left',
        minWidth: '200px',
        selector: row => (row.description_strength),
        cell: row => (
          <div className='py-2'>{row.description_strength}</div>
        )
      },
      {
        name: 'Acciones',
        center: true,
        width: '100px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
          </div>
        ),
      },
    ],
  }), [onActionTable]);

  const filteredItems = state.secciones;

  return (
    <>
      <SEO
        title="Secciones"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap items-center justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Secciones</h1>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader={false}
                  selectableRows={false}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Secciones;