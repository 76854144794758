import { ReactComponent as EyeHideIcon } from "../../assets/icons/eye-hide-icon.svg";
import { ReactComponent as EyeViewIcon } from "../../assets/icons/eye-view-icon.svg";
import { ButtonPrimary } from "../Button";

const LoginForm = ({ data, onChange, onSubmit, onClickForgotPassword }) => (
  <form className="form-login space-y-6 mt-8 animate__animated animate__slideInUp" onSubmit={onSubmit}>
    <input
      name="correo_electronico"
      type="email"
      autoComplete="email"
      placeholder='Correo electrónico'
      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
      value={data?.correo_electronico}
      onChange={(event) => onChange({ correo_electronico: event.target.value })}
    />
    <div className='relative flex items-center'>
      <input
        name="contrasenia"
        type={data.visiblePassword ? "text" : "password"}
        autoComplete="current-password"
        required
        placeholder='Contraseña'
        className="pr-10 bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
        value={data?.contrasenia}
        onChange={(event) => onChange({ contrasenia: event.target.value })}
      />
      <span className='absolute right-0 p-3 c-pointer z-10' onClick={() => onChange({ visiblePassword: !data.visiblePassword })}>
        {data.visiblePassword ? <EyeHideIcon height={16} width={16} /> : <EyeViewIcon height={16} width={16} />}
      </span>
    </div>
    <div className=''>
      <label className='flex items-center justify-center gap-3 c-pointer'>
        <input
          checked={data.is_remember}
          type="checkbox"
          className="bg-Magnolia !border-0 !outline-none !shadow-none !ring-0 !ring-offset-0 p-3 !rounded-md text-Crayola transition-all"
          onChange={(event) => onChange({ is_remember: event.target.checked })}
        />
        <span className="text-sm">Recuerdame en este dispositivo.</span>
      </label>
    </div>
    <div>
      <ButtonPrimary
        text="Ingresa a tu cuenta"
        withIcon
        classNameContainer="flex justify-center mt-4"
        classNameButton="px-6"
        type="submit"
        loading={data.sending}
        onClick={onSubmit}
      />
    </div>
    <div className="flex justify-center">
      <p className='inline-block font-golos text-center text-muted text-Orchid underline cursor-pointer p-1' onClick={onClickForgotPassword}>
        ¿Olvidaste tu contraseña?
      </p>
    </div>
  </form>
);

export default LoginForm;