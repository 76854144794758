import React, { memo, useCallback } from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ReactComponent as EyeHideIcon } from "../../assets/icons/eye-hide-icon.svg";
import { ReactComponent as EyeViewIcon } from "../../assets/icons/eye-view-icon.svg";
import { ButtonPrimary } from "../Button";
import { apiKeys } from '../../config/app';
import { optionsToast } from "../../config/toast";

const RegisterForm = ({ data, onChange, onSubmit }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const message = useCallback((summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }, []);

  const handleSend = useCallback(async (event) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      message('La protección de reCAPTCHA no está disponible.');
      return;
    }

    try {
      const token = await executeRecaptcha('submit');
      return onSubmit(event, token);
    } catch (error) {
      message("Ocurrio un error con la verificación de reCAPTCHA");
    }
  }, [onSubmit, executeRecaptcha, message]);

  return (
    <form className="form-register space-y-6 mt-8 animate__animated animate__slideInUp" onSubmit={handleSend}>
      <input
        name="nombre"
        type="text"
        required
        placeholder='Nombre'
        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
        value={data?.nombre}
        onChange={(event) => onChange({ nombre: event.target.value })}
      />
      <input
        name="correo_electronico"
        type="email"
        autoComplete="email"
        required
        placeholder='Correo electrónico'
        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
        value={data?.correo_electronico}
        onChange={(event) => onChange({ correo_electronico: event.target.value })}
      />
      <div className='relative flex items-center'>
        <input
          name="contrasenia"
          type={data.visiblePassword ? "text" : "password"}
          autoComplete="current-password"
          required
          placeholder='Contraseña'
          className="pr-10 bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
          value={data?.contrasenia}
          onChange={(event) => onChange({ contrasenia: event.target.value })}
        />
        <span className='absolute right-0 p-3 c-pointer z-10' onClick={() => onChange({ visiblePassword: !data.visiblePassword })}>
          {data.visiblePassword ? <EyeHideIcon height={16} width={16} /> : <EyeViewIcon height={16} width={16} />}
        </span>
      </div>
      <div className='flex items-center justify-center'>
        <div id="grecaptcha" />
      </div>
      <div>
        <ButtonPrimary
          text="Regístrate"
          withIcon
          classNameContainer="flex justify-center mt-4"
          classNameButton="px-6"
          type="submit"
          loading={data.sending}
          onClick={handleSend}
        />
      </div>
    </form>
  );
};

const App = (props) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={apiKeys.captcha}
    language={"es"}
    scriptProps={{
      defer: true, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
    container={{ // optional to render inside custom element
      element: "grecaptcha",
      parameters: {
        badge: 'inline', // optional, default undefined
        theme: 'light', // optional, default undefined
      }
    }}
  >
    <RegisterForm {...props} />
  </GoogleReCaptchaProvider>
);

export default memo(App);