import React from 'react';
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const PoliticaPrivacidad = () => {
  return (
    <>
      <SEO
        title="Política de privacidad"
        description="Esta Política de privacidad describe nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de Su información cuando utiliza el Servicio y le informa sobre Sus derechos de privacidad y cómo la ley lo protege."
      />
      <div className={`bg-white`}>
        <HeaderNav
          theme="white"
        />
      </div>

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto mt-20 mb-2 py-10">
          <div className='flex justify-center relative px-6 w-full'>
            <div className='w-full bg-white color-[#676a78] py-10 px-6 rounded-xl shadow-md shadow-DeepKoamaru/16'>
              <div id="contentDocument" className='break-word'>
                <>
                  <p style={{ marginBottom: "14pt", lineHeight: "normal", fontSize: "24pt" }}>
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      POLÍTICA DE PRIVACIDAD
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Última actualización: 03 Marzo del 2022
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Esta Política de privacidad describe nuestras políticas y procedimientos
                      sobre la recopilación, el uso y la divulgación de Su información cuando
                      utiliza el Servicio y le informa sobre Sus derechos de privacidad y cómo
                      la ley lo protege.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Usamos sus datos personales para proporcionar y mejorar el Servicio. Al
                      usar el Servicio, acepta la recopilación y el uso de información de
                      acuerdo con esta Política de privacidad.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      INTERPRETACIÓN Y DEFINICIONES
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Interpretación
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Las palabras cuya letra inicial está en mayúscula tienen significados
                      definidos bajo las siguientes condiciones. Las siguientes definiciones
                      tendrán el mismo significado independientemente de que aparezcan en
                      singular o en plural.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Definiciones
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      A los efectos de esta Política de Privacidad:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Cuenta{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        significa una cuenta única creada para que Usted acceda a nuestro
                        Servicio o partes de nuestro Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Negocio{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        , a los efectos de la CCPA (Ley de Privacidad del Consumidor de
                        California), se refiere a la Compañía como la entidad legal que recopila
                        la información personal de los Consumidores y determina los propósitos y
                        medios del procesamiento de la información personal de los Consumidores,
                        o en nombre de la cual dicha se recopila la información y que solo, o
                        junto con otros, determina los propósitos y medios del procesamiento de
                        la información personal de los consumidores, que hace negocios en el
                        Estado de California.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        La Compañía{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        (referida como "la Compañía", "Nosotros", "Nosotros" o "Nuestro" en este
                        Acuerdo) se refiere a Jime Fit Mom, 2804 E Bluebonnet Ln. A los efectos
                        del RGPD, la Compañía es el Controlador de datos .
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Consumidor{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        , a los efectos de la CCPA (Ley de Privacidad del Consumidor de
                        California), significa una persona física que es residente de
                        California. Un residente, tal como se define en la ley, incluye (1) toda
                        persona que se encuentre en los EE. UU. por un motivo que no sea
                        temporal o transitorio, y (2) toda persona domiciliada en los EE. UU.
                        que se encuentre fuera de los EE. UU. por un motivo temporal o
                        transitorio . finalidad transitoria.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        cookies{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        son pequeños archivos que un sitio web coloca en su computadora,
                        dispositivo móvil o cualquier otro dispositivo, y que contienen los
                        detalles de su historial de navegación en ese sitio web entre sus muchos
                        usos.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        País{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        se refiere a: Texas, Estados Unidos
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Controlador de datos{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        , a los efectos del RGPD (Reglamento General de Protección de Datos), se
                        refiere a la Compañía como la persona jurídica que sola o junto con
                        otras determina los propósitos y medios del procesamiento de Datos
                        Personales.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Dispositivo{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        significa cualquier dispositivo que pueda acceder al Servicio, como una
                        computadora, un teléfono celular o una tableta digital.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Do Not Track{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        (DNT) es un concepto que han promovido las autoridades reguladoras de
                        EE. UU., en particular la Comisión Federal de Comercio (FTC) de EE. UU.,
                        para que la industria de Internet desarrolle e implemente un mecanismo
                        que permita a los usuarios de Internet controlar el seguimiento de sus
                        datos en línea. actividades en los sitios web.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Facebook Fan Page{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        es un perfil público llamado Jime Fit Mom creado específicamente por la
                        Compañía en la red social Facebook, accesible desde{" "}
                      </span>
                      <a
                        href="https://www.facebook.com/jimefitmom/"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://www.facebook.com/jimefitmom/
                        </span>
                      </a>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Los datos personales{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        son cualquier información relacionada con una persona identificada o
                        identificable. A los efectos del RGPD, los datos personales significan
                        cualquier información relacionada con usted, como un nombre, un número
                        de identificación, datos de ubicación, identificador en línea o uno o
                        más factores específicos de la identidad física, fisiológica, genética,
                        mental, económica, cultural o social. Para los fines de la CCPA, los
                        datos personales significan cualquier información que identifique, se
                        relacione, describa o pueda asociarse con, o podría vincularse
                        razonablemente, directamente o indirectamente, contigo.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Venta{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        , a los efectos de la CCPA (Ley de Privacidad del Consumidor de
                        California), significa vender, alquilar, divulgar, divulgar, difundir,
                        poner a disposición, transferir o comunicar de otra manera oralmente,
                        por escrito, por medios electrónicos o de otro tipo, la información
                        personal de un Consumidor. a otra empresa o a un tercero a cambio de una
                        contraprestación monetaria o de otro valor.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Servicio{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        se refiere al sitio web.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Proveedor de servicios
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        significa cualquier persona física o jurídica que procesa los datos en
                        nombre de la Compañía. Se refiere a empresas de terceros o personas
                        empleadas por la Empresa para facilitar el Servicio, proporcionar el
                        Servicio en nombre de la Empresa, realizar servicios relacionados con el
                        Servicio o ayudar a la Empresa a analizar cómo se utiliza el Servicio. A
                        los efectos del RGPD, los Proveedores de servicios se consideran
                        Procesadores de datos.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Servicio de redes sociales de terceros{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        se refiere a cualquier sitio web o sitio web de red social a través del
                        cual un Usuario puede iniciar sesión o crear una cuenta para usar el
                        Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Los Datos de uso{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        se refieren a los datos recopilados automáticamente, ya sea generados
                        por el uso del Servicio o por la propia infraestructura del Servicio
                        (por ejemplo, la duración de una visita a la página).
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        sitio web{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        se refiere a JimeFitMom, accesible desde{" "}
                      </span>
                      <a href="https://jimefitmom.com/" style={{ textDecoration: "none" }}>
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://jimefitmom.com
                        </span>
                      </a>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Usted{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        se refiere a la persona que accede o utiliza el Servicio, o la empresa u
                        otra entidad legal en nombre de la cual dicha persona accede o utiliza
                        el Servicio, según corresponda. Según el RGPD (Reglamento general de
                        protección de datos), se le puede denominar el Sujeto de datos o como
                        Usuario, ya que usted es la persona que utiliza el Servicio.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      RECOPILACIÓN Y USO DE SUS DATOS PERSONALES
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Tipos de datos recopilados
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      INFORMACIÓN PERSONAL
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta
                      información de identificación personal que se puede usar para contactarlo
                      o identificarlo. La información de identificación personal puede incluir,
                      entre otros:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Dirección de correo electrónico
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Nombre y apellido
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Número de teléfono
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Dirección, estado, provincia, código postal, ciudad
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Datos de uso
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      DATOS DE USO
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Los Datos de uso se recopilan automáticamente cuando se utiliza el
                      Servicio.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Los datos de uso pueden incluir información como la dirección del
                      protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el
                      tipo de navegador, la versión del navegador, las páginas de nuestro
                      Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a
                      esas páginas, dispositivo único identificadores y otros datos de
                      diagnóstico.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Cuando accede al Servicio a través de un dispositivo móvil, podemos
                      recopilar cierta información automáticamente, que incluye, entre otros, el
                      tipo de dispositivo móvil que utiliza, la identificación única de su
                      dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema
                      operativo, el tipo de navegador de Internet móvil que utiliza,
                      identificadores únicos de dispositivos y otros datos de diagnóstico.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      También podemos recopilar información que su navegador envía cada vez que
                      visita nuestro Servicio o cuando accede al Servicio a través de un
                      dispositivo móvil.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      INFORMACIÓN DE SERVICIOS DE MEDIOS SOCIALES DE TERCEROS
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía le permite crear una cuenta e iniciar sesión para usar el
                      Servicio a través de los siguientes Servicios de redes sociales de
                      terceros:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Google
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Facebook
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        TWITTER
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Si decide registrarse o concedernos acceso a un Servicio de redes sociales
                      de terceros, podemos recopilar datos personales que ya están asociados con
                      la cuenta de Su Servicio de redes sociales de terceros, como Su nombre, Su
                      dirección de correo electrónico, Sus actividades. o Su lista de contactos
                      asociada con esa cuenta.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      También puede tener la opción de compartir información adicional con la
                      Compañía a través de la cuenta de su Servicio de redes sociales de
                      terceros. Si elige proporcionar dicha información y Datos personales,
                      durante el registro o de otra manera, le está dando permiso a la Compañía
                      para usarla, compartirla y almacenarla de manera consistente con esta
                      Política de privacidad.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      TECNOLOGÍAS DE SEGUIMIENTO Y COOKIES
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Usamos Cookies y tecnologías de seguimiento similares para rastrear la
                      actividad en Nuestro Servicio y almacenar cierta información. Las
                      tecnologías de seguimiento utilizadas son balizas, etiquetas y scripts
                      para recopilar y rastrear información y para mejorar y analizar Nuestro
                      Servicio. Las tecnologías que utilizamos pueden incluir:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Cookies o Cookies del Navegador.
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Una cookie es un pequeño archivo que se coloca en su dispositivo. Puede
                        indicar a su navegador que rechace todas las cookies o que indique
                        cuándo se envía una cookie. Sin embargo, si no acepta las Cookies, es
                        posible que no pueda utilizar algunas partes de nuestro Servicio. A
                        menos que haya ajustado la configuración de su navegador para que
                        rechace las Cookies, nuestro Servicio puede usar Cookies.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Cookies Flash.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ciertas características de nuestro Servicio pueden usar objetos
                        almacenados localmente (o Flash Cookies) para recopilar y almacenar
                        información sobre Sus preferencias o Su actividad en nuestro Servicio.
                        Las cookies de Flash no se gestionan con la misma configuración del
                        navegador que las utilizadas para las cookies del navegador. Para
                        obtener más información sobre cómo puede eliminar las cookies de Flash,
                        lea «¿Dónde puedo cambiar la configuración para deshabilitar o eliminar
                        objetos locales compartidos?» disponible en{" "}
                      </span>
                      <a
                        href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                        </span>
                      </a>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        &nbsp;
                      </span>
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Web Gifts.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ciertas secciones de nuestro Servicio y nuestros correos electrónicos
                        pueden contener pequeños archivos electrónicos conocidos como balizas
                        web (también denominados gifs transparentes, etiquetas de píxeles y gifs
                        de un solo píxel) que permiten a la Compañía, por ejemplo, contar los
                        usuarios que han visitado esas páginas. o abrió un correo electrónico y
                        para otras estadísticas relacionadas con el sitio web (por ejemplo,
                        registrar la popularidad de una determinada sección y verificar la
                        integridad del sistema y del servidor).
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Las Cookies pueden ser Cookies «Persistentes» o «Sesión». Las cookies
                      persistentes permanecen en su computadora personal o dispositivo móvil
                      cuando se desconecta, mientras que las cookies de sesión se eliminan tan
                      pronto como cierra su navegador web. Puede obtener más información sobre
                      las cookies dentro de la política de cookies publicada en el sitio web{" "}
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Utilizamos cookies de sesión y persistentes para los fines establecidos a
                      continuación:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Cookies necesarias/esenciales
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tipo: Cookies de sesión Administradas por: Nosotros Propósito: Estas
                        Cookies son esenciales para brindarle los servicios disponibles a través
                        del sitio web y permitirle usar algunas de sus funciones. Ayudan a
                        autenticar a los usuarios y previenen el uso fraudulento de cuentas de
                        usuario. Sin estas Cookies, no se pueden proporcionar los servicios que
                        ha solicitado, y solo utilizamos estas Cookies para proporcionarle esos
                        servicios.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Política de Cookies / Aceptación
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        de Avisos Tipo de Cookies: Cookies Persistentes Administradas por:
                        Nosotros Finalidad: Estas Cookies identifican si los usuarios han
                        aceptado el uso de cookies en el Sitio Web.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Cookies de funcionalidad
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        : Cookies persistentes Administradas por: Nosotros Propósito: Estas
                        cookies nos permiten recordar las elecciones que hace cuando utiliza el
                        sitio web, como recordar sus datos de inicio de sesión o preferencia de
                        idioma. El propósito de estas Cookies es brindarle una experiencia más
                        personal y evitar que tenga que volver a ingresar sus preferencias cada
                        vez que use el sitio web.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Cookies de seguimiento y rendimiento{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tipo: Cookies persistentes Administradas por: Terceros Propósito: Estas
                        cookies se utilizan para rastrear información sobre el tráfico al sitio
                        web y cómo los usuarios usan el sitio web. La información recopilada a
                        través de estas cookies puede identificarlo directa o indirectamente
                        como un visitante individual. Esto se debe a que la información
                        recopilada generalmente está vinculada a un identificador seudónimo
                        asociado con el dispositivo que utiliza para acceder al sitio web.
                        También podemos usar estas cookies para probar nuevas páginas,
                        características o nuevas funciones del sitio web para ver cómo
                        reaccionan nuestros usuarios.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Cookies de orientación y publicidad{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tipo: Cookies persistentes Administradas por: Terceros Propósito: Estas
                        Cookies rastrean sus hábitos de navegación para permitirnos mostrar
                        publicidad que es más probable que le interese. Estas cookies utilizan
                        información sobre su historial de navegación para agruparlo con otros
                        usuarios que tienen intereses similares. Según esa información, y con
                        nuestro permiso, los anunciantes de terceros pueden colocar cookies para
                        permitirles mostrar anuncios que creemos que serán relevantes para sus
                        intereses mientras se encuentra en sitios web de terceros.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Para obtener más información sobre las cookies que utilizamos y sus
                      opciones con respecto a las cookies, visite nuestra Política de cookies o
                      la sección Cookies de nuestra Política de privacidad.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Uso de sus datos personales
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía puede utilizar los Datos Personales para los siguientes
                      propósitos:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para proporcionar y mantener nuestro Servicio{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        , incluso para monitorear el uso de nuestro Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para gestionar Su Cuenta:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        para gestionar Su registro como usuario del Servicio. Los Datos
                        Personales que proporcione pueden darle acceso a diferentes
                        funcionalidades del Servicio que están disponibles para Usted como
                        usuario registrado.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para la ejecución de un contrato:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        el desarrollo, cumplimiento y realización del contrato de compra de los
                        productos, artículos o servicios que haya adquirido o de cualquier otro
                        contrato con Nosotros a través del Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para contactarlo:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        para contactarlo por correo electrónico, llamadas telefónicas, SMS u
                        otras formas equivalentes de comunicación electrónica, como
                        notificaciones push de una aplicación móvil sobre actualizaciones o
                        comunicaciones informativas relacionadas con las funcionalidades,
                        productos o servicios contratados, incluidas las actualizaciones de
                        seguridad, cuando sea necesario o razonable para su implementación.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para proporcionarle{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        noticias, ofertas especiales e información general sobre otros bienes,
                        servicios y eventos que ofrecemos que son similares a los que ya compró
                        o preguntó, a menos que haya optado por no recibir dicha información.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para gestionar Sus solicitudes:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Para atender y gestionar Sus solicitudes hacia Nosotros.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para transferencias comerciales:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        podemos usar su información para evaluar o realizar una fusión, venta,
                        reestructuración, reorganización, disolución u otra venta o
                        transferencia de algunos o todos nuestros activos, ya sea como una
                        empresa en marcha o como parte de una quiebra, liquidación, o
                        procedimiento similar, en el que los Datos personales que tenemos sobre
                        los usuarios de nuestro Servicio se encuentran entre los activos
                        transferidos.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para otros fines{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        : podemos utilizar su información para otros fines, como el análisis de
                        datos, la identificación de tendencias de uso, la determinación de la
                        eficacia de nuestras campañas promocionales y para evaluar y mejorar
                        nuestro Servicio, productos, servicios, marketing y su experiencia.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos compartir su información personal en las siguientes situaciones:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Con proveedores de servicios:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        podemos compartir su información personal con proveedores de servicios
                        para monitorear y analizar el uso de nuestro servicio, para mostrarle
                        anuncios para ayudar a respaldar y mantener nuestro servicio, para el
                        procesamiento de pagos, para contactarlo.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Para transferencias comerciales:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Podemos compartir o transferir Su información personal en relación con,
                        o durante las negociaciones de, cualquier fusión, venta de activos de la
                        Compañía, financiamiento o adquisición de todo o una parte de Nuestro
                        negocio a otra compañía.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Con afiliados:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        podemos compartir su información con nuestros afiliados, en cuyo caso
                        exigiremos a esos afiliados que respeten esta Política de privacidad.
                        Los afiliados incluyen nuestra empresa matriz y cualquier otra
                        subsidiaria, socios de empresas conjuntas u otras empresas que
                        controlamos o que están bajo control común con nosotros.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Con socios comerciales:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Podemos compartir Su información con Nuestros socios comerciales para
                        ofrecerle ciertos productos, servicios o promociones.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Con otros usuarios:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        cuando comparte información personal o interactúa en las áreas públicas
                        con otros usuarios, dicha información puede ser vista por todos los
                        usuarios y puede distribuirse públicamente al exterior. Si interactúa
                        con otros usuarios o se registra a través de un Servicio de redes
                        sociales de terceros, Sus contactos en el Servicio de redes sociales de
                        terceros pueden ver Su nombre, perfil, imágenes y descripción de Su
                        actividad. De manera similar, otros usuarios podrán ver descripciones de
                        Su actividad, comunicarse con Usted y ver Su perfil.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Con su consentimiento{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        : podemos divulgar su información personal para cualquier otro propósito
                        con su consentimiento.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Retención de sus datos personales
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía conservará sus Datos personales solo durante el tiempo que sea
                      necesario para los fines establecidos en esta Política de privacidad.
                      Conservaremos y utilizaremos sus datos personales en la medida necesaria
                      para cumplir con nuestras obligaciones legales (por ejemplo, si estamos
                      obligados a conservar sus datos para cumplir con las leyes aplicables),
                      resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía también conservará los Datos de uso para fines de análisis
                      interno. Los Datos de uso generalmente se retienen por un período de
                      tiempo más corto, excepto cuando estos datos se utilizan para fortalecer
                      la seguridad o mejorar la funcionalidad de Nuestro Servicio, o cuando
                      estamos legalmente obligados a retener estos datos por períodos de tiempo
                      más largos.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Transferencia de sus datos personales
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Su información, incluidos los Datos personales, se procesa en las oficinas
                      operativas de la Compañía y en cualquier otro lugar donde se encuentren
                      las partes involucradas en el procesamiento. Significa que esta
                      información puede transferirse y mantenerse en computadoras ubicadas fuera
                      de Su estado, provincia, país u otra jurisdicción gubernamental donde las
                      leyes de protección de datos pueden diferir de las de Su jurisdicción.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Su consentimiento a esta Política de privacidad seguido de Su envío de
                      dicha información representa Su acuerdo con esa transferencia.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía tomará todas las medidas razonablemente necesarias para
                      garantizar que sus datos se traten de forma segura y de acuerdo con esta
                      Política de privacidad y no se realizará ninguna transferencia de sus
                      datos personales a una organización o país, a menos que existan controles
                      adecuados establecidos, incluida la seguridad de Sus datos y otra
                      información personal.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Divulgación de sus datos personales
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      TRANSACCIONES DE NEGOCIOS
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Si la Compañía está involucrada en una fusión, adquisición o venta de
                      activos, Sus Datos personales pueden transferirse. Le enviaremos un aviso
                      antes de que sus Datos personales se transfieran y queden sujetos a una
                      Política de privacidad diferente.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      CUMPLIMIENTO DE LA LEY
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      En determinadas circunstancias, es posible que se le solicite a la
                      Compañía que divulgue sus Datos personales si así lo exige la ley o en
                      respuesta a solicitudes válidas de las autoridades públicas (por ejemplo,
                      un tribunal o una agencia gubernamental).
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      OTROS REQUISITOS LEGALES
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía puede divulgar sus datos personales de buena fe cuando
                      considere que esta acción es necesaria para lo siguiente:
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Cumplir con una obligación legal
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Proteger y defender los derechos o propiedad de la Compañía
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Prevenir o investigar posibles irregularidades en relación con el Servicio
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Proteger la seguridad personal de los Usuarios del Servicio o del público
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Protéjase contra la responsabilidad legal
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Seguridad de sus datos personales
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La seguridad de sus datos personales es importante para nosotros, pero
                      recuerde que ningún método de transmisión por Internet o método de
                      almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos por
                      utilizar medios comercialmente aceptables para proteger sus datos
                      personales, no podemos garantizar su seguridad absoluta.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      INFORMACIÓN DETALLADA SOBRE EL TRATAMIENTO DE SUS DATOS PERSONALES
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Los proveedores de servicios tienen acceso a sus datos personales solo
                      para realizar sus tareas en nuestro nombre y están obligados a no
                      divulgarlos ni utilizarlos para ningún otro propósito.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Analítica
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos utilizar proveedores de Servicios de terceros para monitorear y
                      analizar el uso de nuestro Servicio.
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Google Analytics{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Google Analytics es un servicio de análisis web ofrecido por Google que
                        rastrea e informa el tráfico del sitio web. Google utiliza los datos
                        recopilados para rastrear y monitorear el uso de nuestro Servicio. Estos
                        datos se comparten con otros servicios de Google. Google puede utilizar
                        los datos recopilados para contextualizar y personalizar los anuncios de
                        su propia red de publicidad. Puede optar por no permitir que su
                        actividad en el Servicio esté disponible para Google Analytics
                        instalando el complemento de inhabilitación para navegadores de Google
                        Analytics. El complemento evita que el JavaScript de Google Analytics
                        (ga.js, analytics.js y dc.js) comparta información con Google Analytics
                        sobre la actividad de las visitas. Para obtener más información sobre
                        las prácticas de privacidad de Google, visite la página web de Términos
                        y privacidad de Google. página:{" "}
                      </span>
                      <a
                        href="https://policies.google.com/privacy"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://policies.google.com/privacy
                        </span>
                      </a>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Publicidad
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos utilizar proveedores de Servicios para mostrarle anuncios para
                      ayudar a respaldar y mantener Nuestro Servicio.
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Facebook{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Su política de privacidad se puede ver en{" "}
                      </span>
                      <a
                        href="https://help.instagram.com/519522125107875"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://help.instagram.com/519522125107875
                        </span>
                      </a>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Correo de propaganda
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos utilizar sus datos personales para ponernos en contacto con usted
                      con boletines, materiales promocionales o de marketing y otra información
                      que pueda ser de su interés. Puede optar por no recibir ninguna o todas
                      estas comunicaciones nuestras siguiendo el enlace para cancelar la
                      suscripción o las instrucciones proporcionadas en cualquier correo
                      electrónico que enviemos o poniéndose en contacto con nosotros.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos utilizar proveedores de servicios de marketing por correo
                      electrónico para administrar y enviarle correos electrónicos.
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Mailchimp{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Mailchimp es un servicio de envío de marketing por correo electrónico
                        proporcionado por The Rocket Science Group LLC. Para obtener más
                        información sobre las prácticas de privacidad de Mailchimp, visite su
                        Política de privacidad:{" "}
                      </span>
                      <a
                        href="https://mailchimp.com/legal/privacy/"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://mailchimp.com/legal/privacy/
                        </span>
                      </a>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Pagos
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos proporcionar productos y/o servicios pagos dentro del Servicio. En
                      ese caso, podemos utilizar servicios de terceros para el procesamiento de
                      pagos (por ejemplo, procesadores de pagos).
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      No almacenaremos ni recopilaremos los datos de su tarjeta de pago. Esa
                      información se proporciona directamente a Nuestros procesadores de pago de
                      terceros, cuyo uso de Su información personal se rige por su Política de
                      Privacidad. Estos procesadores de pago se adhieren a los estándares
                      establecidos por PCI-DSS administrados por el PCI Security Standards
                      Council, que es un esfuerzo conjunto de marcas como Visa, Mastercard,
                      American Express y Discover. Los requisitos de PCI-DSS ayudan a garantizar
                      el manejo seguro de la información de pago.
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Stripe{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        se puede ver en{" "}
                      </span>
                      <a
                        href="https://stripe.com/us/privacy"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://stripe.com/us/privacy
                        </span>
                      </a>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        PayPal{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Puede consultar su Política de privacidad en{" "}
                      </span>
                      <a
                        href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://www.paypal.com/webapps/mpp/ua/privacy-full
                        </span>
                      </a>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Uso, rendimiento y varios
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos utilizar proveedores de servicios de terceros para proporcionar
                      una mejor mejora de nuestro servicio.
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        ReCAPTCHA invisible{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Utilizamos un servicio de captcha invisible llamado reCAPTCHA. reCAPTCHA
                        es operado por Google. El servicio reCAPTCHA puede recopilar información
                        de usted y de su dispositivo por motivos de seguridad. La información
                        recopilada por reCAPTCHA se mantiene de acuerdo con la Política de
                        privacidad de Google:{" "}
                      </span>
                      <a
                        href="https://www.google.com/intl/en/policies/privacy/"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          https://www.google.com/intl/ es/politicas/privacidad/
                        </span>
                      </a>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      PRIVACIDAD RGPD
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Base legal para el procesamiento de datos personales bajo GDPR
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos procesar datos personales bajo las siguientes condiciones:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Consentimiento:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        ha dado su consentimiento para procesar datos personales para uno o más
                        propósitos específicos.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Ejecución de un contrato:
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        El suministro de Datos Personales es necesario para la ejecución de un
                        acuerdo con Usted y/o para cualquier obligación precontractual del
                        mismo.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Obligaciones legales:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        el Tratamiento de Datos Personales es necesario para el cumplimiento de
                        una obligación legal a la que está sujeta la Empresa.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Intereses vitales:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        el procesamiento de datos personales es necesario para proteger sus
                        intereses vitales o los de otra persona física.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Intereses públicos:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        el procesamiento de datos personales está relacionado con una tarea que
                        se lleva a cabo en interés público o en el ejercicio de la autoridad
                        oficial conferida a la Compañía.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Intereses legítimos:{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        el procesamiento de datos personales es necesario para los fines de los
                        intereses legítimos perseguidos por la Compañía.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      En cualquier caso, la Compañía estará encantada de ayudar a aclarar la
                      base legal específica que se aplica al procesamiento y, en particular, si
                      el suministro de Datos personales es un requisito legal o contractual, o
                      un requisito necesario para celebrar un contrato.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Sus derechos bajo el RGPD
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía se compromete a respetar la confidencialidad de Sus Datos
                      Personales y garantizar que Usted pueda ejercer Sus derechos.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tiene derecho en virtud de esta Política de privacidad, y por ley si se
                      encuentra dentro de la UE, a:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Solicitar acceso a sus datos personales.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        El derecho a acceder, actualizar o eliminar la información que tenemos
                        sobre usted. Siempre que sea posible, puede acceder, actualizar o
                        solicitar la eliminación de sus datos personales directamente en la
                        sección de configuración de su cuenta. Si no puede realizar estas
                        acciones usted mismo, contáctenos para ayudarlo. Esto también le permite
                        recibir una copia de los Datos personales que tenemos sobre usted.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Solicitar la corrección de los Datos personales que tenemos sobre usted.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tiene derecho a que se corrija cualquier información incompleta o
                        inexacta que tengamos sobre usted.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Objetar el procesamiento de sus datos personales.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Este derecho existe cuando nos basamos en un interés legítimo como base
                        legal para nuestro procesamiento y hay algo en su situación particular
                        que hace que desee oponerse a nuestro procesamiento de sus datos
                        personales por este motivo. También tiene derecho a oponerse cuando
                        procesamos sus datos personales con fines de marketing directo.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Solicitar la eliminación de sus datos personales.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tiene derecho a solicitarnos que eliminemos o eliminemos Datos
                        personales cuando no haya una buena razón para que sigamos
                        procesándolos.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Solicitar la transferencia de Sus Datos Personales.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Le proporcionaremos a usted, o a un tercero que haya elegido, sus datos
                        personales en un formato estructurado, de uso común y legible por
                        máquina. Tenga en cuenta que este derecho solo se aplica a la
                        información automatizada que inicialmente nos dio su consentimiento para
                        usar o cuando usamos la información para realizar un contrato con usted.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Retirar su consentimiento.
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tiene derecho a retirar su consentimiento sobre el uso de sus datos
                        personales. Si retira su consentimiento, es posible que no podamos
                        brindarle acceso a ciertas funcionalidades específicas del Servicio.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Ejercicio de sus derechos de protección de datos GDPR
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Puede ejercer sus derechos de acceso, rectificación, cancelación y
                      oposición poniéndose en contacto con nosotros. Tenga en cuenta que podemos
                      pedirle que verifique su identidad antes de responder a dichas
                      solicitudes. Si realiza una solicitud, haremos todo lo posible para
                      responderle lo antes posible.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tiene derecho a quejarse ante una autoridad de protección de datos sobre
                      nuestra recopilación y uso de sus datos personales. Para obtener más
                      información, si se encuentra en el Espacio Económico Europeo (EEE),
                      comuníquese con su autoridad local de protección de datos en el EEE.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      PÁGINA DE FANS DE FACEBOOK
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Controlador de datos para la página de fans de Facebook
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía es el controlador de datos de sus datos personales recopilados
                      durante el uso del Servicio. Como operador de la página de fans de
                      Facebook{" "}
                    </span>
                    <a
                      href="https://www.facebook.com/jimefitmom/"
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          textDecoration: "underline",
                          color: "#0000ff"
                        }}
                      >
                        https://www.facebook.com/jimefitmom/
                      </span>
                    </a>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      , la Compañía y el operador de la red social Facebook son controladores
                      conjuntos.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La Compañía ha celebrado acuerdos con Facebook que definen los términos de
                      uso de la Fan Page de Facebook, entre otras cosas. Estos términos se basan
                      principalmente en los Términos de servicio de Facebook:{" "}
                    </span>
                    <a
                      href="https://www.facebook.com/terms.php"
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          textDecoration: "underline",
                          color: "#0000ff"
                        }}
                      >
                        https://www.facebook.com/terms.php
                      </span>
                    </a>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Visite la Política de privacidad de Facebook{" "}
                    </span>
                    <a
                      href="https://www.facebook.com/policy.php"
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          textDecoration: "underline",
                          color: "#0000ff"
                        }}
                      >
                        https://www.facebook.com/policy.php
                      </span>
                    </a>
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      para obtener más información sobre cómo Facebook administra los datos
                      personales o comuníquese con Facebook en línea o por correo: Facebook,
                      Inc. ATTN, Operaciones de privacidad, 1601 Willow Road, Menlo Park, CA
                      94025, Estados Unidos.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Información de Facebook
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Utilizamos la función Facebook Insights en relación con el funcionamiento
                      de la página de fans de Facebook y sobre la base del RGPD, para obtener
                      datos estadísticos anónimos sobre nuestros usuarios.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Para este propósito, Facebook coloca una Cookie en el dispositivo del
                      usuario que visita Nuestra página de fans de Facebook. Cada Cookie
                      contiene un código identificador único y permanece activa durante un
                      período de dos años, excepto cuando se elimina antes de la finalización de
                      este período.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Facebook recibe, registra y procesa la información almacenada en la
                      Cookie, especialmente cuando el usuario visita los servicios de Facebook,
                      los servicios que son prestados por otros miembros de la Fan Page de
                      Facebook y los servicios de otras empresas que utilizan los servicios de
                      Facebook.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Para obtener más información sobre las prácticas de privacidad de
                      Facebook, visite la Política de privacidad de Facebook aquí:{" "}
                    </span>
                    <a
                      href="https://www.facebook.com/full_data_use_policy"
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          textDecoration: "underline",
                          color: "#0000ff"
                        }}
                      >
                        https://www.facebook.com/full_data_use_policy
                      </span>
                    </a>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      PRIVACIDAD DE LA CCPA
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Esta sección de aviso de privacidad para los residentes de California
                      complementa la información contenida en Nuestra Política de Privacidad y
                      se aplica únicamente a todos los visitantes, usuarios y otras personas que
                      residen en el Estado de California.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Categorías de información personal recopilada
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Recopilamos información que identifica, se relaciona, describe, hace
                      referencia, se puede asociar o podría vincularse razonablemente, directa o
                      indirectamente, con un Consumidor o Dispositivo en particular. La
                      siguiente es una lista de categorías de información personal que podemos
                      recopilar o que se puede haber recopilado de los residentes de California
                      en los últimos doce (12) meses.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tenga en cuenta que las categorías y los ejemplos proporcionados en la
                      lista a continuación son los definidos en la CCPA. Esto no significa que
                      todos los ejemplos de esa categoría de información personal hayan sido
                      recopilados por Nosotros, sino que refleja nuestra creencia de buena fe,
                      según nuestro leal saber y entender, de que parte de esa información de la
                      categoría aplicable puede ser y puede haber sido recopilada. Por ejemplo,
                      ciertas categorías de información personal solo se recopilarían si nos
                      proporcionara dicha información personal directamente.
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría A: Identificadores.
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: un nombre real, alias, dirección postal, identificador
                        personal único, identificador en línea, dirección de Protocolo de
                        Internet, dirección de correo electrónico, nombre de cuenta, número de
                        licencia de conducir, número de pasaporte u otros identificadores
                        similares. Recopilados: Sí.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría B: categorías de información personal enumeradas en el
                        estatuto de registros de clientes de California (Cal. Civ. Code §
                        1798.80(e)).{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: un nombre, firma, número de Seguro Social, características
                        físicas o descripción, dirección, número de teléfono, número de
                        pasaporte, licencia de conducir o número de tarjeta de identificación
                        estatal, número de póliza de seguro, educación, empleo, historial
                        laboral, número de cuenta bancaria, número de tarjeta de crédito ,
                        número de tarjeta de débito o cualquier otra información financiera,
                        información médica o información de seguro médico. Parte de la
                        información personal incluida en esta categoría puede superponerse con
                        otras categorías. Recopilada: sí.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría C: Características de clasificación protegidas bajo la ley
                        federal o de California.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: Edad (40 años o más), raza, color, ascendencia, origen
                        nacional, ciudadanía, religión o credo, estado civil, condición médica,
                        discapacidad física o mental, sexo (incluyendo género, identidad de
                        género, expresión de género, embarazo o parto). y condiciones médicas
                        relacionadas), orientación sexual, condición de veterano o militar,
                        información genética (incluida la información genética familiar).
                        Recopilados: No.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría D: Información comercial.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: Registros e historial de productos o servicios comprados o
                        considerados. Recopilados: Sí.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría E: Información biométrica.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: características genéticas, fisiológicas, de comportamiento y
                        biológicas, o patrones de actividad utilizados para extraer una
                        plantilla u otro identificador o información de identificación, como
                        huellas dactilares, huellas faciales y huellas de voz, escaneos de iris
                        o retina, pulsaciones de teclas, modo de andar u otros patrones físicos
                        y datos de sueño, salud o ejercicio. Recopilados: No.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría F: Internet u otra actividad de red similar.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: Interacción con nuestro Servicio o publicidad. Recopilados:
                        Sí.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría G: Datos de geolocalización.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: Ubicación física aproximada. Recogidos: No.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría H: Datos sensoriales.
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: Información de audio, electrónica, visual, térmica, olfativa o
                        similar. Recopilada: No.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría I: Información profesional o laboral.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: Historial laboral actual o pasado o evaluaciones de desempeño.
                        Recopilados: No.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría J: información educativa no pública (según la Ley de
                        Privacidad y Derechos Educativos de la Familia (20 USC Sección 1232g, 34
                        CFR Parte 99)).{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: registros educativos directamente relacionados con un
                        estudiante mantenidos por una institución educativa o una parte que
                        actúe en su nombre, como calificaciones, expedientes académicos, listas
                        de clases, horarios de estudiantes, códigos de identificación de
                        estudiantes, información financiera de estudiantes o registros
                        disciplinarios de estudiantes. Recopilados: No.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Categoría K: Inferencias extraídas de otra información personal.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Ejemplos: Perfil que refleja las preferencias, características,
                        tendencias psicológicas, predisposiciones, comportamiento, actitudes,
                        inteligencia, habilidades y aptitudes de una persona. Recopilados: No.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Bajo CCPA, la información personal no incluye:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Información disponible públicamente de registros gubernamentales
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Información del consumidor no identificada o agregada
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Información excluida del alcance de la CCPA, como:
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Información médica o de salud cubierta por la Ley de Portabilidad y
                        Responsabilidad de Seguros Médicos de 1996 (HIPAA) y la Ley de
                        Confidencialidad de la Información Médica de California (CMIA) o datos
                        de ensayos clínicos
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Información personal cubierta por ciertas leyes de privacidad
                        específicas del sector, incluida la Ley de informes crediticios justos
                        (FRCA), la Ley Gramm-Leach-Bliley (GLBA) o la Ley de privacidad de la
                        información financiera de California (FIPA) y la Ley de protección de la
                        privacidad del conductor de 1994
                      </span>
                    </li>
                  </ul>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        &nbsp;
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Fuentes de información personal
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Obtenemos las categorías de información personal enumeradas anteriormente
                      de las siguientes categorías de fuentes:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Directamente de usted{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        . Por ejemplo, de los formularios que completa en nuestro Servicio, las
                        preferencias que expresa o proporciona a través de nuestro Servicio, o
                        de Sus compras en nuestro Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Indirectamente de usted
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        . Por ejemplo, al observar Su actividad en nuestro Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        Automáticamente de usted
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        . Por ejemplo, a través de las cookies que nosotros o nuestros
                        proveedores de servicios instalamos en su dispositivo mientras navega
                        por nuestro servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        De los proveedores de servicios
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        . Por ejemplo, proveedores externos para monitorear y analizar el uso de
                        nuestro Servicio, proveedores externos para proporcionar publicidad en
                        nuestro Servicio, proveedores externos para el procesamiento de pagos u
                        otros proveedores externos que usamos para proporcionar el Servicio a
                        Tú.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Uso de información personal para fines comerciales o fines comerciales
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos usar o divulgar la información personal que recopilamos para
                      "fines comerciales" o "fines comerciales" (como se define en la CCPA), que
                      pueden incluir los siguientes ejemplos:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Para operar nuestro Servicio y proporcionarle nuestro Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Para brindarle apoyo y responder a Sus consultas, incluso para
                        investigar y abordar Sus inquietudes y monitorear y mejorar nuestro
                        Servicio.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Para cumplir o cumplir con el motivo por el cual proporcionó la
                        información. Por ejemplo, si comparte su información de contacto para
                        hacer una pregunta sobre nuestro Servicio, utilizaremos esa información
                        personal para responder a su consulta. Si proporciona su información
                        personal para comprar un producto o servicio, utilizaremos esa
                        información para procesar su pago y facilitar la entrega.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Para responder a las solicitudes de cumplimiento de la ley y según lo
                        exija la ley aplicable, una orden judicial o las regulaciones
                        gubernamentales.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Como se le describe al recopilar su información personal o como se
                        establece en la CCPA.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Para fines administrativos internos y de auditoría.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Para detectar incidentes de seguridad y proteger contra actividades
                        maliciosas, engañosas, fraudulentas o ilegales, incluso, cuando sea
                        necesario, para enjuiciar a los responsables de tales actividades.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tenga en cuenta que los ejemplos proporcionados anteriormente son
                      ilustrativos y no pretenden ser exhaustivos. Para obtener más detalles
                      sobre cómo usamos esta información, consulte la sección «Uso de sus datos
                      personales».
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Si decidimos recopilar categorías adicionales de información personal o
                      utilizar la información personal que recopilamos para fines materialmente
                      diferentes, no relacionados o incompatibles, actualizaremos esta Política
                      de privacidad.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Divulgación de información personal para fines comerciales o fines
                      comerciales
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos usar o divulgar y es posible que hayamos usado o divulgado en los
                      últimos doce (12) meses las siguientes categorías de información personal
                      con fines comerciales o de negocios:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría A: Identificadores
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría B: categorías de información personal enumeradas en el
                        estatuto de registros de clientes de California (Cal. Civ. Code §
                        1798.80(e))
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría D: Información comercial
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría F: Internet u otra actividad de red similar
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tenga en cuenta que las categorías enumeradas anteriormente son aquellas
                      definidas en la CCPA. Esto no significa que todos los ejemplos de esa
                      categoría de información personal hayan sido divulgados, sino que refleja
                      nuestra creencia de buena fe, según nuestro leal saber y entender, de que
                      parte de esa información de la categoría aplicable puede ser y puede haber
                      sido divulgada.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Cuando divulgamos información personal con fines comerciales o de
                      negocios, celebramos un contrato que describe el propósito y requiere que
                      el destinatario mantenga la confidencialidad de esa información personal y
                      que no la use para ningún otro fin que no sea el cumplimiento del
                      contrato.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Venta de Información Personal
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tal como se define en la CCPA, "vender" y "venta" significan vender,
                      alquilar, liberar, divulgar, difundir, poner a disposición, transferir o
                      comunicar de otra manera oralmente, por escrito, por medios electrónicos o
                      de otro tipo, la información personal de un consumidor por parte del
                      negocio a un tercero a cambio de una consideración valiosa. Esto significa
                      que es posible que hayamos recibido algún tipo de beneficio a cambio de
                      compartir información personal, pero no necesariamente un beneficio
                      monetario.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tenga en cuenta que las categorías enumeradas a continuación son las
                      definidas en la CCPA. Esto no significa que todos los ejemplos de esa
                      categoría de información personal se hayan vendido, sino que refleja
                      nuestra creencia de buena fe, según nuestro leal saber y entender, de que
                      parte de esa información de la categoría aplicable puede ser y puede haber
                      sido compartida a cambio de valor. .
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos vender y haber vendido en los últimos doce (12) meses las
                      siguientes categorías de información personal:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría A: Identificadores
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría B: categorías de información personal enumeradas en el
                        estatuto de registros de clientes de California (Cal. Civ. Code §
                        1798.80(e))
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría D: Información comercial
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Categoría F: Internet u otra actividad de red similar
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Porcentaje de información personal
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Podemos compartir su información personal identificada en las categorías
                      anteriores con las siguientes categorías de terceros:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Proveedores de servicio
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Procesadores de pagos
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Nuestros afiliados
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Nuestros socios comerciales
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Proveedores externos a quienes usted o sus agentes nos autorizan a
                        divulgar su información personal en relación con los productos o
                        servicios que le brindamos
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Venta de Información Personal de Menores de 16 Años
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      No vendemos la información personal de los Consumidores que sabemos que
                      tienen menos de 16 años, a menos que recibamos una autorización afirmativa
                      (el «derecho a participar») del Consumidor que tiene entre 13 y 16 años, o
                      el padre o tutor de un Consumidor menor de 13 años de edad. Los
                      consumidores que optan por la venta de información personal pueden optar
                      por no participar en futuras ventas en cualquier momento. Para ejercer el
                      derecho de exclusión voluntaria, usted (o su representante autorizado)
                      puede enviarnos una solicitud comunicándose con nosotros.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Si tiene motivos para creer que un niño menor de 13 años (o 16) nos ha
                      proporcionado información personal, comuníquese con nosotros con
                      suficientes detalles para permitirnos eliminar esa información.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Sus derechos bajo la CCPA
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La CCPA brinda a los residentes de California derechos específicos con
                      respecto a su información personal. Si es residente de California, tiene
                      los siguientes derechos:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        El derecho de notificación.
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tiene derecho a que se le notifique qué categorías de Datos personales
                        se recopilan y los fines para los que se utilizan.
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        El derecho a solicitar.
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Según la CCPA, tiene derecho a solicitar que le divulguemos información
                        sobre nuestra recopilación, uso, venta, divulgación con fines
                        comerciales y compartir información personal. Una vez que recibamos y
                        confirmemos su solicitud, le divulgaremos:
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Las categorías de información personal que recopilamos sobre usted
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Las categorías de fuentes de la información personal que recopilamos
                        sobre usted
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Nuestro negocio o propósito comercial para recopilar o vender esa
                        información personal
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Las categorías de terceros con quienes compartimos esa información
                        personal
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Los datos personales específicos que recopilamos sobre usted
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Si vendimos su información personal o divulgamos su información personal
                        con fines comerciales, le divulgaremos:
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Las categorías de categorías de información personal vendidas
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Las categorías de categorías de información personal divulgadas
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        El derecho a decir no a la venta de Datos Personales (opt-out).{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tiene derecho a indicarnos que no vendamos su información personal. Para
                        enviar una solicitud de exclusión, contáctenos.
                      </span>
                    </li>
                  </ul>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        &nbsp;
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        El derecho a eliminar los Datos Personales.{" "}
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tiene derecho a solicitar la eliminación de sus datos personales, sujeto
                        a ciertas excepciones. Una vez que recibamos y confirmemos su solicitud,
                        eliminaremos (y ordenaremos a nuestros proveedores de servicios que
                        eliminen) su información personal de nuestros registros, a menos que se
                        aplique una excepción. Podemos denegar su solicitud de eliminación si
                        nosotros o nuestros proveedores de servicios necesitamos conservar la
                        información para:
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Completar la transacción para la cual recopilamos la información personal,
                      proporcionar un bien o servicio que solicitó, tomar medidas razonablemente
                      anticipadas dentro del contexto de nuestra relación comercial en curso con
                      usted o cumplir nuestro contrato con usted.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Detectar incidentes de seguridad, proteger contra actividades maliciosas,
                      engañosas, fraudulentas o ilegales, o enjuiciar a los responsables de
                      dichas actividades.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Depure productos para identificar y reparar errores que perjudiquen la
                      funcionalidad prevista existente.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Ejercer la libertad de expresión, garantizar el derecho de otro consumidor
                      a ejercer sus derechos de libertad de expresión, o ejercer otro derecho
                      previsto por la ley.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Cumplir con la Ley de Privacidad de Comunicaciones Electrónicas de
                      California (Cal. Penal Code § 1546 et. seq.).
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Participar en investigaciones científicas, históricas o estadísticas
                      públicas o revisadas por pares en interés público que se adhieran a todas
                      las demás leyes de ética y privacidad aplicables, cuando la eliminación de
                      la información probablemente imposibilite o perjudique gravemente el logro
                      de la investigación, si usted proporcionó previamente su consentimiento
                      informado. .
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Habilite únicamente usos internos que estén razonablemente alineados con
                      las expectativas del consumidor en función de su relación con nosotros.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Cumplir con una obligación legal.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Hacer otros usos internos y lícitos de esa información que sean
                      compatibles con el contexto en el que la proporcionó.
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: '"Times New Roman"',
                          fontSize: "12pt",
                          fontWeight: "bold"
                        }}
                      >
                        El derecho a no ser discriminado.
                      </span>
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Tiene derecho a no ser discriminado por ejercer cualquiera de sus
                        derechos como consumidor, incluso por:
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Negarle bienes o servicios
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Cobrar precios o tarifas diferentes por bienes o servicios, incluido el
                      uso de descuentos u otros beneficios o la imposición de sanciones
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Proporcionarle un nivel o calidad diferente de bienes o servicios
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Sugerir que recibirá un precio o tarifa diferente por bienes o servicios o
                      un nivel o calidad diferente de bienes o servicios
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      Ejercicio de sus derechos de protección de datos CCPA
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Para ejercer cualquiera de sus derechos en virtud de la CCPA, y si es
                      residente de California, puede comunicarse con nosotros:
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Por correo electrónico:
                    </span>
                    <span style={{ fontSize: "11pt" }}> </span>
                    <a href="mailto:hola@jimefitmom.com" style={{ textDecoration: "none" }}>
                      <span className="Hyperlink" style={{ fontFamily: '"Times New Roman"' }}>
                        hola@jimefitmom.com
                      </span>
                    </a>
                    <span style={{ fontFamily: '"Times New Roman"' }}> </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Solo usted, o una persona registrada en la Secretaría de Estado de
                      California que autorice para actuar en su nombre, puede realizar una
                      solicitud verificable relacionada con su información personal.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Su solicitud para Nosotros debe:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Proporcione suficiente información que nos permita verificar
                        razonablemente que usted es la persona sobre la cual recopilamos
                        información personal o un representante autorizado
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Describa su solicitud con suficiente detalle que nos permita
                        comprenderla, evaluarla y responderla adecuadamente.
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      No podemos responder a Su solicitud ni proporcionarle la información
                      requerida si no podemos:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Verificar su identidad o autoridad para realizar la solicitud
                      </span>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        Y confirme que la información personal se relaciona con usted
                      </span>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Divulgaremos y entregaremos la información requerida sin cargo dentro de
                      los 45 días posteriores a la recepción de su solicitud verificable. El
                      plazo para proporcionar la información requerida podrá extenderse una vez
                      por 45 días adicionales cuando sea razonablemente necesario y con previo
                      aviso.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Cualquier divulgación que proporcionemos solo cubrirá el período de 12
                      meses anterior a la recepción de la solicitud verificable.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Para las solicitudes de portabilidad de datos, seleccionaremos un formato
                      para proporcionar su información personal que sea fácilmente utilizable y
                      que le permita transmitir la información de una entidad a otra entidad sin
                      obstáculos.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      No vender mi información personal
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tiene derecho a excluirse de la venta de su información personal. Una vez
                      que recibamos y confirmemos una solicitud de consumidor verificable de su
                      parte, dejaremos de vender su información personal. Para ejercer su
                      derecho de optar por no participar, comuníquese con nosotros.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Los Proveedores de servicios con los que nos asociamos (por ejemplo,
                      nuestros socios de análisis o publicidad) pueden usar tecnología en el
                      Servicio que vende información personal según lo define la ley CCPA. Si
                      desea excluirse del uso de su información personal para fines de
                      publicidad basada en intereses y estas ventas potenciales según lo
                      definido por la ley CCPA, puede hacerlo siguiendo las instrucciones a
                      continuación.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tenga en cuenta que cualquier opción de exclusión es específica del
                      navegador que utiliza. Es posible que deba optar por no participar en cada
                      navegador que utilice.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      SITIO WEB
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Puede optar por no recibir anuncios personalizados tal como lo muestran
                      nuestros Proveedores de servicios siguiendo nuestras instrucciones
                      presentadas en el Servicio:
                    </span>
                  </p>
                  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt", listStyle: "disc" }}>
                    <li
                      style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        La plataforma de exclusión voluntaria de NAI:
                      </span>
                      <a
                        href="http://www.networkadvertising.org/choices/"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          http://www.networkadvertising.org/choices/
                        </span>
                      </a>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        La plataforma de exclusión voluntaria de EDAA
                      </span>
                      <a
                        href="http://www.youronlinechoices.com/"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          http://www.youronlinechoices.com/
                        </span>
                      </a>
                    </li>
                    <li
                      style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        lineHeight: "normal",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        AwFontFamily: '"Symbol"',
                        AwFontWeight: "normal",
                        AwNumberFormat: '""'
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                        La plataforma de exclusión de la DAA:{" "}
                      </span>
                      <a
                        href="http://optout.aboutads.info/?c=2&lang=EN"
                        style={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            fontFamily: '"Times New Roman"',
                            fontSize: "12pt",
                            textDecoration: "underline",
                            color: "#0000ff"
                          }}
                        >
                          http://optout.aboutads.info/?c=2&amp;lang=EN
                        </span>
                      </a>
                    </li>
                  </ul>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La opción de exclusión colocará una cookie en su computadora que es única
                      para el navegador que utiliza para optar por no participar. Si cambia de
                      navegador o elimina las cookies guardadas por su navegador, deberá optar
                      por no participar nuevamente.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      DISPOSITIVOS MÓVILES
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Su dispositivo móvil puede darle la posibilidad de optar por no utilizar
                      la información sobre las aplicaciones que utiliza para mostrarle anuncios
                      que están dirigidos a sus intereses:
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      «Optar por no recibir anuncios basados en intereses» o «Optar por no
                      recibir personalización de anuncios» en dispositivos Android
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      «Limitar seguimiento de anuncios» en dispositivos iOS
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      También puede detener la recopilación de información de ubicación desde Su
                      dispositivo móvil cambiando las preferencias en Su dispositivo móvil.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      POLÍTICA DE «NO RASTREO» SEGÚN LO REQUERIDO POR LA LEY DE PROTECCIÓN DE LA
                      PRIVACIDAD EN LÍNEA DE CALIFORNIA (CALOPPA)
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Nuestro Servicio no responde a las señales de No rastrear.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Sin embargo, algunos sitios web de terceros realizan un seguimiento de sus
                      actividades de navegación. Si está visitando dichos sitios web, puede
                      configurar sus preferencias en su navegador web para informar a los sitios
                      web que no desea que lo rastreen. Puede habilitar o deshabilitar DNT
                      visitando la página de preferencias o configuración de su navegador web.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      SUS DERECHOS DE PRIVACIDAD DE CALIFORNIA ( LEY SHINE THE LIGHT DE
                      CALIFORNIA)
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Según la Sección 1798 del Código Civil de California (ley Shine the Light
                      de California), los residentes de California con una relación comercial
                      establecida con nosotros pueden solicitar información una vez al año sobre
                      cómo compartir sus Datos personales con terceros para fines de marketing
                      directo de terceros.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Si desea solicitar más información en virtud de la ley Shine the Light de
                      California, y si es residente de California, puede comunicarse con
                      nosotros utilizando la información de contacto que se proporciona a
                      continuación.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      DERECHOS DE PRIVACIDAD DE CALIFORNIA PARA USUARIOS MENORES ( CÓDIGO DE
                      NEGOCIOS Y PROFESIONES DE CALIFORNIA, SECCIÓN 22581)
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      La sección 22581 del Código de Negocios y Profesiones de California
                      permite a los residentes de California menores de 18 años que son usuarios
                      registrados de sitios, servicios o aplicaciones en línea solicitar y
                      obtener la eliminación del contenido o la información que han publicado.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Para solicitar la eliminación de dichos datos, y si usted es residente de
                      California, puede comunicarse con nosotros utilizando la información de
                      contacto que se proporciona a continuación e incluir la dirección de
                      correo electrónico asociada con su cuenta.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Tenga en cuenta que su solicitud no garantiza la eliminación completa o
                      completa del contenido o la información publicados en línea y que la ley
                      puede no permitir o exigir la eliminación en determinadas circunstancias.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      ENLACES A OTROS SITIOS WEB
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Nuestro Servicio puede contener enlaces a otros sitios web que no son
                      operados por Nosotros. Si hace clic en el enlace de un tercero, será
                      dirigido al sitio de ese tercero. Le recomendamos encarecidamente que
                      revise la Política de privacidad de cada sitio que visite.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      No tenemos control ni asumimos ninguna responsabilidad por el contenido,
                      las políticas de privacidad o las prácticas de los sitios o servicios de
                      terceros.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Es posible que actualicemos nuestra Política de privacidad de vez en
                      cuando. Le notificaremos cualquier cambio publicando la nueva Política de
                      privacidad en esta página.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Le informaremos por correo electrónico y/o un aviso destacado en Nuestro
                      Servicio, antes de que el cambio entre en vigencia y actualizaremos la
                      fecha de "Última actualización" en la parte superior de esta Política de
                      privacidad.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Se le recomienda revisar esta Política de Privacidad periódicamente para
                      cualquier cambio. Los cambios a esta Política de privacidad son efectivos
                      cuando se publican en esta página.
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      marginBottom: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontWeight: "bold",
                        fontStyle: "italic"
                      }}
                    >
                      CONTÁCTENOS
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: "14pt",
                      lineHeight: "normal",
                      fontSize: "12pt"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      Si tiene alguna pregunta sobre esta Política de privacidad, puede
                      contactarnos:
                    </span>
                  </p>
                  <p style={{ lineHeight: "108%", fontSize: "12pt" }}>
                    <a href="mailto:hola@jimefitmom.com" style={{ textDecoration: "none" }}>
                      <span className="Hyperlink" style={{ fontFamily: '"Times New Roman"' }}>
                        hola@jimefitmom.com
                      </span>
                    </a>
                  </p>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PoliticaPrivacidad;