import React from 'react';
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const PoliticaCookies = () => {
  return (
    <>
      <SEO
        title="Política de Cookies"
        description="Esta Política de Cookies explica qué son las Cookies y cómo las usamos. Debe leer esta política para que pueda comprender qué tipo de cookies utilizamos, o la información que recopilamos mediante las cookies y cómo se utiliza esa información."
      />
      <div className={`bg-white`}>
        <HeaderNav
          theme="white"
        />
      </div>

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto px-6 mt-6 py-20">
          <div className='flex justify-center relative'>
            <div className='w-full bg-white color-[#676a78] py-10 px-6 rounded-lg shadow-md shadow-DeepKoamaru/16'>
              <>
                <p style={{ lineHeight: "1.2", marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "18pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 700 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              POLÍTICA DE COOKIES
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Última actualización: 04 de noviembre de 2020
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Esta Política de Cookies explica qué son las Cookies y cómo las
                              usamos. Debe leer esta política para que pueda comprender qué
                              tipo de cookies utilizamos, o la información que recopilamos
                              mediante las cookies y cómo se utiliza esa información.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Las cookies normalmente no contienen ninguna información que
                              identifique personalmente a un usuario, pero la información
                              personal que almacenamos sobre usted puede estar vinculada a la
                              información almacenada y obtenida de las cookies. Para obtener
                              más información sobre cómo usamos, almacenamos y mantenemos
                              seguros sus datos personales, consulte nuestra Política de
                              privacidad.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              No almacenamos información personal confidencial, como
                              direcciones postales, contraseñas de cuentas, etc. en las
                              cookies que utilizamos.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 700 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              INTERPRETACIÓN Y DEFINICIONES
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>Interpretación</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Las palabras cuya letra inicial está en mayúscula tienen
                              significados definidos bajo las siguientes condiciones. Las
                              siguientes definiciones tendrán el mismo significado
                              independientemente de que aparezcan en singular o en plural.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 700 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>Definiciones</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              A los efectos de esta Política de Cookies:
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              La Compañía (referida como "la Compañía", "Nosotros", "Nosotros"
                              o "Nuestro" en esta Política de Cookies) se refiere a
                              JimeFitMom, 2804 E Bluebonnet Ln, Mission TX 78573.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Las cookies significan pequeños archivos que un sitio web coloca
                              en su computadora, dispositivo móvil o cualquier otro
                              dispositivo, y que contienen detalles de su historial de
                              navegación en ese sitio web entre sus muchos usos.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              El sitio web se refiere a JimeFitMom, accesible desde
                              https://jimefitmom.com
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Usted se refiere a la persona que accede o usa el sitio web, o
                              una empresa o cualquier entidad legal en nombre de la cual dicha
                              persona accede o usa el sitio web, según corresponda.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "13.5pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 700 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              EL USO DE LAS COOKIES
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Tipo de cookies que utilizamos
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Las Cookies pueden ser Cookies «Persistentes» o «Sesión». Las
                              cookies persistentes permanecen en su computadora personal o
                              dispositivo móvil cuando se desconecta, mientras que las cookies
                              de sesión se eliminan tan pronto como cierra su navegador web.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Utilizamos cookies de sesión y persistentes para los fines que
                              se describen a continuación:
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Cookies necesarias/esenciales Tipo: Cookies de sesión
                              Administradas por: Nosotros Propósito: Estas Cookies son
                              esenciales para brindarle los servicios disponibles a través del
                              sitio web y permitirle usar algunas de sus funciones. Ayudan a
                              autenticar a los usuarios y previenen el uso fraudulento de
                              cuentas de usuario. Sin estas Cookies, no se pueden proporcionar
                              los servicios que ha solicitado, y solo utilizamos estas Cookies
                              para proporcionarle esos servicios.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Política de Cookies / Cookies de Aceptación de Avisos Tipo:
                              Cookies Persistentes Administrado por: Nosotros Finalidad: Estas
                              Cookies identifican si los usuarios han aceptado el uso de
                              cookies en el Sitio Web.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Cookies de funcionalidad Tipo: Cookies persistentes Administrado
                              por: Nosotros Propósito: Estas Cookies nos permiten recordar las
                              elecciones que hace cuando usa el sitio web, como recordar sus
                              datos de inicio de sesión o preferencia de idioma. El propósito
                              de estas Cookies es brindarle una experiencia más personal y
                              evitar que tenga que volver a ingresar sus preferencias cada vez
                              que use el sitio web.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Cookies de seguimiento y rendimiento Tipo: Cookies persistentes
                              Administrado por: Terceros Propósito: Estas cookies se utilizan
                              para rastrear información sobre el tráfico al sitio web y cómo
                              los usuarios usan el sitio web. La información recopilada a
                              través de estas cookies puede identificarlo directa o
                              indirectamente como un visitante individual. Esto se debe a que
                              la información recopilada generalmente está vinculada a un
                              identificador seudónimo asociado con el dispositivo que utiliza
                              para acceder al sitio web. También podemos usar estas cookies
                              para probar nuevas páginas, características o nuevas funciones
                              del sitio web para ver cómo reaccionan nuestros usuarios.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Cookies de orientación y publicidad Tipo: Cookies persistentes
                              Administradas por: Terceros Propósito: Estas Cookies rastrean
                              sus hábitos de navegación para permitirnos mostrar publicidad
                              que probablemente sea de su interés. Estas cookies utilizan
                              información sobre su historial de navegación para agruparlo con
                              otros usuarios que tienen intereses similares. Según esa
                              información, y con nuestro permiso, los anunciantes de terceros
                              pueden colocar cookies para permitirles mostrar anuncios que
                              creemos que serán relevantes para sus intereses mientras se
                              encuentra en sitios web de terceros.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Sus opciones con respecto a las cookies
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Si prefiere evitar el uso de Cookies en el Sitio Web, primero
                              debe deshabilitar el uso de Cookies en su navegador y luego
                              eliminar las Cookies guardadas en su navegador asociadas a este
                              sitio web. Puede utilizar esta opción para impedir el uso de
                              Cookies en cualquier momento.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Si no acepta Nuestras cookies, es posible que experimente
                              algunos inconvenientes en el uso del sitio web y que algunas
                              funciones no funcionen correctamente.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Si desea eliminar las cookies o indicarle a su navegador web que
                              elimine o rechace las cookies, visite las páginas de ayuda de su
                              navegador web.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Para el navegador web Chrome, visite esta página de Google:
                              https://support.google.com/accounts/answer/32050
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Para el navegador web Internet Explorer, visite esta página de
                              Microsoft: http://support.microsoft.com/kb/278835
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Para el navegador web Firefox, visite esta página de Mozilla:
                              https://support.mozilla.org/en-US/kb/delete-cookies-eliminar-información-sitios-web-almacenados
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Para el navegador web Safari, visite esta página de Apple:
                              https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Para cualquier otro navegador web, visite las páginas web
                              oficiales de su navegador web.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 700 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Más información sobre las cookies
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Puede obtener más información sobre las cookies aquí: Todo sobre
                              las cookies por TermsFeed.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 700 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>Contáctenos</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Si tiene alguna duda acerca de esta Política de Cookies, puede
                              contactar con nosotros:
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ lineHeight: "1.2", marginTop: 19, marginBottom: 19 }}>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontVariant: "normal",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <span style={{ fontFamily: '"Times New Roman"' }}>
                      <span style={{ color: "#000000" }}>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ textDecoration: "none" }}>
                              Al visitar esta página en nuestro sitio web:
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <a
                    href="https://jimefitmom.com/contacto/"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontVariant: "normal",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      <span style={{ fontFamily: '"Times New Roman"' }}>
                        <span style={{ color: "#0000ff" }}>
                          <span style={{ fontWeight: 400 }}>
                            <span style={{ fontStyle: "normal" }}>
                              <span style={{ textDecoration: "underline" }}>
                                <span style={{ WebkitTextDecorationSkip: "none" }}>
                                  <span style={{ textDecorationSkipInk: "none" }}>
                                    {" "}
                                    https://jimefitmom.com/contacto/
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </a>
                </p>
              </>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PoliticaCookies;