import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { prefix } from "../../config/app";
import authReducer from './authSlice';
import miscSlice from './miscSlice';
import subscriptionSlice from './subscriptionSlice';
import adminAuthSlice from './admin/authSlice';

const appPersistConfig = {
  key: prefix?.replace("_", ""),
  storage,
  whitelist: ['auth', 'misc', 'subscription'],
};

const adminPersistConfig = {
  key: prefix?.replace("-app_", "-admin"),
  storage,
  whitelist: ['auth'],
};

const appRootReducer = combineReducers({
  auth: authReducer,
  misc: miscSlice,
  subscription: subscriptionSlice,
});

const adminRootReducer = combineReducers({
  auth: adminAuthSlice,
});

const persistedAppReducer = persistReducer(appPersistConfig, appRootReducer);
const persistedAdminReducer = persistReducer(adminPersistConfig, adminRootReducer);

const rootReducer = combineReducers({
  app: persistedAppReducer,
  admin: persistedAdminReducer,
});

export default rootReducer;