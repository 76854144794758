import React from "react";
import { Dialog } from 'primereact/dialog';
import ReactPlayer from 'react-player';

export default function VideoPlayer({ responsive, visible, onHide, url, propsDialog, propsContainer, propsVideo }) {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      style={{ width: "100%", maxWidth: 760, ...propsDialog?.style }}
      className={`!m-4 ${propsDialog?.className || ""}`}
      draggable={false}
      {...propsDialog}
    >
      <div className={`flex justify-center items-center ${responsive ? "player-wrapper-react-player" : ""} ${propsContainer?.className}`} {...propsContainer}>
        <ReactPlayer
          className={`${responsive ? "react-player" : ""} ${propsVideo?.className}`}
          url={url}
          /*width={propsVideo?.width || '100%'}
          height={propsVideo?.height || '100%'}*/
          width={640}
          height={360}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          // Disable right click
          // onContextMenu={e => e.preventDefault()}
          {...propsVideo}
        />
      </div>
    </Dialog>
  )
}