import React from "react";
import { ReactComponent as DropDownIcon } from "../assets/icons/dropdown-icon.svg";

export const SortIcon = () => (
  <div className='d-flex' style={{ paddingLeft: 5 }}><DropDownIcon fill="#191e25" style={{ height: 20, width: 20 }} /></div>
);

export const NoDataComponent = ({ text }) => (
  <div style={{ padding: "24px" }}>{text || "No hay registros para mostrar"}</div>
);

export const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

export const Checkbox = React.forwardRef(({ onClick, ...props }, ref) => {
  return (
    <>
      <div className="">
        <input
          type="checkbox"
          className="form-check-input rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all"
          ref={ref}
          onClick={onClick}
          {...props}
        />
      </div>
    </>
  )
});