import QueryString from "qs";
import axios from "../";
const prefix = "/admin/programas";

export const getProgramas = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBannerPrograma = async (params) => {
  try {
    const _params = QueryString.stringify({ programID: params?.programID }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/banner?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBannerProgram = async (id, params) => {
  try {
    const response = await axios.put(`${prefix}/banner/${id}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePrograma = async (id, params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.put(`${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};