import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPaid: false,
  data: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscription: (state, action) => {
      if (action.payload?.isPaid === false || action.payload?.isPaid === true) state.isPaid = action.payload?.isPaid;
      if (action.payload?.data) state.data = action.payload?.data;
    },
    clearSubscription: (state) => {
      state.isPaid = null;
      state.data = null;
    },
  },
});

export const { setSubscription, clearSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;