import headerEmbarazo from '../assets/images/programas/header-embarazo.jpg';
import headerPosparto from '../assets/images/programas/header-posparto.jpg';
import headerStrength from '../assets/images/programas/header-strength.jpg';

const headerImagesMap = {
  "fitmom-embarazo": headerEmbarazo,
  "fitmom-posparto": headerPosparto,
  "fitmom-strength": headerStrength,
};

const generateImagePath = (type, extension = 'jpg') => (`../assets/images/programas/header-${type?.replace("fitmom-")}.${extension}`);

const getHeaderImageProgram = (type, isRequired = true, extension = 'jpg') => {
  const headerImage = headerImagesMap[type] || (isRequired ? null : generateImagePath(type, extension));
  return headerImage;
}

export default getHeaderImageProgram;