import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import { ButtonPrimary } from '../../components/Button';
import Loading from '../../components/Loading';
import { getPrograma } from '../../api/client/tu_programa';
import { optionsToast } from '../../config/toast';
import { useCustomState } from '../../hooks/useCustomState';
import { isEmpty } from '../../utils';
import { obtenerDuracionFormateada } from '../../utils/parseDuration';
import getDescriptionCalendar from '../../utils/getDescriptionCalendar';

let fechaActual = dayjs().format("YYYY-MM-DD");
require('dayjs/locale/es');
dayjs.locale("es");

const TuPrograma = () => {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    tu_programa: null,
  });

  useEffect(() => {
    loadData(user);

    // eslint-disable-next-line
  }, [user]);

  const loadData = useCallback(async (user) => {
    fechaActual = dayjs().format("YYYY-MM-DD");

    if (!isEmpty(user?.subscription?.programID)) {
      setState({ loading: true });
      const _getPrograma = await getPrograma({
        populate: ["workouts"],
        group: "day",
        programID: user?.subscription?.programID,
        startDate: fechaActual
      });

      setState({
        tu_programa: _getPrograma?.data,
        loading: false,
      });
    }
    // eslint-disable-next-line
  }, [fechaActual]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onNavigateWorkout = useCallback((item) => {
    if (item?.Workout?.id) {
      navigate(`clase-${item?.Workout?.id}`);
    } else {
      message("Video no encontrado", "No se encontro ningún video relacionado");
    }
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      <SEO
        title="Tu programa"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
      />

      <div className='bg-Magnolia h-full pb-4'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            {(user?.subscription?.isActive !== true) ? (
              <div className='flex flex-col items-center justify-center gap-6'>
                <div className='bg-white px-4 py-6 rounded-xl w-full'>
                  <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
                  <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
                </div>
              </div>
            ) : (
              <>
                {state.loading ? (
                  <Loading containerClassName="h-full" />
                ) : (
                  <>
                    {(state?.tu_programa?.id) ? (
                      <div className='flex flex-col gap-4'>
                        {([1, 2, 3].includes(user?.subscription?.programID)) && (
                          <Etapa2
                            level={user.level}
                            program_info={user.Program}
                            programa={state.tu_programa}
                            navigate={navigate}
                            onNavigateWorkout={onNavigateWorkout}
                          />
                        )}
                      </div>
                    ) : (
                      <div className='bg-white px-4 py-6 rounded-xl'>
                        <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos! <br />Aún no cuenta con la programación de calendario, inténtalo de nuevo más tarde.</p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

const Etapa2 = ({ level, program_info, programa, navigate, onNavigateWorkout }) => {
  const getDays = ['1', '2', '3', '4', '5', '6', '7'];
  const formatDuration = useCallback((workout) => obtenerDuracionFormateada(workout?.durationVideo), []);

  const productTemplate = useCallback((item) => (
    <div className={`grid grid-cols-1 xs:grid-cols-2 md:grid-cols-1 ${item?.semana === 6 ? "lg:grid-cols-3" : "lg:grid-cols-2"} items-center gap-4 pb-4 pt-4 h-full w-full`}>
      <div className='col-span-1 flex flex-col justify-center items-center text-center'>
        <p className={`font-golos-medium text-Crayola text-lg ${item?.Workout?.title ? "cursor-pointer" : ""}`} onClick={() => item?.Workout?.title && onNavigateWorkout(item)}>{item?.Workout?.title ? item?.Workout?.title : "Día de descanso"}</p>
      </div>
      <div className='col-span-1 mx-auto'>
        {item?.Workout?.title ? (
          <>
            {item?.Workout?.image && (
              <div className='group relative rounded-xl overflow-hidden select-none'>
                <div className="absolute inset-0 top-0 left-0 bg-black bg-opacity-30 opacity-100 rounded-2xl transition duration-300 pointer-events-none" />
                <div
                  className="absolute inset-0 flex items-center justify-center cursor-pointer opacity-100 transition duration-300"
                  onClick={() => onNavigateWorkout(item)}
                >
                  <div className="bg-black bg-opacity-50 p-2 rounded-full pointer-events-none">
                    <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6 4l8 6-8 6V4z"></path>
                    </svg>
                  </div>
                </div>
                <Image
                  src={item?.Workout?.image}
                  imageClassName="flex bg-white border border-gray-100 object-cover rounded-xl h-full max-h-[140px] w-full max-w-[240px] select-none pointer-events-none"
                  onError={(event) => {
                    event.target.onerror = null; // previene un bucle infinito
                    event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                  }}
                />
                <div className='absolute bottom-0 right-0 pointer-events-none'>
                  <p className='mr-2 mb-2 bg-black/50 text-white text-muted px-2 py-1 rounded-md select-none'>
                    {`${formatDuration(item?.Workout)?.minutos || "00"}:${formatDuration(item?.Workout)?.segundos || "00"}`}
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          <p className='text-sm text-center lg:text-left'>Recuerda dejar al menos un día a la semana para descansar. {item?.semana === 6 && (<span>¿Te quedó energía todavía? <span className='inline-block'>Haz una clase de nuestra librería:</span></span>)}</p>
        )}
      </div>
      {item?.semana === 6 && (
        <div className='col-span-1 xs:col-span-2 lg:col-span-1 mx-auto'>
          <ButtonPrimary
            text="Workouts"
            classNameContainer="!border-Crayola"
            classNameButton={"!px-8 !bg-Crayola hover:!bg-DeepKoamaru"}
            onClick={() => navigate("/mi-cuenta/workouts")}
          />
        </div>
      )}
    </div>
  ), [formatDuration, navigate, onNavigateWorkout]);

  if (!programa) return;

  const _getDescriptionCalendar = getDescriptionCalendar(level, programa?.CalendarDetail);

  return (
    <>
      <div className='w-full animate__animated animate__fadeIn'>
        <div className='relative flex items-center gap-6 bg-DeepKoamaru px-8 py-3 rounded-t-xl w-full'>
          <p className='font-golos-semibold text-white text-1.5xl'><span>{program_info?.id === 2 ? "Etapa 2" : `Calendario ${program_info?.name?.replace("Fitmom", '')}`}</span></p>
        </div>
        <div className='relative flex flex-col bg-Crayola px-8 py-4 rounded-b-xl text-center xs:text-left'>
          <div className='flex flex-col gap-4'>
            {!isEmpty(programa?.CalendarDetail?.description) && (
              <p className='text-white text-md'>
                <span className='whitespace-pre-line'>{programa?.CalendarDetail?.description}</span>
              </p>
            )}
            {!isEmpty(_getDescriptionCalendar?.instructions) && (
              <p className='text-white text-md space-x-1'>
                <span className='font-golos-semibold'>Instrucciones:</span>
                <span className='whitespace-pre-line'>{_getDescriptionCalendar?.instructions}</span>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-6 animate__animated animate__fadeIn'>
        {getDays?.map((day, index) => (
          <div key={`programa-key-${index}`} className='flex flex-col sm:flex-row bg-white rounded-xl'>
            <div className='flex flex-col items-center justify-center bg-Crayola sm:min-w-[240px] p-4 sm:p-10 rounded-t-xl sm:rounded-tr-none sm:rounded-l-xl'>
              <p className='font-golos-semibold uppercase text-white whitespace-nowrap text-center'>{dayjs(fechaActual).startOf('week').day(day).format("dddd")}</p>
              <p className='capitalize text-white text-sm whitespace-nowrap text-center'>{dayjs(fechaActual).startOf('week').day(day).format("DD MMMM, YYYY")}</p>
            </div>
            <div className='flex flex-col justify-center gap-4 w-full overflow-auto divide-y divide-gray-200 md:px-4'>
              {(day in programa?.CalendarDaysWorkouts) ? (
                <Carousel
                  value={programa?.CalendarDaysWorkouts?.[day]}
                  numScroll={1}
                  numVisible={1}
                  showIndicators={false}
                  showNavigators={programa?.CalendarDaysWorkouts?.[day]?.length > 1 ? true : false}
                  autoplayInterval={0}
                  itemTemplate={productTemplate}
                />
              ) : (
                <div className={`grid grid-cols-1 xs:grid-cols-2 md:grid-cols-1 ${day !== 7 ? "lg:grid-cols-2 xl:grid-cols-3" : "lg:grid-cols-2"} items-center gap-6 px-3 pb-4 pt-4 w-full`}>
                  <div className='col-span-1 flex flex-col justify-center items-center text-center'>
                    <p className='font-golos-medium text-Crayola text-lg'>Día de descanso</p>
                  </div>
                  <div className='col-span-1 mx-auto'>
                    <p className='text-sm text-center lg:text-left'>Recuerda dejar al menos un día a la semana para descansar.</p>
                    {day !== '7' && (
                      <p className='text-sm text-center lg:text-left'>¿Te quedó energía todavía? <span className='inline-block'>Haz una clase de nuestra librería:</span></p>
                    )}
                  </div>
                  {day !== '7' && (
                    <div className='col-span-1 xs:col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-1 mx-auto'>
                      <ButtonPrimary
                        text="Workouts"
                        classNameContainer="!border-Crayola"
                        classNameButton={"!px-8 !bg-Crayola hover:!bg-DeepKoamaru"}
                        onClick={() => navigate("/mi-cuenta/workouts")}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TuPrograma;