import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { optionsToast } from '../../../config/toast';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { createPerfiles } from '../../../api/admin/perfiles';
import { isEmpty } from '../../../utils';

const CrearPerfil = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    sending: false,
    nombre_perfil: "",
    permisos: [],
  });

  useEffect(() => {
    return () => {
      window.PrimeToast.onHide = null;
    }
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onCreate = useCallback(async () => {
    if (isEmpty(state.nombre_perfil)) return message(null, 'El nombre de perfil es obligatorio.');

    try {
      setState({ sending: true });
      const response = await createPerfiles({
        name: state.nombre_perfil,
        ...state.permisos
      });
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate('/admin/perfiles');
        message('Perfil creado', '¡El perfil se ha creado exitosamente! Serás redirigido a la página de perfiles en breve...');
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el perfil. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el perfil. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state.nombre_perfil, state.permisos, setState, navigate]);

  const RolesDesign = useCallback(({ name, onChangeInputCrear, onChangeInputLeer, onChangeInputEditar, onChangeInputEliminar }) => (
    <div className='grid xs:grid-cols-2 items-center gap-2'>
      <p className='flex-1 xs:min-w-[170px]'>{name}</p>
      <div className='flex flex-row flex-wrap xs:flex-nowrap xs:justify-end gap-6'>
        <label className='inline-flex items-center cursor-pointer select-none'>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputCrear}
          />
          <span className='pl-3 text-muted'>Crear</span>
        </label>
        <label className='inline-flex items-center cursor-pointer select-none'>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputLeer}
          />
          <span className='pl-3 text-muted'>Ver</span>
        </label>
        <label className='inline-flex items-center cursor-pointer select-none'>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputEditar}
          />
          <span className='pl-3 text-muted'>Editar</span>
        </label>
        <label className='inline-flex items-center cursor-pointer select-none'>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputEliminar}
          />
          <span className='pl-3 text-muted'>Eliminar</span>
        </label>
      </div>
    </div>
  ), []);

  return (
    <>
      <SEO
        title="Crear nuevo perfil"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Crear nuevo perfil</h1>
          </div>
          <div className='inline-flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg'>
            <div className='mb-8'>
              <input
                type="text"
                placeholder='Nombre de perfil'
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.nombre_perfil}
                onChange={(event) => setState({ nombre_perfil: event.target.value })}
              />
            </div>
            <p className='mb-6'>Asigna permisos al perfil:</p>
            <div className='flex flex-col gap-6'>
              <RolesDesign
                name="Perfiles"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.profilesPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.profilesPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.profilesPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.profilesPermissions")}
              />
              <RolesDesign
                name="Usuarios"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.usersPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.usersPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.usersPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.usersPermissions")}
              />
              <RolesDesign
                name="Clientas"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.clientsPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.clientsPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.clientsPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.clientsPermissions")}
              />
              <RolesDesign
                name="Compras"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.storePermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.storePermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.storePermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.storePermissions")}
              />
              <RolesDesign
                name="Programas"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.programsPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.programsPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.programsPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.programsPermissions")}
              />
              <RolesDesign
                name="Descargables"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.downloadablePermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.downloadablePermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.downloadablePermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.downloadablePermissions")}
              />
              <RolesDesign
                name="Banners"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.bannersPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.bannersPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.bannersPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.bannersPermissions")}
              />
              <RolesDesign
                name="Productos"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.productsPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.productsPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.productsPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.productsPermissions")}
              />
              <RolesDesign
                name="Cupones"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.couponsPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.couponsPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.couponsPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.couponsPermissions")}
              />
              <RolesDesign
                name="Blog"
                onChangeInputCrear={event => setState({ crear: event.target.checked }, "permisos.blogPermissions")}
                onChangeInputLeer={event => setState({ leer: event.target.checked }, "permisos.blogPermissions")}
                onChangeInputEditar={event => setState({ editar: event.target.checked }, "permisos.blogPermissions")}
                onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "permisos.blogPermissions")}
              />
            </div>

            <div className='flex justify-end items-center gap-3 mt-14'>
              <ButtonCustom
                text="CANCELAR"
                classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                onClick={() => navigate('/admin/perfiles')}
              />
              <ButtonCustom
                text="CREAR"
                classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                onClick={onCreate}
                loading={state.sending}
              />
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default CrearPerfil;