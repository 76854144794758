import qs from 'qs';
import axios from "../";
const prefix = "admin/perfiles";

export const getPerfiles = async (id) => {
  try {
    const endpoint = id ? `${id}` : "";
    const response = await axios.get(`/${prefix}/${endpoint}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createPerfiles = async (params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`/${prefix}/nuevo-perfil`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePerfil = async (id, params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.put(`/${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePerfil = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};