import React, { useCallback, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import { Image } from "primereact/image";
import { ReactComponent as PrevIcon } from "../../../assets/icons/prev-icon.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/next-icon.svg";
import { useCustomState } from "../../../hooks/useCustomState";
import { responsiveOptionsCarousel } from "../../../utils/responsiveOptionsCarousel";
import { getRecommendedCores } from "../../../api/client/cores";
import { getRecommendedWorkouts } from "../../../api/client/workouts";
import { getRecommendedVideos } from "../../../api/client/galeriaVideos";
import { getSecciones } from "../../../api/client/secciones";

const CarouselVideosSugeridos = ({ id, isTypeCore, isTypeParto, onChangeVideo }) => {
  const [state, setState] = useCustomState({
    items: [],
    page: null,
    itemsPerPage: null,
  });
  const totalPages = Math.ceil(state.items?.length / state.itemsPerPage);

  useEffect(() => {
    if (isTypeCore) {
      getRecommendedCores(id).then(response => setState({ items: response?.data }));
    } else if (isTypeParto) {
      getSecciones({ code: "parto" }).then(response => {
        const _seccionID = response?.data?.[0]?.id;
        getRecommendedVideos(id, { sectionID: _seccionID }).then(response => setState({ items: response?.data }));
      });
    } else {
      getRecommendedWorkouts(id).then(response => setState({ items: response?.data }));
    }
    getItemsPerPage();
    // eslint-disable-next-line
  }, [isTypeCore, isTypeParto]);

  const getItemsPerPage = () => {
    const windowWidth = window.innerWidth;
    const options = responsiveOptionsCarousel.filter(
      option => parseInt(option.breakpoint) >= windowWidth
    );
    const lastOption = options.length > 0 ? options[options.length - 1] : null;
    const itemsPerPage = lastOption ? lastOption.numVisible : 4;

    setState({ itemsPerPage: itemsPerPage });
  };

  const changePage = useCallback((type) => {
    const newPage = type === "next" ? state.page + 1 : state.page - 1;
    if (newPage >= 0 && newPage < totalPages) {
      setState({ page: newPage });
    }
    // eslint-disable-next-line
  }, [setState, state.page, totalPages]);

  const CarouselTemplate = useCallback((item) => {
    const video = item?.workout || item?.core || item?.galeriaVideo;

    return (
      <div className="flex flex-col justify-center text-center px-3 space-y-4 c-pointer max-w-[213px]" onClick={() => onChangeVideo(video)}>
        <div className="relative min-h-[110px]">
          <Image
            src={video?.image}
            alt="Portada"
            imageClassName="select-none pointer-events-none object-cover rounded-lg min-h-[110px] h-full w-full"
            className="bg-white border border-gray-100 min-h-[110px] h-full rounded-lg flex justify-center items-center"
            onError={(event) => {
              event.target.onerror = null; // previene un bucle infinito
              event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
            }}
          />
          <div className="absolute inset-0 top-0 left-0 bg-black bg-opacity-30 opacity-100 rounded-lg transition duration-300 pointer-events-none" />
          <div className="absolute inset-0 flex items-center justify-center cursor-pointer opacity-100 transition duration-300">
            <div className="bg-black bg-opacity-50 p-2 rounded-full pointer-events-none">
              <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M6 4l8 6-8 6V4z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="overflow-hidden">
          <p className="text-DarkJungleGreen text-sm"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {video?.title}
          </p>
        </div>
      </div>
    );
  }, [onChangeVideo]);

  if (!id) return <></>;

  return (
    <div className='bg-white px-8 py-6 space-y-6 rounded-xl'>
      <div className="flex items-center justify-between space-x-10">
        <p className='font-golos-semibold text-Crayola text-md'>Videos sugeridos:</p>
        <div className="flex items-center space-x-2">
          <div className="c-pointer pl-1 pr-2" onClick={() => changePage("prev")}>
            <PrevIcon fill={state.page <= 0 ? "#9A9A9A" : "#7e73d6"} width={8} />
          </div>
          <div className="c-pointer pl-2 pr-1" onClick={() => changePage("next")}>
            <NextIcon fill={state.page >= (totalPages - 1) ? "#9A9A9A" : "#7e73d6"} width={8} />
          </div>
        </div>
      </div>
      {(state.items?.length > 0) ? (
        <Carousel
          value={state.items}
          numVisible={state.itemsPerPage}
          numScroll={state.itemsPerPage}
          className={`flex ${state.items?.length > 2 ? "items-center" : "items-start"} custom-carousel`}
          showIndicators={false}
          showNavigators={false}
          autoplayInterval={0}
          itemTemplate={CarouselTemplate}
          onPageChange={event => setState({ page: event?.page })}
          page={state.page}
          responsiveOptions={responsiveOptionsCarousel}
        />
      ) : (
        <div className='flex flex-col justify-center text-center'>
          <div className='font-golos-medium text-Crayola'>
            <p>No se encontraron resultados, inténtalo de nuevo más tarde.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default CarouselVideosSugeridos;