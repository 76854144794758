import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonPrimary } from '../../components/Button';
import SEO from '../../components/SEO';
import GlobalContext from "../../contexts/GlobalContext";
import { useCustomState } from '../../hooks/useCustomState';
import { forgotUser } from '../../redux/reducers/authSlice';
import { optionsToast } from '../../config/toast';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { isEmpty } from '../../utils';

import NoEncontrado from '../Errors/404';

const ActualizarContraseniaClient = () => {
  const { setModal } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [state, setState] = useCustomState({
    sending: false,
    form: {
      password: "",
      confirm_password: "",
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = useCallback((params) => {
    setState(params, 'form');
  }, [setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (isEmpty(state.form?.password) || isEmpty(state.form?.confirm_password)) return message(null, "Las contraseñas no pueden estar vacías.");
    if (state.form?.password !== state.form?.confirm_password) return message("Contraseña incorrecta", "Las contraseñas no coinciden.");

    dispatch(forgotUser({ ...state.form, email, code: token }, { verify: true }))
      .then((response) => {
        if (response?.status === true) {
          message('Contraseña actualizada', 'Su contraseña ha sido actualizada correctamente. Si necesita ayuda adicional, no dude en contactarnos. ¡Gracias!');
          navigate('/');
          setModal("login", navigate);
        } else {
          message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la contraseña. Por favor, inténtalo de nuevo más tarde.');
        }
      });
  }, [state.form, email, token, setModal, dispatch, navigate]);

  if (isEmpty(token) && isEmpty(email)) return <NoEncontrado hideButtons={true} />

  return (
    <>
      <SEO
        title="Actualizar contraseña"
      />
      <div className="min-h-screen bg-Magnolia bg-cover bg-center bg-no-repeat flex flex-col justify-center py-12 px-6 lg:px-8" style={{ backgroundImage: `url('${require("../../assets/images/login.jpg")}')` }}>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white pt-12 pb-9 px-4 shadow rounded-md sm:px-10">
            <img alt="Logo" src={require("../../assets/images/logo3.png")} className="mx-auto max-w-[220px] w-full mb-10" />
            <h1 className='font-golos-semibold text-Crayola text-center text-2xl leading-7'>
              ACTUALIZA TU
              <p>CONTRASEÑA</p>
            </h1>
            <form className="form-login space-y-3 mt-7" onSubmit={onSubmit}>
              <input
                type="password"
                autoComplete="current-password"
                required
                placeholder='Contraseña'
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.form?.password}
                onChange={(event) => onChange({ password: event.target.value })}
              />
              <input
                type="password"
                required
                placeholder='Confirmar contraseña'
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.form?.confirm_password}
                onChange={(event) => onChange({ confirm_password: event.target.value })}
              />
              <div className='flex justify-center'>
                <ButtonPrimary
                  text="ENVIAR"
                  classNameContainer="inline-flex justify-center min-w-[140px] !mt-6 !border-0"
                  classNameButton="py-3 px-10 !rounded-full !border-0 !bg-Orchid hover:!bg-OrchidHover w-full !text-center justify-center"
                  type="submit"
                  loading={state.sending}
                  onClick={onSubmit}
                />
              </div>
              <div className="flex justify-center pt-2">
                <p className='inline-block font-golos text-center text-muted text-Orchid underline cursor-pointer p-1' onClick={() => navigate("/")}>
                  Cancelar
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActualizarContraseniaClient;