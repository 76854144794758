import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { optionsToast } from '../../../config/toast';
import Loading from '../../../components/Loading';
import { isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { getCategorias, updateCategory } from '../../../api/admin/categorias';
import { getProgramas } from '../../../api/client/programas';
import { getTypesCategory } from '../../../misc/getTypesCategory';
import obtenerNombreImagen from '../../../utils/obtenerNombreImagen';
const prefix = "/admin/categorias";

const EditarCategoria = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    name: "",
    description: "",
    portada_file: null,
    tipo_categoria_seleccionada: null,
    listado_tipo_categorias: [],
    programa_seleccionado: null,
    listado_programas: [],
  });

  const refPortadaFile = useRef(null);

  useEffect(() => {
    (async () => {
      let categoria = await getCategorias({ id: id, populate: ["sub_categoria"] });
      const programas = await getProgramas();
      if (categoria?.status === true) {
        categoria = categoria?.data?.[0];
        const tipos_categoria = await getTypesCategory();

        const filter_programa_seleccionado = programas?.data?.find(item => parseInt(item?.id) === parseInt(categoria?.programID));

        setState({
          name: categoria?.name,
          description: categoria?.description,
          portada_file: categoria?.image && [{ name: obtenerNombreImagen(categoria?.image, true) }],
          tipo_categoria_seleccionada: categoria?.type,
          listado_tipo_categorias: tipos_categoria,
          listado_programas: programas?.data,
          programa_seleccionado: filter_programa_seleccionado,
          loading: false,
        });
      }
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, [id]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onUpdate = useCallback(async () => {
    if (isEmpty(state.name)) return message(null, 'El nombre es obligatorio.');
    if (isEmpty(state?.tipo_categoria_seleccionada)) return message(null, 'Selecciona un tipo de categoría para continuar.');
    if (isEmpty(state?.programa_seleccionado?.id)) return message(null, 'Selecciona un programa para continuar.');

    const formDataFields = {
      programID: state.programa_seleccionado?.id,
      name: state.name,
      description: state.description,
      image: ((state.portada_file instanceof FileList && state.portada_file?.length > 0) && [...state.portada_file]),
      type: state.tipo_categoria_seleccionada,
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["image"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending: true });
      const response = await updateCategory(id, _formData);
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Categoría actualizada', '¡La categoría se ha actualizado exitosamente! Serás redirigido a la página de categorías en breve...');
      } else {
        window.PrimeToast.onHide = null;
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la categoría. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      window.PrimeToast.onHide = null;
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar la categoría. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, state, setState, navigate]);

  return (
    <>
      <SEO
        title="Editar categoría"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Editar categoría</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='mt-10 mx-6 lg:mx-8'>
              <div className='bg-white rounded-lg max-w-md'>
                <div className='flex flex-col px-8 py-10'>
                  <div className='flex flex-col gap-4 mb-12'>
                    <input
                      type="text"
                      placeholder='Nombre'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.name}
                      onChange={(event) => setState({ name: event.target.value })}
                    />
                    <textarea
                      placeholder='Descripción'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.description || ""}
                      onChange={(event) => setState({ description: event.target.value })}
                    />
                    <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.portada_file?.length > 0 ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                      <p className='truncate'>{(state.portada_file?.length > 0 && state.portada_file?.[0]?.name) || "Sube una portada"}</p>
                      <input ref={refPortadaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_file: event.target.files })} />
                      <button className='underline' onClick={() => refPortadaFile?.current?.click()}>Examinar</button>
                    </div>
                    <Dropdown
                      value={state?.programa_seleccionado}
                      onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                      options={state.listado_programas}
                      optionLabel="name"
                      placeholder="Selecciona programa"
                      emptyMessage="No se encontraron resultados"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    />
                    <Dropdown
                      value={state?.tipo_categoria_seleccionada}
                      onChange={(event) => setState({ tipo_categoria_seleccionada: event.target.value })}
                      options={state.listado_tipo_categorias}
                      optionLabel="name"
                      placeholder="Selecciona tipo de categoría"
                      emptyMessage="No se encontraron resultados"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    />
                  </div>
                  <div className='flex flex-wrap justify-end items-center gap-3 mt-14'>
                    <ButtonCustom
                      text="CANCELAR"
                      classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                      onClick={() => navigate(prefix)}
                    />
                    <ButtonCustom
                      text="ACTUALIZAR"
                      classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                      onClick={onUpdate}
                      loading={state.sending}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarCategoria;