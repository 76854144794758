function paginate(array, page = 1, perPage = 10) {
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  const totalPages = Math.ceil(array.length / perPage);
  const currentPage = Math.min(Math.max(1, page), totalPages);

  const paginatedItems = array.slice(startIndex, endIndex);

  return {
    items: paginatedItems,
    currentPage,
    totalPages,
    totalItems: array.length,
  };
}

export default paginate;