import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '../../components/Button';
import SEO from '../../components/SEO';
import { useCustomState } from '../../hooks/useCustomState';
import { loginUser } from '../../redux/reducers/admin/authSlice';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.admin.auth);
  const [state, setState] = useCustomState({
    sending: false,
    form: {
      correo_electronico: "",
      contrasenia: "",
    },
  });

  useEffect(() => {
    if (isAuthenticated) navigate('/admin', { replace: true });
  }, [isAuthenticated, navigate]);

  const onChange = useCallback((params) => {
    setState(params, 'form');
  }, [setState]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setState({ sending: true });
    dispatch(loginUser({ email: state.form.correo_electronico, password: state.form.contrasenia }, { showAlert: true }))
      .then(_ => {
        setState({ sending: false });
      });
  }, [state.form, dispatch, setState]);

  return (
    <>
      <SEO
        title="Iniciar sesión"
      />
      <div className="min-h-screen bg-Magnolia bg-cover bg-center bg-no-repeat flex flex-col justify-center py-12 px-6 lg:px-8" style={{ backgroundImage: `url('${require("../../assets/images/login.jpg")}')` }}>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white py-12 px-4 shadow rounded-md sm:px-10">
            <img alt="Logo" src={require("../../assets/images/logo3.png")} className="mx-auto max-w-[220px] w-full mb-10" />
            <h1 className='font-golos-semibold text-Crayola text-center text-2xl'>INICIA SESIÓN</h1>
            <form className="form-login space-y-3 mt-7" onSubmit={onSubmit}>
              <input
                name="correo_electronico"
                type="email"
                autoComplete="email"
                required
                placeholder='Correo electrónico'
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.form?.correo_electronico}
                onChange={(event) => onChange({ correo_electronico: event.target.value })}
              />
              <input
                name="contrasenia"
                type="password"
                autoComplete="current-password"
                required
                placeholder='Contraseña'
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                value={state?.form?.contrasenia}
                onChange={(event) => onChange({ contrasenia: event.target.value })}
              />
              <div className="flex justify-end">
                <p className='inline-block font-golos-medium text-right text-muted text-Orchid underline cursor-pointer p-1' onClick={() => navigate("/admin/recuperar-contrasenia")}>
                  ¿Olvidaste tu contraseña?
                </p>
              </div>
              <div className='flex justify-center'>
                <ButtonPrimary
                  text="INGRESAR"
                  classNameContainer="inline-flex justify-center min-w-[140px] !mt-6 !border-0"
                  classNameButton="py-3 px-10 !rounded-full !border-0 !bg-Orchid hover:!bg-OrchidHover w-full !text-center justify-center"
                  type="submit"
                  loading={state.sending}
                  onClick={onSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;