import QueryString from "qs";
import axios from "../";
const prefix = "/mi_suscripcion";

export const getSubscription = async (params) => {
  try {
    const _params = QueryString.stringify({
      id: params?.id,
      userID: params?.userID,
      programID: params?.programID,
    });
    const response = await axios.get(`${prefix}/?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const actualizar_metodo_pago = async (data) => {
  try {
    const response = await axios.put(`${prefix}/actualizar-metodo-pago`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSubscription = async (params) => {
  try {
    const response = await axios.put(`${prefix}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const cancelSubscription = async (params) => {
  try {
    const response = await axios.post(`${prefix}/cancelar-suscripcion`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resumeSubscription = async (params) => {
  try {
    const response = await axios.post(`${prefix}/reanudar-suscripcion`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};