// import QueryString from "qs";
import axios from "../";
const prefix = "/pagos";

export const getClienteSecreto = async (userID) => {
  try {
    const response = await axios.get(`${prefix}/obtener_cliente?userID=${userID}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const actualizar_callback_paypal = async (data) => {
  try {
    const response = await axios.post(`${prefix}/actualizar_callback_paypal`, {
      subscriptionId: data?.subscriptionId,
      isMobile: data?.isMobile,
      platform: data?.platform,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verificar_suscripcion_paypal = async (data) => {
  try {
    const response = await axios.post(`${prefix}/verificar_suscripcion_paypal`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const crear_suscripcion_paypal = async (data, params) => {
  try {
    const response = await axios.post(`${prefix}/crear_suscripcion_paypal`, data ? { requestPaypal: data } : params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmar_pago = async (data) => {
  try {
    const response = await axios.post(`${prefix}/confirmar_pago`, {
      paymentIntent: data?.paymentIntent,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPruebaGratis = async (userID) => {
  try {
    const response = await axios.get(`${prefix}/prueba_gratis?userID=${userID}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verificar_tarjeta = async (data) => {
  try {
    const response = await axios.post(`${prefix}/verificar_tarjeta`, {
      userID: data?.userID,
      paymentMethodId: data?.paymentMethodId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const cancelar_verificar_tarjeta = async (data) => {
  try {
    const response = await axios.post(`${prefix}/cancelar_verificar_tarjeta`, {
      paymentIntentId: data?.paymentIntentId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const comprar_suscripcion = async (data) => {
  try {
    const response = await axios.post(`${prefix}/comprar_suscripcion`, {
      couponID: data?.couponID,
      userID: data?.userID,
      programID: data?.programID,
      cycleType: data?.cycleType,
      cardBrand: data?.cardBrand,
      cardLast4: data?.cardLast4,
      expirationMonth: data?.expirationMonth,
      expirationYear: data?.expirationYear,
      paymentTransaction: data?.paymentTransaction,
      subscriptionTransaction: data?.subscriptionTransaction,
      paypalToken: data?.paypalToken,
      paymentMethodId: data?.paymentMethodId,
      setupIntentId: data?.setupIntentId,
      radarSessionId: data?.radarSessionId,
      methodPayment: data?.methodPayment || "stripe",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};