function generarCodigoFactura() {
  const caracteres = 'ABCDEF0123456789';
  let codigo = '';

  for (let i = 0; i < 9; i++) {
    const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
    codigo += caracteres[indiceAleatorio];
  }

  return codigo;
}

export default generarCodigoFactura;