function calculateIdealVideoHeight(maxHeight, _window = null) {
  const screenWidth = window?.innerWidth || _window?.innerWidth;
  const screenHeight = window?.innerHeight || _window?.innerHeight;
  const aspectRatio = 16 / 9; // Cambia esto según el aspecto del video
  const minHeightRecommended = 240; // Altura mínima recomendada en píxeles

  let idealHeight = screenWidth / aspectRatio;

  if (idealHeight > screenHeight) {
    idealHeight = screenHeight;
  }

  if (idealHeight < minHeightRecommended) {
    idealHeight = minHeightRecommended;
  }

  return (maxHeight && idealHeight > maxHeight) ? maxHeight : idealHeight;
}

export default calculateIdealVideoHeight;