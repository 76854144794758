import qs from 'qs';
import axios from "../";
const prefix = "/admin/cores";

export const getCores = async (id, filters) => {
  try {
    const params = qs.stringify({
      populate: ["programa", "categoria", "sub_categoria", "video_recomendado"],
      filters: filters,
    }, { arrayFormat: 'brackets' });
    const endpoint = id ? `/${id}` : "";
    const response = await axios.get(`${prefix}/${endpoint}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCore = async (params, options) => {
  try {
    const response = await axios.post(`${prefix}/nuevo-core`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
      onUploadProgress: progressEvent => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        if (options?.onUploadProgress) options?.onUploadProgress(percentComplete);
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCore = async (id, params, options) => {
  try {
    const response = await axios.put(`${prefix}/${id}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
      onUploadProgress: progressEvent => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        if (options?.onUploadProgress) options?.onUploadProgress(percentComplete);
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCore = async (id) => {
  try {
    const response = await axios.delete(`${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};