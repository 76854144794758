import QueryString from "qs";
import axios from "../";
const prefix = "/workouts";

export const getWorkouts = async (id, params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/${id || ""}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRecommendedWorkouts = async (id) => {
  try {
    const response = await axios.get(`${prefix}/videos_sugeridos/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoriesWorkouts = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/categorias?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSubCategoriesWorkouts = async (id, params) => {
  try {
    const _params = QueryString.stringify({ ...params, count_workouts: true }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/subcategorias/${id}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};