import QueryString from "qs";
import axios from "../";
const prefix = "/admin/calendarios";

export const getCalendarios = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCalendary = async (params) => {
  try {
    const response = await axios.post(`${prefix}/nuevo-calendario`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const asignarWorkout = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.post(`${prefix}/asignar-workout`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCalendaryDetail = async (params) => {
  try {
    const response = await axios.post(`${prefix}/nuevo-calendario-detalles`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCalendaryDetail = async (id, params) => {
  try {
    const response = await axios.put(`${prefix}/editar-detalles/${id}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCalendary = async (id, params, type) => {
  try {
    const typeCalendar = type === 'semanas' ? "editar-semanas" : "editar-dias";
    const response = await axios.put(`${prefix}/${typeCalendar}/${id}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteWorkout = async (id, params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.delete(`${prefix}/eliminar-workout/${id}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};