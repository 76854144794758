import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  etapaSeleccionada: 1,
  programaSeleccionado: null,
};

const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setDataMisc: (state, action) => {
      if (action.payload?.etapaSeleccionada) state.etapaSeleccionada = action.payload?.etapaSeleccionada;
      if (action.payload?.programaSeleccionado) state.programaSeleccionado = action.payload?.programaSeleccionado;
    },
    clearDataMisc: (state) => {
      state.etapaSeleccionada = 1;
      state.programaSeleccionado = null;
    },
  },
});

export const { setDataMisc, clearDataMisc } = miscSlice.actions;

export default miscSlice.reducer;