export default function getDescriptionCore(program, item) {
  let description;

  switch (program) {
    case "fitmom-embarazo":
      description = item?.description_embarazo;
      break;
    case "fitmom-posparto":
      description = item?.description_posparto;
      break;
    case "fitmom-strength":
      description = item?.description_strength;
      break;

    default:
      description = item?.description;
      break;
  }

  return description;
}