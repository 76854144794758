export default function obtenerNombreImagen(rutaCompleta, incluirExtension = false) {
  const rutaDividida = rutaCompleta?.split('/');
  const nombreArchivo = rutaDividida?.[rutaDividida?.length - 1];
  
  if (incluirExtension) {
    return nombreArchivo;
  } else {
    const nombreDividido = nombreArchivo?.split('.');
    const nombreSinExtension = nombreDividido?.slice(0, nombreDividido.length - 1)?.join('.');
    return nombreSinExtension;
  }
}