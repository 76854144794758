// https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.ts
import { colors } from "../config/colors";

const customTableStyles = {
  table: {
    style: {
      backgroundColor: colors.white2,
    },
  },
  header: {
    style: {
      backgroundColor: colors.white2,
    },
  },
  subHeader: {
    style: {
      backgroundColor: colors.white2,
      borderRadius: 10,
      marginBottom: 15,
    },
  },
  headRow: {
    style: {
      backgroundColor: "#FFF",
      borderWidth: 1,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  },
  headCells: {
    style: {
      color: "#191e25",
      fontWeight: 600,
      paddingLeft: '15px', // override the cell padding for head cells
      paddingRight: '15px',
    },
  },
  rows: {
    style: {
      backgroundColor: colors.white2,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      minHeight: '55px', // override the row height
      "&:last-of-type": {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }
    },
  },
  cells: {
    style: {
      color: "#191e25",
      paddingLeft: '15px', // override the cell padding for data cells
      paddingRight: '15px',
    },
  },
  pagination: {
    style: {
      borderTop: "none",
      marginBottom: 15,
      marginTop: 15,
      borderRadius: 10,
    },
  },
  noData: {
    style: {
      backgroundColor: colors.white2,
    },
  },
};

export default customTableStyles;