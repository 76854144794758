import QueryString from "qs";
import axios from "../";
const prefix = "/secciones";

export const getSecciones = async (params) => {
  try {
    const _params = QueryString.stringify({
      populate: ["programa"],
      ...params
    }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};