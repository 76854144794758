import React from 'react';

const GlobalContext = React.createContext({
  showModal: false,
  loginModal: false,
  contactModal: false,
  testimonioModal: false,
  setModal: () => { }
});

export default GlobalContext;