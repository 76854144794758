import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import SEO from '../../../components/SEO';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import { isEmpty } from '../../../utils/';
import { getProgramas } from '../../../api/admin/programas';
import { setDataMisc } from '../../../redux/reducers/miscSlice';
import CalendarioEmbarazo from './Embarazo';
import CalendarioPosparto from './Posparto';
const prefix = '/admin/calendarios/editar-calendario';

const Calendarios = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const { programaSeleccionado } = useSelector(state => state.app.misc);

  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    programa_seleccionado: null,
    listado_programas: [],
    filtrar_fecha: null,
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    setState({ loading: true });
    const _getListadoProgramas = await getProgramas();
    const _programa_seleccionado = _getListadoProgramas?.data?.filter(item => item?.code === (name || programaSeleccionado?.code));

    if (programaSeleccionado?.code !== _programa_seleccionado?.[0]?.code) dispatch(setDataMisc({ programaSeleccionado: _programa_seleccionado?.[0] }));

    setState({
      listado_programas: _getListadoProgramas?.data,
      programa_seleccionado: _programa_seleccionado?.[0] || null,
      loading: false,
    });
    // eslint-disable-next-line
  }, [name, programaSeleccionado]);

  const onChangePrograma = useCallback((event) => {
    window.history.pushState({}, null, `${prefix}/${(event.target.value)?.code}`);
    setState({ programa_seleccionado: event.target.value });
    dispatch(setDataMisc({ programaSeleccionado: event.target.value }));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SEO
        title={`Calendarios ${!isEmpty(state.programa_seleccionado) ? `/ ${state.programa_seleccionado?.name}` : ""}`}
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col sm:flex-row items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-2xl text-center md:text-left'>
                  {`Calendarios ${!isEmpty(state.programa_seleccionado) ? `/ ${state.programa_seleccionado?.name}` : ""}`}
                </h1>
                <div className='inline-flex flex-wrap justify-center lg:justify-start gap-3'>
                  <Calendar
                    locale="es"
                    value={state.filtrar_fecha}
                    onChange={(e) => setState({ filtrar_fecha: e.value })}
                    view="month"
                    dateFormat="MM yy"
                    hideOnDateTimeSelect={true}
                    readOnlyInput={true}
                    placeholder='Seleccionar fecha'
                    inputClassName="capitalize border-0 bg-Magnolia hover:!bg-MagnoliaHover rounded-md text-sm py-2 transition-all cursor-pointer"
                  />
                  <Dropdown
                    value={state?.programa_seleccionado}
                    onChange={onChangePrograma}
                    options={state.listado_programas}
                    optionLabel="name"
                    placeholder="Seleccionar programa"
                    emptyMessage="No se encontraron resultados"
                    className="custom-dropdown padding-reset border-0 bg-Magnolia hover:!bg-MagnoliaHover rounded-md text-sm py-2 transition-all"
                  />
                </div>
              </div>
              <div className='px-6 lg:px-8 mt-6'>
                {["fitmom-embarazo", "fitmom-strength"]?.includes(state.programa_seleccionado?.code) && (
                  <CalendarioEmbarazo
                    programID={state?.programa_seleccionado?.id}
                    startDate={state?.filtrar_fecha && new Date(state?.filtrar_fecha)?.toISOString()}
                  />
                )}
                {["fitmom-posparto"]?.includes(state.programa_seleccionado?.code) && (
                  <CalendarioPosparto
                    programID={state?.programa_seleccionado?.id}
                    startDate={state?.filtrar_fecha && new Date(state?.filtrar_fecha)?.toISOString()}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Calendarios;