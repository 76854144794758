import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Carousel } from "primereact/carousel";
import { Image } from "primereact/image";
import { ReactComponent as PrevIcon } from "../../../assets/icons/prev-icon.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/next-icon.svg";
import { ReactComponent as FlechaIcon } from "../../../assets/icons/flecha-icon.svg";
import { useCustomState } from "../../../hooks/useCustomState";
import { responsiveOptionsCarousel } from "../../../utils/responsiveOptionsCarousel";
import { getProductos } from "../../../api/client/productos";
import { isEmpty } from "../../../utils";

const CarouselProductosRecomendados = () => {
  const user = useSelector(state => state?.app?.auth?.user);
  const [state, setState] = useCustomState({
    items: [],
    page: null,
    itemsPerPage: null,
  });
  const totalPages = Math.ceil(state.items?.length / state.itemsPerPage);

  useEffect(() => {
    if (!isEmpty(user?.programID)) {
      getProductos({
        programas: [user?.programID],
      }).then(response => setState({ items: response?.data }));
    }

    getItemsPerPage();
    // eslint-disable-next-line
  }, [user?.programID]);

  const getItemsPerPage = () => {
    const windowWidth = window.innerWidth;
    const options = responsiveOptionsCarousel.filter(
      option => parseInt(option.breakpoint) >= windowWidth
    );
    const lastOption = options.length > 0 ? options[options.length - 1] : null;
    const itemsPerPage = lastOption ? lastOption.numVisible : 4;

    setState({ itemsPerPage: itemsPerPage });
  };

  const changePage = useCallback((type) => {
    const newPage = type === "next" ? state.page + 1 : state.page - 1;
    if (newPage >= 0 && newPage < totalPages) {
      setState({ page: newPage });
    }
    // eslint-disable-next-line
  }, [setState, state.page, totalPages]);

  const CarouselTemplate = useCallback((item) => (
    <div className="inline-flex flex-col px-2 space-y-4">
      <Image
        src={item?.image}
        alt={item?.name}
        imageClassName="bg-white border border-gray-100 object-contain h-full max-w-full rounded-xl p-4"
        className='inline-flex justify-center items-center min-h-[120px] h-[120px] min-w-[120px] rounded-xl'
        onError={(event) => {
          event.target.onerror = null; // previene un bucle infinito
          event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
        }}
      />
      <div className="overflow-hidden">
        <p className="text-DarkJungleGreen text-sm"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.name}
        </p>
        <a href={item?.url} className="inline-flex items-center space-x-3" rel="noreferrer" target='_blank'>
          <span className="flex text-Crayola text-sm underline">Ir a comprar</span>
          <span className="flex align-middle">
            <FlechaIcon fill={"#7e73d6"} width={14} />
          </span>
        </a>
      </div>
    </div>
  ), []);

  return (
    <>
      {isEmpty(user?.programID) ? (
        <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
          <div className='flex flex-col items-center justify-center gap-6'>
            <div>
              <p className='font-golos-medium text-Crayola text-center'>¡Lo sentimos!</p>
              <p className='font-golos-medium text-Crayola text-center'>Se requiere tener una suscripción activa para acceder a esta sección.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className='bg-white px-8 py-6 space-y-6 rounded-xl'>
          <div className="flex items-center justify-between space-x-10">
            <p className='font-golos-semibold text-Crayola text-md'>Productos que te recomiendo:</p>
            <div className="flex items-center space-x-2">
              <div className="c-pointer pl-1 pr-2" onClick={() => changePage("prev")}>
                <PrevIcon fill={state.page <= 0 ? "#9A9A9A" : "#7e73d6"} width={8} />
              </div>
              <div className="c-pointer pl-2 pr-1" onClick={() => changePage("next")}>
                <NextIcon fill={state.page >= (totalPages - 1) ? "#9A9A9A" : "#7e73d6"} width={8} />
              </div>
            </div>
          </div>
          {state.items?.length > 0 ? (
            <Carousel
              value={state.items}
              numVisible={state.itemsPerPage || 1}
              numScroll={state.itemsPerPage || 1}
              className="custom-carousel"
              showIndicators={false}
              showNavigators={false}
              autoplayInterval={0}
              itemTemplate={CarouselTemplate}
              onPageChange={event => setState({ page: event?.page })}
              page={state.page}
              responsiveOptions={responsiveOptionsCarousel}
            />
          ) : (
            <div className='font-golos-medium text-center text-Crayola'>
              <p>No se encontraron resultados, inténtalo de nuevo más tarde.</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CarouselProductosRecomendados;