import QueryString from "qs";
import axios from "../";
const prefix = "/descargables";

export const getDescargables = async (params) => {
  try {
    const _params = QueryString.stringify({
      populate: ["programa"],
      ...params,
    });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};