const intersectionObserver = ({ elementsRef, options, callback }) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (callback) callback(entry);
      });
    },
    options || { threshold: 1 }
  );

  elementsRef.current.forEach((element) => {
    if (element instanceof Element) {
      observer.observe(element);
    }
  });

  return observer;
}

export default intersectionObserver;