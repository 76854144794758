import { createSlice } from '@reduxjs/toolkit';
import { forgot, forgotVerify, login } from '../../../api/auth';
import { optionsToast } from '../../../config/toast';
import parseErrorMessage from '../../../utils/parseErrorMessage';

const prefix = "admin";
const initialState = {
  isAuthenticated: false,
  user: null,
  error: null,
};

const authSlice = createSlice({
  name: `${prefix}-auth`,
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
  },
});

export const { setUser, setError, logout } = authSlice.actions;

export const loginUser = (credentials, options) => {
  return async (dispatch) => {
    try {
      const response = await login(credentials, true);
      dispatch(setUser(response));
    } catch (error) {
      dispatch(setError(parseErrorMessage(error)));
      if (options?.showAlert) {
        window.PrimeToast.show({
          ...optionsToast(),
          detail: parseErrorMessage(error),
        });
      }
    }
  };
};

export const forgotUser = (credentials, options) => {
  return async (dispatch) => {
    try {
      const response = await (options?.verify ? forgotVerify(credentials, true) : forgot(credentials, true));
      return response;
    } catch (error) {
      dispatch(setError(parseErrorMessage(error)));
      if (options?.showAlert) {
        window.PrimeToast.show({
          ...optionsToast(),
          detail: parseErrorMessage(error),
        });
      }
      return error;
    }
  };
};

export default authSlice.reducer;