import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { ButtonPrimary } from '../components/Button';
import { useCustomState } from '../hooks/useCustomState';
import intersectionObserver from '../utils/intersectionObserver';
import VideoPlayer from '../components/Modal/VideoPlayer';

const SobreJime = () => {
  const elementsRef = useRef([]);
  const navigate = useNavigate();

  const [state, setState] = useCustomState({
    isVideoVisible: false,
    videoUrl: require("../assets/videos/intro.mp4"),
  });

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SEO
        title="Sobre Jime"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <VideoPlayer
        url={state.videoUrl}
        visible={state.isVideoVisible}
        onHide={() => setState({ isVideoVisible: false })}
        propsVideo={{
          controls: true
        }}
        propsContainer={{
          style: {
            height: 360
          }
        }}
      />
      <div className={`bg-white h-60 lg:h-128`}>
        <HeaderNav
          theme="white"
        />
      </div>

      <div className='bg-Magnolia lg:-mt-8'>
        <div className="xl:container mx-auto px-6">
          <div className='flex justify-center relative z-[1] -top-[4rem] lg:-top-[19rem]'>
            <div className='bg-white inline-flex flex-col sm:flex-row items-center justify-center sm:pb-2 md:pb-8 px-4 sm:px-10 rounded-xl shadow-md sm:shadow-xl shadow-DeepKoamaru/16'>
              <div className='px-6 py-6 rounded-l-xl'>
                <Image
                  src={require("../assets/images/sobre-jime/perfil.png")}
                  imageClassName="w-full max-w-[160px] xs:max-w-[220px] md:max-w-xs rounded-xl"
                />
              </div>
              <div className='sm:max-w-[320px] md:max-w-[390px] md:pl-8 py-6 rounded-r-xl text-center md:text-left'>
                <div className='mb-8'>
                  <p className='text-DeepKoamaru text-xl sm:text-2xl md:text-4xl'>¡Hola soy</p>
                  <p className='font-golos-bold text-DeepKoamaru text-xl sm:text-2xl md:text-4xl'>Jimena Cantú!</p>
                </div>
                <div className='mb-6'>
                  <p className='font-golos text-Orchid text-sm md:text-xl'>Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core.</p>
                </div>
                <p className='font-golos-bold text-Orchid text-sm md:text-xl'>¡Conoce un poco sobre mi!</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-Magnolia pb-4 md:pb-20 -mt-[2rem] lg:-mt-[12rem]'>
        <div className="lg:container mx-auto mb-6 px-6">
          <div className='grid md:grid-cols-2 gap-6 sm:px-10 text-center md:text-left'>
            <div className='space-y-6'>
              <p><span className='font-golos-bold text-Crayola'>Para mí el 2020 fue un año difícil</span>, a nivel global nos enfrentábamos a una pandemia que mantuvo al mundo aislado. Además, tras el fallecimiento reciente de mi papá, estaba pasando por un momento muy triste y enterarme que sería mamá por tercera vez me creó mucha ansiedad e incertidumbre. “¿Cómo podía sentirme feliz por la llegada de un nuevo bebé cuando me sentía triste por la pérdida de mi papá?”.</p>
              <p>Los primeros 3 meses de mi embarazo lloraba mucho y me sentía muy triste y deprimida y una de las cosas que más me ayudaba a lidiar con mi tristeza y depresión era el ejercicio.</p>
            </div>
            <div className='space-y-6'>
              <p>Yo ya traía la espinita de certificarme como Personal Trainer, porque siempre me ha encantado el ejercicio, pero con la noticia del nuevo embarazo, sentí inseguridad sobre mi capacidad de desempeñar mi rol como coach estando embarazada. Me certifiqué como Personal Trainer, pero seguía con la incertidumbre sobre qué ejercicios eran seguros y cuáles no.</p>
              <p>Entonces buscando en internet me encontré con una certificación especializada en Fitness Pre y Posnatal que me llamó la atención y fue así como me adentré en este nicho y me dí cuenta que era un segmento muy desatendido.</p>
            </div>
          </div>
        </div>
      </div>

      <Image src={require("../assets/images/sobre-jime/portada.jpg")} alt="Portada" imageClassName='w-full select-none pointer-events-none' />

      <div className='bg-white py-10 md:py-20'>
        <div className="lg:container mx-auto mb-6 px-6 text-center md:text-left">
          <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__slideInDown"} className='animate__animated flex flex-col sm:flex-row items-center justify-center gap-6 mb-10 md:mb-20'>
            <p className='text-DeepKoamaru text-5xl mb-3'>Así nace</p>
            <Image
              src={require("../assets/images/logo-head.png")}
              imageClassName="max-w-[220px] mx-auto"
            />
          </div>
          <div className='space-y-6 mb-24'>
            <div className='flex flex-wrap items-center justify-center gap-8 sm:px-10'>
              <div className='sm:flex-1 space-y-6 max-w-sm'>
                <p>En Julio del 2020 abrí mi cuenta en Instagram con el objetivo de informar y motivar a mamás y futuras mamás a llevar un embarazo saludable con ejercicio. Empecé dando clases con un grupo pequeño de alumnas y a partir de ahí fui creciendo. En noviembre de 2020 lancé mi estudio digital <span className='font-golos-bold text-Crayola'>www.jimefitmom.com</span> y mi primer programa para embarazo.</p>
                <p>A finales del 2020 nació mi hija Ángela y me enfrentaba a un nuevo reto: el posparto. Tenía que recuperarme y recuperar mi fuerza para poder regresar al ejercicio y a mi nuevo rol de coach.</p>
              </div>
              <div>
                <Image
                  src={require("../assets/images/sobre-jime/img03.png")}
                  alt="Imagen 3"
                  imageClassName='rounded-xl w-full max-w-xs sm:max-w-[260px] md:max-w-xs'
                />
              </div>
            </div>
            <div className='flex flex-wrap items-center justify-center gap-8 sm:px-10'>
              <div className='order-2 sm:order-1'>
                <Image
                  src={require("../assets/images/sobre-jime/img04.png")}
                  alt="Imagen 4"
                  imageClassName='rounded-xl w-full max-w-xs sm:max-w-[260px] md:max-w-xs'
                />
              </div>
              <div className='order-1 sm:order-2 sm:flex-1 space-y-6 max-w-sm'>
                <p>A principios del 2021, entre el posparto, una recién nacida y otras 2 hijas que atender, inicié una nueva certificación que le dió un giro completo a mi forma de enseñanza. Con esta certificación aprendí que problemas comunes en el embarazo y posparto como la diástasis y la incontinencia pueden ser prevenidos mediante técnicas específicas de entrenamiento.</p>
                <p>Recuerdo que pensé ¿Por qué nadie me había dicho esto antes? y me di cuenta que muchos de estos problemas no eran normales como yo creía.</p>
              </div>
            </div>
            <div className='flex flex-wrap items-center justify-center gap-8 sm:px-10 pt-6'>
              <div className='sm:flex-1 space-y-6 max-w-sm'>
                <p>Con este nuevo aprendizaje, decidí trabajar en mi nuevo programa para posparto, enfocado en la rehabilitación y recuperación del cuerpo después de tener un bebé y en regresar al ejercicio de forma segura. Fue tanta mi pasión por el tema que a finales del 2021 obtuve una certificación más como Especialista en Diástasis y Core.</p>
                <p>Desde entonces me sigo actualizando en temas del fitness pre y posnatal y sigo adaptando mis programas constantemente. Miles de mujeres han tomado mis programas y experimentado sus beneficios incluyendo corrección de problemas como incontinencia, dolor de espalda baja y diástasis además de mejores experiencias de parto y recuperaciones más rápidas en el posparto.</p>
              </div>
              <div>
                <Image
                  src={require("../assets/images/sobre-jime/img05.png")}
                  alt="Imagen 5"
                  imageClassName='rounded-xl w-full max-w-xs sm:max-w-[260px] md:max-w-xs'
                />
              </div>
            </div>
          </div>
          <p ref={(el) => elementsRef.current.push(el)} data-animation={"animate__slideInDown"} className='animate__animated text-DeepKoamaru text-center text-3xl md:text-4xl mb-10 break-words'>Mis Certificaciones</p>
          <div className='flex flex-wrap items-center justify-center gap-10'>
            <Image
              src={require("../assets/images/sobre-jime/certificados/certif01.jpg")}
              alt="Certificado 01"
              imageClassName='w-full max-w-[260px]'
              preview
            />
            <Image
              src={require("../assets/images/sobre-jime/certificados/certif02.jpg")}
              alt="Certificado 02"
              imageClassName='w-full max-w-[260px]'
              preview
            />
            <Image
              src={require("../assets/images/sobre-jime/certificados/certif03.jpg")}
              alt="Certificado 03"
              imageClassName='w-full max-w-[260px]'
              preview
            />
            <Image
              src={require("../assets/images/sobre-jime/certificados/certif04.jpg")}
              alt="Certificado 04"
              imageClassName='w-full max-w-[260px]'
              preview
            />
            <Image
              src={require("../assets/images/sobre-jime/certificados/certif05.jpg")}
              alt="Certificado 05"
              imageClassName='w-full max-w-[260px]'
              preview
            />
          </div>
        </div>
      </div>

      <div className='py-16 bg-Magnolia'>
        <div className="xl:container mx-auto px-6">
          <div className='flex flex-wrap gap-10 items-center justify-center'>
            <div ref={(el) => elementsRef.current.push(el)} className='animate__animated relative flex items-center justify-center' data-animation={"animate__fadeInLeft"}>
              <Image src={require('../assets/images/home/video-img.jpg')} alt="video preview" className='flex justify-center items-center mx-auto' imageClassName='mx-auto max-w-[240px] sm:max-w-[320px] lg:max-w-[460px]' imageStyle={{ borderRadius: 25, width: "90%" }} />
              <div className='absolute c-pointer active:scale-90 transition ease-in-out' onClick={() => setState({ isVideoVisible: true })}>
                <Image src={require('../assets/images/play-btn.png')} alt="icon play" imageClassName='mx-auto' imageStyle={{ width: "90%", maxWidth: 120 }} />
              </div>
            </div>
            <div className='flex-1 text-center md:text-left xs:min-w-[320px]' style={{ maxWidth: 420 }}>
              <Image src={require('../assets/images/logo-video.png')} alt="icon logo" imageClassName='mx-auto md:mx-none' imageStyle={{ width: "90%", maxWidth: 120 }} />
              <h1 ref={(el) => elementsRef.current.push(el)} className='animate__animated font-medium text-DeepKoamaru text-4xl mt-8' data-animation={"animate__fadeInRight"}>Entrena desde cualquier país <span className='font-golos-bold'>y únete a la comunidad.</span></h1>
              <p className='font-medium text-DeepKoamaru mt-8' style={{ fontSize: ".920em" }}>Únete a las miles de mamás que han experimentado los beneficios de mis programas.</p>
              <div className='flex justify-center md:justify-start'>
                <ButtonPrimary
                  text="Comienza tu prueba gratis"
                  withIcon
                  styleContainer={{ marginTop: "2em" }}
                  onClick={() => navigate("/#programas-de-entrenamiento")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SobreJime;