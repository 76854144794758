import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { useDebounce } from 'primereact/hooks';
import { dayjs, dayjs_custom } from '../../../utils/dayjs_custom';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { asignarWorkout } from '../../../api/admin/calendarios';
import { getCategorias } from '../../../api/admin/categorias';
import { getSubCategorias } from '../../../api/admin/subcategorias';
import { getWorkouts } from '../../../api/admin/workouts';
import { getStatusActive } from '../../../misc/getStatus';
import { optionsToast } from '../../../config/toast';
import { useCustomState } from '../../../hooks/useCustomState';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import paginate from '../../../utils/paginate';
import CalendarOverlay from '../../../components/Calendario';
import Pagination from '../../../components/Pagination';
const prefix = "/admin/calendarios";

const AsignarWorkout = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { programaSeleccionado } = useSelector(state => state.app.misc);
  const [nameWorkout, debouncedNameWorkout, setNameWorkout] = useDebounce('', 2000);
  const [state, setState] = useCustomState({
    loading: true,
    loading_workouts: true,
    sending: false,
    page: 1,
    estatus_seleccionado: "todos",
    categoria_seleccionado: null,
    subcategoria_seleccionado: null,
    workouts_seleccionados: [],
    listado_estatus: [],
    listado_categorias: [],
    listado_subcategorias: [],
    listado_workouts: [],
  });
  const data_paginate = state?.listado_workouts?.length > 0 ? paginate(state?.listado_workouts, state.page, 10) : null;

  const listado_subcategorias = (state.listado_subcategorias?.length > 0 && state.categoria_seleccionado?.length > 0) ? state.listado_subcategorias?.map(subcategoria => {
    const filteredItems = subcategoria?.items?.filter(subcategoria_item => {
      return state.categoria_seleccionado.some(categoria => categoria.id === subcategoria_item.categoryID);
    });

    return {
      ...subcategoria,
      items: filteredItems
    };
  }) : state.listado_subcategorias;

  useEffect(() => {
    loadData(programaSeleccionado);
    // eslint-disable-next-line
  }, [state.programaSeleccionado]);

  useEffect(() => {
    reloadWorkouts({
      estatus_seleccionado: state.estatus_seleccionado === "todos" ? null : state.estatus_seleccionado,
      programa_seleccionado: [programaSeleccionado?.id],
      categoria_seleccionado: state.categoria_seleccionado,
      subcategoria_seleccionado: state.subcategoria_seleccionado,
      nameWorkout: debouncedNameWorkout,
    });
    // eslint-disable-next-line
  }, [state.estatus_seleccionado, state.programaSeleccionado?.id, state.categoria_seleccionado, state.subcategoria_seleccionado, debouncedNameWorkout]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const reloadWorkouts = useCallback(async (filters) => {
    setState({ page: 1, loading_workouts: true });
    const _filters = {
      program: filters.programa_seleccionado,
      category: filters?.categoria_seleccionado?.map(item => item?.id),
      subCategory: filters?.subcategoria_seleccionado?.map(item => item?.id),
      status: filters?.estatus_seleccionado,
      name: filters?.nameWorkout,
    }
    const workouts = await getWorkouts(null, _filters);
    setState({
      listado_workouts: workouts?.data,
      loading_workouts: false,
    });
  }, [setState]);

  const loadData = useCallback(async (programaSeleccionado) => {
    setState({ loading: true });
    const categorias = await getCategorias({ grouped: "program", type: "workouts", programID: programaSeleccionado?.id });
    const subcategorias = await getSubCategorias({ grouped: "program", programID: programaSeleccionado?.id });

    const status = await getStatusActive();

    setState({
      listado_estatus: [...status, { name: "Todos", value: "todos" }],
      listado_categorias: categorias?.data,
      listado_subcategorias: subcategorias?.data,
      loading: false,
    });
    // eslint-disable-next-line
  }, []);

  const onCreate = useCallback(async () => {
    if (state.workouts_seleccionados?.length <= 0) {
      return message(null, 'Selecciona mínimo un workout para continuar.');
    }

    const isWeek = searchParams.get("isWeek");
    if (!isWeek && state.workouts_seleccionados?.filter(item => !item?.date)?.length > 0) {
      return message(null, 'Selecciona la fecha en el workout para continuar.');
    }

    const calendarDayID = searchParams.get("calendarDayID");
    const calendarWeekID = searchParams.get("calendarWeekID");
    const dayWeek = searchParams.get("dayWeek");

    try {
      setState({ sending: true });

      const response = await asignarWorkout({
        calendarDayID: calendarDayID,
        calendarWeekID: calendarWeekID,
        workoutID: state.workouts_seleccionados?.map(item => item?.id),
        startDate: state.workouts_seleccionados?.map(item => item?.date),
        dayWeek: state.workouts_seleccionados?.map(_ => dayWeek),
        isWeek: isWeek,
      });

      setState({
        sending: false,
        workouts_seleccionados: []
      });

      if (response?.status === true) {
        message('Workout asignado', '¡Los workouts han sido asignados exitosamente!');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al asignar los workouts. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al asignar los workouts. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [searchParams, state.workouts_seleccionados, setState]);

  const onSelectWorkout = useCallback((item) => {
    let _workouts_seleccionados = state?.workouts_seleccionados || [];
    const isSelected = state.workouts_seleccionados?.findIndex(elem => elem?.id === item?.id);
    if (isSelected !== -1) {
      _workouts_seleccionados = _workouts_seleccionados.filter(elem => elem?.id !== item?.id);
    } else {
      _workouts_seleccionados?.push({ id: item?.id });
    }
    setState({ workouts_seleccionados: _workouts_seleccionados });
  }, [state.workouts_seleccionados, setState]);

  const onChangeDateWorkout = useCallback((item, date) => {
    const _date = dayjs(date.value).format("YYYY-MM-DD");
    let _workouts_seleccionados = state?.workouts_seleccionados || [];
    const isSelected = state.workouts_seleccionados?.findIndex(elem => elem?.id === item?.id);
    if (isSelected !== -1) {
      _workouts_seleccionados = _workouts_seleccionados.map(elem => {
        return elem?.id === item?.id ? { ...elem, date: _date } : elem;
      });
    } else {
      _workouts_seleccionados?.push({ id: item?.id, date: _date });
    }
    setState({ workouts_seleccionados: _workouts_seleccionados });
  }, [state.workouts_seleccionados, setState]);

  const groupedItemTemplate = useCallback((option) => {
    return (
      <div className="flex items-center pl-0">
        <div className='capitalize'>{option.label}</div>
      </div>
    );
  }, []);

  const itemTemplateSelectGroup = useCallback((option) => {
    return (
      <div className='capitalize'>{option.name}</div>
    );
  }, []);

  const itemTemplateSelectGroup2 = useCallback((option) => {
    return (
      <div className='capitalize'>{option.name} <sup className='text-gray-600'>({option.Category.name})</sup></div>
    );
  }, []);

  return (
    <>
      <SEO
        title="Asignar workout"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Asignar workout</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg'>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col xs:flex-row flex-wrap gap-3 xs:items-center'>
                  <p className='min-w-[80px] text-neutral-700'>Mostrar</p>
                  <Dropdown
                    value={state?.estatus_seleccionado}
                    onChange={(event) => setState({ estatus_seleccionado: event.target.value })}
                    options={state.listado_estatus}
                    optionLabel="name"
                    placeholder="Estado"
                    emptyMessage="No se encontraron resultados"
                    className="flex-1 bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    disabled={state.sending || state.loading_workouts || state.loading}
                  />
                </div>
                <div className='flex flex-col xs:flex-row flex-wrap gap-3 xs:items-center'>
                  <p className='min-w-[80px] text-neutral-700'>Filtrar por:</p>
                  <MultiSelect
                    display="chip"
                    value={state?.categoria_seleccionado}
                    onChange={(event) => setState({ categoria_seleccionado: event.target.value })}
                    options={state.listado_categorias}
                    optionLabel="name"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionGroupTemplate={groupedItemTemplate}
                    itemTemplate={itemTemplateSelectGroup}
                    placeholder="Categorías"
                    className="flex-1 bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    panelClassName="custom-dropdown-panel"
                    disabled={state.sending || state.loading_workouts || state.loading}
                  />
                </div>
                <div className='flex flex-col xs:flex-row flex-wrap gap-3 xs:items-center'>
                  <p className='min-w-[80px] text-neutral-700'>Filtrar por:</p>
                  <MultiSelect
                    display="chip"
                    value={state.subcategoria_seleccionado}
                    onChange={(event) => setState({ subcategoria_seleccionado: event.target.value })}
                    options={listado_subcategorias}
                    optionLabel="name"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionGroupTemplate={groupedItemTemplate}
                    itemTemplate={itemTemplateSelectGroup2}
                    placeholder="Subcategorías"
                    className="flex-1 bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    panelClassName="custom-dropdown-panel"
                    disabled={state.sending || state.loading_workouts || state.loading}
                  />
                </div>
                <span className="p-input-icon-right">
                  <i className="pi pi-search z-10" />
                  <InputText
                    type="search"
                    placeholder='Buscar por nombre'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={nameWorkout}
                    onChange={(event) => setNameWorkout(event.target.value)}
                    disabled={state.sending || state.loading_workouts || state.loading}
                  />
                </span>
                <div className='mt-4'>
                  <p className='mb-7'>Resultados:</p>
                  {state.loading_workouts ? (
                    <Loading onlyIcon />
                  ) : (
                    <>
                      {data_paginate?.items?.length > 0 ? (
                        <>
                          <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4'>
                            {data_paginate?.items?.map((item, index) => {
                              const workouts_seleccionado = state?.workouts_seleccionados?.findIndex(elem => elem?.id === item?.id);
                              const _workouts_seleccionado = workouts_seleccionado !== -1 && state?.workouts_seleccionados?.[workouts_seleccionado];

                              return (
                                <div key={`workout-id-${item?.id || index}`} className="bg-white rounded-lg">
                                  <div className={`bg-Magnolia ${workouts_seleccionado !== -1 ? "border-t-[3px] border-Crayola" : "border-t-[3px] border-Magnolia"} px-4 pt-4 rounded-t-lg transition-all`}>
                                    <Image
                                      src={item?.image}
                                      imageClassName="bg-black flex items-center justify-center h-24 object-cover mx-auto w-full rounded-t-lg"
                                      onError={(event) => {
                                        event.target.onerror = null; // previene un bucle infinito
                                        event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                                      }}
                                    />
                                  </div>
                                  <div className='flex flex-col bg-Crayola py-1 rounded-b-lg'>
                                    <div className='flex flex-row items-center'>
                                      <p className='flex-1 font-golos-medium text-white text-left text-sm py-1.5 px-2'>{item?.title}</p>
                                      <div className='flex items-center py-1.5 px-2 cursor-pointer' onClick={() => onSelectWorkout(item)}>
                                        <i className={`pi ${workouts_seleccionado !== -1 ? "pi-times-circle" : "pi-plus-circle"} text-white`} style={{ fontSize: '0.986rem' }} />
                                      </div>
                                    </div>
                                    {!searchParams.get("isWeek") && (
                                      <CalendarOverlay
                                        labelButton={(_workouts_seleccionado?.date && dayjs_custom(_workouts_seleccionado?.date).format("YYYY-MM-DD")) || "Seleccionar fecha"}
                                        classNameContainer="flex justify-center"
                                        classNameButton={"border-0 px-2 hover:!bg-transparent !text-xs"}
                                        onChangeDate={(event) => onChangeDateWorkout(item, event)}
                                        notDisabledDays={[parseInt(searchParams.get("dayWeek"))]}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {data_paginate?.totalPages > 1 && (
                            <Pagination
                              className="mt-8"
                              pageCurrent={parseInt(state.page)}
                              totalPages={data_paginate.totalPages}
                              onClickPage={(_page) => setState({ page: _page })}
                              onNextPage={() => state.page < data_paginate.totalPages && setState({ page: parseInt(state.page) + 1 })}
                              onPrevPage={() => state.page > 1 && setState({ page: parseInt(state.page) - 1 })}
                              breakpoints={{
                                visiblePageCount: {
                                  0: 3,
                                  460: 5,
                                  760: 7,
                                  1200: 9,
                                  1460: 11,
                                }
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <div className="flex flex-1 items-center justify-center w-full">
                          <p className="font-golos-medium text-center text-Crayola">No se encontraron resultados.</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text="REGRESAR"
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                />
                {state.workouts_seleccionados?.length > 0 && (
                  <ButtonCustom
                    text="ASIGNAR"
                    classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                    onClick={onCreate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default AsignarWorkout;