export default function getDescriptionCalendar(nivel, item) {
  const data = {
    description: null,
    instructions: null,
  };
  nivel = nivel?.toLowerCase();

  switch (nivel) {
    case "principiante":
      data.instructions = item?.instructions_principiante;
      break;
    case "intermedio":
      data.instructions = item?.instructions_intermedio;
      break;
    case "avanzado":
      data.instructions = item?.instructions_avanzado;
      break;

    default:
      data.instructions = item?.instructions_principiante;
      break;
  }

  return data;
}