/**
 * Obtiene el valor de una propiedad anidada en un objeto dado su nombre en formato de ruta de acceso (p. ej., "prop1.prop2").
 *
 * @param {Object} object - El objeto del que se quiere obtener la propiedad.
 * @param {string} path - El nombre de la propiedad en formato de ruta de acceso.
 * @param {*} [defaultValue=null] - El valor a devolver si la propiedad no existe en el objeto.
 * @return {*} El valor de la propiedad o el valor por defecto si la propiedad no existe.
 */
function get(object, path, defaultValue = null) {
  const pathArray = path.split('.');
  let value = object;

  for (let i = 0; i < pathArray.length; i++) {
    const key = pathArray[i];

    if (value.hasOwnProperty(key)) {
      value = value[key];
    } else {
      return defaultValue;
    }
  }

  return value;
}

/**
 * Filtra un arreglo de objetos por un término de búsqueda en campos específicos y con filtros adicionales.
 *
 * @param {Array<Object>} items - El arreglo de objetos a filtrar.
 * @param {Object} options - Las opciones de filtrado.
 * @param {string} options.searchText - El término de búsqueda.
 * @param {Array<string>} options.fieldsToSearch - Los campos en los que buscar el término de búsqueda. Puede ser una ruta de acceso a un campo anidado, usando puntos para separar los nombres de los campos (por ejemplo, 'User.fullName' o si es array 'Users[].name').
 * @param {Array<Object>} [options.additionalFilters=[]] - Los filtros adicionales para aplicar al arreglo de objetos.
 * @return {Array<Object>} El arreglo de objetos filtrado.
 * 
 * @example
 * const state = {
 *   workouts: [...], // Array de elementos a filtrar
 *   filterText: 'texto de búsqueda',
 * };
 *
 * const filteredItems = onSearchFields(state.workouts, {
 *   searchText: state.filterText,
 *   fieldsToSearch: ['title', 'Program.name', 'categorias.Category[].name', 'sub_categorias.SubCategory[].name'],
 *   additionalFilters: [
 *     { field: 'status', value: 'active' },
 *   ],
 * });
 * console.log(filteredItems);
 */
function onSearchFields(items, { searchText, fieldsToSearch, additionalFilters = [] }) {
  if (!searchText || searchText?.length === 0) return items;
  const filterText = searchText?.trim()?.replace(/\s+/g, ' ')?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.toLowerCase();
  if (!filterText && additionalFilters.length === 0) {
    return items;
  }

  return items.filter((item) => {
    return fieldsToSearch.some((field) => {
      let value = item;
      let matchingValues = []; // Variable para almacenar los valores que coinciden en un array
      const fieldParts = field.split('.');
      for (let i = 0; i < fieldParts.length; i++) {
        const fieldName = fieldParts[i];
        if (fieldName.endsWith('[]')) {
          const arrayFieldName = fieldName.slice(0, -2); // Eliminar el marcador '[]'
          const lastFieldName = fieldParts[fieldParts.length - 1]; // Obtener el último nombre de campo en la ruta
          if (Array.isArray(value)) {
            value.forEach((v) => {
              const nestedValue = get(v, arrayFieldName); // Usar la función `get` para acceder a las propiedades anidadas
              if (Array.isArray(nestedValue)) {
                const matchingElements = nestedValue.filter((element) => {
                  const lastFieldValue = get(element, lastFieldName); // Obtener el valor del último campo
                  const normalizedValue = lastFieldValue?.toString().replace(/\s+/g, ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                  return normalizedValue?.includes(filterText);
                });
                matchingElements.forEach((matchingElement) => {
                  const matchingFieldValue = get(matchingElement, lastFieldName); // Obtener el valor del campo coincidente
                  matchingValues.push(matchingFieldValue);
                });
              } else {
                const lastFieldValue = get(nestedValue, lastFieldName); // Obtener el valor del último campo
                const normalizedValue = lastFieldValue?.toString().replace(/\s+/g, ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                if (normalizedValue?.includes(filterText)) {
                  matchingValues.push(lastFieldValue); // Agregar el valor coincidente al array
                }
              }
            });
          } else {
            return false; // El campo está marcado como un array, pero el valor no es un array
          }
        } else if (typeof value === 'object' && value !== null) {
          value = value[fieldName];
        } else {
          return false;
        }
      }
      if (Array.isArray(matchingValues) && matchingValues.length > 0) {
        return true; // Si hay valores coincidentes en matchingValues, devolver verdadero
      } else if (value) {
        const normalizedValue = value?.toString().replace(/\s+/g, ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        return normalizedValue?.includes(filterText) || fieldsToSearch.some((searchField) => {
          const [objectField] = searchField.split('.').slice(-1); // Obtener el último campo en la ruta
          const objectValue = get(item, objectField);
          const normalizedObjectValue = objectValue?.toString().replace(/\s+/g, ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
          return normalizedObjectValue?.includes(filterText);
        });
      }
      return false;
    }) && additionalFilters.every((filter) => {
      const value = get(item, filter.field);
      return value === filter.value;
    });
  });
}

export default onSearchFields;