import React, { memo, useRef } from "react";
import { Image } from "primereact/image";
import classNames from "classnames";
import { useCustomState } from "../../hooks/useCustomState";

import { useSpring, animated } from "@react-spring/web";

const RenderPregunta = memo(({ item }) => {
  const [state, setState] = useCustomState({
    isVisibleContent: false,
  });

  const ref = useRef(null);

  function handleDescriptionToggle() {
    setState({ isVisibleContent: !state.isVisibleContent });
  }

  const spring = useSpring({
    to: {
      height: state.isVisibleContent ? ref.current.scrollHeight : 0,
    },
    config: { duration: 200 },
  });

  const descriptionClasses = classNames({
    "text-DarkJungleGreen": true,
    "text-sm": true,
    "mt-2": state.isVisibleContent,
    "overflow-hidden": !state.isVisibleContent,
    "animate__animated": true,
    "animate__fadeIn": state.isVisibleContent,
    "animate__faster": true
  });

  return (
    <div className="flex items-start gap-4">
      <div
        className="bg-white pt-3 pb-4 px-4 rounded-lg flex-1 overflow-hidden"
        style={{ height: "auto" }}
      >
        <p className="font-golos-semibold text-DarkJungleGreen text-md">
          {item?.title}
        </p>
        <animated.div className={descriptionClasses} style={spring}>
          <div className="whitespace-pre-line" ref={ref}>
            {item?.description}
          </div>
        </animated.div>
      </div>
      <div
        className="c-pointer active:scale-90 transition ease-in-out"
        onClick={handleDescriptionToggle}
      >
        <Image
          alt={
            state.isVisibleContent
              ? "Cerrar descripción"
              : "Abrir descripción"
          }
          src={require(`../../assets/images/${state.isVisibleContent ? "menos-btn" : "mas-btn"
            }.png`)}
          imageStyle={{ width: 36 }}
          imageClassName="select-none pointer-events-none"
        />
      </div>
    </div>
  );
});


const PreguntasFrecuentes = ({ data }) => (
  <div className='grid gap-4'>
    {data?.length > 0 && data?.map((item, index) => (
      <RenderPregunta key={`pregunta-frecuente-key-${item?.id || index}`} item={item} />
    ))}
  </div>
);

export default PreguntasFrecuentes;