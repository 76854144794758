import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import GlobalContext from '../../contexts/GlobalContext';
import { useCustomState } from '../../hooks/useCustomState';
import { ButtonPrimary } from '../Button';
import Rating from '../Rating';
import { createReview } from '../../api/client/review';
import { optionsToast } from '../../config/toast';
import parseErrorMessage from '../../utils/parseErrorMessage';

function CalificarModal({ stylesContainer }) {
  const modalType = "calificar";
  const { user } = useSelector(state => state.app.auth);
  const { showModal, typeModal, setModal, propsModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    sending: false,
    rating: 0,
    review: "",
  });

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const closeModal = useCallback(() => {
    setModal(modalType);
  }, [setModal, modalType]);

  const onSubmit = useCallback(async (event) => {
    event?.preventDefault();

    if (state.rating === 0) return message(null, 'Se requiere seleccionar mínimo 1 estrella.');

    try {
      setState({ sending: true });
      const response = await createReview({
        userID: user?.userID,
        workoutID: propsModal?.workoutID,
        coreID: propsModal?.coreID,
        galeriaVideoID: propsModal?.galeriaVideoID,
        score: state.rating,
        review: state.review,
      });
      setState({ sending: false });
      if (response?.status === true) {
        closeModal();
        message('Calificación enviada', 'La calificación se ha enviado exitosamente');
        if (propsModal?.onSuccess) propsModal?.onSuccess();
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al enviar la calificación. Por favor, inténtalo de nuevo más tarde.');
        if (propsModal?.onError) propsModal?.onError();
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al enviar la calificación. Por favor, inténtalo de nuevo más tarde.');
      if (propsModal?.onError) propsModal?.onError();
    }
  }, [propsModal, state, user, setState, closeModal]);

  return (
    <Dialog
      visible={showModal && typeModal === modalType}
      onHide={closeModal}
      dismissableMask={true}
      draggable={false}
      headerClassName="!rounded-t-xl"
      contentClassName="!rounded-b-xl"
      headerStyle={{ paddingBottom: 0 }}
      style={{ width: '35vw', margin: "auto" }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
    >
      <div {...stylesContainer}>
        <h2 className='text-center text-5xl text-Crayola animate__animated animate__slideInDown'>¡Califícanos!</h2>
        <p className='text-center text-sm text-DeepKoamaru/80 mt-3 animate__animated animate__slideInRight'><span className='font-golos-semibold'>¿Te gustó esta clase?</span> Queremos escucharte.</p>
        <div className='flex items-center justify-center mt-6 animate__animated animate__slideInLeft'>
          <Rating
            selectedStars={state.rating}
            onClick={(rate) => setState({ rating: rate })}
          />
        </div>
        <form className="form-login space-y-4 mt-10 animate__animated animate__slideInUp" onSubmit={onSubmit}>
          <InputTextarea
            value={state?.review}
            onChange={(event) => setState({ review: event.target.value })}
            className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
            placeholder="Escribe tu opinión (Es opcional)"
            rows={7}
          />
          <div>
            <ButtonPrimary
              text="Enviar calificación"
              withIcon
              classNameContainer="flex justify-center"
              classNameButton="px-6"
              type="submit"
              loading={state.sending}
              onClick={onSubmit}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
}

export default CalificarModal;