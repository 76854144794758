import QueryString from "qs";
import axios from "../";
const prefix = "/perfil";

export const getPerfil = async (params) => {
  try {
    const _params = QueryString.stringify({
      id: params?.id,
      email: params?.email,
      loginDevice: params?.loginDevice || "web",
    });
    const response = await axios.get(`${prefix}/?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePerfil = async (id, params) => {
  try {
    const _params = QueryString.stringify(params);
    const response = await axios.put(`${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};