export function shuffleAndStore(array, key, useDate = false) {
  let storedData = localStorage.getItem(key);
  let originalData = storedData ? JSON.parse(storedData) : null;

  if (useDate) {
    const currentDate = new Date();
    const today = currentDate.toDateString();

    // Si no hay arreglo almacenado para el día actual, mézclalo y guárdalo
    if (!storedData || storedData === null || storedData === undefined) {
      const shuffledArray = shuffleArray(array);
      localStorage.setItem(key, JSON.stringify({ date: today, data: shuffledArray }));
      return shuffledArray;
    }

    // Si ya existe un arreglo almacenado para el día actual, úsalo
    const parsedData = JSON.parse(storedData);
    if (parsedData.date === today) {
      return parsedData.data;
    }

    // Si la fecha ha cambiado, mézclalo nuevamente y guárdalo
    const shuffledArray = shuffleArray(array);
    localStorage.setItem(key, JSON.stringify({ date: today, data: shuffledArray }));
    return shuffledArray;
  } else {
    let shuffledArray;

    // Intenta mezclar el arreglo hasta que sea diferente al original (máximo de 100 intentos)
    let attempts = 0;

    while (attempts < 100) {
      shuffledArray = shuffleArray(array);

      if (!arraysAreEqual(shuffledArray, originalData)) {
        if (shuffledArray[0] !== array[0]) {
          break;
        }
      }

      attempts++;
    }

    // Almacena el nuevo arreglo mezclado en localStorage
    localStorage.setItem(key, JSON.stringify(shuffledArray));

    return shuffledArray;
  }
}

export function arraysAreEqual(arr1, arr2) {
  if (arr1?.length !== arr2?.length) return false;

  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export default function shuffleArray(array) {
  let shuffledArray = [...array] || [];
  let attempts = 0;

  while (attempts < 100) {
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    // Verifica si el arreglo mezclado es diferente del original
    if (!arraysAreEqual(shuffledArray, array)) {
      return shuffledArray;
    }

    attempts++;
  }

  // Si después de 100 intentos no se obtiene un arreglo diferente, simplemente devuelve el arreglo original
  return array;
}