import React, { memo, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { useCustomState } from '../../../hooks/useCustomState';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import NombreWorkout from './components/NombreWorkout';
import { createCalendary, createCalendaryDetail, getCalendarios, updateCalendaryDetail } from '../../../api/admin/calendarios';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { optionsToast } from '../../../config/toast';
import { isEmpty } from '../../../utils';
import { Dropdown } from 'primereact/dropdown';

const CalendarioEmbarazo = ({ programID, startDate }) => {
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    error: null,
    nivel_seleccionado: null,
    calendarDetails: null,
    descripcion: "",
    instructions_principiante: "",
    instructions_intermedio: "",
    instructions_avanzado: "",
    listado_workouts: undefined,
    startDateDayWorkout: [],
  });

  useEffect(() => {
    loadData({ programID, startDate, startDateDayWorkout: state.startDateDayWorkout });
    // eslint-disable-next-line
  }, [programID, startDate, state.startDateDayWorkout]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const loadData = useCallback(async ({ programID, startDate, startDateDayWorkout }) => {
    try {
      if (state?.loading !== true) setState({ loading: true });
      const _getListadoCalendarios = await getCalendarios({ populate: ["workouts"], programID, startDate: (startDate && dayjs(startDate).format("YYYY-MM-DD")), startDateDayWorkout, group: "day" });

      const calendarDetail = _getListadoCalendarios?.data?.CalendarDetail;
      setState({
        listado_workouts: _getListadoCalendarios?.data || null,
        calendarDetails: calendarDetail,
        descripcion: calendarDetail?.description || "",
        instructions_principiante: calendarDetail?.instructions_principiante || "",
        instructions_intermedio: calendarDetail?.instructions_intermedio,
        instructions_avanzado: calendarDetail?.instructions_avanzado,
        loading: false,
      });
    } catch (error) {
      setState({
        listado_workouts: undefined,
        descripcion: "",
        instructions_principiante: "",
        instructions_intermedio: "",
        instructions_avanzado: "",
        loading: false,
        error: parseErrorMessage(error) || null
      });
    }

    // eslint-disable-next-line
  }, [state?.loading]);

  const onChangeDate = useCallback(async (event, day) => {
    let startDateDayWorkout = (state.startDateDayWorkout && [...state.startDateDayWorkout]) || [];
    const isExists = startDateDayWorkout?.findIndex(item => item?.day === day);

    if (isExists !== -1) {
      if (event.value) {
        if (startDateDayWorkout?.[isExists]) startDateDayWorkout[isExists].date = dayjs(event.value).format("YYYY-MM-DD");
      } else {
        startDateDayWorkout = startDateDayWorkout.filter(item => item?.day !== day);
      }
    } else {
      if (event.value) {
        startDateDayWorkout.push({
          day: day,
          date: dayjs(event.value).format("YYYY-MM-DD")
        });
      } else {
        startDateDayWorkout = startDateDayWorkout.filter(item => item?.day !== day);
      }
    }

    setState({ startDateDayWorkout });

    // eslint-disable-next-line
  }, [state.startDateDayWorkout]);

  const onCreateCalendar = useCallback(async () => {
    const _startDate = startDate || dayjs(new Date()).format("YYYY-MM-DD");

    try {
      setState({ sending: true });
      const response = await createCalendary({ programID, startDate: _startDate });

      setState({ sending: false });

      if (response?.status !== true) {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear el calendario. Por favor, inténtalo de nuevo más tarde.');
      } else {
        loadData({ programID, startDate: _startDate });
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear el calendario. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, [programID, startDate, setState, loadData]);

  const onUpdateCalendar = useCallback(async () => {
    // if (isEmpty(state.descripcion)) return message(null, 'La descripción no puede estar vacía.');

    try {
      setState({ sending: true });
      const _params = {
        description: state.descripcion,
        instructions_principiante: state.instructions_principiante,
        instructions_intermedio: state.instructions_intermedio,
        instructions_avanzado: state.instructions_avanzado,
      };
      const response = isEmpty(state.calendarDetails?.id) ? await createCalendaryDetail({ ..._params, programID }) : await updateCalendaryDetail(state.calendarDetails?.id, _params);

      setState({ sending: false });
      if (response?.status === true) {
        if (isEmpty(state.calendarDetails?.id)) {
          try {
            await loadData({ programID, startDate, startDateDayWorkout: state.startDateDayWorkout });
          } catch (error) { }
        }
        message('Calendario actualizado', '¡El calendario se ha actualizado exitosamente!');
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el calendario. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el calendario. Por favor, inténtalo de nuevo más tarde.');
    }
    // eslint-disable-next-line
  }, [state, programID, startDate, state.startDateDayWorkout, setState, loadData]);

  const onChangeLevel = useCallback((event) => setState({ nivel_seleccionado: event.target.value }), [setState]);

  if (state?.error) {
    return (<p className='font-golos-medium text-center text-red-600'>{state?.error}</p>);
  } else if (startDate && state?.listado_workouts === null) {
    return (
      <div className='flex flex-col justify-center text-center gap-6'>
        <div className='font-golos-medium text-Crayola'>
          <p>Lamentablemente, no se ha encontrado ningún calendario disponible para la fecha seleccionada.</p>
          <p>Sin embargo, puedes generar un calendario específico para esta fecha haciendo clic en el siguiente botón.</p>
        </div>
        <ButtonCustom
          text="Crear calendario"
          classNameButton="inline-flex whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
          onClick={onCreateCalendar}
          loading={state?.sending}
        />
      </div>
    );
  } else if (state?.listado_workouts === null) {
    return (
      <div className='flex flex-col justify-center text-center gap-6'>
        <div className='font-golos-medium text-Crayola'>
          <p>Lamentablemente, no se ha encontrado ningún calendario disponible.</p>
          <p>Sin embargo, puedes generar un calendario específico para esta fecha haciendo clic en el siguiente botón.</p>
        </div>
        <ButtonCustom
          text="Crear calendario"
          classNameButton="inline-flex whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
          onClick={onCreateCalendar}
          loading={state?.sending}
        />
      </div>
    );
  }

  return (
    state.loading ? (
      <Loading containerClassName="h-full" />
    ) : (
      <div>
        <div className='flex flex-col gap-3'>
          <div className='flex flex-row gap-4'>
            <textarea
              rows={2}
              placeholder='Agregar descripción'
              className="min-h-[60px] bg-white border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 flex-[1] transition-all"
              value={state?.descripcion}
              onChange={(event) => setState({ descripcion: event.target.value })}
            />
            <div className='flex-1 flex flex-col gap-2'>
              <Dropdown
                value={state?.nivel_seleccionado}
                onChange={onChangeLevel}
                options={["principiante", "intermedio", "avanzado"]}
                placeholder="Seleccionar nivel"
                emptyMessage="No se encontraron resultados"
                className="custom-dropdown padding-reset border-0 bg-white rounded-md text-sm py-2 capitalize transition-all"
              />
              {!isEmpty(state.nivel_seleccionado) && (
                <textarea
                  data-nivel={`instructions_${state.nivel_seleccionado}`}
                  rows={2}
                  placeholder='Agregar instrucciones'
                  className="min-h-[60px] bg-white border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 transition-all"
                  value={state?.[`instructions_${state.nivel_seleccionado}`] || ""}
                  onChange={(event) => setState({ [`instructions_${state.nivel_seleccionado}`]: event.target.value })}
                >{state?.[`instructions_${state.nivel_seleccionado}`]}</textarea>
              )}
            </div>
          </div>
          <ButtonCustom
            text="Actualizar"
            classNameContainer="flex justify-end"
            classNameButton="inline-flex whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
            onClick={onUpdateCalendar}
          />
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5 mt-6'>
          {[1, 2, 3, 4, 5].map(id => (
            <NombreWorkout
              key={`grid-day-key-${id}`}
              day={id}
              data={state?.listado_workouts?.CalendarDaysWorkouts?.[id]}
              startDate={startDate}
              startDateDayWorkout={state.startDateDayWorkout}
              calendarDayID={state?.listado_workouts?.id}
              onChangeDate={onChangeDate}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default memo(CalendarioEmbarazo, (prev, next) => (
  prev?.programID === next?.programID &&
  prev?.startDate === next?.startDate
));