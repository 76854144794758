export const optionsToast = () => ({
  // summary: 'Titulo',
  // detail: 'Descripción',
  // content: 'Contenido del mensaje, "summary & detail" seran ignorados',
  severity: 'info',
  icon: <></>,
  life: 8000,
  contentStyle: {
    border: 0,
    fontSize: "0.926rem",
    paddingLeft: 0,
    paddingRight: 10,
    paddingTop: 12,
    paddingBottom: 12,
  },
  style: {
    position: "relative",
    backgroundColor: "#e569ba",
    borderColor: "#b03c88",
    color: "#fff",
    zIndex: 999999,
  }
});