import QueryString from "qs";
import axios from "../";
const prefix = "/admin/posts";

export const getPosts = async (params) => {
  try {
    const _params = QueryString.stringify({
      ...params,
    });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createPost = async (params) => {
  try {
    const response = await axios.post(`${prefix}/nueva-entrada`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePost = async (id, params) => {
  try {
    const response = await axios.put(`${prefix}/${id}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePost = async (id) => {
  try {
    const response = await axios.delete(`${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};