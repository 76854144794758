import React, { useMemo } from 'react';
import { Dropdown } from 'primereact/dropdown';
import CountryList from '@gunnaway/country-list-with-dial-code-and-flag';

const TelefonoCodes = ({ theme, selectedCode, onChangeCode, value, onChange }) => {
  const countryList = useMemo(() => {
    const _countryList = CountryList.getAll();

    return _countryList.map(country => {
      return {
        label: `${country.data.flag} ${country.data.name} (${country.data.dial_code})`,
        value: country.data.dial_code
      }
    }).sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  }, []);

  const _theme = useMemo(() => {
    const background = theme === "white" ? "bg-white" : "bg-Magnolia";

    return { background };
  }, [theme]);

  return (
    <div className="flex gap-3 items-stretch">
      <Dropdown
        filter={true}
        value={selectedCode}
        onChange={onChangeCode}
        options={countryList}
        optionLabel="label"
        placeholder="..."
        className={`${_theme.background} border-0 min-w-[120px]`}
      />
      <input
        name="telefono"
        type="number"
        placeholder='Teléfono'
        className={`flex-1 ${_theme.background} border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-lg text-sm text-gray-900 w-full transition-all [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default TelefonoCodes;