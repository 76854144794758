import QueryString from "qs";
import axios from "../";
const prefix = "/programas";

export const getProgramas = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBannerPrograma = async (params) => {
  try {
    const _params = QueryString.stringify({ programID: params?.programID }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}/banner?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};