export const getStatusActive = async () => {
  return [
    {
      name: "Activo",
      value: "active"
    },
    {
      name: "Inactivo",
      value: "inactive"
    }
  ];
}