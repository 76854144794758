import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from "primereact/slider";
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import VideoPlayer from '../../../components/Modal/VideoPlayer';
import Loading from '../../../components/Loading';
import VideosRecomendados from '../../../components/VideosRecomendados';
import { getProgramas } from '../../../api/admin/programas';
import { getCategorias } from '../../../api/admin/categorias';
import { getStatusActive } from '../../../misc/getStatus';
import { getSubCategorias } from '../../../api/admin/subcategorias';
import { getEquipoSugerido } from '../../../api/admin/equipo_sugerido';
import { getCores, updateCore } from '../../../api/admin/cores';
import { optionsToast } from '../../../config/toast';
import { useCustomState } from '../../../hooks/useCustomState';
import { isEmpty } from '../../../utils';
import getDataVimeoApi, { getVimeoVideoId } from '../../../utils/getDataVimeoApi';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { convertirASegundos, obtenerDuracionFormateada } from '../../../utils/parseDuration';
import obtenerNombreImagen from '../../../utils/obtenerNombreImagen';
const prefix = "/admin/galeria-cores";

const EditarCore = ({ isEditar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    progress_upload: 0,
    isVideoVisible: false,
    duracion: {
      segundos: 0,
      minutos: 0,
    },
    url_video: "",
    id_video: null,
    pdf_complementarios_file: null,
    portada_file: null,
    portada_url: null,
    estatus_seleccionado: null,
    categoria_seleccionado: null,
    subcategoria_seleccionado: null,
    programa_seleccionado: null,
    equipo_seleccionado: null,
    video_recomendado: false,
    titulo_video: "",
    descripcion_video: "",
    listado_estatus: [],
    listado_categorias: [],
    listado_subcategorias: [],
    listado_programas: [],
    listado_equipo_sugerido: [],

    buscador_videos_recomendados: "",
    videos_sugeridos_seleccionados: [],
  });

  const listado_categorias = state.programa_seleccionado?.length > 0 ? state.listado_categorias.map(item => ({ ...item, items: item.items?.filter(subItem => state.programa_seleccionado?.some(item2 => item2?.id === subItem.programID)) }))?.filter(item => item?.items?.length > 0) : [];
  const categoria_seleccionado = state?.categoria_seleccionado?.filter(item => state.programa_seleccionado?.some(item2 => parseInt(item2?.id) === parseInt(item?.programID)));
  const listado_subcategorias = state.programa_seleccionado?.length > 0 ? state.listado_subcategorias.map(item => {
    const filteredItems = state.categoria_seleccionado?.length > 0 ? item.items?.filter(subItem => {
      const categoryMatches = categoria_seleccionado?.some(categoria => categoria?.id === subItem.categoryID);
      const programMatches = state.programa_seleccionado?.some(programa => programa?.id === subItem.programID);
      return categoryMatches && programMatches;
    }) : item.items;

    return {
      ...item,
      items: filteredItems
    };
  }).filter(item => item?.items?.length > 0) : [];
  const subcategoria_seleccionado = state?.subcategoria_seleccionado?.filter(item => state.programa_seleccionado?.some(item2 => parseInt(item2?.id) === parseInt(item?.programID)));

  const refPortadaFile = useRef(null),
    refPDFDocFile = useRef(null);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const loadPreviewVideo = useCallback(async (url_video) => {
    if (!isEmpty(url_video) || !isEmpty(state.url_video)) {
      try {
        const _url_video = url_video || state.url_video;
        const response = await getDataVimeoApi(_url_video);
        const id_video = response?.data?.video?.id || getVimeoVideoId(_url_video);
        if (response?.data && state.id_video !== id_video) {
          const data = response?.data;
          return setState({
            id_video: id_video,
            // titulo_video: state?.titulo_video || data?.title,
            // descripcion_video: state?.descripcion_video || data?.description,
            portada_url: data?.video?.thumbs?.base || data?.pictures?.base_link,
          });
        }
      } catch (error) {
        window.PrimeToast?.show({
          ...optionsToast(),
          detail: 'El video no ha sido encontrado',
        });
      }
    }

    setState({
      id_video: null,
      // titulo_video: "",
      // descripcion_video: "",
      portada_url: "",
    });
  }, [state.id_video, state.url_video, setState]);

  const onUpdate = useCallback(async () => {
    if (isEditar !== true) return;

    if (isEmpty(state.id_video)) {
      return message(null, 'El vídeo es incorrecto o inválido.');
    }

    if (isEmpty(state.titulo_video)) {
      return message(null, 'El título es obligatorio.');
    }

    // if (state.duracion?.segundos === 0 && state.duracion?.minutos === 0) {
    //   return message(null, 'La duración es obligatoria.');
    // }

    if (isEmpty(state.categoria_seleccionado)) {
      return message(null, 'Selecciona una categoría para continuar.');
    }

    // if (isEmpty(state.subcategoria_seleccionado)) {
    //   return message(null, 'Selecciona una subcategoría para continuar.');
    // }

    if (isEmpty(state.programa_seleccionado)) {
      return message(null, 'Selecciona un programa para continuar.');
    }

    if (state.video_recomendado === true && (state.videos_sugeridos_seleccionados || [])?.length === 0) {
      return message(null, 'Se requiere seleccionar mínimo un video recomendado para continuar.');
    }

    const formDataFields = {
      programID: state.programa_seleccionado?.map(item => item.id) || [],
      title: state.titulo_video,
      description: state.descripcion_video,
      image: ((state.portada_file instanceof FileList && state.portada_file?.length > 0) && [...state.portada_file]) || (state.portada_file?.length > 0 ? null : state.portada_url),
      idVideo: state.id_video,
      durationVideo: convertirASegundos(state.duracion),
      equipment: state.equipo_seleccionado?.map(item => item.id) || [],
      isVideoRecommended: state.video_recomendado === false ? "false" : "true",
      status: state.estatus_seleccionado,
      category: state.categoria_seleccionado?.map(item => item.id) || [],
      subCategory: state.subcategoria_seleccionado?.map(item => item.id) || [],
      videosRecommended: JSON.stringify(state.videos_sugeridos_seleccionados?.map(item => item && ({ id: item?.id, type: item?.type }))) || [],
      documentsPDF: (state.pdf_complementarios_file instanceof FileList && state.pdf_complementarios_file?.length > 0) && [...state.pdf_complementarios_file],
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["documentsPDF", "image"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending: true });

      const response = await updateCore(id, _formData, { onUploadProgress: (progress) => setState({ progress_upload: progress }) });

      setState({ sending: false });

      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Core actualizado', '¡El core se ha actualizado exitosamente! Serás redirigido a la página de core en breve...');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el core. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el core. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, isEditar, state, setState, navigate]);

  const onSelectVideoSugerido = useCallback((item) => {
    let _videos_sugeridos_seleccionados = state?.videos_sugeridos_seleccionados || [];
    const isSelectedIndex = state.videos_sugeridos_seleccionados?.findIndex(elem => (elem?.id === item?.id && elem?.type === item?.type));
    if (isSelectedIndex === -1) {
      _videos_sugeridos_seleccionados?.push(item);
    } else {
      _videos_sugeridos_seleccionados.splice(isSelectedIndex, 1);
    }
    setState({ videos_sugeridos_seleccionados: _videos_sugeridos_seleccionados });
  }, [state.videos_sugeridos_seleccionados, setState]);

  const groupedItemTemplate = useCallback((option) => {
    return (
      <div className="flex items-center pl-0">
        <div className='capitalize'>{option.label}</div>
      </div>
    );
  }, []);

  const itemTemplateSelectGroup = useCallback((option) => {
    return (
      <div className='capitalize'>{option.name}</div>
    );
  }, []);

  const itemTemplateSelectGroup2 = useCallback((option) => {
    return (
      <div className='capitalize'>{option.name} <sup className='text-gray-600'>({option.Category.name})</sup></div>
    );
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const core = await getCores(id);
        if (core?.status === true) {
          const programas = await getProgramas();
          const categorias = await getCategorias({ grouped: "program", type: "core" });
          const filterCategories = categorias?.data?.length > 0 && categorias?.data?.flatMap(item => item?.items?.map(item2 => item2?.id));
          const subcategorias = await getSubCategorias({ grouped: "program", categoryID: filterCategories });
          const equipo_sugerido = await getEquipoSugerido();

          const programas_filter = programas?.data?.filter(item => core?.data?.programas?.some(item2 => item?.id === item2?.programID));
          const categorias_filter = categorias?.data?.flatMap(item => item.items.filter(subItem => core?.data?.categorias?.some(item2 => subItem?.id === item2?.categoryID)));
          const subcategorias_filter = subcategorias?.data?.flatMap(item => item.items.filter(subItem => core?.data?.sub_categorias?.some(item2 => subItem?.id === item2?.subCategoryID)));
          const _videos_recomendados = core?.data?.RecommendedVideos?.length > 0 ? core?.data?.RecommendedVideos?.map(item => (item?.workout || item?.core || item?.galeriaVideo)) : null;

          const selectedCore = {
            url_video: getVimeoVideoId(core?.data?.idVideo, true),
            programa_seleccionado: programas_filter,
            categoria_seleccionado: categorias_filter,
            subcategoria_seleccionado: subcategorias_filter,
            videos_sugeridos_seleccionados: Array.isArray(_videos_recomendados) ? _videos_recomendados : [_videos_recomendados],
            equipo_seleccionado: core?.data?.equipment_details,
            estatus_seleccionado: core?.data?.status,
            portada_file: [{ name: obtenerNombreImagen(core?.data?.image, true) }],
            pdf_complementarios_file: Array.isArray(core?.data?.documentsPDF) ? core?.data?.documentsPDF : (core?.data?.documentsPDF ? [core?.data?.documentsPDF] : null),
            video_recomendado: core?.data?.isVideoRecommended === "false" ? false : true,
            titulo_video: core?.data?.title || "",
            descripcion_video: core?.data?.description || "",
            duracion: obtenerDuracionFormateada(core?.data?.durationVideo)
          }

          setState({
            listado_estatus: await getStatusActive(),
            listado_categorias: categorias?.data,
            listado_subcategorias: subcategorias?.data,
            listado_programas: programas?.data,
            listado_equipo_sugerido: equipo_sugerido?.data,
            ...selectedCore,
            loading: false,
          });
          setTimeout(_ => loadPreviewVideo(core?.data?.idVideo), 100);
        }
      })();
    }

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar core" : "Ver core"}
      />
      <VideoPlayer
        url={getVimeoVideoId(state.id_video, true)}
        visible={state.isVideoVisible}
        onHide={() => setState({ isVideoVisible: false })}
        propsVideo={{
          controls: true,
          className: "rounded-lg xs:absolute xs:top-0 xs:left-0",
        }}
        propsContainer={{
          className: "rounded-lg relative xs:min-h-[360px]",
          style: {
            height: "100%",
          }
        }}
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar core" : "Ver core"}</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg'>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <div className='flex flex-col gap-4'>
                  <input
                    type="url"
                    placeholder='Agregar liga'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.url_video}
                    onChange={(event) => setState({ url_video: event.target.value })}
                    onBlur={() => loadPreviewVideo()}
                    disabled={!isEditar}
                  />
                  <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.portada_file?.length > 0 ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                    <p className='truncate'>{(state.portada_file?.length > 0 && state.portada_file?.[0]?.name) || "Sube una portada"}</p>
                    <input ref={refPortadaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_file: event.target.files })} disabled={!isEditar} />
                    {isEditar && (<button className='underline' onClick={() => refPortadaFile?.current?.click()}>Examinar</button>)}
                  </div>
                  <div>
                    <p className='text-xs text-gray-500 mb-2 ml-2'>Seleccionar Programas</p>
                    <MultiSelect
                      display="chip"
                      value={state?.programa_seleccionado}
                      onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                      options={state.listado_programas}
                      optionLabel="name"
                      placeholder="Programas"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      disabled={!isEditar}
                    />
                  </div>
                  <div>
                    <p className='text-xs text-gray-500 mb-2 ml-2'>Seleccionar Estado</p>
                    <Dropdown
                      value={state?.estatus_seleccionado}
                      onChange={(event) => setState({ estatus_seleccionado: event.target.value })}
                      options={state.listado_estatus}
                      optionLabel="name"
                      placeholder="Estado"
                      emptyMessage="No se encontraron resultados"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      disabled={!isEditar}
                    />
                  </div>
                  <div onClick={() => state.programa_seleccionado?.length > 0 ? null : message(null, "Selecciona un programa para habilitar está opción.")}>
                    <p className='text-xs text-gray-500 mb-2 ml-2'>Seleccionar Categorías</p>
                    <MultiSelect
                      display="chip"
                      value={categoria_seleccionado}
                      onChange={(event) => setState({ categoria_seleccionado: event.target.value })}
                      options={listado_categorias}
                      optionLabel="name"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                      optionGroupTemplate={groupedItemTemplate}
                      itemTemplate={itemTemplateSelectGroup}
                      placeholder="Categorías"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      panelClassName="custom-dropdown-panel"
                      disabled={!isEditar || (listado_categorias?.length <= 0 && state.programa_seleccionado?.length <= 0)}
                    />
                  </div>
                  <div onClick={() => state.programa_seleccionado?.length > 0 ? null : message(null, "Selecciona un programa para habilitar está opción.")}>
                    <p className='text-xs text-gray-500 mb-2 ml-2'>Seleccionar Subcategorías</p>
                    <MultiSelect
                      display="chip"
                      value={subcategoria_seleccionado}
                      onChange={(event) => setState({ subcategoria_seleccionado: event.target.value })}
                      options={listado_subcategorias}
                      optionLabel="name"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                      optionGroupTemplate={groupedItemTemplate}
                      itemTemplate={itemTemplateSelectGroup2}
                      placeholder="Subcategorías"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      panelClassName="custom-dropdown-panel"
                      disabled={!isEditar || (listado_subcategorias?.length <= 0 && state.programa_seleccionado?.length <= 0)}
                    />
                  </div>
                  <div>
                    <p className='text-xs text-gray-500 mb-2 ml-2'>Seleccionar Equipo sugerido</p>
                    <MultiSelect
                      display="chip"
                      value={state?.equipo_seleccionado}
                      onChange={(event) => setState({ equipo_seleccionado: event.target.value })}
                      options={state.listado_equipo_sugerido}
                      optionLabel="name"
                      placeholder="Equipo sugerido"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      disabled={!isEditar}
                    />
                  </div>
                  <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.pdf_complementarios_file?.length > 0 ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                    <p className='truncate'>{(state.pdf_complementarios_file?.length > 0 && (`${state.pdf_complementarios_file?.length} PDF Seleccionados`)) || "Selecciona los PDF complementarios"}</p>
                    <input ref={refPDFDocFile} type="file" multiple hidden accept="application/pdf" style={{ display: "none" }} onChange={(event) => setState({ pdf_complementarios_file: event.target.files })} disabled={!isEditar} />
                    {isEditar && (<button className='underline' onClick={() => refPDFDocFile?.current?.click()}>Examinar</button>)}
                  </div>
                  <div className='bg-Magnolia flex items-center px-3 py-3 rounded-md text-sm'>
                    <p className='flex-1 pr-3 text-gray-900'>{state.video_recomendado ? "Desactivar" : "Activar"} videos recomendados</p>
                    <InputSwitch checked={state.video_recomendado || false} onChange={(event) => setState({ video_recomendado: event.value })} disabled={!isEditar} />
                  </div>
                </div>
                <div className='flex flex-col gap-4'>
                  <div className='relative flex items-center justify-center bg-Magnolia min-h-[165px] max-h-[165px] rounded-md'>
                    <Image
                      alt="Play"
                      src={state.portada_url}
                      imageClassName="w-full h-full object-contain"
                      className='h-full w-full'
                    />
                    <div className={`${state.id_video ? "bg-neutral-800/50 text-neutral-50" : "text-neutral-400"} absolute flex flex-col justify-center items-center h-full w-full text-center rounded-md`}>
                      <Image
                        alt="Play"
                        src={require("../../../assets/images/play-btn.png")}
                        className="cursor-pointer active:scale-90 transition-all select-none pointer-events-auto"
                        imageClassName="min-w-[3.5rem] w-[3.5rem] select-none pointer-events-none"
                        onClick={() => state.id_video && setState({ isVideoVisible: true })}
                      />
                      <p className='mt-5 text-inherit text-sm'>Preview de video</p>
                    </div>
                  </div>
                  <div className='space-y-4'>
                    <input
                      type="text"
                      placeholder='Título'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.titulo_video}
                      onChange={(event) => setState({ titulo_video: event.target.value })}
                      disabled={!isEditar}
                    />
                    <textarea
                      rows={4}
                      placeholder='Descripción'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.descripcion_video}
                      onChange={(event) => setState({ descripcion_video: event.target.value })}
                      disabled={!isEditar}
                    />
                    <div className="bg-Crayola text-white flex flex-col rounded-md text-sm">
                      <div className='flex items-center gap-2 px-3 py-2'>
                        <p className='font-golos-medium'>Duración:</p>
                        <p className='truncate'>{state.duracion?.minutos || 0} minutos</p>
                        <p className='truncate'>{state.duracion?.segundos || 0} segundos</p>
                      </div>
                      <div className='flex items-center gap-6 bg-Magnolia px-5 py-6 rounded-b-md'>
                        <div className='flex-1 text-center space-y-2'>
                          <p className='text-gray-700'>Segundos</p>
                          <Slider
                            value={parseInt(state.duracion?.segundos)}
                            onChange={(e) => setState({ segundos: e.value }, 'duracion')}
                            className="text-sm w-full"
                            step={1}
                            max={60}
                            disabled={!isEditar}
                          />
                        </div>
                        <div className='flex-1 text-center space-y-2'>
                          <p className='text-gray-700'>Minutos</p>
                          <Slider
                            value={parseInt(state.duracion?.minutos)}
                            onChange={(e) => setState({ minutos: e.value }, 'duracion')}
                            className="text-sm w-full"
                            step={1}
                            max={60}
                            disabled={!isEditar}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {state.video_recomendado && (
                <div className='mt-10'>
                  <div className='bg-white rounded-xl'>
                    <div className='flex flex-col xs:flex-row items-center bg-Crayola py-3 px-4 rounded-t-xl gap-4'>
                      <h1 className='flex-1 font-golos-medium text-white text-md text-center xs:text-left'>{!isEditar ? "Videos recomendados" : "Seleccionar videos recomendados"}</h1>
                      {isEditar && (
                        <input
                          type="search"
                          placeholder='Buscar'
                          className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-2 relative rounded-md text-sm text-gray-900 transition-all"
                          value={state?.buscador_videos_recomendados}
                          onChange={(event) => setState({ buscador_videos_recomendados: event.target.value })}
                        />
                      )}
                    </div>
                    <div className='border border-gray-100 p-6 rounded-b-xl'>
                      <VideosRecomendados
                        buscador={state?.buscador_videos_recomendados}
                        hideIconSelect={!isEditar}
                        core_parent={id}
                        videos_sugeridos_seleccionados={state?.videos_sugeridos_seleccionados}
                        programa_seleccionado={state?.programa_seleccionado || []}
                        // categoria_seleccionado={categoria_seleccionado || []}
                        // subcategoria_seleccionado={subcategoria_seleccionado || []}
                        onSelectVideoSugerido={onSelectVideoSugerido}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text={isEditar ? "CANCELAR" : "REGRESAR"}
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                  withIconBack={!isEditar}
                />
                {isEditar && (
                  <ButtonCustom
                    text="ACTUALIZAR"
                    classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                    onClick={onUpdate}
                    loading={state.sending}
                    loadingText={`${state?.progress_upload || 0}%`}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarCore;