import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { colors } from '../../../config/colors';
import { optionsToast } from '../../../config/toast';
import Loading from '../../../components/Loading';
import { isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { getCategorias } from '../../../api/admin/categorias';
import { createSubCategory } from '../../../api/admin/subcategorias';
import { getProgramas } from '../../../api/client/programas';
const prefix = "/admin/categorias";

const CrearSubCategoria = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    name: "",
    portada_file: null,
    categoria_seleccionada: null,
    listado_categorias: [],
    programa_seleccionado: null,
    listado_programas: [],
  });

  const listado_categorias = state.programa_seleccionado?.id ? state.listado_categorias.map(item => ({ ...item, items: item.items.filter(subItem => subItem.programID === state.programa_seleccionado?.id) })) : [];

  const refPortadaFile = useRef(null);

  useEffect(() => {
    (async () => {
      const categorias = await getCategorias({ grouped: "type" });
      const programas = await getProgramas();

      setState({
        listado_categorias: categorias?.data,
        listado_programas: programas?.data,
        loading: false,
      });
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, []);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const groupedItemTemplate = useCallback((option) => {
    return (
      <div className="flex items-center pl-0">
        <div className='capitalize'>{option.label}</div>
      </div>
    );
  }, []);

  const itemTemplate = useCallback((option) => {
    return (
      <div className="flex items-center pl-2">
        <i className="pi pi-circle-fill" style={{ color: colors.Crayola, fontSize: '0.346rem' }} />
        <div className='ml-2'>{option.name}</div>
      </div>
    );
  }, []);

  const onCreate = useCallback(async () => {
    if (isEmpty(state.name)) return message(null, 'El nombre es obligatorio.');
    if (isEmpty(state?.categoria_seleccionada?.id)) return message(null, 'Selecciona una categoría para continuar.');
    if (isEmpty(state?.programa_seleccionado?.id)) return message(null, 'Selecciona un programa para continuar.');

    const formDataFields = {
      programID: state.programa_seleccionado?.id,
      categoryID: state.categoria_seleccionada?.id,
      name: state.name,
      image: (state.portada_file && [...state.portada_file]),
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["image"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending: true });
      const response = await createSubCategory(_formData);
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Subcategoría creada', '¡La subcategoría se ha creado exitosamente! Serás redirigido a la página de subcategorías en breve...');
      } else {
        window.PrimeToast.onHide = null;
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear la subcategoría. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      window.PrimeToast.onHide = null;
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear la subcategoría. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state, setState, navigate]);

  return (
    <>
      <SEO
        title="Crear subcategoría"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Crear subcategoría</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg max-w-md'>
              <div className='flex flex-col gap-4 mb-12'>
                <input
                  type="text"
                  placeholder='Nombre'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.name}
                  onChange={(event) => setState({ name: event.target.value })}
                />
                <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.portada_file?.length > 0 ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                  <p className='truncate'>{(state.portada_file?.length > 0 && state.portada_file?.[0]?.name) || "Sube una portada"}</p>
                  <input ref={refPortadaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_file: event.target.files })} />
                  <button className='underline' onClick={() => refPortadaFile?.current?.click()}>Examinar</button>
                </div>
                <Dropdown
                  value={state?.programa_seleccionado}
                  onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                  options={state.listado_programas}
                  optionLabel="name"
                  placeholder="Selecciona programa"
                  emptyMessage="No se encontraron resultados"
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                />
                <span onClick={() => listado_categorias?.length > 0 ? null : message(null, "Selecciona un programa para habilitar está opción.")}>
                  <Dropdown
                    value={state?.categoria_seleccionada}
                    onChange={(event) => setState({ categoria_seleccionada: event.target.value })}
                    options={listado_categorias}
                    optionLabel="name"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionGroupTemplate={groupedItemTemplate}
                    itemTemplate={itemTemplate}
                    placeholder="Selecciona categoría"
                    emptyMessage="No se encontraron resultados"
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    panelClassName="custom-dropdown-panel"
                    disabled={listado_categorias?.length > 0 ? false : true}
                  />
                </span>
              </div>
              <div className='flex justify-end items-center gap-3 mt-14'>
                <ButtonCustom
                  text="CANCELAR"
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                />
                <ButtonCustom
                  text="CREAR"
                  classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                  onClick={onCreate}
                  loading={state.sending}
                />
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default CrearSubCategoria;