function calculateDiscount(originalPrice, discountAmount, type) {
  originalPrice = parseFloat(originalPrice);
  discountAmount = parseFloat(discountAmount);

  if (type === "cantidad") {
    // Descuento por cantidad fija
    if (discountAmount > originalPrice) {
      // return { error: "El descuento no puede ser mayor que el precio original." };
      return 0;
    }
    return originalPrice - discountAmount;
  } else if (type === "porcentaje") {
    // Descuento por porcentaje
    if (discountAmount < 0 || discountAmount > 100) {
      // return { error: "El porcentaje de descuento debe estar entre 0 y 100." };
      discountAmount = discountAmount > 100 ? 100 : 0;
    }
    const discount = (originalPrice * discountAmount) / 100;
    return originalPrice - discount;
  } else {
    return { error: "Tipo de descuento no válido. Debe ser 'cantidad' o 'porcentaje'." };
  }
}

export default calculateDiscount;