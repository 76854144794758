import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { downloadApps } from '../config/app';
import intersectionObserver from '../utils/intersectionObserver';

const StoreApps = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });
  }, [elementsRef]);

  return (
    <div className='relative flex flex-wrap gap-2 items-center justify-center md:justify-start'>
      <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInDown"} className="animate__animated">
        <Link to={downloadApps.app_store} target={"_blank"}>
          <img src={require('../assets/images/Appstore.png')} alt="App Download" className='object-cover h-[56px] max-w-[160px]' />
        </Link>
      </div>
      <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInDown"} className="animate__animated">
        <Link to={downloadApps.play_store} target={"_blank"}>
          <img src={require('../assets/images/Googleplay.png')} alt="App Download" className='object-cover h-[60px] max-w-[160px]' />
        </Link>
      </div>
    </div>
  );
}

export default StoreApps;