import React, { useCallback } from "react";
import { colors } from "../config/colors";

const StarEmpty = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
    <path
      d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4 459.8 484c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6 68.6-141.3C270.4 5.2 278.7 0 287.9 0zm0 79-52.5 108.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9l85.9 85.1c5.5 5.5 8.1 13.3 6.8 21l-20.3 119.7 105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2-20.2-119.6c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1-118.3-17.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
      fill={props.fill || "inherit"}
      stroke={props.stroke || "inherit"}
      strokeWidth={props.strokeWidth || 10}
    />
  </svg>
);

const StarFull = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
    <path
      d="M316.9 18c-5.3-11-16.5-18-28.8-18s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329l-24.6 145.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329l104.2-103.1c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7l-143.7-21.2L316.9 18z"
      fill={props.fill || "inherit"}
    />
  </svg>
);

const Rating = ({ totalRating, selectedStars, onClick }) => {
  const _totalRating = totalRating || 5;

  const renderStar = useCallback((value, selectedStars) => {
    const isSelected = selectedStars >= value;

    return (
      <div className={`c-pointer ${isSelected ? "star-full selected" : "star-empty"}`} onClick={() => onClick(value)}>
        {isSelected ? (
          <StarFull height={46} width={46} fill={colors.Crayola} style={{ maxWidth: "100%" }} />
        ) : (
          <StarEmpty height={46} width={46} fill={colors.Crayola} stroke={colors.Crayola} style={{ maxWidth: "100%" }} />
        )}
      </div>
    );
  }, [onClick]);

  return (
    <div className="inline-flex items-center space-x-3">
      {Array.from({ length: _totalRating }, (_, i) => i + 1).map((value) => (
        <React.Fragment key={value}>
          {renderStar(value, selectedStars)}
        </React.Fragment>
      ))}
    </div>
  );
}

export default Rating;