import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Chip } from 'primereact/chip';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { MultiSelect } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { getWorkouts, deleteWorkout } from '../../../api/admin/workouts';
import { getCategorias } from '../../../api/admin/categorias';
import { getSubCategorias } from '../../../api/admin/subcategorias';
import { getProgramas } from '../../../api/admin/programas';
import { paginationComponentOptions, NoDataComponent, SortIcon } from '../../../config/table.config';
import FilterComponent from '../../../components/Form/FilterComponent';
import customTableStyles from '../../../styles/customTableStyles';
import onSearchFields from '../../../utils/onSearchFields';
const prefix = "/admin/galeria-workouts";

const GaleriaWorkouts = () => {
  const op = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    sending: false,
    loading: true,
    filterText: "",
    listado_categorias: [],
    listado_subcategorias: [],
    categoria_seleccionado: null,
    subcategoria_seleccionado: null,
    listado_programas: [],
    programa_seleccionado: [],
    workouts: [],
    // page: null,
    // pageSize: null,
    totalRows: 0,
  });

  const listado_categorias = state.programa_seleccionado?.length > 0 ? state.listado_categorias.map(item => ({ ...item, items: item.items?.filter(subItem => state.programa_seleccionado?.some(item2 => item2 === subItem.programID)) }))?.filter(item => item?.items?.length > 0) : state.listado_categorias;
  const categoria_seleccionado = state.programa_seleccionado?.length > 0 ? state?.categoria_seleccionado?.filter(item => state.programa_seleccionado?.some(item2 => parseInt(item2) === parseInt(item?.programID))) : state?.categoria_seleccionado;
  const listado_subcategorias = state.programa_seleccionado?.length > 0 ? state.listado_subcategorias.map(item => {
    const filteredItems = state.categoria_seleccionado?.length > 0 ? item.items?.filter(subItem => {
      const categoryMatches = categoria_seleccionado?.some(categoria => categoria?.id === subItem.categoryID);
      const programMatches = state.programa_seleccionado?.some(programa => programa === subItem.programID);
      return categoryMatches && programMatches;
    }) : item.items;

    return {
      ...item,
      items: filteredItems
    };
  }).filter(item => item?.items?.length > 0) : state.listado_subcategorias;
  const subcategoria_seleccionado = state.programa_seleccionado?.length > 0 ? state?.subcategoria_seleccionado?.filter(item => state.programa_seleccionado?.some(item2 => parseInt(item2) === parseInt(item?.programID))) : state?.subcategoria_seleccionado;

  const { filters } = useMemo(() => ({
    filters: {
      category: categoria_seleccionado?.length > 0 ? categoria_seleccionado?.map(item => item?.id) : null,
      subCategory: subcategoria_seleccionado?.length > 0 ? subcategoria_seleccionado?.map(item => item?.id) : null,
      program: state.programa_seleccionado,
    }
    // eslint-disable-next-line
  }), [state.programa_seleccionado, categoria_seleccionado?.length, subcategoria_seleccionado?.length]);

  useEffect(() => {
    loadData(filters);
    // eslint-disable-next-line
  }, [filters]);

  // useEffect(() => {
  //   loadWorkouts(filters, { page: 1, pageSize: state.pageSize });
  //   // eslint-disable-next-line
  // }, [filters]);

  const loadData = useCallback(async (filters, params) => {
    setState({ loading: true });
    const workouts = await getWorkouts(null, filters, params);
    const programas = await getProgramas();
    const categorias = await getCategorias({ grouped: "program", type: "workouts" });
    const filterCategories = categorias?.data?.length > 0 && categorias?.data?.flatMap(item => item?.items?.map(item2 => item2?.id));
    const subcategorias = await getSubCategorias({ grouped: "program", categoryID: filterCategories });

    setState({
      workouts: workouts?.data,
      listado_programas: programas?.data,
      listado_categorias: categorias?.data,
      listado_subcategorias: subcategorias?.data,
      totalRows: workouts?.totalItems,
      loading: false,
    });
  }, [setState]);

  // const loadWorkouts = useCallback(async (filters, params) => {
  //   setState({ sending: true });
  //   const workouts = await getWorkouts(null, filters, params);

  //   setState({
  //     workouts: workouts?.data,
  //     totalRows: workouts?.totalItems,
  //     sending: false,
  //   });
  // }, [state.page, setState]);

  // const handlePageChange = useCallback(page => {
  //   if (state.page === page) return;
  //   loadWorkouts(filters, { page: page, pageSize: state.pageSize });
  //   setState({ page });
  // }, [filters, state.pageSize, state.page, loadWorkouts, setState]);

  // const handlePerRowsChange = useCallback(async (newPerPage, page) => {
  //   if (state.page === page) return;
  //   await loadWorkouts(filters, { page, pageSize: newPerPage });
  //   setState({ pageSize: newPerPage });
  // }, [filters, state.page, loadWorkouts, setState]);

  const onActionTable = useCallback(async (event, params, type) => {
    // eslint-disable-next-line
    if (type === "ver") {
      if (params?.id) navigate(`${prefix}/${params?.id}`);
    } else if (type === "editar") {
      if (params?.id) navigate(`${prefix}/editar-workout/${params?.id}`);
    } else if (type === "eliminar") {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <div className='text-center'>
            <p>¿Estás segura?</p>
            <p>Esta acción no se puede deshacer.</p>
          </div>
        ),
        icon: null,
        rejectClassName: "bg-neutral-400 border-neutral-400 hover:!bg-neutral-500 hover:!border-neutral-500 !py-1",
        acceptClassName: 'p-button-danger !py-1',
        acceptLabel: "Si, eliminar",
        accept: async () => {
          if (params?.id) await deleteWorkout(params?.id);
          loadData();
        },
      });
    }
  }, [loadData, navigate]);

  const { columnsTable, filteredItems } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Título workout',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.title),
        cell: row => (row.title)
      },
      {
        name: 'Programas',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => row.programas?.map(item => item?.Program?.name)?.join(" "),
        cell: row => {
          return (
            <div className='flex flex-wrap gap-1 my-2'>
              {
                row.programas?.map(item => (
                  <Chip className='bg-blue-500 text-white rounded-md font-medium text-xs' label={item?.Program?.name} />
                ))
              }
            </div>
          );
        },
      },
      {
        name: 'Categorías',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => row.categorias?.map(item => item?.Category?.name)?.join(" "),
        cell: row => {
          return (
            <div className='flex flex-wrap gap-1 my-2'>
              {
                row.categorias?.map(item => (
                  <Chip
                    className='bg-blue-500 text-white rounded-md font-medium text-xs'
                    label={
                      <span>
                        {item?.Category?.name} <sup>({item?.Category?.Program?.name?.replace("Fitmom", "")?.trim()})</sup>
                      </span>
                    }
                  />
                ))
              }
            </div>
          );
        },
      },
      {
        name: 'Subcategorías',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => row.sub_categorias?.map(item => item?.SubCategory?.name)?.join(" "),
        cell: row => {
          return (
            <div className='flex flex-wrap gap-1 my-2'>
              {
                row.sub_categorias?.map(item => (
                  <Chip
                    className='bg-blue-500 text-white rounded-md font-medium text-xs'
                    label={
                      <span>
                        {item?.SubCategory?.name} <sup>({item?.SubCategory?.Category?.Program?.name?.replace("Fitmom", "")?.trim()})</sup>
                      </span>
                    }
                  />
                ))
              }
            </div>
          );
        },
      },
      {
        name: 'Recomendado',
        sortable: true,
        fixed: 'left',
        minWidth: '140px',
        selector: row => row.isVideoRecommended === "false" ? "No" : "Si",
        cell: row => (
          <div className={`rounded-md ${row.isVideoRecommended === "false" ? "bg-gray-500" : "bg-blue-500"} text-white font-medium py-1 px-3`}>
            {row.isVideoRecommended === "false" ? "No" : "Si"}
          </div>
        ),
      },
      {
        name: 'Estado',
        sortable: true,
        fixed: 'left',
        minWidth: '130px',
        selector: row => {
          if (row.status === "active") {
            return "Activo";
          } else if (row.status === "inactive") {
            return "Inactivo";
          }
          return "";
        },
        cell: row => {
          if (row.status === "active") {
            return <span className='bg-green-600 text-white rounded-lg py-2 px-4'>Activo</span>;
          } else if (row.status === "inactive") {
            return <span className='bg-gray-500 text-white rounded-lg py-2 px-4'>Inactivo</span>;
          }
          return "";
        },
      },
      {
        name: 'Acciones',
        center: true,
        width: '260px',
        style: {
          borderLeft: "1px solid #eee"
        },
        cell: row => (
          <div className='flex flex-row gap-2'>
            <button type="button" className='bg-green-600 hover:bg-green-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "ver")}>Ver</button>
            <button type="button" className='bg-blue-600 hover:bg-blue-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "editar")}>Editar</button>
            <button type="button" className='bg-red-600 hover:bg-red-700 text-white rounded-lg duration-300 transition-all py-2 px-4' onClick={(event) => onActionTable(event, row, "eliminar")}>Eliminar</button>
          </div>
        ),
      },
    ],
    filteredItems: onSearchFields(state?.workouts, {
      searchText: state.filterText,
      fieldsToSearch: ['title', 'programas.Program[].name', 'categorias.Category[].name', 'sub_categorias.SubCategory[].name'],
    })
  }), [state.workouts, state.filterText, onActionTable]);

  const subHeaderComponentMemo = useMemo(() => (
    <FilterComponent onFilter={e => setState({ filterText: e.target.value })} filterText={state.filterText} allowedValues={["Titulo", "Programa", "Categorías", "Subcategorías"]} />
  ), [state.filterText, setState]);

  const groupedItemTemplate = useCallback((option) => {
    return (
      <div className="flex items-center pl-0">
        <div className='capitalize'>{option.label}</div>
      </div>
    );
  }, []);

  const itemTemplateSelectGroup = useCallback((option) => {
    return (
      <div className='capitalize'>{option.name}</div>
    );
  }, []);

  const itemTemplateSelectGroup2 = useCallback((option) => {
    return (
      <div className='capitalize'>{option.name} <sup className='text-gray-600'>({option.Category.name})</sup></div>
    );
  }, []);

  return (
    <>
      <SEO
        title="Galería Workouts"
      />
      <ConfirmPopup />
      <OverlayPanel
        ref={op}
        showCloseIcon={false}
        className={"shadow-sm shadow-[#ddd] max-w-[95%] mx-4"}
      >
        <div className='flex flex-col flex-1 divide-y divide-[#ddd] space-y-4 text-center sm:text-left w-full'>
          <div className='flex flex-1 flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Categorías</p>
            <div className='flex flex-1 flex-wrap items-center gap-2 max-w-[495px]'>
              <MultiSelect
                display="chip"
                value={categoria_seleccionado}
                onChange={(event) => setState({ categoria_seleccionado: event.target.value })}
                options={listado_categorias}
                optionLabel="name"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupedItemTemplate}
                itemTemplate={itemTemplateSelectGroup}
                placeholder="Categorías"
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                panelClassName="custom-dropdown-panel"
                disabled={state.loading || state.sending}
              />
            </div>
          </div>
          <div className='flex flex-1 flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Subcategorías</p>
            <div className='flex flex-1 flex-wrap items-center gap-2 max-w-[495px]'>
              <MultiSelect
                display="chip"
                value={subcategoria_seleccionado}
                onChange={(event) => setState({ subcategoria_seleccionado: event.target.value })}
                options={listado_subcategorias}
                optionLabel="name"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupedItemTemplate}
                itemTemplate={itemTemplateSelectGroup2}
                placeholder="Subcategorías"
                className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                panelClassName="custom-dropdown-panel"
                disabled={state.loading || state.sending}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Programas</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {state.listado_programas?.map((item, index) => (
                <div
                  key={`programa-key-${item?.id || index}`}
                  className={`${state.programa_seleccionado?.includes(item?.id) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    const updatedProgramsSelected = state.programa_seleccionado?.includes(item?.id)
                      ? state.programa_seleccionado?.filter(program => program !== item?.id)
                      : [...state.programa_seleccionado, item?.id];

                    setState({ programa_seleccionado: updatedProgramsSelected });
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OverlayPanel>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col sm:flex-row items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Galería Workouts</h1>
                <div className='inline-flex flex-wrap justify-center lg:justify-start gap-3'>
                  <ButtonCustom
                    text="Filtros"
                    icon={<i className="pi pi-filter" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={(e) => op.current.toggle(e)}
                  />
                  <ButtonCustom
                    text="Nuevo workout"
                    classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru"
                    onClick={() => navigate(`${prefix}/crear-workout`)}
                  />
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  // paginationServer={true}
                  // paginationTotalRows={state.totalRows}
                  // onChangeRowsPerPage={handlePerRowsChange}
                  // onChangePage={handlePageChange}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  selectableRows={false}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default GaleriaWorkouts;