import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import GlobalContext from '../../contexts/GlobalContext';
import { useCustomState } from '../../hooks/useCustomState';
import { forgotUser, loginUser, registerUser } from '../../redux/reducers/authSlice';
import LoginForm from '../Form/LoginForm';
import RegisterForm from '../Form/RegisterForm';
import ForgotForm from '../Form/ForgotForm';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { optionsToast } from '../../config/toast';
import { isEmail, isEmpty } from '../../utils';

function LoginModal({ toNavigate, propsContainer, stylesContainer }) {
  const modalType = "login";
  const dispatch = useDispatch();
  const { showModal, typeModal, navigate, setModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    sending: false,
    isLoginForm: true,
    isRegisterForm: false,
    isForgotForm: false,
    loginForm: {
      correo_electronico: "",
      contrasenia: "",
      visiblePassword: false,
      is_remember: true,
    },
    registerForm: {
      nombre: "",
      correo_electronico: "",
      contrasenia: "",
      visiblePassword: false,
    },
    forgotForm: {
      correo_electronico: "",
    },
  });

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const closeModal = useCallback(() => {
    setModal(modalType);
  }, [setModal, modalType]);

  const onChange = useCallback((_state, form) => {
    setState(_state, form);
  }, [setState]);

  const onSubmitLogin = useCallback((event) => {
    event?.preventDefault();

    if (isEmpty(state.loginForm.correo_electronico)) return message(null, "La dirección de correo electrónico es requerida");
    if (!isEmail(state.loginForm.correo_electronico)) return message(null, "La dirección de correo electrónico no es válido");
    if (isEmpty(state.loginForm.contrasenia)) return message(null, "La contraseña es requerida");

    setState({ sending: true });
    dispatch(
      loginUser({
        email: state.loginForm.correo_electronico,
        pass: state.loginForm.contrasenia,
        device: "web",
      })
    )
      .then((response) => {
        setState({ sending: false });
        if (response?.status === true) {
          closeModal();
          // if (toNavigate && navigate)
          navigate(toNavigate?.screen || '/mi-cuenta', { replace: true });
        } else {
          const error = parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al iniciar sesión. Por favor, inténtalo de nuevo más tarde.';
          message('Ocurrio un problema', error);
          if (error?.indexOf("La contraseña tiene que ser renovada por seguridad") !== -1) {
            const data = response?.response?.data?.errors?.data;
            closeModal();
            navigate(`/renovar-contrasenia?email=${data?.email}`, { replace: true });
          }
        }
      });

    // eslint-disable-next-line 
  }, [state.loginForm, setState, dispatch, navigate, toNavigate, closeModal]);

  const onSubmitRegister = useCallback((event, tokenCaptcha) => {
    event?.preventDefault();
    if (isEmpty(state.registerForm.nombre)) return message(null, "El nombre es requerido");
    if (isEmpty(state.registerForm.correo_electronico)) return message(null, "La dirección de correo electrónico es requerida");
    if (!isEmail(state.registerForm.correo_electronico)) return message(null, "La dirección de correo electrónico no es válido");
    if (isEmpty(state.registerForm.contrasenia)) return message(null, "La contraseña es requerida");
    if (isEmpty(tokenCaptcha)) return message(null, "Selecciona la verificación de ReCaptcha para continuar");

    setState({ sending: true });
    dispatch(
      registerUser({
        email: state.registerForm.correo_electronico,
        password: state.registerForm.contrasenia,
        firstName: state.registerForm.nombre,
        device: "web",
        token: tokenCaptcha
      })
    )
      .then((response) => {
        setState({ sending: false });
        if (response?.status === true) {
          closeModal();
          if (toNavigate && navigate) navigate(toNavigate?.screen || '/mi-cuenta', { replace: true });
        } else {
          message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al registrarse. Por favor, inténtalo de nuevo más tarde.');
        }
      });

    // eslint-disable-next-line 
  }, [state.registerForm, setState, dispatch, toNavigate, closeModal]);

  const onSubmitForgot = useCallback((event, tokenCaptcha) => {
    event?.preventDefault();
    if (isEmpty(state.forgotForm.correo_electronico)) return message(null, "La dirección de correo electrónico es requerida");
    if (!isEmail(state.forgotForm.correo_electronico)) return message(null, "La dirección de correo electrónico no es válido");
    if (isEmpty(tokenCaptcha)) return message(null, "Selecciona la verificación de ReCaptcha para continuar");

    setState({ sending: true });
    dispatch(
      forgotUser({
        email: state.forgotForm.correo_electronico,
        token: tokenCaptcha
      })
    )
      .then((response) => {
        if (response?.status === true) {
          setState({
            sending: false,
            isForgotForm: false,
            isLoginForm: true
          });
          closeModal();
          message(null, response?.mensaje);
        } else {
          setState({ sending: false });
          message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al recuperar la contraseña. Por favor, inténtalo de nuevo más tarde.');
        }
      });

    // eslint-disable-next-line 
  }, [state.forgotForm, setState, dispatch, toNavigate, closeModal]);

  return (
    <Dialog
      visible={showModal && typeModal === modalType}
      onHide={closeModal}
      dismissableMask={true}
      draggable={false}
      headerClassName="!rounded-t-xl"
      contentClassName="!rounded-b-xl -mt-1"
      headerStyle={{ border: "0 !important", paddingBottom: 0 }}
      style={{ marginLeft: 15, marginRight: 15 }}
      breakpoints={{ '960px': '50vw', '641px': '95vw' }}
    >
      <div {...propsContainer} style={{ paddingLeft: 20, paddingRight: 20, ...stylesContainer }}>
        <h1 className='font-golos-bold text-center text-lg sm:text-2xl text-DeepKoamaru animate__animated animate__fadeIn'>Bienvenida</h1>
        {state.isLoginForm && (
          <>
            <h2 className='text-center text-2xl sm:text-5xl text-Crayola/80 animate__animated animate__slideInLeft'>Inicia sesión</h2>
            <p className='text-center text-sm text-DeepKoamaru/80 mt-2 animate__animated animate__slideInRight'>
              ¿Aún no tienes una cuenta? <span className='font-golos-semibold underline cursor-pointer' onClick={() => { navigate("/carrito?is_register=true"); closeModal(); }}>¡Inscríbete!</span>
            </p>
            <LoginForm
              data={{ ...state.loginForm, sending: state.sending }}
              onChange={(_state) => onChange(_state, 'loginForm')}
              onSubmit={onSubmitLogin}
              onClickForgotPassword={() => setState({ isLoginForm: false, isForgotForm: true })}
            />
          </>
        )}
        {state.isRegisterForm && (
          <>
            <h2 className='text-center text-2xl sm:text-5xl text-Crayola/80 animate__animated animate__slideInLeft'>Regístrate</h2>
            <p className='text-center text-sm text-DeepKoamaru/80 mt-2 animate__animated animate__slideInRight'>
              ¿Ya tienes una cuenta? <span className='font-golos-semibold underline cursor-pointer' onClick={() => setState({ isRegisterForm: false, isLoginForm: true })}>¡Inicia sesión!</span>
            </p>
            <RegisterForm
              data={{ ...state.registerForm, sending: state.sending }}
              onChange={(_state) => onChange(_state, 'registerForm')}
              onSubmit={onSubmitRegister}
            />
          </>
        )}
        {state.isForgotForm && (
          <>
            <h2 className='text-center text-xl sm:text-4xl text-Crayola/80 animate__animated animate__slideInLeft'>Recuperar cuenta</h2>
            <p className='text-center text-sm text-DeepKoamaru/80 mt-2 underline cursor-pointer animate__animated animate__slideInRight' onClick={() => setState({ isForgotForm: false, isLoginForm: true })}>
              Volver al inicio de sesión
            </p>
            <ForgotForm
              data={{ ...state.forgotForm, sending: state.sending }}
              onChange={(_state) => onChange(_state, 'forgotForm')}
              onSubmit={onSubmitForgot}
            />
          </>
        )}
      </div>
    </Dialog>
  );
}

export default LoginModal;