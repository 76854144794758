export default function getPageHome() {
  const location = (window.location?.href || window.location?.pathname);
  let page_home = "/";

  if (location?.indexOf("/mi-cuenta") !== -1) {
    page_home = "/mi-cuenta";
  } else if (location?.indexOf("/admin") !== -1) {
    page_home = "/admin";
  }
  return page_home;
}