import qs from 'qs';
import axios from "../";
const prefix = "/admin/compras";

export const getCompras = async (id, filters) => {
  try {
    const params = qs.stringify({
      populate: ["program", "coupon"],
      filters: filters,
    });
    const endpoint = id ? `/${id}` : "";
    const response = await axios.get(`${prefix}/${endpoint}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportCompras = async (filters, _params) => {
  try {
    const params = qs.stringify({
      populate: ["program", "coupon"],
      filters: filters,
    });
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`${prefix}/exportar-xls?${params}`, _params, {
      responseType: 'blob',
      timeout: 0,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};