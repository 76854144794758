import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { getBannerPrograma, getProgramas, updateBannerProgram, updatePrograma } from '../../../api/admin/programas';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { optionsToast } from '../../../config/toast';
import { useCustomState } from '../../../hooks/useCustomState';
import { getStatusActive } from '../../../misc/getStatus';
import obtenerNombreImagen from '../../../utils/obtenerNombreImagen';
import parseErrorMessage from '../../../utils/parseErrorMessage';
const prefix = "/admin/programas";

const EditarPrograma = ({ isEditar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    sending_banner: false,
    programa: null,
    bannerDetalles: null,
    previewBanner: null,
    portada_banner_file: null,
    estado_seleccionado: null,
    listado_estados: [],
  });
  const refPortadaFile = useRef(null);

  useEffect(() => {
    loadProgramDetails();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, [isEditar, id]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const loadProgramDetails = useCallback(async () => {
    try {
      const programasResponse = await getProgramas({ id });
      const bannerResponse = await getBannerPrograma({ programID: id }).catch(() => null);

      if (programasResponse?.status === true) {
        const statusActive = await getStatusActive();
        setState({
          bannerDetalles: bannerResponse?.data,
          portada_banner_file: { name: obtenerNombreImagen(bannerResponse?.data?.image, true) },
          previewBanner: null,
          programa: programasResponse?.data?.[0],
          estado_seleccionado: programasResponse?.data?.[0]?.status,
          listado_estados: statusActive,
          loading: false,
        });
      }
    } catch (error) {
      setState({ loading: false });
    }
  }, [id, setState]);

  const onChangeBanner = useCallback((event) => {
    const file = event.target.files[0];

    setState((prevState) => ({
      ...prevState,
      portada_banner_file: file,
      previewBanner: file ? URL.createObjectURL(file) : null
    }));
  }, [setState]);

  const onUpdate = useCallback(async () => {
    try {
      setState({ sending: true });
      const response = await updatePrograma(id, state.programa);
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Programa actualizado', '¡El programa se ha actualizado exitosamente! Serás redirigido a la página de programas en breve...');
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el programa. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el programa. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, state.programa, setState, navigate]);

  const onUpdateBanner = useCallback(async () => {
    if (isEditar !== true) return;

    const formDataFields = {
      image: (state.portada_banner_file instanceof File) ? state.portada_banner_file : undefined,
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["image"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending_banner: true });

      const response = await updateBannerProgram(state.bannerDetalles?.id, _formData);

      setState({ sending_banner: false });

      if (response?.status === true) {
        message('Banner actualizado', '¡El banner se ha actualizado exitosamente!');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el banner. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending_banner: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el banner. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [isEditar, state, setState]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar programa" : "Ver programa"}
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
                <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar programa" : "Ver programa"}</h1>
              </div>
              <div className='mt-10 px-6 lg:px-8 w-full'>
                <div className="flex flex-wrap items-start gap-4">
                  <div className='flex-1 bg-white px-8 py-10 rounded-lg w-full sm:max-w-md'>
                    <div className='flex flex-col gap-6'>
                      <input
                        type="text"
                        placeholder='Nombre de programa'
                        className="bg-gray-200 border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        defaultValue={state?.programa?.name}
                        disabled={true}
                        readOnly
                      />
                      <input
                        type="number"
                        placeholder='Costo mensual'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.programa?.cost_month}
                        onChange={(event) => setState({ cost_month: event.target.value }, 'programa')}
                        disabled={!isEditar}
                      />
                      <input
                        type="number"
                        placeholder='Costo semestral'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.programa?.cost_biannual}
                        onChange={(event) => setState({ cost_biannual: event.target.value }, 'programa')}
                        disabled={!isEditar}
                      />
                      <Dropdown
                        value={state?.estado_seleccionado}
                        onChange={(event) => setState({ estado_seleccionado: event.target.value })}
                        options={state.listado_estados}
                        optionLabel="name"
                        placeholder="Estado"
                        emptyMessage="No se encontraron resultados"
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      />
                    </div>
                    <div className='flex flex-wrap justify-end items-center gap-3 mt-14'>
                      <ButtonCustom
                        text={isEditar ? "CANCELAR" : "REGRESAR"}
                        classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                        onClick={() => navigate(prefix)}
                        withIconBack={!isEditar}
                      />
                      {isEditar && (
                        <ButtonCustom
                          text="ACTUALIZAR"
                          classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                          onClick={onUpdate}
                          loading={state.sending}
                        />
                      )}
                    </div>
                  </div>

                  <div className='flex-1 flex flex-col bg-white px-8 py-10 rounded-lg w-full sm:max-w-md'>
                    <div className='flex flex-col gap-4'>
                      <a
                        href={state?.previewBanner || state.bannerDetalles?.image}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Image
                          src={state?.previewBanner || state.bannerDetalles?.image}
                          alt={`Banner - ${state?.programa?.name}`}
                          imageClassName='h-full w-full rounded-lg object-cover'
                          className='flex bg-white border border-gray-100 min-h-[160px] h-[160px] w-full rounded-lg'
                          onError={(event) => {
                            event.target.onerror = null; // previene un bucle infinito
                            event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';
                          }}
                        />
                      </a>
                      <div>
                        <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.portada_banner_file ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                          <p className='flex-1 break-word'>{state.portada_banner_file?.name || "Sube una imagen"}</p>
                          <input ref={refPortadaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={onChangeBanner} disabled={!isEditar} />
                          {isEditar && (<button className='underline' onClick={() => refPortadaFile?.current?.click()}>Examinar</button>)}
                        </div>
                        <p className='text-xs text-gray-500 flex-1 break-word mt-2 ml-2'>Se recomienda que la imagen tenga una resolución de 1920x720 píxeles.</p>
                      </div>
                    </div>
                    <div className='flex justify-center items-center gap-3 mt-10'>
                      <ButtonCustom
                        text={isEditar ? "CANCELAR" : "REGRESAR"}
                        classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                        onClick={() => navigate(prefix)}
                        withIconBack={!isEditar}
                      />
                      {isEditar && (
                        <ButtonCustom
                          text="ACTUALIZAR"
                          classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                          onClick={onUpdateBanner}
                          loading={state.sending_banner}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarPrograma;