const getActivities = (action) => {
  let activity;

  switch (action) {
    case "viewed_video":
      activity = "Vídeo visto";
      break;
    case "subscription_cancel":
      activity = "Cancelar suscripción";
      break;
    case "change_password":
      activity = "Actualizar contraseña";
      break;

    default:
      activity = action;
      break;
  }

  return activity;
}

export const ACTIVITY_ACTIONS = {
  VIEWED_VIDEO: 'viewed_video',
  SUBSCRIPTION_CANCEL: 'subscription_cancel',
  CHANGE_PASSWORD: 'change_password',
};

export const getFormatDescription = (action, description, user) => {
  let output;

  switch (action) {
    case "viewed_video":
      output = (
        <span className="py-2">
          <strong>{user?.Client?.firstName || ""} {user?.Client?.lastName || ""}</strong> vio el vídeo <a className="hover:underline" href={description.urlVideo} target="_blank" rel="noreferrer"><strong>{description.title}</strong></a>
        </span>
      );
      break;

    default:
      output = description;
      break;
  }

  return output;
}

export default getActivities;