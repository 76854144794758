import React, { memo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { optionsToast } from '../../../config/toast';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { isEmpty } from '../../../utils';
import { getPerfiles, updatePerfil } from '../../../api/admin/perfiles';

const RolesDesignMemo = memo(
  ({ name, data, onChangeInputCrear, onChangeInputLeer, onChangeInputEditar, onChangeInputEliminar, isEditar }) => (
    <div className='grid xs:grid-cols-2 items-center gap-2'>
      <p className='flex-1 xs:min-w-[170px]'>{name}</p>
      <div className='flex flex-row flex-wrap xs:flex-nowrap xs:justify-end gap-6'>
        <label className={`${isEditar ? "cursor-pointer" : ""} inline-flex items-center select-none`}>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputCrear}
            checked={(data?.crear === "true" || data?.crear === true) ? true : false}
            disabled={!isEditar}
          />
          <span className='pl-3 text-muted'>Crear</span>
        </label>
        <label className={`${isEditar ? "cursor-pointer" : ""} inline-flex items-center select-none`}>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputLeer}
            checked={(data?.leer === "true" || data?.leer === true) ? true : false}
            disabled={!isEditar}
          />
          <span className='pl-3 text-muted'>Ver</span>
        </label>
        <label className={`${isEditar ? "cursor-pointer" : ""} inline-flex items-center select-none`}>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputEditar}
            checked={(data?.actualizar === "true" || data?.actualizar === true) ? true : false}
            disabled={!isEditar}
          />
          <span className='pl-3 text-muted'>Editar</span>
        </label>
        <label className={`${isEditar ? "cursor-pointer" : ""} inline-flex items-center select-none`}>
          <input
            type="checkbox"
            className='check-input-custom rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all'
            onChange={onChangeInputEliminar}
            checked={(data?.eliminar === "true" || data?.eliminar === true) ? true : false}
            disabled={!isEditar}
          />
          <span className='pl-3 text-muted'>Eliminar</span>
        </label>
      </div>
    </div>
  ),
  (prev, next) => (
    prev?.data?.crear === next?.data?.crear &&
    prev?.data?.leer === next?.data?.leer &&
    prev?.data?.actualizar === next?.data?.actualizar &&
    prev?.data?.eliminar === next?.data?.eliminar &&
    prev?.data?.id === next?.data?.id
  )
);

const EditarPerfil = ({ isEditar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    perfil: null,
  });

  useEffect(() => {
    (async () => {
      const perfiles = await getPerfiles(id);
      if (perfiles?.status === true) {
        setState({
          perfil: perfiles?.data,
          loading: false,
        });
      }
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, [isEditar, id]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onUpdate = useCallback(async () => {
    if (isEmpty(state.perfil?.name)) return message(null, 'El nombre de perfil es obligatorio.');

    try {
      setState({ sending: true });
      const response = await updatePerfil(id, state.perfil);
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate('/admin/perfiles');
        message('Perfil actualizado', '¡El perfil se ha actualizado exitosamente! Serás redirigido a la página de perfiles en breve...');
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el perfil. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el perfil. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, state.perfil, setState, navigate]);

  const RolesDesign = useCallback((props) => (
    <RolesDesignMemo
      isEditar={isEditar}
      {...props}
    />
  ), [isEditar]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar perfil" : "Ver perfil"}
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
                <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar perfil" : "Ver perfil"}</h1>
              </div>
              <div className='inline-flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg'>
                <div className='mb-8'>
                  <input
                    type="text"
                    placeholder='Nombre de perfil'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.perfil?.name}
                    onChange={(event) => setState({ name: event.target.value }, 'perfil')}
                    disabled={!isEditar}
                  />
                </div>
                <p className='mb-6'>Asigna permisos al perfil:</p>
                <div className='flex flex-col gap-6'>
                  <RolesDesign
                    name="Perfiles"
                    data={state?.perfil?.profilesPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.profilesPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.profilesPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.profilesPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.profilesPermissions")}
                  />
                  <RolesDesign
                    name="Usuarios"
                    data={state?.perfil?.usersPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.usersPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.usersPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.usersPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.usersPermissions")}
                  />
                  <RolesDesign
                    name="Clientas"
                    data={state?.perfil?.clientsPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.clientsPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.clientsPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.clientsPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.clientsPermissions")}
                  />
                  <RolesDesign
                    name="Compras"
                    data={state?.perfil?.storePermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.storePermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.storePermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.storePermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.storePermissions")}
                  />
                  <RolesDesign
                    name="Programas"
                    data={state?.perfil?.programsPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.programsPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.programsPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.programsPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.programsPermissions")}
                  />
                  <RolesDesign
                    name="Descargables"
                    data={state?.perfil?.downloadablePermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.downloadablePermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.downloadablePermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.downloadablePermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.downloadablePermissions")}
                  />
                  <RolesDesign
                    name="Banners"
                    data={state?.perfil?.bannersPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.bannersPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.bannersPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.bannersPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.bannersPermissions")}
                  />
                  <RolesDesign
                    name="Productos"
                    data={state?.perfil?.productsPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.productsPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.productsPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.productsPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.productsPermissions")}
                  />
                  <RolesDesign
                    name="Cupones"
                    data={state?.perfil?.couponsPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.couponsPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.couponsPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.couponsPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.couponsPermissions")}
                  />
                  <RolesDesign
                    name="Blog"
                    data={state?.perfil?.blogPermissions}
                    onChangeInputCrear={event => setState({ crear: event.target.checked }, "perfil.blogPermissions")}
                    onChangeInputLeer={event => setState({ leer: event.target.checked }, "perfil.blogPermissions")}
                    onChangeInputEditar={event => setState({ actualizar: event.target.checked }, "perfil.blogPermissions")}
                    onChangeInputEliminar={event => setState({ eliminar: event.target.checked }, "perfil.blogPermissions")}
                  />
                </div>
                <div className='flex justify-end items-center gap-3 mt-14'>
                  <ButtonCustom
                    text={isEditar ? "CANCELAR" : "REGRESAR"}
                    classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                    onClick={() => navigate('/admin/perfiles')}
                    withIconBack={!isEditar}
                  />
                  {isEditar && (
                    <ButtonCustom
                      text="ACTUALIZAR"
                      classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                      onClick={onUpdate}
                      loading={state.sending}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarPerfil;