import QueryString from "qs";
import axios from "../";
const prefix = "/admin/reviews";

export const getReviews = async (params) => {
  try {
    const _params = QueryString.stringify(params);
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportReviews = async (filters, _params) => {
  try {
    const params = QueryString.stringify({
      filters: filters,
    });
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`${prefix}/exportar-xls?${params}`, _params, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};