import { useEffect, useState } from "react";

const getVisiblePageCount = (breakpoints) => {
  const width = window.innerWidth;
  let count = 5; // Valor por defecto

  if (breakpoints) {
    for (const breakpoint in breakpoints) {
      if (width > parseInt(breakpoint)) {
        count = breakpoints[breakpoint];
      }
    }
  }

  return count;
};

const Pagination = ({
  breakpoints,
  pageCurrent,
  totalPages,
  onClickPage,
  onNextPage,
  onPrevPage,
  className,
}) => {
  const [visiblePageCount, setVisiblePageCount] = useState(getVisiblePageCount(breakpoints?.visiblePageCount));

  useEffect(() => {
    const handleResize = () => {
      setVisiblePageCount(getVisiblePageCount(breakpoints?.visiblePageCount));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoints?.visiblePageCount]);

  const halfVisiblePageCount = Math.floor(visiblePageCount / 2);

  let startPage = Math.max(pageCurrent - halfVisiblePageCount, 1);
  const endPage = Math.min(startPage + visiblePageCount - 1, totalPages);

  if (endPage - startPage < visiblePageCount - 1) {
    startPage = Math.max(endPage - visiblePageCount + 1, 1);
  }

  const pageButtons = [];

  for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
    pageButtons.push(
      <button
        key={pageNumber}
        className={`flex flex-1 items-center justify-center ${pageNumber === pageCurrent
          ? "bg-Crayola text-white focus:outline-0 focus:outline-offset-0 focus:outline-Crayola"
          : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
          } px-4 py-2 text-sm font-semibold`}
        onClick={() => onClickPage(pageNumber)}
      >
        {pageNumber}
      </button>
    );
  }

  return (
    <div className={className}>
      <nav
        className="isolate flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <button
          className="flex flex-1 items-center justify-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          onClick={onPrevPage}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {pageButtons}

        <button
          className="flex flex-1 items-center justify-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          onClick={onNextPage}
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </nav>
    </div>
  );
};

export default Pagination;