import axios from "./";
const prefix = "/contacto";

export const sendMailContact = async (params) => {
  try {
    const response = await axios.post(`${prefix}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMailTestimonio = async (params) => {
  try {
    const response = await axios.post(`${prefix}/testimonio`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};