import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Image } from 'primereact/image';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ReactComponent as IconoBack } from "../../assets/icons/flechaback-icon.svg";
import { ReactComponent as GuiaIcon } from "../../assets/icons/guia-icon.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/star-icon.svg";
import { ReactComponent as StarIcon2 } from "../../assets/icons/star-icon2.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import { ButtonPrimary } from '../../components/Button';
import CarouselVideosSugeridos from './components/CarouselVideosSugeridos';
import GlobalContext from '../../contexts/GlobalContext';
import { useCustomState } from '../../hooks/useCustomState';
import { getCores } from '../../api/client/cores';
import { getWorkouts } from '../../api/client/workouts';
import { getVideos } from '../../api/client/galeriaVideos';
import { getReviews } from '../../api/client/review';
import { createFavorite, deleteFavorite, getFavorites } from '../../api/client/favorites';
import { optionsToast } from '../../config/toast';
import { isEmpty, searchTypeClase } from '../../utils';
import parseErrorMessage from '../../utils/parseErrorMessage';
import calculateIdealVideoHeight from '../../utils/calculateIdealVideoHeight';
import { colors } from '../../config/colors';

const VideoClase = () => {
  const isMounted = useRef(false);
  const refPlayer = useRef(null);
  const { user } = useSelector(state => state.app.auth);
  const { setModal } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id_category, id_subcategory, id, type } = useParams();
  const classId = parseInt(id?.split('-')?.[1] || 0);

  const [state, setState] = useCustomState({
    videoHeight: (calculateIdealVideoHeight(480) - 60),
    title: "",
    description: "",
    videoUrl: null,
    videoId: null,
    workout: null,
    existsRating: false,
    isFavorite: false,
    loading: {
      fetching: true,
      favorite: false,
    }
  });
  const documentsPDF = Array.isArray(state.workout?.documentsPDF) ? state.workout?.documentsPDF : [state.workout?.documentsPDF];

  useEffect(() => {
    const handleResize = () => {
      setState({ videoHeight: calculateIdealVideoHeight(480) - 60 })
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const { TYPE_URL_MAP } = useMemo(() => ({
    TYPE_URL_MAP: {
      "tu-programa": '/mi-cuenta/tu-programa',
      "mis-favoritos": '/mi-cuenta/mis-favoritos',
      cores: (location.pathname?.indexOf('/cores/only_category') !== -1) ? `/mi-cuenta/cores/only_category/${id_category}` : `/mi-cuenta/cores/category/${id_category}/subcategory/${id_subcategory}`,
      workouts: (location.pathname?.indexOf('/workouts/only_category') !== -1) ? `/mi-cuenta/workouts/only_category/${id_category}` : `/mi-cuenta/workouts/category/${id_category}/subcategory/${id_subcategory}`,
      parto: (location.pathname?.indexOf('/parto/only_category') !== -1) ? `/mi-cuenta/parto/only_category/${id_category}` : `/mi-cuenta/parto/category/${id_category}/subcategory/${id_subcategory}`,
    },
  }), [id_category, id_subcategory, location.pathname]);

  const { isTypeCore, isTypeParto } = useMemo(() => ({
    // isTypePrograma: searchTypeClase('tu-programa', location.pathname),
    isTypeCore: type === "core" || searchTypeClase('cores', location.pathname),
    // isTypeWorkout: ["workout", "workouts"].includes(type) || searchTypeClase('workouts', location.pathname),
    isTypeParto: type === "parto" || searchTypeClase('parto', location.pathname),
  }), [type, location.pathname]);

  useEffect(() => {
    loadData(classId, user?.userID, isTypeCore, isTypeParto);
    // eslint-disable-next-line
  }, [user?.userID, classId, isTypeCore, isTypeParto]);

  const loadData = useCallback(async (id_workout, userID, isTypeCore, isTypeParto) => {
    setState({ fetching: true }, 'loading');
    const _workout = isTypeCore
      ? await getCores(id_workout, isMounted.current ? null : { userID: userID, loginDevice: "web" })
      : isTypeParto
        ? await getVideos(id_workout, null, isMounted.current ? null : { userID: userID, loginDevice: "web" })
        : await getWorkouts(id_workout, isMounted.current ? null : { userID: userID, loginDevice: "web" });

    const commonParams = { userID: userID };

    const _review = await getReviews(
      isTypeCore
        ? { ...commonParams, coreID: id_workout }
        : isTypeParto
          ? { ...commonParams, galeriaVideoID: id_workout }
          : { ...commonParams, workoutID: id_workout }
    );

    const _favorite = await getFavorites(
      isTypeCore
        ? { ...commonParams, coreID: id_workout }
        : isTypeParto
          ? { ...commonParams, galeriaVideoID: id_workout }
          : { ...commonParams, workoutID: id_workout }
    );

    isMounted.current = true;

    setState({
      title: _workout?.data?.title,
      description: _workout?.data?.description,
      videoId: _workout?.data?.idVideo,
      videoUrl: !isEmpty(_workout?.data?.urlVideo) ? _workout?.data?.urlVideo : `https://vimeo.com/${_workout?.data?.idVideo}`,
      workout: _workout?.data,
      existsRating: _review?.data?.length > 0,
      isFavorite: _favorite?.data?.length > 0 ? _favorite?.data?.[0] : false,
    });
    setState({ fetching: false }, 'loading');
    // eslint-disable-next-line
  }, [isMounted.current, isTypeCore, isTypeParto]);

  const onBackButton = useCallback(async () => {
    for (const [type, url] of Object.entries(TYPE_URL_MAP)) {
      if (searchTypeClase(type, location.pathname)) {
        navigate(url);
        break;
      }
    }
  }, [TYPE_URL_MAP, location.pathname, navigate]);

  const onChangeVideo = useCallback(async (item) => {
    isMounted.current = false;
    setState({ videoId: item?.idVideo, videoUrl: item?.urlVideo });
  }, [setState]);

  const onChangeFavorites = useCallback(async () => {
    const isFavorite = state.isFavorite;

    try {
      setState({ favorite: true }, 'loading');

      const commonParams = {
        userID: user?.userID,
        workoutID: (isTypeCore || isTypeParto) ? null : classId,
        coreID: isTypeCore ? classId : null,
        galeriaVideoID: isTypeParto ? classId : null,
      };

      const response = !isFavorite?.id
        ? await createFavorite(commonParams)
        : await deleteFavorite(isFavorite.id);

      setState({ favorite: false }, 'loading');

      if (response?.status === true) {
        setState({ isFavorite: (isFavorite?.id) ? false : (response?.data || false) });
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al guardar en favoritos. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ favorite: false }, 'loading');
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al guardar en favoritos. Por favor, inténtalo de nuevo más tarde.');
    }

    // eslint-disable-next-line
  }, [classId, state.isFavorite, user, isTypeCore, isTypeParto, setState]);

  const onSuccessCalificacion = useCallback(async () => {
    setState({ existsRating: true });
  }, [setState]);

  return (
    <>
      <SEO
        title={state?.title}
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
      />

      <div className='bg-Magnolia h-full pb-4'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            {state.loading.fetching ? (
              <Loading containerClassName="h-full" />
            ) : (
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-6 bg-white px-4 py-6 sm:px-8 sm:py-8 rounded-xl'>
                  <div className='flex flex-wrap gap-4 items-center justify-center sm:justify-between'>
                    <p className='flex-1 font-golos-semibold text-Crayola text-xl text-center xs:text-left whitespace-nowrap'>{state?.title}</p>
                    <ButtonPrimary
                      text={
                        <div className='flex items-center'>
                          <span className="d-block mr-3">
                            <IconoBack fill={"#fff"} width={18} />
                          </span>
                          <p className='font-golos-semibold'>
                            {/* {isTypePrograma && "Volver a programa"}
                            {isTypeCore && "Volver a core"}
                            {isTypeWorkout && "Volver a workouts"}
                            {(!isTypePrograma && !isTypeCore && !isTypeWorkout) && "Volver"} */}
                            Regresar
                          </p>
                        </div>
                      }
                      withIcon={false}
                      classNameContainer="!border-DeepKoamaru"
                      classNameButton={"!bg-DeepKoamaru hover:!bg-[#1e114b] !py-1.5 px-4"}
                      onClick={onBackButton}
                    />
                  </div>
                  <div className='relative'>
                    <ReactPlayer
                      ref={refPlayer}
                      controls
                      pip
                      url={state.videoUrl}
                      width={'100%'}
                      height={state.videoHeight || "100%"}
                      className="object-cover min-h-[160px]"
                    />
                  </div>
                  {!isEmpty(state.description) && (
                    <p className='flex-1 text-DarkJungleGreen text-sm text-center xs:text-left'>{state.description}</p>
                  )}
                  <div className='flex flex-col sm:flex-row flex-wrap gap-6 items-center justify-center sm:justify-between mt-2'>
                    <div className='flex flex-1 flex-wrap gap-6'>
                      {(Array.isArray(state.workout?.equipment_details) && state.workout?.equipment_details?.length > 0) && (
                        <div className='flex-1 text-center sm:text-left mt-1'>
                          <p className='font-golos-semibold text-md text-Crayola mb-3'>Equipo sugerido:</p>
                          <div className='inline-flex flex-wrap justify-center sm:justify-start gap-2'>
                            {state.workout?.equipment_details?.map((item, index) => (
                              <div key={`equipo-id-${item?.id || index}`} className='equipment_icon inline-flex items-center bg-white border border-gray-100 px-3 py-2 rounded-xl space-x-3'>
                                <Image
                                  src={require(`../../assets/icons/equipo/${item?.icon}.svg`)}
                                  imageClassName='object-contain w-full h-full'
                                  className='inline-flex items-center w-[24px] h-[20px] sm:w-[30px] sm:h-[24px] select-none pointer-events-none'
                                  data-pr-tooltip={item?.name}
                                />
                                <p className='break-word flex-1 text-Crayola font-golos-medium text-muted'>{item?.name}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='flex flex-wrap gap-3 items-center justify-center sm:justify-between'>
                      {(!state.existsRating) && (
                        <ButtonPrimary
                          text="Calificar"
                          withIcon={false}
                          classNameButton="!py-1.5 px-2"
                          onClick={() => setModal("calificar", null, isTypeCore ? { coreID: classId, onSuccess: onSuccessCalificacion } : (isTypeParto ? { galeriaVideoID: classId, onSuccess: onSuccessCalificacion } : { workoutID: classId, onSuccess: onSuccessCalificacion }))}
                        />
                      )}
                      <ButtonPrimary
                        text={
                          <div className='flex items-center'>
                            <span className="inline-flex items-center mr-3">
                              {state.loading.favorite ? (
                                <ProgressSpinner style={{ width: '16px', height: '16px' }} />
                              ) : (
                                <>
                                  {(state.isFavorite?.id) ? <StarIcon fill={"#fff"} width={16} /> : <StarIcon2 fill={"#fff"} width={16} />}
                                </>
                              )}
                            </span>
                            <p className='font-golos-semibold'>{(state.isFavorite?.id) ? "Eliminar de favoritos" : "Agregar a favoritos"}</p>
                          </div>
                        }
                        withIcon={false}
                        classNameButton="!py-1.5 px-5"
                        onClick={onChangeFavorites}
                      />
                    </div>
                  </div>
                  {(state.workout?.documentsPDF?.location || (Array.isArray(state.workout?.documentsPDF) && state.workout?.documentsPDF?.length > 0)) && (
                    <div className='flex-1 flex flex-col flex-wrap justify-center sm:justify-start gap-2 w-full'>
                      <p className='font-golos-semibold text-md text-Crayola mb-1'>Documentos complementarios:</p>
                      <div className='inline-flex flex-wrap justify-center sm:justify-start gap-2'>
                        {(state.workout?.documentsPDF?.location || (Array.isArray(state.workout?.documentsPDF) && state.workout?.documentsPDF?.length > 0)) && (
                          <>
                            {documentsPDF?.map((item, index) => (
                              <a
                                key={`pdf-complementario-key-${item?.name || index}`}
                                href={item?.location}
                                className='border border-gray-200 bg-white hover:bg-gray-300 flex items-center space-x-3 rounded-xl py-2 px-4 c-pointer w-full xs:w-auto select-none transition-all'
                                rel="noreferrer"
                                target="_blank"
                              >
                                <GuiaIcon fill={colors.Crayola} width={18} height={18} style={{ minWidth: 18 }} />
                                <p className='break-all flex-1 text-Crayola text-muted'>{item?.name}</p>
                              </a>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {state.workout?.isVideoRecommended === "true" && (
                  <CarouselVideosSugeridos isTypeCore={isTypeCore} isTypeParto={isTypeParto} id={classId} onChangeVideo={onChangeVideo} />
                )}
              </div>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default VideoClase;