import QueryString from "qs";
import axios from "../";
const prefix = "/admin/frases";

export const getFrases = async (params) => {
  try {
    const _params = QueryString.stringify({
      ...params
    }, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createFrase = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`${prefix}/nueva-frase`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateFrase = async (id, params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.put(`${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFrase = async (id) => {
  try {
    const response = await axios.delete(`${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};