import React from 'react';
import { Editor } from 'primereact/editor';

const HeaderToolbar = React.memo(() => {
  return (
    <>
      <span className="ql-formats">
        <select className="ql-font" aria-label="Font"></select>
        <select className="ql-size" aria-label="Font Size"></select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button className="ql-strike" aria-label="Strike Through"></button>
      </span>
      <span className="ql-formats">
        <select className="ql-color" aria-label="Text Color"></select>
        <select className="ql-background" aria-label="Background Color"></select>
      </span>
      <span className="ql-formats">
        <button className="ql-script" value="sub" aria-label="Subscript"></button>
        <button className="ql-script" value="super" aria-label="Superscript"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-header" value="1" aria-label="Header 1"></button>
        <button className="ql-header" value="2" aria-label="Header 2"></button>
        <button className="ql-blockquote" aria-label="Blockquote"></button>
        <button className="ql-code-block" aria-label="Code Block"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" aria-label="Ordered List"></button>
        <button className="ql-list" value="bullet" aria-label="Bullet List"></button>
        <button className="ql-indent" value="-1" aria-label="Decrease Indent"></button>
        <button className="ql-indent" value="+1" aria-label="Increase Indent"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-direction" value="rtl" aria-label="Right-to-Left"></button>
        <select className="ql-align" aria-label="Text Alignment"></select>
      </span>
      <span className="ql-formats">
        <button className="ql-link" aria-label="Insert Link"></button>
        <button className="ql-image" aria-label="Insert Image"></button>
        <button className="ql-video" aria-label="Insert Video"></button>
        <button className="ql-formula" aria-label="Insert Formula"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-clean" aria-label="Clear Formatting"></button>
      </span>
    </>
  );
});

export default function WysiwygEditor({ editorRef, disabled, value, onChange, onLoad }) {
  const [text, setText] = React.useState(value);

  return (
    <div className={disabled ? 'select-none' : ''}>
      <Editor
        ref={editorRef}
        readOnly={disabled}
        headerTemplate={<HeaderToolbar />}
        value={text}
        onTextChange={(e) => {
          if (!disabled) {
            setText(e.htmlValue);
            if (onChange) onChange(e);
          }
        }}
        onLoad={onLoad}
        style={{ height: '320px' }}
      />
    </div>
  )
}