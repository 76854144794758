import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { getBanners, updateBanner } from '../../../api/admin/banners';
import { getProgramas } from '../../../api/admin/programas';
import { optionsToast } from '../../../config/toast';
import { useCustomState } from '../../../hooks/useCustomState';
import { getStatusActive } from '../../../misc/getStatus';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import obtenerNombreImagen from '../../../utils/obtenerNombreImagen';
const prefix = "/admin/banners";

const EditarBanner = ({ isEditar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    name: "",
    portada_file: null,
    portada_mobile_file: null,
    estado_seleccionado: null,
    listado_estados: [],
    programa_seleccionado: null,
    listado_programas: [],
  });

  const refPortadaFile = useRef(null),
    refPortadaMobileFile = useRef(null);

  useEffect(() => {
    (async () => {
      const banner = await getBanners({ id });
      if (banner?.status === true && banner?.data?.length > 0) {
        const _banner = banner?.data?.[0];
        const programas = await getProgramas();

        const programas_filter = programas?.data?.filter(item => _banner?.programas?.some(item2 => item?.id === item2?.programID));

        setState({
          name: _banner?.name,
          portada_file: [{ name: obtenerNombreImagen(_banner?.image, true) }],
          portada_mobile_file: [{ name: obtenerNombreImagen(_banner?.image_mobile, true) }],
          estado_seleccionado: _banner?.status,
          listado_estados: await getStatusActive(),
          programa_seleccionado: programas_filter,
          listado_programas: programas?.data,
          loading: false,
        });
      }
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
  }, [id, setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onUpdate = useCallback(async () => {
    if (isEditar !== true) return;

    const formDataFields = {
      programID: state.programa_seleccionado?.map(item => item.id) || [],
      name: state.name,
      image: ((state.portada_file instanceof FileList && state.portada_file?.length > 0) && [...state.portada_file]),
      image_mobile: ((state.portada_mobile_file instanceof FileList && state.portada_mobile_file?.length > 0) && [...state.portada_mobile_file]),
      status: state.estado_seleccionado,
    };

    const _formData = new FormData();

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (["image", "image_mobile"].includes(key)) {
            value.forEach(item => _formData.append(key, item));
          } else {
            value.forEach(item => _formData.append(key + '[]', item));
          }
        } else {
          _formData.append(key, value);
        }
      }
    });

    try {
      setState({ sending: true });

      const response = await updateBanner(id, _formData);

      setState({ sending: false });

      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Banner actualizado', '¡El banner se ha actualizado exitosamente! Serás redirigido a la página de banners en breve...');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el banner. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el banner. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, isEditar, state, setState, navigate]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar banner" : "Ver banner"}
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar banner" : "Ver banner"}</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg max-w-md'>
              <div className='flex flex-col gap-4'>
                <input
                  type="text"
                  placeholder='Nombre'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                  value={state?.name}
                  onChange={(event) => setState({ name: event.target.value })}
                  disabled={!isEditar}
                />
                <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.portada_file?.length > 0 ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                  <p className='flex-1 break-word'>{(state.portada_file?.length > 0 && state.portada_file?.[0]?.name) || "Sube una imagen"}</p>
                  <input ref={refPortadaFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_file: event.target.files })} disabled={!isEditar} />
                  {isEditar && (<button className='underline' onClick={() => refPortadaFile?.current?.click()}>Examinar</button>)}
                </div>
                <div className={`flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm ${state.portada_file?.length > 0 ? 'text-gray-900' : 'text-gray-500'} w-full transition-all`}>
                  <p className='flex-1 break-word'>{(state.portada_mobile_file?.length > 0 && state.portada_mobile_file?.[0]?.name) || "Sube una imagen para móviles"}</p>
                  <input ref={refPortadaMobileFile} type="file" hidden accept="image/*" style={{ display: "none" }} onChange={(event) => setState({ portada_mobile_file: event.target.files })} disabled={!isEditar} />
                  {isEditar && (<button className='underline' onClick={() => refPortadaMobileFile?.current?.click()}>Examinar</button>)}
                </div>
                <span className="flex-1">
                  <MultiSelect
                    display="chip"
                    value={state?.programa_seleccionado}
                    onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                    options={state.listado_programas}
                    optionLabel="name"
                    placeholder="Seleccionar Programas"
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    disabled={!isEditar}
                  />
                </span>
                <Dropdown
                  value={state?.estado_seleccionado}
                  onChange={(event) => setState({ estado_seleccionado: event.target.value })}
                  options={state.listado_estados}
                  optionLabel="name"
                  placeholder="Estado"
                  emptyMessage="No se encontraron resultados"
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                />
              </div>
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text={isEditar ? "CANCELAR" : "REGRESAR"}
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                  withIconBack={!isEditar}
                />
                {isEditar && (
                  <ButtonCustom
                    text="ACTUALIZAR"
                    classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                    onClick={onUpdate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarBanner;