import React, { useRef } from "react";
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useCustomState } from "../hooks/useCustomState";
import { ButtonCustom } from "./Button";
import dayjs from "dayjs";

const CalendarOverlay = ({ fecha_seleccionada, setDate, viewDate, labelButton, showDate, classNameContainer, classNameButton, notDisabledDays, minDate, maxDate, onChangeDate }) => {
  const _minDate = minDate ? new Date(minDate) : null;
  const _maxDate = maxDate ? new Date(maxDate) : null;
  const op = useRef(null);

  const [state, setState] = useCustomState({
    fecha_seleccionada: null,
  });

  const weekdays = [0, 1, 2, 3, 4, 5, 6];

  const _fecha_seleccionada = (setDate) ? fecha_seleccionada : state.fecha_seleccionada;

  return (
    <div className="w-full">
      <ButtonCustom
        type="button"
        icon="pi pi-calendar"
        text={(showDate && _fecha_seleccionada) ? dayjs(_fecha_seleccionada).format("YYYY-MM-DD") : (labelButton || "")}
        classNameContainer={classNameContainer}
        classNameButton={classNameButton || "border-0 px-2 hover:!bg-DeepKoamaru !text-xs"}
        classNameText={`${(_fecha_seleccionada || labelButton) ? "ml-2" : ""}`}
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel
        ref={op}
        pt={{
          root: { className: "!shadow-none" },
          content: { className: "!p-0" }
        }}
      >
        <Calendar
          disabledDays={notDisabledDays && weekdays.filter(item => !notDisabledDays.includes(item))}
          locale="es"
          value={_fecha_seleccionada}
          onChange={(e) => {
            if (onChangeDate) onChangeDate(e);
            (setDate) ? setDate({ fecha_seleccionada: e.value }) : setState({ fecha_seleccionada: e.value });
            op.current.toggle(e);
          }}
          view="date"
          viewDate={viewDate}
          dateFormat="dd MM yy"
          hideOnDateTimeSelect={true}
          readOnlyInput={true}
          placeholder={""}
          inline={true}
          showButtonBar={true}
          showOtherMonths={false}
          minDate={_minDate}
          maxDate={_maxDate}
          showMinMaxRange={(_minDate && _maxDate) ? true : false}
          inputClassName="capitalize border-0 bg-white hover:!bg-white rounded-md text-sm py-2 transition-all cursor-pointer"
        />
      </OverlayPanel>
    </div>
  );
};

export default CalendarOverlay;