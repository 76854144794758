import React, { useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { useDebounce } from 'primereact/hooks';
import { ReactComponent as SearchIcon } from "../assets/icons/search-icon.svg";
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { colors } from '../config/colors';
import BlogEntradas from './components/BlogEntradas';
import { useCustomState } from '../hooks/useCustomState';
import { isEmpty } from '../utils';

const headerImage = require('../assets/images/jimeblog-header.jpg');
const bannerImages = {
  vertical: require("../assets/images/banner-blog-vertical.jpg"),
  horizontal: require("../assets/images/banner-blog-horizontal.jpg"),
}

const BlogInicio = () => {
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get("q");
  const [state, setState] = useCustomState({
    isSearching: false,
    search: querySearch || "",
  });
  const [searchInput, , setSearchInput] = useDebounce(querySearch || "", 1500);

  const onSearch = useCallback(() => {
    if (searchInput !== state.search) setState({ isSearching: true, search: searchInput });
  }, [searchInput, state.search, setState]);

  const onEndSearch = useCallback(({ isSearching } = {}) => {
    setState({ isSearching });
  }, [setState]);

  return (
    <>
      <SEO
        title="Blog"
        description="Lorem ipsum dolor sit."
      />
      <div className={`bg-Crayola/40 bg-gradient-to-tl from-primary to-secondary bg-cover bg-center h-[13rem] pt-20`} style={{ backgroundImage: `url(${headerImage})` }}>
        <HeaderNav />
        <div className='xl:container mx-auto px-8 mt-8'>
          {/* <h2 className='font-golos-medium text-white text-xl -mb-1'>Lorem ipsum dolor sit</h2> */}
          <h1 className='font-golos-semibold text-white text-5xl'>BLOG</h1>
        </div>
      </div>

      <div className='bg-Magnolia pb-10 pt-10'>
        <div className="xl:container mx-auto px-6">
          <div className='flex flex-col sm:flex-row gap-10 w-full'>
            <div className='flex-1 order-2 sm:order-1'>
              <div className='lg:pl-5'>
                <h3 className='font-golos-medium text-Crayola text-xl'>{!isEmpty(state.search) ? "Resultados" : "Entradas recientes"}:</h3>
                {/* <p className='font-golos'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </div>
              <BlogEntradas search={state.search} onEndSearch={onEndSearch} />
            </div>
            <div className='sm:flex-[0.6] md:flex-[0.4] order-1 sm:order-2'>
              <div className="mb-4 relative flex items-center w-full">
                <InputText
                  type='text'
                  className='py-4 pl-4 pr-12 text-sm border-0 rounded-xl w-full'
                  placeholder="Búsqueda en el blog"
                  defaultValue={searchInput}
                  onChange={event => setSearchInput(event.target.value)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') { onSearch() }
                  }}
                />
                <div className='absolute right-0 pl-1 pr-3 c-pointer active:scale-90 transition ease-in-out' onClick={onSearch}>
                  {!state.isSearching ? (
                    <div className='p-2'>
                      <SearchIcon fill={colors.Orchid} height={14} width={14} />
                    </div>
                  ) : (
                    <i className={"pi pi-spin pi-spinner p-2"} style={{ color: colors.Orchid, fontSize: '0.9rem', fontWeight: 800 }} />
                  )}
                </div>
              </div>
              <Link to={"/#programas"}>
                <picture>
                  <source media="(min-width: 640px)" srcSet={bannerImages.vertical} />
                  <img className='rounded-xl' src={bannerImages.horizontal} alt={"Banner"} />
                </picture>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogInicio;