import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { ToggleButton } from 'primereact/togglebutton';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { getCupones, updateCupon } from '../../../api/admin/cupones';
import { getProgramas } from '../../../api/admin/programas';
import { optionsToast } from '../../../config/toast';
import { useCustomState } from '../../../hooks/useCustomState';
import { isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
const prefix = "/admin/cupones";

require('dayjs/locale/es');
dayjs.locale("es");

addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar'
});

const EditarCupon = ({ isEditar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    codigo_cupon: "",
    descuento_cupon: "",
    limitUsers: "",
    vigencia_inicio: null,
    vigencia_fin: null,
    isPercentType: false,
    isVisibleCalendar: false,
    visibleCalendar: null,
    plan_seleccionado: null,
    programa_seleccionado: null,
    listado_programas: [],
  });

  useEffect(() => {
    (async () => {
      const cupon = await getCupones({ id });
      if (cupon?.status === true && cupon?.data?.length > 0) {
        const _cupon = cupon?.data?.[0];
        const programas = await getProgramas();

        const programas_filter = programas?.data?.filter(item => _cupon?.programas?.some(item2 => item?.id === item2?.programID));
        const planes_filter = programas_filter?.reduce((acc, item) => {
          const programa = _cupon?.programas?.find(item2 => item2?.programID === item?.id);
          acc[item.code] = programa?.planType;
          return acc;
        }, {});

        setState({
          programa_seleccionado: programas_filter,
          plan_seleccionado: planes_filter,
          listado_programas: programas?.data,
          codigo_cupon: _cupon?.code,
          descuento_cupon: _cupon?.discount,
          isPercentType: _cupon?.type === "porcentaje" ? true : false,
          limitUsers: _cupon?.limit_users,
          vigencia_inicio: dayjs(_cupon?.startValidity).startOf("day").toDate(),
          vigencia_fin: dayjs(_cupon?.endValidity).startOf("day").toDate(),
          loading: false,
        });
      }
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
  }, [id, setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onUpdate = useCallback(async () => {
    if (isEditar !== true) return;

    if (isEmpty(state.codigo_cupon)) {
      return message(null, 'El código del cupón es obligatorio.');
    }
    if (isEmpty(state.programa_seleccionado)) {
      return message(null, 'Selecciona un programa para continuar.');
    }

    const getTypePlan = state.programa_seleccionado?.map(item => state.plan_seleccionado?.[item.code]);
    if (getTypePlan?.filter(item => (!item || item?.length === 0))?.length > 0) {
      return message(null, 'Selecciona el plan del programa faltante para continuar.');
    }

    try {
      setState({ sending: true });

      const response = await updateCupon(id, {
        programID: state.programa_seleccionado?.map(item => item.id) || [],
        planType: getTypePlan || [],
        code: state.codigo_cupon,
        discount: state.isPercentType ? parseInt(state?.descuento_cupon) : state?.descuento_cupon,
        type: state.isPercentType ? "porcentaje" : "cantidad",
        limit_users: state.limitUsers,
        startValidity: dayjs(state.vigencia_inicio).startOf("day").toISOString(),
        endValidity: dayjs(state.vigencia_fin).startOf("day").toISOString(),
      });

      setState({ sending: false });

      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Cupón actualizado', '¡El cupón se ha actualizado exitosamente! Serás redirigido a la página de cupones en breve...');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar el cupón. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar el cupón. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, isEditar, state, setState, navigate]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar cupón" : "Ver cupón"}
      />
      <Dialog
        visible={state.isVisibleCalendar}
        onHide={() => setState({ isVisibleCalendar: false })}
        style={{ width: "60%", margin: "auto" }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      >
        <Calendar
          value={state.visibleCalendar === "vigencia_inicio" ? state.vigencia_inicio : state.vigencia_fin}
          onChange={(e) => setState({ [state.visibleCalendar]: e.value, isVisibleCalendar: false })}
          showOnFocus={false}
          dateFormat="M dd, yy"
          readOnlyInput
          locale="es"
          visible={true}
          inline={true}
          style={{ position: "relative", zIndex: 9999, width: "100%" }}
        />
      </Dialog>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar cupón" : "Ver cupón"}</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg max-w-md'>
              <div className='flex flex-col gap-4'>
                <input
                  type="text"
                  placeholder='Código de cupón'
                  className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 uppercase w-full transition-all"
                  value={state?.codigo_cupon}
                  onChange={(event) => setState({ codigo_cupon: event.target.value })}
                  disabled={!isEditar}
                />
                <div className="p-inputgroup flex-1">
                  <ToggleButton
                    onLabel="%"
                    offLabel="$"
                    checked={state.isPercentType}
                    onChange={(e) => setState({ isPercentType: e.value })}
                    className="!bg-Crayola !text-white p-inputgroup-addon !border-0 !border-r !border-r-gray-200 text-sm !px-2"
                    disabled={!isEditar}
                  />
                  <InputNumber
                    placeholder="Descuento de cupón"
                    value={parseFloat(state?.descuento_cupon) >= 0 ? (state.isPercentType ? parseInt(state?.descuento_cupon || 0) : (state?.descuento_cupon || 0)) : ""}
                    onValueChange={(event) => setState({ descuento_cupon: event.value })}
                    inputClassName='!rounded-r-none border-x-0 border-y-0 bg-Magnolia focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:!shadow-none placeholder-gray-500 text-gray-900 text-sm transition-all'
                    min={0}
                    max={state.isPercentType ? 100 : 9999}
                    minFractionDigits={!state.isPercentType && 2}
                    maxFractionDigits={!state.isPercentType && 2}
                    showButtons
                    incrementButtonClassName="bg-Orchid text-white border-0 hover:!bg-OrchidHover focus:!shadow-none"
                    decrementButtonClassName="bg-Orchid text-white border-0 hover:!bg-OrchidHover focus:!shadow-none"
                    disabled={!isEditar}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <InputNumber
                    placeholder="Límite de usos"
                    value={state?.limitUsers}
                    onValueChange={(event) => setState({ limitUsers: event.value })}
                    inputClassName='!rounded-r-none border-x-0 border-y-0 bg-Magnolia focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:!shadow-none placeholder-gray-500 text-gray-900 text-sm transition-all'
                    min={0}
                    max={9999}
                    showButtons
                    incrementButtonClassName="bg-Orchid text-white border-0 hover:!bg-OrchidHover focus:!shadow-none"
                    decrementButtonClassName="bg-Orchid text-white border-0 hover:!bg-OrchidHover focus:!shadow-none"
                    disabled={!isEditar}
                  />
                </div>
                <span className="flex-1">
                  <MultiSelect
                    display="chip"
                    value={state?.programa_seleccionado}
                    onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                    options={state.listado_programas}
                    optionLabel="name"
                    placeholder="Seleccionar Programas"
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    disabled={!isEditar}
                  />
                </span>
                {state.programa_seleccionado?.length > 0 && (
                  <span className="flex flex-col flex-1 gap-4">
                    {state.programa_seleccionado?.map((item, index) => (
                      <div key={`programa-plan-key-${item?.id || index}`}>
                        <p className='font-golos-medium mb-1 text-sm text-DarkJungleGreen/80'>{item.name}:</p>
                        <Dropdown
                          value={state?.plan_seleccionado?.[item.code]}
                          onChange={(event) => setState({ [item.code]: event.target.value }, 'plan_seleccionado')}
                          options={[
                            {
                              name: "Mensual",
                              value: "mensual",
                            }, {
                              name: "Semestral",
                              value: "semestral",
                            }, {
                              name: "Ambos",
                              value: "ambos",
                            }
                          ]}
                          optionLabel="name"
                          placeholder="Seleccionar Plan"
                          className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                          disabled={!isEditar}
                        />
                      </div>
                    ))}
                  </span>
                )}
                <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                  <p className='truncate'>{(state.vigencia_inicio && dayjs(state.vigencia_inicio).format("YYYY-MM-DD")) || "Vigencia inicio"}</p>
                  {isEditar && (<button className='underline' onClick={() => setState({ isVisibleCalendar: true, visibleCalendar: "vigencia_inicio" })}>Ver calendario</button>)}
                </div>
                <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                  <p className='truncate'>{(state.vigencia_fin && dayjs(state.vigencia_fin).format("YYYY-MM-DD")) || "Vigencia fin"}</p>
                  {isEditar && (<button className='underline' onClick={() => setState({ isVisibleCalendar: true, visibleCalendar: "vigencia_fin" })}>Ver calendario</button>)}
                </div>
              </div>
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text={isEditar ? "CANCELAR" : "REGRESAR"}
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                  withIconBack={!isEditar}
                />
                {isEditar && (
                  <ButtonCustom
                    text="ACTUALIZAR"
                    classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                    onClick={onUpdate}
                    loading={state.sending}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarCupon;