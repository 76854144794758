import qs from 'qs';
import axios from "../";
const prefix = "admin/usuarios";

export const getUsuarios = async (id) => {
  try {
    const params = qs.stringify({
      populate: ["profile"]
    });
    const endpoint = id ? `/${id}` : "";
    const response = await axios.get(`/${prefix}/${endpoint}?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUsuario = async (params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.post(`/${prefix}/nuevo-usuario`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUsuario = async (id, params) => {
  try {
    const _params = qs.stringify(params, { arrayFormat: 'brackets' });

    const response = await axios.put(`/${prefix}/${id}`, _params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUsuario = async (id) => {
  try {
    const response = await axios.delete(`/${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};