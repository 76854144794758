import React from 'react';
import joinValuesCustom from '../../utils/joinValuesCustom';

const FilterComponent = ({ filterText, placeholderText, onFilter, allowedValues, children }) => {
  return (
    <div className='containerFilterSearch w-full'>
      <div className="flex flex-col xs:flex-row justify-center xs:justify-between items-center w-full py-2 gap-4">
        {allowedValues && <p className='mb-0 text-muted text-DarkJungleGreen text-center xs:text-left'>Puedes buscar por: <b>{joinValuesCustom(allowedValues)}</b></p>}
        <div className="flex xs:ml-auto" style={{ minWidth: 140 }}>
          <input
            type="search"
            className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-2 relative rounded-md text-sm text-gray-900 w-full transition-all"
            value={filterText}
            onChange={onFilter}
            placeholder={placeholderText || "Buscar..."}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

export default FilterComponent;