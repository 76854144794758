import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { optionsToast } from '../../../config/toast';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { getSecciones, updateSeccion } from '../../../api/admin/secciones';
import { getProgramas } from '../../../api/admin/programas';

const EditarSeccion = ({ isEditar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    listado_programas: [],
    seccion: null,
  });

  useEffect(() => {
    (async () => {
      const seccions = await getSecciones({ id });
      const programas = await getProgramas();

      if (seccions?.status === true) {
        setState({
          seccion: seccions?.data?.[0],
          listado_programas: programas?.data,
          loading: false,
        });
      }
    })();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, [isEditar, id]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onUpdate = useCallback(async () => {
    if (isEditar !== true) return;

    try {
      setState({ sending: true });
      const response = await updateSeccion(id, state.seccion);
      setState({ sending: false });
      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate('/admin/secciones');
        message('Sección actualizada', '¡La sección se ha actualizado exitosamente! Serás redirigido a la página de secciones en breve...');
      } else {
        message('Ocurrio un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la sección. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrio un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar la sección. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, state.seccion, isEditar, setState, navigate]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar sección" : "Ver sección"}
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
                <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar sección" : "Ver sección"}</h1>
              </div>
              <div className='mt-10 px-6 lg:px-8 w-full'>
                <div className='bg-white px-8 py-10 rounded-lg w-full sm:max-w-lg'>
                  <div className='flex flex-col gap-6'>
                    <input
                      type="text"
                      placeholder='Nombre'
                      className="bg-gray-200 border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      defaultValue={state?.seccion?.name}
                      disabled={true}
                      readOnly
                    />
                    {state?.seccion?.code === "core" ? (
                      <div className='flex flex-col gap-3'>
                        {state.listado_programas?.map((item, index) => (
                          <div key={`programa-key-${item?.id || index}`}>
                            <p className='font-golos-medium mb-1 text-sm text-DarkJungleGreen/80'>{item?.name}</p>
                            <textarea
                              placeholder='Descripción'
                              className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                              value={state?.seccion?.[`description_${(item?.code)?.replace("fitmom-", "")}`] || ""}
                              onChange={(event) => setState({ [`description_${(item?.code)?.replace("fitmom-", "")}`]: event.target.value }, 'seccion')}
                              disabled={!isEditar}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <textarea
                        placeholder='Descripción'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.seccion?.description || ""}
                        onChange={(event) => setState({ description: event.target.value }, 'seccion')}
                        disabled={!isEditar}
                      />
                    )}
                  </div>
                  <div className='flex flex-wrap justify-end items-center gap-3 mt-14'>
                    <ButtonCustom
                      text={isEditar ? "CANCELAR" : "REGRESAR"}
                      classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                      onClick={() => navigate('/admin/secciones')}
                      withIconBack={!isEditar}
                    />
                    {isEditar && (
                      <ButtonCustom
                        text="ACTUALIZAR"
                        classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                        onClick={onUpdate}
                        loading={state.sending}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarSeccion;