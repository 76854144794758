import QueryString from "qs";
import axios from "../";
const prefix = "/tu_programa";

export const getPrograma = async (params) => {
  try {
    const _params = QueryString.stringify(params, { arrayFormat: 'brackets' });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};