import axios from "axios";
import { isEmpty } from ".";
import { API_BASE_URL } from "../config/app";

export function getVimeoVideoId(url, withUrl) {
  if (isEmpty(url)) return "El ID del video es requerido";
  url = url?.toString();

  // Expresión regular para extraer el ID del video de Vimeo
  var regex = /(?:https?:\/\/(?:www\.)?)?(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)/i;
  var match = url.match(regex);

  const base_url = withUrl ? "https://vimeo.com/" : "";

  if (match && match[1]) {
    return base_url + match[1];
  } else {
    // Si no se encuentra el ID en la URL, se asume que ya es el ID
    return base_url + url;
  }
}

const getDataVimeoApi = async (id) => {
  if (isEmpty(id)) return "El ID del video es requerido";

  try {
    const response = await axios.get(`${API_BASE_URL}/workouts/video/${getVimeoVideoId(id)}`);
    return response?.data;
  } catch (error) {
    return error;
  }
}

export default getDataVimeoApi;