export const searchTypeClase = (type, location) => {
  const regex = new RegExp(`/mi-cuenta/${type}(/|$)`);
  return regex.test(location);
}

export function mergeObjects(...objects) {
  return Object.assign({}, ...objects);
}

export function isJSON(query) {
  query = typeof query !== 'string' ? JSON.stringify(query) : query;

  try {
    query = JSON.parse(query);
    if (typeof query === 'object' && query !== null) {
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
}

export const isEmail = text => {
  let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
};

export const isNumberValid = p => {
  // eslint-disable-next-line
  var phoneRe = /^[\+]?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im;
  var digits = p?.replace(/\D/g, '');
  return phoneRe?.test(digits) && digits?.length === 10;
};

export function isEmpty(text) {
  if (text === '' || /^\s+$/.test(text) || text == null || !text) {
    return true;
  }
  return false;
}

export function isImage(url) {
  return /^(file|https)?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export function isValidDate(value) {
  var dateWrapper = new Date(value);
  return !isNaN(dateWrapper.getDate());
}

export function isInstanceDate(value) {
  var date = new Date(value);
  return (date instanceof Date && !isNaN(date.valueOf()));
}

export function CapitalizeFirstLetter(string, all_words = false) {
  try {
    if (all_words === true) {
      const words = string.split(" ");

      return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ");
    } else {
      return string?.toString()?.charAt(0)?.toUpperCase() + string?.slice(1);
    }
  } catch (error) { return error; }
}

export function getNumericZero(numeric) {
  return (numeric > 9 || numeric?.length === 2) ? numeric : `0${numeric || 0}`;
}