import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ContextProviderCompound from './contexts';
import { persistor, store } from './redux/store/index';
import RootRouter from './router/RootRouter';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ContextProviderCompound>
          <RootRouter />
        </ContextProviderCompound>
      </PersistGate>
    </Provider>
  );
}

export default App;