import { memo, useCallback } from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from 'primereact/inputtextarea';
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown-icon.svg";
import { ButtonPrimary } from "../Button";
import { apiKeys } from '../../config/app';
import { optionsToast } from "../../config/toast";

const ContactForm = ({ data, listado_asuntos, onChange, onSubmit }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const message = useCallback((summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }, []);

  const handleSend = useCallback(async (event) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      message('La protección de reCAPTCHA no está disponible.');
      return;
    }

    try {
      const token = await executeRecaptcha('submit');
      return onSubmit(event, token);
    } catch (error) {
      message("Ocurrio un error con la verificación de reCAPTCHA");
    }
  }, [onSubmit, executeRecaptcha, message]);

  return (
    <form className="form-contact space-y-4 mt-8 animate__animated animate__slideInUp" onSubmit={handleSend}>
      <input
        name="nombre"
        type="text"
        required
        placeholder='Nombre'
        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
        value={data?.nombre}
        onChange={(event) => onChange({ nombre: event.target.value })}
      />
      <input
        name="correo_electronico"
        type="email"
        autoComplete="email"
        required
        placeholder='Correo electrónico'
        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
        value={data?.correo_electronico}
        onChange={(event) => onChange({ correo_electronico: event.target.value })}
      />
      <Dropdown
        value={data?.asunto}
        onChange={(event) => onChange({ asunto: event.value })}
        options={listado_asuntos}
        optionLabel="name"
        dropdownIcon={<DropdownIcon fill="#342566" />}
        placeholder="Selecciona un asunto"
        className="bg-Magnolia border-0 placeholder-gray-500 p-0 relative rounded-md text-sm text-gray-900 w-full transition-all"
      />
      <InputTextarea
        value={data?.dudas}
        onChange={(event) => onChange({ dudas: event.target.value })}
        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
        placeholder="Cuéntame tus dudas"
        rows={5}
      />
      <div className="flex items-center justify-center gap-6 pt-4">
        <div id="grecaptcha" />
        <ButtonPrimary
          text="Enviar"
          withIcon
          classNameContainer="flex justify-center"
          classNameButton="px-6"
          type="submit"
          loading={data.sending}
          onClick={handleSend}
        />
      </div>
    </form>
  );
}

const App = (props) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={apiKeys.captcha}
    language={"es"}
    scriptProps={{
      defer: true, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
    container={{ // optional to render inside custom element
      element: "grecaptcha",
      parameters: {
        badge: 'inline', // optional, default undefined
        theme: 'light', // optional, default undefined
      }
    }}
  >
    <ContactForm {...props} />
  </GoogleReCaptchaProvider>
);

export default memo(App);