import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import CheckIcon2 from "../assets/icons/check-icon-2.svg";
import HeaderNav from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { ButtonPrimary } from '../components/Button';
import Loading from '../components/Loading';
import StoreApps from '../components/StoreApps';
import { getProfile } from '../api/auth';
import { getSubscription } from '../api/client/mi_suscripcion';
import { socialMedia } from '../config/app';
import { useCustomState } from '../hooks/useCustomState';
import { setUser } from '../redux/reducers/authSlice';
import { clearSubscription } from '../redux/reducers/subscriptionSlice';
import getHeaderImageProgram from '../utils/getHeaderImageProgram';
import intersectionObserver from '../utils/intersectionObserver';

const Pagado = () => {
  const [searchParams] = useSearchParams();
  const elementsRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    suscripcion: null,
  });

  const { auth: { isAuthenticated, user }, subscription: { isPaid, data: suscripcion_seleccionada } } = useSelector(state => state.app);
  const id = searchParams.get("id");
  const headerImage = getHeaderImageProgram(suscripcion_seleccionada?.code);
  const interval_cycle = suscripcion_seleccionada?.plan === "mensual" ? "por mes" : "cada 6 meses";

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) checkPaymentStatus(id);

    if (!(isAuthenticated) || !(isPaid)) return navigate("/");
    // eslint-disable-next-line
  }, [id, isAuthenticated, isPaid, navigate]);

  React.useLayoutEffect(() => {
    if (user?.id && user?.email) getProfile({ id: user?.id, email: user?.email }).then(response => (response?.status === true && response?.data) && dispatch(setUser(response?.data)));
    // eslint-disable-next-line
  }, [user?.id, user?.email]);

  const checkPaymentStatus = useCallback(async (id) => {
    const suscripcion = await getSubscription({ id });
    if (suscripcion.status === true) {
      setState({ suscripcion: suscripcion?.data });
    }
  }, [setState]);

  const goToAccount = useCallback(() => {
    dispatch(clearSubscription());
    navigate("/mi-cuenta");
  }, [dispatch, navigate]);

  if (!(state.suscripcion) || !(isAuthenticated) || !(isPaid)) return <Loading containerClassName="h-screen" />

  return (
    <>
      <SEO
        title="Suscripción confirmada"
        description="Gracias por unirte a jimefitmom y permitirme acompañarte en esta etapa maravillosa. Te enviamos un correo con toda la información e instrucciones necesarias para disfrutar al máximo tus entrenamientos."
      />
      <HeaderNav theme="white" />

      <div className='bg-Magnolia pt-20 pb-10'>
        <div className="xl:container mx-auto px-6 justify-center flex">
          <div className='bg-white rounded-lg px-6 mt-6 py-6 md:px-20 md:py-16 inline-flex flex-col justify-center'>
            <div className='inline-flex items-center justify-center gap-4 md:mt-6'>
              <div>
                <Image
                  src={CheckIcon2}
                  alt="Icono de Check"
                  imageClassName='min-w-[2rem] w-[2rem] md:min-w-[2.64rem] md:w-[2.64rem]'
                />
              </div>
              <div className='flex flex-col'>
                <h1 className='inline-flex font-golos-semibold text-lg md:text-3xl text-DeepKoamaru md:!leading-7'>¡Bienvenida!</h1>
                <h2 className='inline-flex text-lg md:text-3xl text-DeepKoamaru'>Tu suscripción ha sido confirmada</h2>
              </div>
            </div>
            <div className='w-full flex justify-center mt-10'>
              <div className='w-full inline-flex flex-col sm:flex-row justify-center rounded-xl'>
                <div className='bg-Crayola px-4 py-4 md:px-7 md:py-5 lg:px-10 lg:py-8 rounded-t-xl sm:rounded-tr-none sm:rounded-l-xl text-center lg:text-left lg:min-w-[280px]'>
                  <p className='font-golos text-white text-sm mb-4'>{suscripcion_seleccionada?.tipo_programa}:</p>
                  <div className='mb-0'>
                    <p className='font-golos-semibold text-xl md:text-2xl lg:text-3xl text-white uppercase !leading-7'>{suscripcion_seleccionada?.nombre}</p>
                    <p className='font-golos-semibold text-xl md:text-2xl lg:text-3xl text-white uppercase !leading-7'>{suscripcion_seleccionada?.plan}</p>
                  </div>
                </div>
                <div className='bg-white relative rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl w-full'>
                  <Image
                    src={headerImage}
                    imageClassName="min-h-[10rem] h-full w-full object-cover rounded-b-xl sm:rounded-bl-none sm:rounded-r-xl"
                    className='sm:absolute h-full w-full'
                  />
                </div>
              </div>
            </div>
            <div className='w-full max-w-2xl mx-auto'>
              <p className='mt-14 text-center'>Gracias por unirte a jimefitmom y permitirme acompañarte en esta etapa maravillosa. <br />Te enviamos un correo con toda la información e instrucciones necesarias para disfrutar al máximo tus entrenamientos</p>

              <ButtonPrimary
                text="¡Empieza ahora!"
                withIcon
                classNameContainer="flex justify-center mt-10"
                onClick={goToAccount}
              />

              <div className='flex justify-center'>
                <div className='inline-flex flex-col items-center justify-center gap-4 bg-Crayola px-8 py-3 rounded-xl mt-10'>
                  <p className='text-white'>Se parte de nuestra <span className='underline'>comunidad en Facebook.</span></p>
                  <div>
                    <ButtonPrimary
                      text="¡Únete ahora!"
                      withIcon
                      icon={{
                        fill: "#df9cd4"
                      }}
                      classNameContainer="text-Orchid"
                      classNameButton="text-Orchid !text-muted"
                      theme="white"
                      onClick={() => window.open(socialMedia.facebook_comunidad)}
                    />
                  </div>
                </div>
              </div>

              <div className='mt-10'>
                <p className='text-center'>También puedes disfrutar de los entrenamientos a través de nuestra app, en caso de ya tener un programa previo, borra el app y vuelvela a bajar para que nuevo programa cargue correctamente.</p>
                <div className='flex justify-center mt-6'>
                  <StoreApps />
                </div>
              </div>

              <div className='bg-Magnolia mt-14 p-6 md:py-8 md:px-10 rounded-lg flex flex-col justify-center items-center'>
                <p className='text-center mt-2'>A continuación encontrarás la información de tu cuenta:</p>
                <div className='flex flex-col gap-4 md:gap-2 mt-6 text-center md:text-left'>
                  <div className='flex flex-col md:flex-row items-center md:gap-4'>
                    <p className='font-golos-semibold whitespace-nowrap md:min-w-[90px]'>Nombre:</p>
                    <p className='flex-1'>{user?.fullName || user?.firstName}</p>
                  </div>
                  <div className='flex flex-col md:flex-row items-center md:gap-4'>
                    <p className='font-golos-semibold whitespace-nowrap md:min-w-[90px]'>Correo:</p>
                    <p className='flex-1'>{user?.email}</p>
                  </div>
                  <div className='flex flex-col md:flex-row items-center md:gap-4'>
                    <p className='font-golos-semibold whitespace-nowrap md:min-w-[90px]'>Programa:</p>
                    <p className='flex-1'>{suscripcion_seleccionada?.nombre} - <span className='capitalize'>{suscripcion_seleccionada?.plan}</span></p>
                  </div>
                  <div className='flex flex-col md:flex-row items-center md:gap-4'>
                    <p className='font-golos-semibold whitespace-nowrap md:min-w-[90px]'>Costo:</p>
                    {state.suscripcion?.isTrial === true ? (
                      <p className='flex-1'>${parseFloat(state.suscripcion?.initialPayment)?.toFixed(2)} (Prueba de 7 días). <span className='underline'>Después ${parseFloat(state.suscripcion?.billingAmount)?.toFixed(2)} {interval_cycle}.</span></p>
                    ) : (state.suscripcion?.couponID ? (
                      <p className='flex-1'>${parseFloat(state.suscripcion?.initialPayment)?.toFixed(2)} (Cupón: {state.suscripcion?.couponCode}) {interval_cycle}</p>
                    ) : (
                      <p className='flex-1'>${parseFloat(state.suscripcion?.initialPayment)?.toFixed(2)} {state.suscripcion?.initialPayment !== state.suscripcion?.billingAmount ? <span className='underline'>Después ${parseFloat(state.suscripcion?.billingAmount)?.toFixed(2)} {interval_cycle}.</span> : interval_cycle}</p>
                    ))}
                  </div>
                </div>
                <div className='text-center mt-7 space-y-1'>
                  <p className='text-muted text-gris-9A9A9A'>Puedes cancelar en cualquier momento.</p>
                  <p className='text-muted text-gris-9A9A9A'>Recibo #{state.suscripcion?.invoiceCode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Pagado;