import QueryString from "qs";
import axios from "../";
const prefix = "/favorites";

export const getFavorites = async (params) => {
  try {
    const _params = QueryString.stringify(params);
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createFavorite = async (params) => {
  try {
    const response = await axios.post(`${prefix}`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFavorite = async (id) => {
  try {
    const response = await axios.delete(`${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};