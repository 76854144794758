import QueryString from "qs";
import axios from "../";
const prefix = "/admin/descargables";

export const getDescargables = async (params) => {
  try {
    const _params = QueryString.stringify({
      populate: ["programa"],
      ...params,
    });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createDescargable = async (params, options) => {
  try {
    const response = await axios.post(`${prefix}/nuevo-descargable`, params, {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
      onUploadProgress: progressEvent => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        if (options?.onUploadProgress) options?.onUploadProgress(percentComplete);
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDescargable = async (id, params, options) => {
  try {
    const response = await axios.put(`${prefix}/${id}`, params, {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
      onUploadProgress: progressEvent => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        if (options?.onUploadProgress) options?.onUploadProgress(percentComplete);
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDescargable = async (id) => {
  try {
    const response = await axios.delete(`${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};