import React, { memo, useEffect, useRef } from 'react';
import { Image } from 'primereact/image';
import { ButtonPrimary } from '../../components/Button';
import { externalLinks } from '../../config/app';
import intersectionObserver from '../../utils/intersectionObserver';

const EvaluacionPersonalizada = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className='sm:bg-Orchid mt-8 sm:mt-14 relative rounded-b-xl md:rounded-b-none mx-6 mb-10 sm:mx-0 sm:mb-0'>
      <div className="bg-inherit rounded-b-xl sm:rounded-b-none xl:container mx-auto sm:px-8">
        <div className='bg-inherit rounded-b-xl sm:rounded-b-none flex flex-wrap sm:flex-nowrap items-center justify-center sm:gap-3 md:gap-10'>
          <div ref={(el) => elementsRef.current.push(el)} className='flex self-end animate__animated -mt-[2.5em]' data-animation={"animate__fadeIn"} style={{ minWidth: 320 }}>
            <Image src={require("../../assets/images/home/banner-img2.png")} alt="portada banner" imageClassName='w-full sm:max-w-[320px] md:max-w-[760px] lg:max-w-[920px] xl:max-w-none' />
          </div>
          <div className='bg-Orchid rounded-b-xl sm:rounded-b-none flex flex-col gap-2 py-8 px-6 sm:px-0 w-full'>
            <div className='text-center lg:text-left lg:max-w-[520px] mx-auto sm:mx-0'>
              <h1 ref={(el) => elementsRef.current.push(el)} data-animation={"animate__slideInDown"} className='text-DeepKoamaru text-2xl sm:text-3xl lg:text-4xl mb-2 text-center lg:text-left animate__animated'>
                Comienza a cuidar de tu <span className='font-golos-bold'>salud emocional</span>
              </h1>
            </div>
            <div className='flex flex-col lg:flex-row items-center gap-8'>
              <p ref={(el) => elementsRef.current.push(el)} data-animation={"animate__slideInUp"} className='lg:max-w-[300px] xl:max-w-[260px] text-white text-[14.767px] lg:text-[16px] text-center lg:text-left animate__animated'>En OpcionYo tu paz interior es nuestra prioridad. <span className='font-golos-bold'>Terapia online recomendada por Jimena Cantú</span></p>
              <ButtonPrimary
                text="Terapia online"
                withIcon
                theme="white"
                onClick={() => window.location.href = externalLinks.opcionYo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(EvaluacionPersonalizada);