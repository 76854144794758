import axios from "../";
const prefix = "/admin/equipo_sugerido";

export const getEquipoSugerido = async () => {
  try {
    const response = await axios.get(prefix);
    return response.data;
  } catch (error) {
    throw error;
  }
};