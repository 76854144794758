import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import es from 'dayjs/locale/es';
import { isEmpty } from '.';

// Establece la zona horaria por defecto
const default_timezone = 'America/Mexico_City';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(es);
dayjs.tz.setDefault(default_timezone);

/**
 * Función para obtener una instancia de Day.js con o sin zona horaria específica.
 *
 * @param {string} date - La fecha que deseas convertir.
 * @param {Object} options - Opciones para personalizar la conversión.
 * @param {string} options.tz - Zona horaria a utilizar. Si no se proporciona, se utiliza la zona horaria por defecto.
 * @param {boolean} options.useCustomDayjs - Un booleano que controla si se utiliza una instancia personalizada de Day.js.
 * @param {Object} options.dayjs - Instancia personalizada de Day.js. Solo se utiliza si options.useCustomDayjs es true.
 * @param {boolean} options.noTz - Un booleano que controla si se devuelve una instancia de Day.js sin la extensión .tz.
 * @returns {dayjs} - Una instancia de Day.js con la fecha y zona horaria especificadas (o sin .tz si se establece noTz en true).
 * @example
 * // Usar la zona horaria por defecto
 * const date = dayjs_custom('2023-10-16 12:00:00');
 * 
 * // Usar una zona horaria específica
 * const dateInNewYork = dayjs_custom('2023-10-16 12:00:00', { tz: 'America/New_York' });
 * 
 * // Utilizar una instancia de Day.js personalizada
 * const customDayjs = dayjs();
 * const customDate = dayjs_custom('2023-10-16 12:00:00', { useCustomDayjs: true, dayjs: customDayjs });
 * 
 * // Obtener una instancia de Day.js sin .tz
 * const dateWithoutTz = dayjs_custom('2023-10-16 12:00:00', { noTz: true });
 */
export function dayjs_custom(date, { tz, useCustomDayjs, dayjs: _dayjs, noTz = false, isUTC = false, fromFormat = null } = {}) {
  const _date = dayjs(isEmpty(date) ? new Date() : date).format("YYYY-MM-DD HH:mm:ss");
  const _tz = tz || default_timezone;

  if (fromFormat) date = dayjs(date).format(fromFormat);

  try {
    if (isUTC) {
      return dayjs.utc(isEmpty(date) ? new Date() : date);
    } else if (noTz) {
      return (useCustomDayjs && _dayjs) ? _dayjs(_date) : dayjs(_date);
    } else {
      return (useCustomDayjs && _dayjs) ? _dayjs.tz(_date, _tz) : dayjs.tz(_date, _tz);
    }
  } catch (error) {
    return null;
  }
}

export default dayjs.tz;
export { dayjs };