import QueryString from "qs";
import axios from "../";
const prefix = "/admin/productos";

export const getProductos = async (params) => {
  try {
    const _params = QueryString.stringify({
      populate: ["programa"],
      ...params,
    });
    const response = await axios.get(`${prefix}?${_params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProducto = async (params) => {
  try {
    const response = await axios.post(`${prefix}/nuevo-producto`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProducto = async (id, params) => {
  try {
    const response = await axios.put(`${prefix}/${id}`, params, {
      maxBodyLength: Infinity,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProducto = async (id) => {
  try {
    const response = await axios.delete(`${prefix}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};