import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { exportCompras, getCompras } from '../../../api/admin/compras';
import { getProgramas } from '../../../api/admin/programas';
import { paginationComponentOptions, Checkbox, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
import downloadFile from '../../../utils/downloadFile';
import onSearchFields from '../../../utils/onSearchFields';
import { isEmpty } from '../../../utils';

const Compras = () => {
  const op = useRef(null);
  const [state, setState] = useCustomState({
    exports_loading: false,
    sending: false,
    loading: true,
    buscador: "",
    statusSelected: null,
    fechaAdquisicionSelected: null,
    programSelected: [],
    selectedRows: [],
    compras: [],
    programas: [],
  });
  // const isDatesSelected = !isEmpty(state.fechaAdquisicionSelected?.[0]) && !isEmpty(state.fechaAdquisicionSelected?.[1]) ? state.fechaAdquisicionSelected.join("-") : false;

  const { filters } = useMemo(() => ({
    filters: {
      status: state.statusSelected,
      fecha_adquisicion: (!isEmpty(state.fechaAdquisicionSelected?.[0]) && !isEmpty(state.fechaAdquisicionSelected?.[1])) ? state.fechaAdquisicionSelected : null,
      programa: state.programSelected,
    }
  }), [state.fechaAdquisicionSelected, state.statusSelected, state.programSelected]);

  useEffect(() => {
    loadData(filters);
    // eslint-disable-next-line
  }, [filters]);

  const loadData = useCallback(async (_filters) => {
    setState({ loading: true });
    const compras = await getCompras(null, _filters);
    const programas = await getProgramas();
    setState({
      compras: compras?.data,
      programas: programas?.data,
      loading: false,
    });
  }, [setState]);

  const { columnsTable, filteredItems } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Folio',
        sortable: false,
        fixed: 'left',
        minWidth: '180px',
        cell: row => row?.invoiceCode
      },
      {
        name: 'Nombre',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.User.Client?.fullName),
        cell: row => (row.User.Client?.fullName)
      },
      {
        name: 'Correo electrónico',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => (row.User.Client?.email),
        cell: row => (row.User.Client?.email)
      },
      {
        name: 'Transacciones',
        sortable: false,
        fixed: 'left',
        minWidth: '180px',
        cell: row => {
          return (
            <div className='py-2 space-y-2'>
              <div>
                <p className='font-golos-semibold'>Pago:</p>
                <span>{row.paymentTransaction || "N/A"}</span>
              </div>
              <div>
                <p className='font-golos-semibold'>Suscripción:</p>
                <span>{row.subscriptionTransaction || "N/A"}</span>
              </div>
            </div>
          )
        }
      },
      {
        name: 'Código de cupón',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.couponCode;
        },
        cell: row => {
          return row?.couponCode;
        },
      },
      {
        name: 'Periodo de prueba',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.isTrial === true ? "Si" : "No";
        },
        cell: row => {
          return row?.isTrial === true ? "Si" : "No";
        },
      },
      {
        name: 'Suscripción manual',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.isInternal === true ? "Si" : "No";
        },
        cell: row => {
          return row?.isInternal === true ? "Si" : "No";
        },
      },
      {
        name: 'Pasarela de cobro',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.paymentType;
        },
        cell: row => {
          return row?.paymentType;
        },
      },
      {
        name: 'Programa',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        selector: row => {
          return (`${row.Program?.name}${row?.typePlan ? " - " + row?.typePlan : ""}`);
        },
        cell: row => {
          return (`${row.Program?.name}${row?.typePlan ? " - " + row?.typePlan : ""}`);
        },
      },
      {
        name: 'Precio',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        selector: row => {
          return row?.initialPayment;
        },
        cell: row => {
          return `$${row?.initialPayment}`;
        },
      },
      {
        name: 'Estado',
        sortable: true,
        fixed: 'left',
        minWidth: '130px',
        selector: row => {
          if (row?.status) {
            if (row.status === "paid") return "Pagado";
            if (row.status === "hold") return "En espera";
            if (row.status === "rejected") return "Rechazada";
            if (row.status === "cancelled") return "Cancelada";
          } else {
            return "";
          }
        },
        cell: row => {
          if (row?.status) {
            if (row.status === "paid") return <span className='bg-green-600 text-white rounded-lg py-2 px-4'>Pagado</span>;
            if (row.status === "hold") return <span className='bg-amber-500 text-white rounded-lg py-2 px-4'>En espera</span>;
            if (row.status === "rejected") return <span className='bg-red-500 text-white rounded-lg py-2 px-4'>Rechazada</span>;
            if (row.status === "cancelled") return <span className='bg-red-600 text-white rounded-lg py-2 px-4'>Cancelada</span>;
          }
          return "";
        },
      },
      {
        name: 'Fecha de adquisición',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.createdAt && dayjs(row.createdAt).format("DD/MM/YYYY");
        },
        cell: row => {
          return row?.createdAt && dayjs(row.createdAt).format("DD/MM/YYYY");
        },
      },
      {
        name: 'Fecha de vigencia',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.dateExpirationEnd && dayjs(row.dateExpirationEnd).format("DD/MM/YYYY");
        },
        cell: row => {
          return row?.dateExpirationEnd && dayjs(row.dateExpirationEnd).format("DD/MM/YYYY");
        },
      },
      {
        name: 'Fecha de cancelación',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.cancelledAt && dayjs(row.cancelledAt).format("DD/MM/YYYY");
        },
        cell: row => {
          return row?.cancelledAt && dayjs(row.cancelledAt).format("DD/MM/YYYY");
        },
      },
    ],
    filteredItems: onSearchFields(state?.compras, {
      searchText: state.buscador,
      fieldsToSearch: ['invoiceCode', 'couponCode', 'paymentType', 'User.Client.fullName', 'User.Client.email'],
    })
  }), [state.compras, state.buscador]);

  const descargarReporte = useCallback(async () => {
    setState({ exports_loading: true });

    try {
      const response = await exportCompras({ ...filters, id: state.selectedRows?.map(item => (item?.id)) });
      await downloadFile(response, 'reporte_compras.xlsx');
      setState({ exports_loading: false });
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      setState({ exports_loading: false });
    }
  }, [state.selectedRows, filters, setState]);

  return (
    <>
      <SEO
        title="Compras"
      />
      <OverlayPanel
        ref={op}
        showCloseIcon={false}
        className={"shadow-sm shadow-[#ddd] max-w-[95%] mx-4"}
      >
        <div className='divide-y divide-[#ddd] space-y-4 text-center sm:text-left'>
          <div className='flex flex-col sm:flex-row items-center sm:items-start gap-4'>
            <p className='sm:min-w-[140px]'>Estado</p>
            <div className='flex flex-1 flex-wrap gap-6'>
              <label className='inline-flex items-center c-pointer text-sm'>
                <input
                  type="checkbox"
                  className="check-input-custom !cursor-pointer rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                  checked={state.statusSelected === "paid"}
                  onChange={(event) => setState({ statusSelected: event.target.checked ? "paid" : null })}
                />
                <span className='pl-3'>Pagado</span>
              </label>
              <label className='inline-flex items-center c-pointer text-sm'>
                <input
                  type="checkbox"
                  className="check-input-custom !cursor-pointer rounded-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                  checked={state.statusSelected === "cancelled"}
                  onChange={(event) => setState({ statusSelected: event.target.checked ? "cancelled" : null })}
                />
                <span className='pl-3'>Cancelada</span>
              </label>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Fecha de compra</p>
            <div className='flex flex-1 flex-col sm:flex-row items-center gap-2'>
              <input
                type="date"
                className="rounded-md border-gris-F7F7F7 text-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                onChange={(event) => setState({ fechaAdquisicionSelected: [(event.target.value && dayjs(event.target.value)?.toISOString()), state.fechaAdquisicionSelected?.[1]] })}
                value={(!isEmpty(state.fechaAdquisicionSelected?.[0])) ? dayjs(state.fechaAdquisicionSelected?.[0]).format("YYYY-MM-DD") : ""}
              />
              <span className='text-[#aaa] rotate-90 sm:rotate-0'>—</span>
              <input
                type="date"
                className="rounded-md border-gris-F7F7F7 text-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                onChange={(event) => setState({ fechaAdquisicionSelected: [state.fechaAdquisicionSelected?.[0], (event.target.value && dayjs(event.target.value)?.toISOString())] })}
                value={(!isEmpty(state.fechaAdquisicionSelected?.[1])) ? dayjs(state.fechaAdquisicionSelected?.[1]).format("YYYY-MM-DD") : ""}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Programas</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {state.programas?.map(item => (
                <div
                  key={`programa-key-${item?.id}`}
                  className={`${state.programSelected?.includes(item?.id) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    const updatedProgramsSelected = state.programSelected?.includes(item?.id)
                      ? state.programSelected?.filter(program => program !== item?.id)
                      : [...state.programSelected, item?.id];

                    setState({ programSelected: updatedProgramsSelected });
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OverlayPanel>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col sm:flex-row items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Compras</h1>
                <div className='inline-flex flex-col xs:flex-row gap-3'>
                  <input
                    type="search"
                    placeholder='Buscar'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-2 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.buscador}
                    onChange={(event) => setState({ buscador: event.target.value })}
                  />
                  <div className='flex-1 inline-flex justify-center flex-wrap xs:flex-nowrap gap-3'>
                    <Button
                      type="button"
                      className='flex items-center font-golos-semibold text-sm border-transparent p-button bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru text-AntiFlashWhite !py-2 !px-4 overflow-visible'
                      icon={<i className="pi pi-filter" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                      label="Filtros"
                      onClick={(e) => op.current.toggle(e)}
                    />
                    <ButtonCustom
                      text="Descargar .xls"
                      icon={<i className="pi pi-download" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                      loading={state.exports_loading}
                      classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru min-h-[38px]"
                      onClick={descargarReporte}
                    />
                  </div>
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader={false}
                  selectableRows
                  onSelectedRowsChange={({ selectedRows }) => setState({ selectedRows })}
                  selectableRowsComponent={Checkbox}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default Compras;