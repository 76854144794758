import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { areEqual } from 'react-window';
import { Image } from "primereact/image";
import { getPosts } from "../../api/client/blog";
import Loading from "../../components/Loading";
import { ReactComponent as DropDownIcon } from "../../assets/icons/dropdown-icon.svg";
import { useCustomState } from "../../hooks/useCustomState";
import { dayjs_custom } from "../../utils/dayjs_custom";
import truncate from "../../utils/truncate";
import { colors } from "../../config/colors";

const Row = memo(({ item }) => {
  return (
    <div
      className="flex flex-col gap-2 bg-white rounded-xl px-5 py-4"
    >
      <Image
        src={item?.image}
        alt={item?.name}
        loading='lazy'
        imageClassName="object-cover rounded-md h-[220px]"
        width="100%"
      />
      <div className="flex flex-col flex-1 mt-1 w-full">
        <p className="font-golos-medium text-Crayola text-2xl">{item?.name}</p>
        <p className="font-golos text-sm mt-1">{truncate(item?.summary, 186)}</p>
      </div>
      <div className="flex flex-row flex-1 mt-4 mb-2 w-full">
        <p className="flex-1 font-golos text-sm">Publicado {dayjs_custom(item?.createdAt).format("DD/MM/YYYY")}</p>
        <Link to={`/blog/${item?.slug}`} className="font-golos-medium text-Crayola text-sm mt-1 underline">Continuar leyendo</Link>
      </div>
    </div>
  );
}, areEqual);

const BlogEntradas = ({ search, onEndSearch }) => {
  const [state, setState] = useCustomState({
    loading: true,
    loadingMore: false,
    posts: [],
    page: 1,
    pageSize: 5,
    totalPages: null,
  });

  const { paramsApi } = useMemo(() => {
    const paramsApi = {
      page: state.page,
      pageSize: state.pageSize,
      filters: {
        name: search,
      }
    }

    return { paramsApi };
    // eslint-disable-next-line
  }, [search, state.pageSize]);

  useEffect(() => {
    loadData(paramsApi);

    // eslint-disable-next-line
  }, [paramsApi]);

  const loadData = useCallback(async (params, isLoadMore) => {
    const posts = await getPosts(params);

    setState({
      posts: isLoadMore?.loadMore ? [...isLoadMore?.prevPosts, ...posts?.data] : posts?.data,
      totalPages: posts?.totalPages,
      loading: false
    });
    if (onEndSearch) onEndSearch({ isSearching: false });
  }, [onEndSearch, setState]);

  const loadMore = useCallback(async () => {
    const newPage = parseInt(state.page || 1) + 1;
    setState({ page: newPage, loadingMore: true });
    await loadData({ ...paramsApi, page: newPage }, { loadMore: true, prevPosts: state.posts });
    setState({ loadingMore: false });
  }, [state.posts, paramsApi, state.page, setState, loadData]);

  return (
    <>
      {(state.loading) ? (
        <div className="w-full mt-8">
          <Loading />
        </div>
      ) : (
        <div className="w-full mt-8">
          {state.posts?.length > 0 ? (
            <div className="flex flex-col gap-4">
              {state.posts?.map((item, index) => (
                <Row
                  key={`post-id-${item?.id}`}
                  item={item}
                  index={index}
                />
              ))}
              {state.page < state.totalPages && (
                <div className="flex items-center justify-center">
                  {state.loadingMore ? (
                    <Loading containerClassName={"mt-4"} />
                  ) : (
                    <div className="inline-flex flex-row items-center justify-center gap-1 py-2 px-4 mt-2 c-pointer active:scale-90 transition ease-in-out" onClick={loadMore}>
                      <p className="font-golos-semibold text-Crayola text-md text-center">Cargar más entradas</p>
                      <DropDownIcon fill={colors.Crayola} height={32} width={32} />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center w-full">
              <p className="font-golos-medium text-center text-Crayola">No se encontraron resultados.</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default memo(BlogEntradas, areEqual);