import React from 'react';
import { useParams } from 'react-router-dom';
import NoEncontrado from '../Errors/404';
// import ProgramaPrincipal from './Embarazo';
import ProgramaEmbarazo from './Embarazo';
import ProgramaPosparto from './Posparto';
import ProgramaStrength from './Strength';

const ProgramasIndex = () => {
  const { name } = useParams();
  const programasDisponibles = ['fitmom-embarazo', 'fitmom-posparto', 'fitmom-strength'];

  return (
    <>
      {name && programasDisponibles.includes(name) ? (
        <>
          {name === "fitmom-embarazo" && <ProgramaEmbarazo />}
          {name === "fitmom-posparto" && <ProgramaPosparto />}
          {name === "fitmom-strength" && <ProgramaStrength />}
        </>
      ) : (
        <>
          {/* {!name && <ProgramaPrincipal />} */}
          {/* {name && <NoEncontrado />} */}
          {<NoEncontrado />}
        </>
      )}
    </>
  );
};

export default ProgramasIndex;