import React from "react";
import { Image } from "primereact/image";

const EspecialistaCertificada = () => (
  <div className='flex justify-center items-center mt-16'>
    <div className='inline-flex bg-gradient-to-t from-Magnolia to-white px-10 pb-7 rounded-2xl mx-auto'>
      <div className='flex flex-col md:flex-row items-center gap-4 mt-10'>
        <div className='text-center md:text-left md:max-w-[220px]'>
          <h1 className='font-golos-semibold text-2xl text-DeepKoamaru mb-2'>Especialista certificada</h1>
          <p className='text-sm text-DarkJungleGreen'>Ejercicios Correctivos Pre y Posnatales y Consultora de Diástasis y del Core.</p>
        </div>
        <div className='py-4'>
          <Image
            src={require("../../assets/images/logos_badges_Feb_2022.png")}
            alt="Logo Certificados"
            imageClassName='w-full mx-auto select-none pointer-events-none'
            imageStyle={{ maxWidth: 540 }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default EspecialistaCertificada;