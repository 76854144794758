import React, { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SEO from '../../components/SEO';
import GlobalContext from '../../contexts/GlobalContext';

import { ButtonCustom } from '../../components/Button';
import { Container, Icon, Title, Message } from './styles';

function NoEncontrado({ hideButtons }) {
  const { setModal } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { isAuthenticated: isAuthenticatedUser } = useSelector(state => state.app.auth);
  const { isAuthenticated: isAuthenticatedAdmin } = useSelector(state => state.admin.auth);
  const isAdmin = (document.location?.pathname)?.includes("/admin");

  const onLogin = useCallback(() => {
    if (isAdmin) {
      navigate("/admin/login");
    } else {
      setModal("login", navigate);
    }
  }, [isAdmin, navigate, setModal]);

  return (
    <>
      <SEO title="Página no encontrada" />
      <div style={Container}>
        <Icon />
        <h1 style={Title}>Oops!</h1>
        <p style={Message}>
          Lo sentimos, la página que estás buscando no existe.
        </p>
        {!hideButtons && (
          <>
            {(isAuthenticatedUser && isAuthenticatedAdmin) ? (
              <Link
                to={isAdmin ? "/admin" : "/"}
                className='font-golos-medium text-sm py-2 px-5 bg-Crayola hover:!bg-CrayolaHover border !border-Crayola text-white rounded-lg transition-all'
              >
                Ir a la página principal
              </Link>
            ) : (
              <ButtonCustom
                text="Iniciar sesión"
                classNameButton="bg-Crayola hover:!bg-CrayolaHover border !border-Crayola text-white rounded-lg transition-all"
                onClick={onLogin}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
export default NoEncontrado;