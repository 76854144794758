export const responsiveOptionsCarousel = [
  {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
  },
  {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
  },
  {
      breakpoint: '640px',
      numVisible: 1,
      numScroll: 1
  }
];